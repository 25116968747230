import React from 'react';
import { Skeleton } from '@biss/react-horizon-web';

function RecipeOptimizationTransferSkeleton() {
  return (
    <div data-testid="RecipeOptimizationTransferSkeleton" className="flex flex-col gap-4 p-2">
      <div className="col flex flex-col gap-1">
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" width="50%" />
      </div>

      <div className="flex flex-col gap-2">
        <Skeleton variant="text" width="50%" />
        <Skeleton variant="rectangular" />
      </div>

      <div className="flex flex-col gap-2">
        <Skeleton variant="text" width="50%" />
        <Skeleton variant="rectangular" />
      </div>

      <div className="flex flex-col gap-2">
        <Skeleton variant="text" width="50%" />
        <Skeleton variant="rectangular" />
      </div>

      <Skeleton variant="rectangular" className="mt-4" />
    </div>
  );
}

export default RecipeOptimizationTransferSkeleton;
