import React, { FunctionComponentElement, useEffect } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { ActivityIndicator, Button, PlusIcon } from '@biss/react-horizon-web';
import { useLocalStorage } from 'usehooks-ts';

import { ProcessRecordId } from '../../../../shared/common/types/process-record';
import ProcessRecordModal from '../../../components/process-record-modal/process-record-modal';
import { ErrorMessage } from '../../../../shared/components/error-message';
import { GenericError } from '../../../../shared/common/types/generic-error';
import { ApiError } from '../../../../shared/common/types/api-error/api-error';

import {
  ReferenceCurveSelectorHeaderProps,
  ReferenceCurveSelectorProps,
} from './reference-curve-selector.definitions';
import RemoveButton from './remove-button';

export function ReferenceCurveSelectorHeader({
  children,
  isError,
  error,
  onRemoveReferenceCurve,
}: ReferenceCurveSelectorHeaderProps): FunctionComponentElement<ReferenceCurveSelectorHeaderProps> {
  const showRemoveButton = !!onRemoveReferenceCurve;
  return (
    <>
      <div className="flex flex-row items-center justify-between text-textGray">
        <div
          className={`${
            showRemoveButton ? 'mr-2 w-full' : 'flex w-full flex-row items-center justify-between'
          }`}
        >
          <div className="font-bold">
            <FormattedMessage
              description="Reference Curve selector: Title"
              defaultMessage="Reference Curve"
              id="XmOBdP"
            />
          </div>
          {children}
        </div>
        {showRemoveButton && <RemoveButton onClick={onRemoveReferenceCurve} />}
      </div>
      {isError && error && (
        <div className="mt-2">
          <ErrorMessage
            message="An internal error occurred while loading the process record. Please wait a moment and try to add a reference again."
            error={error as GenericError<ApiError>}
            variant="reduced"
          />
        </div>
      )}
    </>
  );
}

function ReferenceCurveSelector({
  referenceProcessRecord,
  setupId,
  isLoading,
  isError,
  error,
  onReferenceProcessRecord,
}: ReferenceCurveSelectorProps): FunctionComponentElement<ReferenceCurveSelectorProps> | null {
  const storageKey = `monitoring:${setupId}:referenceCurve`;
  const [referenceProcessRecordId, setReferenceProcessRecordId] = useLocalStorage(storageKey, '');
  const onSelectReferenceCurve = (processRecordId: ProcessRecordId) => {
    setReferenceProcessRecordId(processRecordId);
  };

  const onRemoveReferenceCurve = () => {
    setReferenceProcessRecordId('');
  };

  useEffect(() => {
    onReferenceProcessRecord(referenceProcessRecordId);
  }, [referenceProcessRecordId]);

  // no reference process record selected or error
  if (!referenceProcessRecordId || (isError && error)) {
    return (
      <ReferenceCurveSelectorHeader isError={isError} error={error}>
        <ProcessRecordModal
          trigger={
            <Button data-testid="select-reference-curve-button" leftIcon={<PlusIcon />}>
              <FormattedMessage
                description="Reference Curve selector: Button to add a reference curve"
                defaultMessage="Add Reference"
                id="QD7fOc"
              />
            </Button>
          }
          onSelect={onSelectReferenceCurve}
        />
      </ReferenceCurveSelectorHeader>
    );
  }

  // loading without data
  if (isLoading && !referenceProcessRecord && !isError) {
    return (
      <ReferenceCurveSelectorHeader>
        <ActivityIndicator showLabel size="sm" />
      </ReferenceCurveSelectorHeader>
    );
  }

  // selected curve
  return (
    <ReferenceCurveSelectorHeader onRemoveReferenceCurve={onRemoveReferenceCurve}>
      <FormattedMessage
        description="Reference Curve selector: display of selected process record"
        defaultMessage="{title} ({start} - {stop})"
        id="4JKnoI"
        values={{
          title: referenceProcessRecord?.title,
          start: <FormattedDate value={referenceProcessRecord?.startTimestamp} />,
          stop: <FormattedDate value={referenceProcessRecord?.stopTimestamp} />,
        }}
      />
    </ReferenceCurveSelectorHeader>
  );
}

export default ReferenceCurveSelector;
