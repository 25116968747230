import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { Uuid } from '../../../../shared/common/types/uuid';
import { joinPaths } from '../../../../shared/utils';
import { projectConfigInstance } from '../../../../shared/globals/project-config/project-config-accessor';
import { ProcessRecordOptimizationVariable } from '../../types/process-record-optimization-variables';

export function createRecipeOptimizationVariableMappingUrl(processRecordId: Uuid): string {
  return joinPaths(
    projectConfigInstance.get('ROS_BACKEND_URL'),
    'process-records/',
    processRecordId,
    'variablesMapping',
  );
}

export async function mapVariables(
  data: ProcessRecordOptimizationVariable[],
  processRecordId: Uuid,
  accessToken: string,
): Promise<void> {
  // filter out all the empty mappings
  const optimizedData = data.filter((mapping) =>
    mapping.type === 'attribute' ? mapping.attributeName : mapping.dataTrackId,
  );

  await fetchData(createRecipeOptimizationVariableMappingUrl(processRecordId), {
    method: HttpMethods.Put,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(optimizedData),
  });
}
