import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Roles, useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { Member } from '../../types';

import { putMember } from './use-update-member.helpers';

function useUpdateMember() {
  const queryClient = useQueryClient();

  const { acquireAccessToken } = useAuthentication();

  return useMutation<
    void,
    unknown,
    { memberId: string; roles: Roles; mfa: boolean },
    { previousMember: Member; newMember: Member }
  >({
    mutationFn: async (member) => {
      const accessToken = await acquireAccessToken();
      await putMember(member.memberId, member.roles, member.mfa, accessToken);
    },
    // When mutate is called:
    onMutate: async (member) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [QKey.MEMBERS, member.memberId] });

      // Snapshot the previous value
      const previousMember = queryClient.getQueryData([QKey.MEMBERS, member.memberId]) as Member;

      const newMember = {
        ...previousMember,
        memberRoles: member.roles,
      };
      // Optimistically update to the new value
      queryClient.setQueryData([QKey.MEMBERS, member.memberId], newMember);

      // Return a context with the previous and new member
      return { previousMember, newMember };
    },
    // If the mutation fails, use the context we returned above
    onError: (_1, _2, context) => {
      queryClient.setQueryData(
        [QKey.MEMBERS, context?.newMember.memberId],
        context?.previousMember,
      );
    },
    // Always refetch after error or success:
    onSettled: (_1, _2, _3, context) => {
      queryClient.invalidateQueries({ queryKey: [QKey.MEMBERS, context?.newMember.memberId] });
      queryClient.invalidateQueries({ queryKey: [QKey.MEMBERS] });
    },
  });
}

export default useUpdateMember;
