import { z } from 'zod';

import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { Uuid } from '../../../../shared/common/types/uuid';
import { joinPaths } from '../../../../shared/utils';
import { projectConfigInstance } from '../../../../shared/globals/project-config/project-config-accessor';

export function createRecipeOptimizationTransferUrl(processRecordId: Uuid): string {
  return joinPaths(
    projectConfigInstance.get('ROS_BACKEND_URL'),
    'process-records/',
    processRecordId,
    'transfers',
  );
}

function isExperimentResponse(response: unknown) {
  const schema = z.object({
    experimentId: z.string().uuid(),
  });

  const parsed = schema.safeParse(response);

  if (parsed.success) {
    return parsed.data;
  }

  throw new Error('The received experiment response is invalid');
}

export async function transferProcessRecord(
  processRecordId: Uuid,
  accessToken: string,
): Promise<{ experimentId: string }> {
  const result = await fetchData(createRecipeOptimizationTransferUrl(processRecordId), {
    method: HttpMethods.Post,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return isExperimentResponse(result);
}
