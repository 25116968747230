// eslint-disable-next-line eslint-comments/disable-enable-pair -- Necessary to disable transalation warnings
/* eslint-disable formatjs/no-literal-string-in-jsx -- Only available in english */
import React, { FunctionComponentElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

/**
 * Scene to show when a user tries to open a route that is not available.
 *  It allows the user to redirect to the base scene.
 */
function Imprint(): FunctionComponentElement<unknown> {
  return (
    <div data-testid="Imprint">
      <div className="p-8">
        <h1 className="mb-4 text-2xl font-bold">Imprint</h1>
        <p>
          DASGIP Information and Process Technology GmbH <br />
          an Eppendorf Company
          <br />
          Rudolf-Schulten-Str. 5<br />
          52428 Juelich, Germany
          <br />
          Phone: +49 2461 980 0<br />
          DASGIP&#64;dasgip&#46;de
          <br />
          <a
            href="https://www.eppendorf.com"
            title="Eppendorf Website"
            rel="noreferrer"
            target="_blank"
          >
            www.eppendorf.de
          </a>
          <br />
        </p>
        <p className="mt-4">
          <b>Chairman of the Supervisory Board:</b>
          <br />
          Philipp von Loeper
          <br />
          <br />
          Turnover tax identification number pursuant to § 27 a Turnover Tax Law: DE 118649726
          <br />
          <br />
          <b>Responsible for content pursuant to § 55, 2 Rundfunk-Staatsvertrag:</b>
          <br />
          Dr. Ralph Esper,&nbsp;Barkhausenweg 1, 22339 Hamburg, Germany
          <br />
          <br />
          <b>Liability</b>
          <br />
          Despite careful control of the content we are not liable for the content of external
          links.
          <br />
          The owners of the external sites are responsible for the content of the linked websites.
        </p>
        <div className="mt-8">
          <Link to="/" id="Base" data-testid="Imprint-homelink">
            <FormattedMessage
              description="Link to the Home Page"
              defaultMessage="Go Back Home"
              id="knhtvR"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Imprint;
