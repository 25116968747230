import React, { FunctionComponentElement } from 'react';
import { HeaderBar as Header } from '@biss/react-horizon-web';
import { useNavigate } from 'react-router-dom';

import { SendFeedback } from '../send-feedback';
import AccountBar from '../account-bar';
import RouteDefinition from '../../common/routes/routes.definitions';

import { Navigation } from './navigation';
import MiniMenu from './mini-menu';

/**
 * Simple header bar for the biocloud
 */
function HeaderBar(): FunctionComponentElement<unknown> {
  const navigate = useNavigate();
  const handleLogoClick = () => {
    navigate(RouteDefinition.Welcome);
  };

  return (
    <Header navigation={<Navigation />} onLogoClick={handleLogoClick} tail={<AccountBar />}>
      <SendFeedback />
      <MiniMenu />
    </Header>
  );
}

export default HeaderBar;
