import React, { FunctionComponentElement } from 'react';
import { Tile } from '@biss/react-horizon-web';

import {
  SHORTCUTS,
  Y_AXIS_TIP,
} from '../../../../../shared/components/time-series-chart/time-series-chart.definitions';

import { CombinedChart, SplitChart } from '../../../../../shared/charts';

import PlaceholderChart from '../../../../../shared/charts/placeholder-chart';

import { SetupTimeSeriesChartVisualizationProps } from './setup-time-series-chart-visualization.definitions';

function SetupTimeSeriesChartVisualization({
  startTimestamp,
  stopTimestamp,
  seriesMarkLines,
  seriesData,
  yAxisRanges,
  setYAxisRanges,
}: SetupTimeSeriesChartVisualizationProps): FunctionComponentElement<SetupTimeSeriesChartVisualizationProps> | null {
  if (!startTimestamp) {
    return null;
  }

  if (!seriesData?.length) {
    return <PlaceholderChart />;
  }

  return (
    <div>
      <CombinedChart
        data={seriesData}
        startTime={startTimestamp}
        stopTime={stopTimestamp}
        markLines={seriesMarkLines}
        defaultYAxisRange={yAxisRanges}
        onYAxisRangeChange={setYAxisRanges}
      />
      <br />
      <SplitChart
        data={seriesData}
        startTime={startTimestamp}
        stopTime={stopTimestamp}
        markLines={seriesMarkLines}
        defaultYAxisRange={yAxisRanges}
        onYAxisRangeChange={setYAxisRanges}
      />
      <Tile.Footer>
        {SHORTCUTS}
        {Y_AXIS_TIP}
      </Tile.Footer>
    </div>
  );
}

export default SetupTimeSeriesChartVisualization;
