import React from 'react';
import { FormattedMessage } from 'react-intl';

import { UploadReceiptProps } from './upload-receipt.definitions';

function UploadReceipt({ uploadResult }: UploadReceiptProps) {
  if (uploadResult.status === 'ok') {
    return (
      <span>
        <FormattedMessage
          description="File upload container: Message on upload successful"
          defaultMessage="File successfully uploaded."
          id="vrqpaH"
        />
      </span>
    );
  }

  return (
    <p className="text-red">
      <span>
        <FormattedMessage
          description="File upload container: Message on error during upload"
          defaultMessage="File upload failed."
          id="ZS+osY"
        />
      </span>
    </p>
  );
}

export default UploadReceipt;
