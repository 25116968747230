import React from 'react';

import { Navigate, Route } from 'react-router-dom';

import ProcessRecords from '../../scenes/process-records';
import ProcessRecordDetail from '../../scenes/process-record-detail';
import Upload from '../../scenes/upload';
import ProcessRecordComparison from '../../scenes/process-record-comparison';
import Protected from '../../../shared/components/protected/protected';
import DatatrackFormat from '../../scenes/datatrack-format';
import { Title } from '../../../shared/components/title';
import generateTitle from '../../../shared/utils/generate-title';
import DataTrackCalculationDocumentation from '../../scenes/data-track-calculation-documentation';

const routes = (
  <>
    <Route
      index
      element={
        <Title text="Redirecting to '/process-records'">
          <Navigate to="process-records" />
        </Title>
      }
    />

    <Route path="process-records">
      <Route
        index
        element={
          <Protected>
            <Title text={generateTitle('Analytics', 'Process Records')}>
              <ProcessRecords />
            </Title>
          </Protected>
        }
      />
      <Route
        path="compare"
        element={
          <Protected>
            <Title text={generateTitle('Analytics', 'Process Record Comparison')}>
              <ProcessRecordComparison />
            </Title>
          </Protected>
        }
      />
      <Route
        path="datatrack-format"
        element={
          <Protected>
            <Title text={generateTitle('Analytics', 'Datatrack Format Guide')}>
              <DatatrackFormat />
            </Title>
          </Protected>
        }
      />
      <Route
        path=":id"
        element={
          <Protected>
            <Title text={generateTitle('Analytics', 'Process Record Detail')}>
              <ProcessRecordDetail />
            </Title>
          </Protected>
        }
      />
      <Route
        path="data-track-calculations"
        element={
          <Protected>
            <Title text={generateTitle('Analytics', 'Data Track Calculation Documentation')}>
              <DataTrackCalculationDocumentation />
            </Title>
          </Protected>
        }
      />
    </Route>

    <Route
      path="upload"
      element={
        <Protected>
          <Title text={generateTitle('Analytics', 'Upload Control Procedure')}>
            <Upload />
          </Title>
        </Protected>
      }
    />
  </>
);

export default routes;
