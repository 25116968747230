import { useCallback, useEffect, useState } from 'react';

/**
 * The useDocumentVisibility hook is useful for tracking the visibility state of a document or web page.
 * It allows to detect when the document is visible or hidden.
 */
const useDocumentVisibility = () => {
  const [isDocumentVisible, setIsDocumentVisible] = useState(true);

  const visibilityChange = useCallback(() => {
    setIsDocumentVisible(document.visibilityState === 'visible');
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', visibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', visibilityChange);
    };
  }, []);

  return isDocumentVisible;
};

export default useDocumentVisibility;
