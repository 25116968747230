import React from 'react';
import { useIntl } from 'react-intl';

import useFeatureFlag from '../../../../shared/common/hooks/use-feature-flag';
import FKey from '../../../../shared/common/feature-keys';
import ReleaseFeatureFlag from '../../../../shared/common/types/release-feature-flag';

function TestStream() {
  const testStreamEnabled = useFeatureFlag(
    new ReleaseFeatureFlag(FKey.MONITORING_DETAIL_VIEW_PROTOTYPING),
  );
  const intl = useIntl();

  if (testStreamEnabled) {
    return (
      <div
        className="pb-5"
        data-testid="test-stream"
        style={{
          minHeight: '600px',
        }}
      >
        <iframe
          className="h-full min-w-full"
          title={intl.formatMessage({
            defaultMessage: 'Test Stream',
            id: 'Xsc11V',
            description: 'Test stream',
          })}
          src="https://mef-mediamtx-container-app.happywave-17a35e3a.germanywestcentral.azurecontainerapps.io/teststream/"
        />
      </div>
    );
  }
  return null;
}

export default TestStream;
