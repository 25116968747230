import {
  SPLIT_CHART_GRID_HEIGHT,
  SPLIT_CHART_GRID_OFFSET,
  SPLIT_CHART_GRID_OFFSET_LEFT,
  SPLIT_CHART_LEGEND_HEIGHT,
  SPLIT_CHART_RIGHT_OFFSET_PX,
  SPLIT_CHART_TOOLBOX_HEIGHT,
} from '../split-chart.config';
import { EChartSeriesConfig, MarkLine } from '../../chart-markline';
import { getMarkLineSeries } from '../../chart-markline/chart-markline';

export function getGridEchartsConfig() {
  return {
    // reserve space for y-axis on the left of the chart
    left: SPLIT_CHART_GRID_OFFSET_LEFT,
    top: SPLIT_CHART_TOOLBOX_HEIGHT + SPLIT_CHART_LEGEND_HEIGHT + SPLIT_CHART_GRID_OFFSET,
    right: SPLIT_CHART_RIGHT_OFFSET_PX,
    height: SPLIT_CHART_GRID_HEIGHT,
  };
}

export function getLegendEchartsConfig(data: Array<{ name: string; color: string }>) {
  return {
    data: data.map((entry) => ({
      name: entry.name,
      itemStyle: {
        color: entry.color,
      },
      lineStyle: {
        color: entry.color,
      },
    })),
    top: SPLIT_CHART_TOOLBOX_HEIGHT,
    height: SPLIT_CHART_LEGEND_HEIGHT,
  };
}

export function getSeriesEchartsConfig(
  data: Array<{ color: string; name: string; lineType?: string; lineWidth?: number }>,
  markLines: MarkLine[],
): EChartSeriesConfig[] {
  const series = data.map<EChartSeriesConfig>((entry, index) => ({
    type: 'line',
    lineStyle: {
      type: entry.lineType || 'line',
      width: entry.lineWidth || 2,
    },
    xAxisIndex: 0,
    yAxisIndex: 0,
    name: entry.name,
    datasetIndex: index,
    animation: false,
    showSymbol: false,
    color: entry.color,
  }));

  const markLineSeries = getMarkLineSeries(markLines);

  return markLines.length > 0 ? [...series, markLineSeries] : series;
}

export function getTitleConfig(data: Array<{ title: string }>) {
  return { text: data[0].title, left: 'center' };
}
