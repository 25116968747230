import React from 'react';
import { FormattedMessage } from 'react-intl';
import { List } from '@biss/react-horizon-web';

import { EventListMessageProps } from './event-list-message.definitions';

function EventListMessage({ isError, isEvents, isEventList }: EventListMessageProps) {
  return (
    <List.Placeholder>
      {isError && (
        <FormattedMessage
          defaultMessage="An error occurred while loading events."
          description="Events error message"
          id="aXlB6W"
        />
      )}
      {isEvents && !isEventList && (
        <FormattedMessage
          defaultMessage="No matching events found."
          description="No matching events found message"
          id="FqMsv0"
        />
      )}
      {!isEvents && !isError && (
        <FormattedMessage
          defaultMessage="There are no events available."
          description="Events unavailable message"
          id="ZzR1lD"
        />
      )}
    </List.Placeholder>
  );
}

export default EventListMessage;
