import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { Organization } from '../../types/organization/organization';

import fetchOrganizations from './use-organizations.helpers';

function useOrganizations(): UseQueryResult<Organization[], Error> {
  const { acquireAccessToken } = useAuthentication();

  return useQuery({
    queryKey: [QKey.ORGANIZATIONS],
    queryFn: async () => {
      const accessToken = await acquireAccessToken();
      return fetchOrganizations(accessToken);
    },
  });
}

export default useOrganizations;
