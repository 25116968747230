import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { DataHowLabConfiguration } from '../../types';

import { fetchDataHowLabConfiguration } from './use-datahowlab-configuration.helpers';

function useDataHowLabConfiguration(
  organizationId: string,
): UseQueryResult<DataHowLabConfiguration | null, Error> {
  const { acquireAccessToken } = useAuthentication();
  return useQuery({
    queryKey: [QKey.DATAHOWLAB_CONFIGURATION, organizationId],
    queryFn: async () => {
      const accessToken = await acquireAccessToken();
      return fetchDataHowLabConfiguration(organizationId, accessToken);
    },
  });
}

export default useDataHowLabConfiguration;
