import React from 'react';
import { Tag, Button, DocumentMoveGenericIcon } from '@biss/react-horizon-web';
import { FormattedMessage } from 'react-intl';

import DataTrackModal from '../../../components/data-track-modal';

import { DEFAULT_DATA_TRACK_TYPES } from '../setup-overview.definitions';

import useLogger from '../../../../shared/common/hooks/use-logger/use-logger';
import TrackedEvent from '../../../../shared/common/tracked-event';

import { SetupOverviewFilterProps } from './setup-overview-filter.definitions';

function SetupOverviewFilter({
  dataTracks,
  filterDataTracks,
  selectDataTracks,
  toggleDataTrack,
  removeDataTrack,
}: SetupOverviewFilterProps): React.FunctionComponentElement<SetupOverviewFilterProps> {
  const logger = useLogger();

  const toggleTag = (label: string) => {
    logger.trackEvent(TrackedEvent.ToggleDataTrackOnMonitoringOverview);
    toggleDataTrack(label);
  };

  const removeTag = (label: string) => {
    logger.trackEvent(TrackedEvent.RemoveDataTrackOnMonitoringOverview);
    removeDataTrack?.(label);
  };

  return (
    <div data-testid="setup-overview-filter" className="flex flex-col bg-white p-4">
      <div className="mb-2 self-start text-sm font-bold">
        <FormattedMessage
          description="Monitoring Overview Filter: Data Tracks title"
          defaultMessage="Data Tracks"
          id="rwjwps"
        />
      </div>
      <div className="mb-2 flex flex-wrap gap-x-2 gap-y-1" data-testid="data-track-filter">
        {Object.keys(filterDataTracks)?.map((track) => (
          <Tag
            onClick={toggleTag}
            selected={filterDataTracks[track].selected}
            closable={!DEFAULT_DATA_TRACK_TYPES.includes(track)}
            onClose={removeTag}
            label={track}
            key={track}
          />
        ))}
      </div>
      <DataTrackModal
        trigger={
          <div>
            <Button
              data-testid="addDataTracks-button"
              kind="secondary"
              mood="neutral"
              leftIcon={<DocumentMoveGenericIcon />}
            >
              <FormattedMessage
                description="Monitoring Overview: Button to add a data tracks"
                defaultMessage="Add Data Tracks"
                id="cAuXiQ"
              />
            </Button>
          </div>
        }
        selectedDataTracks={Object.keys(filterDataTracks)}
        disabledDataTracks={DEFAULT_DATA_TRACK_TYPES}
        dataTracks={dataTracks}
        onSelect={selectDataTracks}
      />
    </div>
  );
}

export default SetupOverviewFilter;
