import React, { FunctionComponentElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, NotificationMessage } from '@biss/react-horizon-web';
import { Link } from 'react-router-dom';

import useLogger from '../../../../shared/common/hooks/use-logger/use-logger';
import { GenericError } from '../../../../shared/common/types/generic-error';
import { ApiError } from '../../../../shared/common/types/api-error/api-error';
import { ClientError } from '../../../../shared/common/types/client-error';
import { ErrorMessage } from '../../../../shared/components/error-message';
import TrackedEvent from '../../../../shared/common/tracked-event';

import { SetupOverviewMessageProps } from './setup-overview-message.definitions';

function SetupOverviewMessage({
  isError,
  error,
}: SetupOverviewMessageProps): FunctionComponentElement<void> {
  const intl = useIntl();
  const logger = useLogger();

  if (isError) {
    const thrownError = error as GenericError<ApiError | ClientError>;
    return (
      <div className="mt-2" data-testid="overview-error-message">
        <ErrorMessage
          error={thrownError}
          message="An internal error occurred while loading the running units."
          variant="highlighted"
        />
      </div>
    );
  }

  return (
    <div className="mt-2">
      <NotificationMessage
        data-testid="overview-no-units-message"
        status="information"
        variant="reduced"
        title={intl.formatMessage({
          description: 'No Running Units Connected title',
          defaultMessage: 'No Running Units Connected',
          id: 'zEtCrK',
        })}
        action={
          <Link
            to="/welcome"
            onClick={() => {
              logger.trackEvent(TrackedEvent.LearnMoreAboutDWSConnection);
            }}
          >
            <Button kind="ghost" mood="highlight">
              <FormattedMessage
                description="No Running Units Connected button"
                defaultMessage="Learn More"
                id="6aQMW5"
              />
            </Button>
          </Link>
        }
      >
        <FormattedMessage
          description="No Running Units Connected description"
          defaultMessage="Connect your DASware control system and enable the automatic upload to cloud option, in order to monitor data from currently running units."
          id="iD5Md4"
        />
      </NotificationMessage>
    </div>
  );
}

export default SetupOverviewMessage;
