import { z } from 'zod';

// eslint-disable-next-line import/prefer-default-export -- validators file
export const calculationRequestSchema = z.object({
  expression: z.string().trim().min(1, { message: 'A formula expression must be provided.' }),
  processRecordId: z.string().trim().min(1, { message: 'A processRecordId must be provided.' }),
  resultDataTrackType: z.string().trim().min(1, { message: 'A display name must be provided.' }),
  resultDisplayName: z.string().trim().min(1, { message: 'A display name must be provided.' }),
  resultEngineeringUnit: z.string().trim().optional(),
});
