import React from 'react';
import { Outlet } from 'react-router-dom';

import Footer from '../../components/footer';

function PublicBase() {
  return (
    <div className="flex min-h-screen flex-col bg-gray-50">
      <Outlet />
      <Footer />
    </div>
  );
}

export default PublicBase;
