import React from 'react';
import { Link } from 'react-router-dom';

import { ConditionalLinkProps } from './conditional-link.definitions';

function ConditionalLink({ condition, children, ...props }: ConditionalLinkProps) {
  return condition ? (
    <Link
      // eslint-disable-next-line react/jsx-props-no-spreading -- wrapping component
      {...props}
    >
      {children}
    </Link>
  ) : (
    children
  );
}

export default ConditionalLink;
