import { useQuery } from '@tanstack/react-query';

import QKey from '../keys';
import useLogger from '../use-logger/use-logger';
import { FeatureFlag } from '../../types/feature-flag';

import { fetchFeatureFlag } from './use-feature-flag.helpers';

const useFeatureFlag = (flag: FeatureFlag): boolean => {
  const logging = useLogger();

  const queryResult = useQuery({
    queryKey: [QKey.FEATURE, flag.name],
    queryFn: async () => fetchFeatureFlag(flag.name, logging),
    staleTime: 300_000, // 5min
    enabled: flag.readyForQuery,
  });

  return queryResult.data === true;
};

export default useFeatureFlag;
