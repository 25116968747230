enum DataTrackTimeAlignment {
  RelativeToStartTime,
  RelativeToInoculationTime,
}

export default DataTrackTimeAlignment;

export type SetupDetailsProps = {
  defaultSidebarOpen?: boolean;
};
