import { UseQueryResult, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { Uuid } from '../../../../shared/common/types/uuid';
import { SetupEvent } from '../../../../shared/common/types/setup';
import useClient from '../../../../shared/common/hooks/use-client';

export function useSetupEvents(
  systemId: Uuid,
  timestamp: string,
  unit: string,
  stopTimestamp?: string,
): UseQueryResult<SetupEvent[], Error> {
  const { acquireAccessToken } = useAuthentication();
  const { pmsClient } = useClient();
  return useQuery({
    queryKey: [QKey.SETUPS, unit, QKey.EVENTS],
    queryFn: async () => {
      pmsClient.authorize(await acquireAccessToken());
      return pmsClient.getSetupEventsById(systemId, timestamp, stopTimestamp, unit);
    },
    refetchOnWindowFocus: false,
    retry: false,
  });
}

export function useSetupEventsLastTimestamp() {
  const { acquireAccessToken } = useAuthentication();
  const { pmsClient } = useClient();
  const queryClient = useQueryClient();

  const getLastEvents = async (
    systemId: Uuid,
    timestamp: string,
    unit: string,
  ): Promise<SetupEvent[]> => {
    try {
      return await queryClient.fetchQuery({
        queryKey: [unit, QKey.EVENTS],
        queryFn: async () => {
          pmsClient.authorize(await acquireAccessToken());
          return pmsClient.getSetupEventsById(systemId, timestamp, undefined, unit);
        },
        retry: false,
      });
    } catch (e) {
      return [];
    }
  };

  return { getLastEvents };
}
