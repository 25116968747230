import useForceRerender from '../../../common/hooks/use-force-rerender';
import { ZoomRangeType, ZoomResetDirection } from '../time-series-chart.definitions';
import { ZoomYType } from '../use-vertical-zoom';

/**
 * reset the zoom settings on the chart and rerender
 * @param zoomX ref to horizontal zoom object
 * @param zoomY ref to vertical zoom object
 */
function useResetZoom(
  zoomX: React.MutableRefObject<ZoomRangeType>,
  zoomY: React.MutableRefObject<ZoomYType>,
) {
  const rerender = useForceRerender();

  return (direction: ZoomResetDirection = ZoomResetDirection.Both) => {
    if (direction === ZoomResetDirection.Both || direction === ZoomResetDirection.X) {
      // eslint-disable-next-line no-param-reassign -- mutating an object reference
      zoomX.current = { start: 0, end: 100 };
    }
    if (direction === ZoomResetDirection.Both || direction === ZoomResetDirection.Y) {
      // eslint-disable-next-line no-param-reassign -- mutating an object reference
      zoomY.current = Object.fromEntries(
        Object.keys(zoomY.current).map((key) => [key, { start: 0, end: 100 }]),
      );
    }
    rerender();
  };
}

export default useResetZoom;
