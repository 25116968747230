import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import createApplicationInsightsLogger from './application-insights-logger';
import consoleLogger from './console-logger';
import { ILogger } from './logger.definitions';

function createLogger(appInsights: ApplicationInsights): ILogger {
  if (process.env.NODE_ENV === 'production') {
    return createApplicationInsightsLogger(appInsights);
  }

  return consoleLogger;
}

export default createLogger;
