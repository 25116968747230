import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { Organization } from '../../types';

import putOrganization from './use-update-organization.helpers';

function useUpdateOrganization() {
  const queryClient = useQueryClient();

  const { acquireAccessToken } = useAuthentication();

  return useMutation<
    void,
    unknown,
    Organization,
    { previousOrganization: Organization; newOrganization: Organization }
  >({
    mutationFn: async (organization: Organization) => {
      const accessToken = await acquireAccessToken();
      await putOrganization(
        organization.organizationId,
        organization.organizationName,
        accessToken,
      );
    },
    // When mutate is called:
    onMutate: async (organization) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [QKey.ORGANIZATIONS, organization.organizationId],
      });

      // Snapshot the previous value
      const previousOrganization = queryClient.getQueryData([
        QKey.ORGANIZATIONS,
        organization.organizationId,
      ]) as Organization;

      const newOrganization = {
        ...previousOrganization,
        organizationName: organization.organizationName,
      };
      // Optimistically update to the new value
      queryClient.setQueryData([QKey.ORGANIZATIONS, organization.organizationId], newOrganization);

      // Return a context with the previous and new organization
      return { previousOrganization, newOrganization };
    },
    // If the mutation fails, use the context we returned above
    onError: (_1, _2, context) => {
      queryClient.setQueryData(
        [QKey.ORGANIZATIONS, context?.newOrganization.organizationId],
        context?.previousOrganization,
      );
    },
    // Always refetch after error or success:
    onSettled: (_1, _2, _3, context) => {
      queryClient.invalidateQueries({
        queryKey: [QKey.ORGANIZATIONS, context?.newOrganization.organizationId],
      });
      queryClient.invalidateQueries({ queryKey: [QKey.ORGANIZATIONS] });
    },
  });
}

export default useUpdateOrganization;
