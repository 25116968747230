import { UseQueryResult } from '@tanstack/react-query';

import { ProcessRecordId, StructuredProcessRecordObject } from '../../types/process-record';
import { useMultipleProcessRecords } from '../use-multiple-process-records';

export default function useProcessRecord(
  processRecordId: ProcessRecordId,
): UseQueryResult<StructuredProcessRecordObject, Error> {
  return useMultipleProcessRecords([processRecordId])[0];
}
