const seekOrganization = (
  query: string,
  organizationName: string,
  administratorDisplayName: string | undefined,
  administratorEmail: string,
) => {
  const queryLower = query.toLocaleLowerCase();

  return (
    organizationName.toLowerCase().includes(queryLower) ||
    (administratorDisplayName && administratorDisplayName.toLowerCase().includes(queryLower)) ||
    administratorEmail.toLowerCase().includes(queryLower)
  );
};

export default seekOrganization;
