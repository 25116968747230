import { createCheckers } from 'ts-interface-checker';

import fetchData from '../../../../shared/common/fetch-data';
import { NewInvitation, InvitationDTO, transformMemberDTO, MemberDTO, Member } from '../../types';
import { HttpMethods } from '../../../../shared/common/types/http';
import exportedTypeSuite from '../../types/member/dto/member-validators';
import { isValidIsoTimestamp } from '../../../../shared/common/types/timestamp/dto/validators';
import { projectConfigInstance } from '../../../../shared/globals/project-config/project-config-accessor';
import { MfaFactor } from '../../types/member/dto/invitation-dto';
import { joinPaths } from '../../../../shared/utils';

export function createMembersUrl(): string {
  return joinPaths(projectConfigInstance.get('UM_BACKEND_URL'), 'members');
}

function timestampsAreValid(dto: InvitationDTO): boolean {
  return [isValidIsoTimestamp(dto.invitedAt), isValidIsoTimestamp(dto.validUntil)].every(Boolean);
}

export function isMemberDTO(dto: unknown): asserts dto is MemberDTO {
  const { MemberDTO: memberChecker } = createCheckers(exportedTypeSuite);

  // throws an error when object is not in the correct format
  memberChecker.check(dto);
  const member = dto as MemberDTO;
  if (member?.invitation && !timestampsAreValid(member?.invitation)) {
    throw new Error('Invitation timestamps are not valid');
  }
}

export async function postNewMemberInvitation(
  memberToInvite: NewInvitation,
  accessToken: string,
): Promise<Member> {
  const { mfa, ...payload } = memberToInvite;
  const result = await fetchData(createMembersUrl(), {
    method: HttpMethods.Post,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      ...payload,
      mfaFactor: (mfa ? 'Email' : 'None') satisfies MfaFactor,
    }),
  });

  isMemberDTO(result);
  return transformMemberDTO(result);
}
