import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { ApiError } from '../../../../shared/common/types/api-error/api-error';

import {
  ControlProcedureId,
  StructuredSetup,
  SetupId,
} from '../../../../shared/common/types/setup';
import useClient from '../../../../shared/common/hooks/use-client';
import { DataTrackBase } from '../../../../shared/common/types/generated/pms.generated';

function useSaveDataTracks(
  setupId: SetupId,
  {
    onSuccess,
    ...options
  }: UseMutationOptions<
    unknown,
    ApiError,
    { dataTracks: DataTrackBase[]; controlProcedureId: ControlProcedureId },
    unknown
  > = {},
) {
  const { acquireAccessToken } = useAuthentication();
  const queryClient = useQueryClient();
  const { pmsClient } = useClient();
  return useMutation({
    ...options,
    async mutationFn({ dataTracks, controlProcedureId }) {
      pmsClient.authorize(await acquireAccessToken());
      return pmsClient.createCustomDataTracks(controlProcedureId, setupId, dataTracks);
    },
    mutationKey: [QKey.CUSTOM_DATA_TRACKS, setupId],
    onSuccess(data, variables, context) {
      // update cache
      queryClient.setQueryData<StructuredSetup>([QKey.SETUPS, setupId], (prev) => {
        if (!prev) {
          return undefined;
        }

        return {
          ...prev,
          dataTracks: [...prev.dataTracks, ...data],
        };
      });

      onSuccess?.(data, variables, context);
    },
  });
}

export default useSaveDataTracks;
