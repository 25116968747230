import { SeriesName } from '../../../../shared/components/time-series-chart';
import { convertYAxisRangesToMap } from '../../../common/helpers';
import { YAxisRange } from '../../setup-overview/setup-overview-y-axis/y-axis-range-controls/y-axis-range-controls.validation';
import { isSpOrPvType, PV, SP, splitDataTrackType } from '../setup-details.helpers';

// eslint-disable-next-line import/prefer-default-export -- further imports will be added
export function getYAxisRanges(
  storageYAxis?: Record<SeriesName, YAxisRange>,
): Map<SeriesName, YAxisRange> {
  if (storageYAxis) {
    const dataTrackTypes = Object.keys(storageYAxis);
    const yAxisRanges = dataTrackTypes.reduce((acc, type) => {
      const [dataTrackName, dataTrackType] = splitDataTrackType(type);

      const isPVExist = dataTrackTypes.some((item) => {
        const [trackName, trackType] = splitDataTrackType(item);
        return trackName === dataTrackName && trackType === PV;
      });
      // if there is data track with PV and SP ignore SP range
      if (dataTrackType === SP && isPVExist) {
        return acc;
      }
      const dataTrack = isSpOrPvType(dataTrackType) ? dataTrackName : type;
      return {
        ...acc,
        [dataTrack]: {
          ...storageYAxis[type],
        },
      };
    }, {});

    return convertYAxisRangesToMap(yAxisRanges);
  }
  return new Map();
}
