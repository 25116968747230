import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import {
  ProcessRecordId,
  StructuredProcessRecordObject,
} from '../../../../shared/common/types/process-record';
import QKey from '../../../../shared/common/hooks/keys';

import useClient from '../../../../shared/common/hooks/use-client';
import { transformProcessRecordObject } from '../../../../shared/common/types/process-record/transformers';

function useProcessRecord(
  id: ProcessRecordId,
): UseQueryResult<StructuredProcessRecordObject, Error> {
  const { acquireAccessToken } = useAuthentication();
  const { prsClient } = useClient();

  return useQuery({
    queryKey: [QKey.PROCESS_RECORDS, id],
    enabled: !!id,
    queryFn: async () => {
      // fetch
      prsClient.authorize(await acquireAccessToken());
      const response = await prsClient.getProcessRecordById(undefined, id);

      // transform
      return transformProcessRecordObject(response);
    },
  });
}
export default useProcessRecord;
