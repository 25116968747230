import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, DeleteIcon, List, PageLayout } from '@biss/react-horizon-web';
import { Roles, useAuthContext } from '@biss/react-auth-web';

import useMembers from '../../../common/hooks/use-members/use-members';
import RouteDefinition from '../../../../shared/common/routes/routes.definitions';
import ConfirmDialog from '../../../components/confirm-dialog/confirm-dialog';
import useDeleteMember from '../../../common/hooks/use-delete-member/use-delete-member';
import LoadingIndicator from '../../../../shared/components/loading-indicator/loading-indicator';
import SearchInput from '../../../../shared/components/search-input/search-input';
import Footer from '../../../../shared/components/footer';

import seekMember from './members.helpers';
import { MembersProps } from './members.definitions';

const styling = { b: (value: any) => <b>{value}</b> };
function Members({ defaultSearch = '' }: MembersProps) {
  const { data: members, isError, isLoading } = useMembers();
  const { mutate: deleteMember } = useDeleteMember();
  const intl = useIntl();
  const [searchParam, setSearchParam] = useState<string>(defaultSearch);
  const { account } = useAuthContext();
  const handleSearch = (val: string) => {
    const value = val.trim();
    setSearchParam(value);
  };

  const navigate = useNavigate();

  const memberItems =
    members?.map((i) => ({
      key: i.memberId,
      displayName: i.memberDisplayName,
      email: i.memberEmail,
      roles: i.memberRoles,
      invitation: i.invitation,
    })) ?? [];

  const filteredMemberItems = memberItems.filter((i) =>
    seekMember(searchParam, i.displayName, i.email),
  );

  function mapRole(roles: Roles): React.ReactNode {
    if ((roles as string[]).includes('EppendorfAdministrator')) {
      return 'Eppendorf Administrator';
    }
    if ((roles as string[]).includes('Administrator')) {
      return 'Administrator';
    }
    return 'Data Access';
  }

  return (
    <PageLayout sidebarOpen="none" className="!h-[calc(100dvh-var(--header-bar-height,0))]">
      <PageLayout.Main>
        <PageLayout.Main.ActionBar pageTitle="User Management" sidePanelOpen="none" />
        <PageLayout.Main.Content className="flex h-full flex-col gap-4 overflow-y-auto px-4 pb-4">
          <List data-testid="UserList" className="rounded border">
            <List.Header sticky>
              <div className="flex min-w-[10rem] flex-row items-center justify-between gap-1 bg-white py-2">
                <div>
                  <Button
                    onClick={() => navigate(`${RouteDefinition.UserManagment}/members/new`)}
                    isSubmit
                  >
                    <FormattedMessage
                      description="Submit button label for invite new user."
                      defaultMessage="Invite New User"
                      id="gFdUQn"
                    />
                  </Button>
                </div>

                <SearchInput
                  placeholder={intl.formatMessage({
                    defaultMessage: 'Type to search...',
                    description: 'User list searchbar placeholder',
                    id: 'ryfjtM',
                  })}
                  onDebouncedChange={handleSearch}
                  initialValue={searchParam}
                  expand="auto"
                  disabled={!members}
                  data-testid="process-record-list-search"
                />
              </div>
            </List.Header>
            <List.Items data-testid="organizations">
              {filteredMemberItems.length === 0 && !isLoading && (
                <span className="p-4">
                  <FormattedMessage
                    description="User list: No matching Users message"
                    defaultMessage="No matching users."
                    id="axSMxv"
                  />
                </span>
              )}
              {filteredMemberItems.map((item) => (
                <List.Item
                  key={item.key}
                  clickable
                  select="single"
                  lining="zebra"
                  onCheckedChange={() => {}}
                  onClick={() => {
                    navigate(`${RouteDefinition.UserManagment}/members/${item.key}`);
                  }}
                  actions={
                    <List.Item.ActionArea stopPropagation>
                      <ConfirmDialog
                        defaultMessage={intl.formatMessage({
                          defaultMessage: 'Delete',
                          id: 'XE57Xw',
                          description: 'Delete title.',
                        })}
                        title={intl.formatMessage({
                          defaultMessage: 'Confirm Deletion',
                          id: 'FuSwjG',
                          description: 'Confirm Deletion title.',
                        })}
                        subtitle={intl.formatMessage(
                          {
                            defaultMessage:
                              'The user {name} <b>will be permanently deleted</b>. Are you sure you want to proceed?',
                            description: 'Deletion message',
                            id: 'n6jpHP',
                          },
                          { name: item.displayName, ...styling },
                        )}
                        onConfirm={() => {
                          deleteMember({
                            memberId: item.key,
                            invitationPending: item.invitation !== undefined,
                          });
                        }}
                        trigger={
                          <Button
                            mood="neutral"
                            kind="ghost"
                            isSubmit
                            data-testid="MemberItem-deleteButton"
                            leftIcon={<DeleteIcon />}
                            disabled={item.key === account?.accountId}
                          />
                        }
                      />
                    </List.Item.ActionArea>
                  }
                >
                  <List.Item.Content>
                    <div className="gap-4 text-sm max-md:flex max-md:flex-col md:grid md:grid-cols-3 md:grid-rows-1">
                      <List.Item.Description>
                        <List.Item.Title>
                          <div className="break-words text-gray-900">{item.displayName}</div>
                        </List.Item.Title>
                        {item?.invitation !== undefined && (
                          <FormattedMessage
                            description="Invitation pending until"
                            defaultMessage="Invitation pending until {invitationValidUntil}"
                            values={{
                              invitationValidUntil: `${intl.formatDate(
                                item?.invitation.validUntil,
                                {
                                  month: 'short',
                                  day: 'numeric',
                                  year: 'numeric',
                                },
                              )}`,
                            }}
                            id="uo7iGX"
                          />
                        )}
                      </List.Item.Description>
                      <div className="break-words text-gray-900">{item.email}</div>
                      <div className="break-words text-gray-900">{mapRole(item.roles)}</div>
                    </div>
                  </List.Item.Content>
                </List.Item>
              ))}
            </List.Items>

            {memberItems && !memberItems.length && !isLoading && (
              <span className="p-4">
                <FormattedMessage
                  description="User list: No matching Users message"
                  defaultMessage="No matching users."
                  id="axSMxv"
                />
              </span>
            )}

            {isError && (
              <span className="p-4 text-red">
                <FormattedMessage
                  description="Organization list view: error message when error occurred during loading."
                  defaultMessage="An error occurred while loading the organization list."
                  id="3PfqQV"
                />
              </span>
            )}

            {isLoading && (
              <div className="p-4">
                <LoadingIndicator />
              </div>
            )}
            <Outlet />
          </List>
          <Footer />
        </PageLayout.Main.Content>
      </PageLayout.Main>
    </PageLayout>
  );
}

export default Members;
