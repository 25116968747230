import React from 'react';
import { AuthenticationTemplate } from '@biss/react-auth-web';

import { ProtectedProps } from './protected.definitions';

function Protected({ children }: ProtectedProps) {
  return <AuthenticationTemplate>{children}</AuthenticationTemplate>;
}

export default Protected;
