import React, { FunctionComponentElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NotificationMessage } from '@biss/react-horizon-web';

import { useFormatTime, useFormatDate } from '../../../../shared/common/hooks/use-format-time';

import { FinishedUnitMessageProps } from './finished-unit-message.definitions';

function FinishedUnitMessage({
  visible,
  stopTimestamp,
}: FinishedUnitMessageProps): FunctionComponentElement<FinishedUnitMessageProps> | null {
  const intl = useIntl();
  const timeFormatter = useFormatTime();
  const dateFormatter = useFormatDate();

  if (!visible) {
    return null;
  }
  const stopTime = stopTimestamp?.getTime();

  return (
    <NotificationMessage
      data-testid="status-message"
      status="success"
      title={intl.formatMessage({
        description: 'Process Finished title',
        defaultMessage: 'Process Finished.',
        id: 'YyI7pe',
      })}
    >
      {stopTime && (
        <FormattedMessage
          description="Monitoring Detail view screen: The Process on this Unit is Finished"
          defaultMessage="The process finished on {date} at {time}."
          id="3jQ+ip"
          values={{
            date: dateFormatter(stopTime),
            time: timeFormatter(stopTime),
          }}
        />
      )}
    </NotificationMessage>
  );
}

export default FinishedUnitMessage;
