import canvasSize from 'canvas-size';

import { CanvasSizeMaxReturn } from './use-max-allowed-canvas-size.definitions';

// eslint-disable-next-line import/prefer-default-export -- helper file
export async function getMaxCanvasSize(): Promise<CanvasSizeMaxReturn> {
  return canvasSize.maxArea({
    step: 100,
    usePromise: true,
  });
}
