import { DataPointObject } from '../../../../shared/common/types/setup';
import { filterAndSortDataPoints, isPrevDataPoints } from '../../helpers';

// eslint-disable-next-line import/prefer-default-export -- helpers file
export function combineDataPoints(
  dataPoints: DataPointObject[],
  lastDataPoints: DataPointObject[],
): DataPointObject[] {
  const dataPointsCombined = [...dataPoints, ...lastDataPoints];
  return isPrevDataPoints(dataPoints, lastDataPoints)
    ? filterAndSortDataPoints(dataPointsCombined)
    : dataPointsCombined;
}
