import { DEFAULT_DWC_ATTRIBUTES } from './is-ignorable-attribute.definitions';

/**
 * Determines whether the input attribute is a default attribute.
 * This function assumes that, attributes in {@link DEFAULT_DWC_ATTRIBUTES} whose values equal `""` or `"0"`
 * are to be ignored.
 *
 * @param name - key of the attribute
 * @param value - value of the attribute
 * @returns whether the attribute is a default sent by the DWC
 */
const isIgnorableAttribute = (name: string, value: string) => {
  if (DEFAULT_DWC_ATTRIBUTES.includes(name) === false) {
    return false;
  }

  return value === '' || value === '0';
};

export default isIgnorableAttribute;
