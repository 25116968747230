import React, { FunctionComponentElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button, ChevronLeftIcon } from '@biss/react-horizon-web';

import FileUpload from '../../components/file-upload';
import ActionBar from '../../../shared/containers/action-bar';
import RouteDefinition from '../../../shared/common/routes/routes.definitions';

function Upload(): FunctionComponentElement<unknown> {
  return (
    <>
      <ActionBar textAlign="left">
        <Link to={`${RouteDefinition.Analytics}/process-records`}>
          <Button kind="ghost" mood="highlight" leftIcon={<ChevronLeftIcon />}>
            <FormattedMessage
              description="Upload: link to the process records list."
              defaultMessage="Go to Process Records"
              id="YQWd6I"
            />
          </Button>
        </Link>
      </ActionBar>

      <FileUpload />
    </>
  );
}

export default Upload;
