import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { Organization } from '../../types/organization/organization';
import { joinPaths } from '../../../../shared/utils';
import { projectConfigInstance } from '../../../../shared/globals/project-config/project-config-accessor';
import { isOrganizationDTO } from '../../types';

export function createOrganizationsUrl(): string {
  return joinPaths(projectConfigInstance.get('UM_BACKEND_URL'), 'organizations');
}

async function fetchOrganizations(accessToken: string): Promise<Organization[]> {
  const result = await fetchData(createOrganizationsUrl(), {
    method: HttpMethods.Get,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!Array.isArray(result)) {
    throw new Error('expected to receive an array of organizations.');
  }

  // throws an error when object is not in the correct format
  result.forEach(isOrganizationDTO);

  return result;
}

export default fetchOrganizations;
