/**
 * This module was automatically generated by `ts-interface-builder`
 */
import * as t from 'ts-interface-checker';
// tslint:disable:object-literal-key-quotes

export const OrganizationDTO = t.iface([], {
  organizationId: 'string',
  organizationName: 'string',
  administratorDisplayName: t.opt('string'),
  administratorEmail: 'string',
  active: 'boolean',
  deactivatedAt: t.opt('string'),
});

export const DataHowLabConfigurationDTO = t.iface([], {
  instanceUrl: 'string',
  dataTransferApiKey: 'string',
  userAnonymizationApiKey: 'string',
});

export const SubscriptionDTO = t.iface([], {
  packageKey: 'string',
  isSubscribed: 'boolean',
});

const exportedTypeSuite: t.ITypeSuite = {
  OrganizationDTO,
  DataHowLabConfigurationDTO,
  SubscriptionDTO,
};
export default exportedTypeSuite;
