import { Column } from '@tanstack/react-table';
import { CSSProperties } from 'react';

export default function stickyColumnStyle<T>(column: Column<T>): CSSProperties {
  const isPinned = column.getIsPinned();

  return {
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    position: isPinned ? 'sticky' : 'relative',
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0,
    boxShadow: isPinned ? '-3px 0px 0px -2px #B9BDC4 inset' : undefined,
  };
}
