import React, { ChangeEvent, FunctionComponentElement, useEffect, useState } from 'react';
import { Checkbox, Input } from '@biss/react-horizon-web';
import { useIntl } from 'react-intl';

import LoadingIndicator from '../../../../../shared/components/loading-indicator';
import useUpdateDataHowLabConfiguration from '../../../../common/hooks/use-update-datahowlab-configuration/use-update-datahowlab-configuration';
import useValidateDataHowLabConfiguration from '../../../../common/hooks/use-validate-datahowlab-configuration/use-validate-datahowlab-configuration';
import useDataHowLabConfiguration from '../../../../common/hooks/use-datahowlab-configuration/use-datahowlab-configuration';

import {
  DataHowLabConfigurationErrorState,
  DataHowLabConfigurationProps,
  DataHowLabConfigurationState,
} from './datahowlab-configuration.definitions';

function DataHowLabConfiguration({
  organizationId,
  dataHowLabSubscribed,
  isOrganizationActive,
  onSaved,
  onVerified,
  registerOnSave,
  onConfigurationChanged,
  onSubscriptionChanged,
}: DataHowLabConfigurationProps): FunctionComponentElement<DataHowLabConfigurationProps> {
  const { formatMessage } = useIntl();

  const {
    data: dataHowLabConfiguration,
    isLoading: dataHowLabConfigurationIsLoading,
    error: dataHowLabConfigurationError,
  } = useDataHowLabConfiguration(organizationId);

  const [dataHowLabConfigurationState, setDataHowLabConfigurationState] =
    useState<DataHowLabConfigurationState>({
      dataHowLabInstanceUrl: '',
      dataHowLabDataTransferApiKey: '',
      dataHowLabUserAnonymizationApiKey: '',
    });

  const [errors, setErrors] = useState<DataHowLabConfigurationErrorState>({});

  const { mutate: update } = useUpdateDataHowLabConfiguration(organizationId, (isValid) => {
    setDataHowLabConfigurationState((prevState) => ({
      ...prevState,
      isDataHowLabConnectionValid: isValid,
    }));
    onSaved(isValid);
  });

  const { mutate: validate } = useValidateDataHowLabConfiguration(organizationId, (isValid) => {
    setDataHowLabConfigurationState((prevState) => ({
      ...prevState,
      isDataHowLabConnectionValid: isValid,
    }));
    onVerified(isValid);
  });

  const onStateChange = (state: DataHowLabConfigurationState): void => {
    const isUrlValid =
      state.dataHowLabInstanceUrl !== undefined && state.dataHowLabInstanceUrl.length > 0;
    const isDataTransferKeyValid =
      state.dataHowLabDataTransferApiKey !== undefined &&
      state.dataHowLabDataTransferApiKey.length > 0;
    const isUserAnonymizationKeyValid =
      state.dataHowLabUserAnonymizationApiKey !== undefined &&
      state.dataHowLabUserAnonymizationApiKey.length > 0;
    onConfigurationChanged(isUrlValid && isDataTransferKeyValid && isUserAnonymizationKeyValid);
  };

  const onEnableDataHowLabConfigurationChanged = (checked: boolean | string) => {
    setDataHowLabConfigurationState((prevState) => {
      const newState = {
        ...prevState,
        dataHowLabSubscribed: checked === true,
      };
      onSubscriptionChanged(checked === true);
      onStateChange(newState);
      return newState;
    });
  };

  const onDataHowLabDataTransferApiKeyReset = () => {
    setDataHowLabConfigurationState((prevState) => ({
      ...prevState,
      dataHowLabDataTransferApiKey: dataHowLabConfiguration?.dataTransferApiKey ?? '',
    }));
  };

  const onDataHowLabDataUserAnonymizationApiKeyReset = () => {
    setDataHowLabConfigurationState((prevState) => ({
      ...prevState,
      dataHowLabUserAnonymizationApiKey: dataHowLabConfiguration?.userAnonymizationApiKey ?? '',
    }));
  };

  const onSave = () => {
    if (!dataHowLabSubscribed) {
      return;
    }
    if (
      !dataHowLabConfigurationState.dataHowLabInstanceUrl ||
      !dataHowLabConfigurationState.dataHowLabDataTransferApiKey ||
      !dataHowLabConfigurationState.dataHowLabUserAnonymizationApiKey
    ) {
      setDataHowLabConfigurationState((prevState) => ({
        ...prevState,
        isDataHowLabConnectionValid: false,
      }));
      return;
    }

    const config = {
      instanceUrl: dataHowLabConfigurationState.dataHowLabInstanceUrl,
      dataTransferApiKey:
        dataHowLabConfigurationState.dataHowLabDataTransferApiKey ===
        dataHowLabConfiguration?.dataTransferApiKey
          ? undefined
          : dataHowLabConfigurationState.dataHowLabDataTransferApiKey,
      userAnonymizationApiKey:
        dataHowLabConfigurationState.dataHowLabUserAnonymizationApiKey ===
        dataHowLabConfiguration?.userAnonymizationApiKey
          ? undefined
          : dataHowLabConfigurationState.dataHowLabUserAnonymizationApiKey,
    };

    if (!dataHowLabConfigurationState.isDataHowLabConnectionValid) {
      validate(config);
    } else {
      update(config);
    }
  };

  registerOnSave(onSave);

  const setFormState = (state: DataHowLabConfigurationState): DataHowLabConfigurationState => ({
    ...state,
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const { value } = event.target;
    setDataHowLabConfigurationState((values) => {
      const newState = setFormState({
        ...values,
        [name]: value,
        isDataHowLabConnectionValid: undefined,
      });
      onStateChange(newState);
      return newState;
    });
    setErrors((err) => ({
      ...err,
      [`${name}Error`]: !value ? 'Required Input.' : undefined,
    }));
  };

  useEffect(() => {
    if (!dataHowLabConfiguration) {
      return;
    }

    setDataHowLabConfigurationState((prevState) => ({
      ...prevState,
      dataHowLabDataTransferApiKey: dataHowLabConfiguration.dataTransferApiKey ?? '',
      dataHowLabUserAnonymizationApiKey: dataHowLabConfiguration.userAnonymizationApiKey ?? '',
      dataHowLabInstanceUrl: dataHowLabConfiguration.instanceUrl,
    }));
  }, [
    dataHowLabConfiguration?.instanceUrl,
    dataHowLabConfiguration?.dataTransferApiKey,
    dataHowLabConfiguration?.userAnonymizationApiKey,
  ]);

  return (
    <div className="flex-1">
      {dataHowLabConfigurationIsLoading ? (
        <LoadingIndicator />
      ) : (
        <div>
          {!dataHowLabConfigurationError && (
            <>
              <div className="mb-4 mt-4.5">
                <Checkbox
                  className="mb-1 mt-1"
                  id="enableDataHowLabPackageInput"
                  label={formatMessage({
                    defaultMessage: 'Enable DataHowLab Package',
                    id: '/Cl0pU',
                    description: 'Label for enableDataHowLabPackageInput input.',
                  })}
                  checked={dataHowLabSubscribed}
                  onCheckedChange={(checked) => onEnableDataHowLabConfigurationChanged(checked)}
                  disabled={!isOrganizationActive}
                />
              </div>
              {dataHowLabSubscribed && (
                <>
                  <Input
                    className="mb-4 w-full"
                    id="dataHowLabInstanceUrlInput"
                    name="dataHowLabInstanceUrl"
                    label={formatMessage({
                      defaultMessage: 'DataHowLab instance URL',
                      id: 'QXsaaP',
                      description: 'Label for dataHowLabInstanceUrlInput input.',
                    })}
                    placeholder={formatMessage({
                      defaultMessage: 'DataHowLab instance URL',
                      id: '41eW/x',
                      description: 'Placeholder for dataHowLabInstanceUrlInput input.',
                    })}
                    type="text"
                    value={dataHowLabConfigurationState.dataHowLabInstanceUrl}
                    onChange={handleChange}
                    error={errors?.dataHowLabInstanceUrlError}
                    disabled={!isOrganizationActive}
                  />
                  <Input
                    className="mb-4 w-full"
                    id="dataHowLabDataTransferApiKeyInput"
                    name="dataHowLabDataTransferApiKey"
                    label={formatMessage({
                      defaultMessage: 'DataHowLab data transfer API key',
                      id: '5qCd7m',
                      description: 'Label for dataHowLabDataTransferApiKeyInput input.',
                    })}
                    placeholder={formatMessage({
                      defaultMessage: 'DataHowLab Data Transfer API Key',
                      id: 'Jr3kpD',
                      description: 'Placeholder for dataHowLabDataTransferApiKeyInput input.',
                    })}
                    type="text"
                    value={dataHowLabConfigurationState.dataHowLabDataTransferApiKey}
                    onChange={handleChange}
                    isClearable={
                      dataHowLabConfiguration?.dataTransferApiKey !== undefined &&
                      dataHowLabConfigurationState?.dataHowLabDataTransferApiKey !==
                        dataHowLabConfiguration.dataTransferApiKey
                    }
                    onClearClick={onDataHowLabDataTransferApiKeyReset}
                    error={errors?.dataHowLabDataTransferApiKeyError}
                    disabled={!isOrganizationActive}
                  />
                  <Input
                    className="w-full"
                    id="dataHowLabUserAnonymizationApiKeyInput"
                    name="dataHowLabUserAnonymizationApiKey"
                    label={formatMessage({
                      defaultMessage: 'DataHowLab user anonymization API key',
                      id: '/x5Ykg',
                      description: 'Label for dataHowLabUserAnonymizationApiKeyInput input.',
                    })}
                    placeholder={formatMessage({
                      defaultMessage: 'DataHowLab user anonymization API key',
                      id: 'wpWGeN',
                      description: 'Placeholder for dataHowLabUserAnonymizationApiKeyInput input.',
                    })}
                    type="text"
                    value={dataHowLabConfigurationState.dataHowLabUserAnonymizationApiKey}
                    onChange={handleChange}
                    isClearable={
                      dataHowLabConfiguration?.userAnonymizationApiKey !== undefined &&
                      dataHowLabConfigurationState?.dataHowLabUserAnonymizationApiKey !==
                        dataHowLabConfiguration.userAnonymizationApiKey
                    }
                    onClearClick={onDataHowLabDataUserAnonymizationApiKeyReset}
                    error={errors?.dataHowLabUserAnonymizationApiKeyError}
                    disabled={!isOrganizationActive}
                  />
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
export default DataHowLabConfiguration;
