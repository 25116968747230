import { TIME_SPANS } from '../../../common/types/setup';

export type SetupOverviewTimespanProps = {
  timeSpan: number;
  setTimeSpan: (timespan: number) => void;
};

export const TIME_SPAN_OPTIONS = TIME_SPANS.map((timeSpan) => timeSpan.toString());

export const HOUR = 'h';
