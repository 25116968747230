import { z } from 'zod';

export const minLesserThanMax = (arg: { min?: number; max?: number | string }): boolean => {
  if (typeof arg.min !== 'number' || typeof arg.max !== 'number') {
    return true;
  }

  return arg.min < arg.max;
};

const numericalField = z.preprocess(
  (value) =>
    // interpret empty string as `undefined`
    typeof value === 'string' && value.trim().length === 0 ? undefined : value,
  z.coerce.number({ message: 'Please enter a number' }).optional(),
);

export const yAxisRangeSchema = z
  .object({
    max: numericalField,
    min: numericalField,
  })
  .refine(minLesserThanMax, {
    message: 'Min should be less than Max',
    path: ['min'],
  })
  .refine(minLesserThanMax, {
    message: ' ',
    path: ['max'],
  });

export type YAxisRange = Partial<z.infer<typeof yAxisRangeSchema>>;
