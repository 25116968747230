import { Skeleton } from '@biss/react-horizon-web';
import React from 'react';

function ProcessRecordHeaderSkeleton() {
  return (
    <div
      className="flex h-full w-full flex-col items-start justify-between overflow-hidden rounded border border-none bg-white shadow data-[state=checked]:bg-blue
    "
    >
      <div className="flex w-full flex-col gap-4 p-4">
        <Skeleton variant="text" width="16rem" />

        <div className="flex w-1/2 flex-row flex-wrap gap-2 pb-2">
          <Skeleton variant="text" width="10rem" />
          <Skeleton variant="text" width="10rem" />
          <Skeleton variant="text" width="10rem" />
        </div>
      </div>

      <div className="flex flex-row gap-2 p-4">
        <Skeleton variant="circular" width="6rem" />
        <Skeleton variant="circular" width="6rem" />
        <Skeleton variant="circular" width="6rem" />
      </div>

      <div className="p-4">
        <Skeleton variant="rectangular" width="8rem" />
      </div>

      <div className="h-1.5 w-full animate-pulse rounded-b bg-gray-300" />
    </div>
  );
}

export default ProcessRecordHeaderSkeleton;
