import { z } from 'zod';

import { DataTrack } from '../../../../shared/common/types/process-record';

export const displayNameSchema = z
  .string({ required_error: 'a name should be entered for the datatrack' })
  .min(1, 'name is empty')
  .refine((val) => val.trim().length !== 0, {
    message: 'name cannot be all white space',
  });

export const engineeringUnitSchema = z.string({
  required_error: 'an engineering unit must be entered value should be entered',
});

export const DUPLICATE_ENTRY_MSG = `this display name has been entered more than once`;
export const TAKEN_NAME_MSG = `a datatrack with this name already exists`;

/**
 * validate each neccessary field of a datatrack and check if the datatrack is valid as a whole
 * @param datatrack
 */
export const validate = (
  { displayName, engineeringUnit }: Pick<DataTrack, 'displayName' | 'engineeringUnit'>,
  localNames: string[],
  takenNames: string[],
) => {
  const nameRes = displayNameSchema
    .refine(
      (val) => localNames.filter((nm) => nm.trim() === val.trim()).length <= 1, // should not be a duplicate entry
      {
        message: DUPLICATE_ENTRY_MSG,
      },
    )
    .refine(
      (val) => takenNames.map((nm) => nm.trim()).includes(val.trim()) === false, // should not already exist
      {
        message: TAKEN_NAME_MSG,
      },
    )
    .safeParse(displayName);

  const unitRes = engineeringUnitSchema.safeParse(engineeringUnit);

  return {
    fields: { nameRes, unitRes },
    isValid: nameRes.success && unitRes.success,
  };
};
