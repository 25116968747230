import { useEffect, useState } from 'react';

import { isMobile } from '../../../utils';

/**
 * optimized version of @see {useTouch} for the case of mobile devices
 * @returns should the graph be zoomable through touch gestures (not scroll events)
 */
function useShouldEnableTouch() {
  const [isTouching, setIsTouching] = useState(false);
  const mobile = isMobile();

  const handleTouchStart = (_: TouchEvent) => {
    if (mobile) {
      // prevent extra rerenders
      return;
    }
    setIsTouching(true);
  };

  const handleTouchEnd = (_: TouchEvent) => {
    if (mobile) {
      // prevent extra rerenders
      return;
    }
    setIsTouching(false);
  };

  useEffect(() => {
    window.addEventListener('touchstart', handleTouchStart);

    window.addEventListener('touchend', handleTouchEnd);
    window.addEventListener('touchcancel', handleTouchEnd);

    return () => {
      window.removeEventListener('touchstart', handleTouchStart);

      window.removeEventListener('touchend', handleTouchEnd);
      window.removeEventListener('touchcancel', handleTouchEnd);
    };
  }, []);

  return mobile || isTouching;
}

export default useShouldEnableTouch;
