import React from 'react';
import { List, Skeleton } from '@biss/react-horizon-web';

import { ProcessRecordListItemSkeletonProps } from './process-record-item.definitions';

function ProcessRecordListItemSkeleton({
  isActionBarDisabled = true,
  select = 'single',
  lining = 'zebra',
}: ProcessRecordListItemSkeletonProps) {
  return (
    <List.Item
      lining={lining}
      select={select}
      clickable={false}
      disabled
      actions={
        isActionBarDisabled ? undefined : (
          <List.Item.ActionArea>
            <Skeleton variant="rectangular" width="2rem" />
            <Skeleton variant="rectangular" width="2rem" />
          </List.Item.ActionArea>
        )
      }
    >
      <List.Item.Content>
        <div className="gap-4 text-sm max-md:flex max-md:flex-col md:grid md:grid-cols-[auto,15rem,15rem] md:grid-rows-1 xl:grid-cols-[2fr,1fr,1fr]">
          <div>
            <List.Item.Description>
              <List.Item.Title>
                <Skeleton variant="text" width="80%" className="mb-2" />
                <Skeleton variant="text" width="80%" className="mb-2" />
              </List.Item.Title>
              <List.Item.Subtitle>
                <Skeleton variant="text" width="64%" />
              </List.Item.Subtitle>
            </List.Item.Description>
          </div>

          <div className="flex flex-col flex-wrap gap-x-4 gap-y-2 font-light">
            <div className="flex items-center gap-x-2">
              <Skeleton variant="text" width="1.5rem" />
              <Skeleton variant="text" width="8rem" />
            </div>
            <div className="flex items-center gap-x-2">
              <Skeleton variant="text" width="1.5rem" />
              <Skeleton variant="text" width="8rem" />
            </div>
            <div className="flex items-center gap-x-2">
              <Skeleton variant="text" width="1.5rem" />
              <Skeleton variant="text" width="8rem" />
            </div>
          </div>

          <div className="flex flex-col flex-wrap gap-x-4 gap-y-2 font-light">
            <div className="flex items-center gap-x-2">
              <Skeleton variant="text" width="1.5rem" />
              <Skeleton variant="text" width="8rem" />
            </div>
            <div className="flex items-center gap-x-2">
              <Skeleton variant="text" width="1.5rem" />
              <Skeleton variant="text" width="8rem" />
            </div>
          </div>
        </div>
      </List.Item.Content>
    </List.Item>
  );
}
export default ProcessRecordListItemSkeleton;
