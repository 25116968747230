import React, { FunctionComponentElement, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { InfoIcon } from '@biss/react-horizon-web';

import { MAX_SERIES_LENGTH } from '../time-series-chart.helpers';

import TimeSeriesChartInfoBoxProps from './teme-series-chart-info-box.definitions';

function InfoMessage({ children }: PropsWithChildren): FunctionComponentElement<PropsWithChildren> {
  return (
    <>
      <InfoIcon className="mr-0.5 h-5 w-5" title="Info" />
      {children}
    </>
  );
}

function TimeSeriesChartInfoBox({
  seriesLength,
  combined,
}: TimeSeriesChartInfoBoxProps): FunctionComponentElement<TimeSeriesChartInfoBoxProps> {
  return (
    <div
      className="mx-4 flex items-center justify-end text-xs text-gray-700"
      data-testid="TimeSeriesChart-InfoBox"
    >
      {seriesLength === MAX_SERIES_LENGTH && combined && (
        <InfoMessage>
          <FormattedMessage
            description="Time Series Chart: Adding more data tracks will split the graph automatically."
            defaultMessage="Adding more data tracks will split the graph automatically."
            id="BmIODi"
          />
        </InfoMessage>
      )}
      {seriesLength > MAX_SERIES_LENGTH && (
        <InfoMessage>
          <FormattedMessage
            description="Time Series Chart: Combined Graph only with 8 or less data tracks selected"
            defaultMessage="Combining graphs into one graph is only possible with max. {maxNumber} selected data tracks."
            id="Y/Tnz8"
            values={{ maxNumber: MAX_SERIES_LENGTH }}
          />
        </InfoMessage>
      )}
    </div>
  );
}
export default TimeSeriesChartInfoBox;
