import { IntlShape } from 'react-intl';

import { many } from '../../common/utils';
import {
  ACCUMULATIVE_DAY_SPLIT_NUMBER,
  ACCUMULATIVE_HOUR_SPLIT_NUMBER,
  ACCUMULATIVE_SPLIT_NUMBER,
  ChartXAxisTimeStampFormat,
  DAY_IN_MILLISECONDS,
  HOUR_IN_MILLISECONDS,
} from '../chart-formatters/chart-formatters.definitions';

import { getXAxisFormatter } from '../chart-formatters/chart-formatters';

import { TimeRange } from './chart-x-axis.definitions';

export function getXAxisIntervals(timeStampFormat: ChartXAxisTimeStampFormat) {
  if (timeStampFormat === ChartXAxisTimeStampFormat.StaticAccumulativeDay) {
    return DAY_IN_MILLISECONDS;
  }

  if (timeStampFormat === ChartXAxisTimeStampFormat.StaticAccumulativeHour) {
    return HOUR_IN_MILLISECONDS;
  }

  return undefined;
}

export function getXAxisMinMax(
  startTime: number,
  stopTime: number,
  timeStampFormat: ChartXAxisTimeStampFormat,
): { min: number; max: number } {
  if (
    timeStampFormat === ChartXAxisTimeStampFormat.StaticAccumulativeDay ||
    timeStampFormat === ChartXAxisTimeStampFormat.AccumulativeDay
  ) {
    /** the beginning of the day of the {@link startTime} */
    const min = startTime - (startTime % DAY_IN_MILLISECONDS);

    /** the end the day of the {@link stopTime} */
    const max = stopTime + (DAY_IN_MILLISECONDS - (stopTime % DAY_IN_MILLISECONDS));

    return { min, max };
  }

  if (
    timeStampFormat === ChartXAxisTimeStampFormat.StaticAccumulativeHour ||
    timeStampFormat === ChartXAxisTimeStampFormat.AccumulativeHour
  ) {
    /** the hour of {@link startTime} */
    const min = startTime - (startTime % HOUR_IN_MILLISECONDS);

    /** the hour after {@link stopTime} */
    const max = stopTime + (HOUR_IN_MILLISECONDS - (stopTime % HOUR_IN_MILLISECONDS));

    return { min, max };
  }

  return {
    min: startTime,
    max: stopTime,
  };
}

export function getStaticLabels(min: number, max: number, interval: number) {
  // number of static labels between min and max
  const count = Math.round((max - min) / interval);
  return [min, ...many((i) => min + interval * i, count), max];
}

export function getXAxisSplitNumber(timeStampFormat: ChartXAxisTimeStampFormat) {
  if (timeStampFormat === ChartXAxisTimeStampFormat.AccumulativeDay) {
    return ACCUMULATIVE_DAY_SPLIT_NUMBER;
  }

  if (timeStampFormat === ChartXAxisTimeStampFormat.AccumulativeHour) {
    return ACCUMULATIVE_HOUR_SPLIT_NUMBER;
  }

  if (timeStampFormat === ChartXAxisTimeStampFormat.Accumulative) {
    return ACCUMULATIVE_SPLIT_NUMBER;
  }

  return undefined;
}

/**
 * get the echarts xAxis config when the time alignment is static
 * @param min
 * @param max
 * @param formatter
 * @param timeStampFormat
 */
export function getStaticXAxisEchartsConfig(
  min: number,
  max: number,
  interval: number,
  formatter: (timeStamp: number) => string,
) {
  // static time stamps label
  const staticLabels = getStaticLabels(min, max, interval);

  return {
    min,
    max,
    interval,
    type: 'time',
    // the split and combined charts only uses a single x axis
    gridIndex: 0,
    splitNumber: -1,
    axisLabel: {
      formatter,
      customValues: staticLabels,
      show: true,
      hideOverlap: true,
      showMinLabel: true,
      showMaxLabel: true,
      rotate: 45,
    },
    // turned off in static alignments because they don't align with the static labels
    axisTick: {
      show: false,
    },
    splitLine: {
      show: false,
    },
  };
}

/**
 * Constructs the x axis configuration for the split chart based on the provided parameters.
 *
 * @param range - start and stop value for the x axis
 * @param timeStampFormat - decides the format of the generated time stamp strings
 * @param intl - required to allow localized formatting of x axis labels
 */
export function getXAxisEchartsConfig(
  { startTime, stopTime }: TimeRange,
  timeStampFormat: ChartXAxisTimeStampFormat,
  intl: IntlShape,
) {
  const { min, max } = getXAxisMinMax(startTime, stopTime, timeStampFormat);
  const formatter = getXAxisFormatter(timeStampFormat, intl);
  const interval = getXAxisIntervals(timeStampFormat);
  const splitNumber = getXAxisSplitNumber(timeStampFormat);

  if (
    timeStampFormat === ChartXAxisTimeStampFormat.StaticAccumulativeDay ||
    timeStampFormat === ChartXAxisTimeStampFormat.StaticAccumulativeHour
  ) {
    if (interval === undefined) {
      throw new Error('Expected interval to be defined for static formatters');
    }

    return getStaticXAxisEchartsConfig(min, max, interval, formatter);
  }

  return {
    min,
    max,
    splitNumber,
    minInterval: interval,
    maxInterval: interval,
    // the split and combined charts only uses a single x axis
    gridIndex: 0,
    interval: undefined, // NOSONAR
    type: 'time',
    axisLabel: {
      show: true,
      customValues: undefined, // NOSONAR
      hideOverlap: true,
      showMinLabel: true,
      showMaxLabel: true,
      rotate: 45,
      formatter,
    },
    axisTick: {
      show: true,
    },
    splitLine: {
      show: true,
    },
  };
}
