import React, { FunctionComponentElement } from 'react';
import { FormattedMessage } from 'react-intl';

import { SetupMessageProps } from './setup-message.definitions';

function SetupMessage({
  isLoading,
  isOutdated,
  isDataTracks,
  isDataTracksSelected,
  timeSpan,
}: SetupMessageProps): FunctionComponentElement<SetupMessageProps> | null {
  // don't show any messages if data is loading
  if (isLoading) {
    return null;
  }

  // the setup stop time precedes the chart start time
  if (isOutdated) {
    return (
      <FormattedMessage
        description="Monitoring overview screen: Message that the process on unit finished over 24h ago"
        defaultMessage="The process on this unit finished over {timeSpan}h ago."
        id="JLqb8e"
        values={{ timeSpan }}
      />
    );
  }
  return (
    <>
      {/* setup does not have data tracks */}
      {!isDataTracks && (
        <FormattedMessage
          description="Monitoring overview screen: Message that no data tracks provided."
          defaultMessage="No data tracks provided."
          id="UmVdEk"
        />
      )}

      {/* no data tracks selected in the data track filter */}
      {isDataTracks && !isDataTracksSelected && (
        <FormattedMessage
          description="Monitoring overview screen: Message that no data tracks selected."
          defaultMessage="No data tracks selected."
          id="Y6o/K4"
        />
      )}
    </>
  );
}

export default SetupMessage;
