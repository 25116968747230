import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { createUrlOrganizationById } from '../use-organization/use-organization.helpers';

const deleteOrganization = async (organizationId: string, accessToken: string): Promise<void> => {
  await fetchData(createUrlOrganizationById(organizationId), {
    method: HttpMethods.Delete,
    headers: {
      Accept: 'application/json',
      'Accept-Encoding': 'gzip, deflate, br',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export default deleteOrganization;
