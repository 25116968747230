import { DATA_COLORS } from '@biss/react-horizon-web';

import { EChartSeriesConfig, MarkLine } from './chart-markline.definitions';

/**
 * Mark lines are shown vertically in the chart at the given timestamp.
 */

export function getMarkLines(markLines: MarkLine[]) {
  if (markLines.length === 0) {
    return undefined;
  }

  return {
    symbol: ['none', 'none'],
    silent: true,
    data: markLines.map((markLine) => ({
      name: markLine.name,
      xAxis: markLine.timestamp,
      label: {
        show: true,
        formatter: markLine.name,
        position: 'insideStartBottom',
        color: markLine.color || DATA_COLORS.gray,
      },
      itemStyle: { color: markLine.color || DATA_COLORS.gray },
      lineStyle: {
        color: markLine.color || DATA_COLORS.gray,
      },
    })),
  };
}

export function getMarkLineSeries(markLines: MarkLine[]): EChartSeriesConfig {
  return {
    type: 'line',
    name: 'Annotations',
    data: [],
    animation: false,
    showSymbol: false,
    markLine: getMarkLines(markLines),
  };
}
