import React, { FunctionComponentElement } from 'react';
import { FormattedMessage } from 'react-intl';

import { useMultipleProcessRecords } from '../../../shared/common/hooks/use-multiple-process-records';
import ProcessRecordVisualization, {
  ProcessRecordVisualizationSkeleton,
} from '../process-record-detail/process-record-visualization';

import useProcessRecordIdsFromSearchQuery from './process-record-comparison.helpers';

/**
 * Scene for comparison of multiple process records
 */
function ProcessRecordComparison(): FunctionComponentElement<unknown> {
  const ids = useProcessRecordIdsFromSearchQuery();
  const results = useMultipleProcessRecords(ids);

  const errorIds = ids.filter((_, index) => results[index].isError);
  // when isSuccess is true the data is available
  // https://tanstack.com/query/v4/docs/react/guides/queries#query-basics
  const processRecords = results.filter((result) => result.isSuccess).map((result) => result.data!); // NOSONAR

  return (
    <div className="h-[calc(100%-var(--actionbar-height))]">
      {ids.length === 0 && (
        <FormattedMessage
          description="Process record comparison scene: URL not correctly used"
          defaultMessage="Wrong usage: Process record IDs have to be provided via the route."
          id="TgXXtZ"
        />
      )}

      {errorIds.length > 0 && (
        <FormattedMessage
          description="Process record comparison scene: Error while loading process records"
          defaultMessage="An error occurred while loading the following process records: {ids}."
          id="vYIGU4"
          values={{
            ids: errorIds.join(', '),
          }}
        />
      )}

      {processRecords.length === 0 && (
        <ProcessRecordVisualizationSkeleton totalProcessRecordCount={ids.length} />
      )}

      {processRecords.length > 0 && (
        <ProcessRecordVisualization
          processRecords={processRecords}
          totalProcessRecordCount={ids.length}
          pageTitle="Comparison"
        />
      )}
    </div>
  );
}
export default ProcessRecordComparison;
