import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { Organization } from '../../types/organization/organization';
import { NewOrganization } from '../../types/organization/new-organization';

import { projectConfigInstance } from '../../../../shared/globals/project-config/project-config-accessor';
import { joinPaths } from '../../../../shared/utils';
import transformOrganizationDTO from '../../types/organization/dto/transform-organization-dto';
import { isOrganizationDTO } from '../../types';

export function createOrganizationsUrl(): string {
  return joinPaths(projectConfigInstance.get('UM_BACKEND_URL'), 'organizations');
}

async function postNewOrganization(
  organizationToCreate: NewOrganization,
  accessToken: string,
): Promise<Organization> {
  const result = await fetchData(createOrganizationsUrl(), {
    method: HttpMethods.Post,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(organizationToCreate),
  });

  isOrganizationDTO(result);

  return transformOrganizationDTO(result);
}

export default postNewOrganization;
