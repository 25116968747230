import React, { FunctionComponentElement, useState } from 'react';
import { Button, List, NotificationMessage } from '@biss/react-horizon-web';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import SearchInput from '../search-input';
import { many } from '../../common/utils';
import RouteDefinition from '../../common/routes/routes.definitions';

import ProcessRecordListItem, { ProcessRecordListItemSkeleton } from './process-record-list-item';
import { ProcessRecordListProps } from './process-record-list.definitions';
import { filterProcessRecords } from './process-records.helpers';

/**
 * Displays process record list.
 */
function ProcessRecordList({
  processRecords,
  headerContent,
  initialSearch = '',
  onCheckedChange,
  onSearch,
  onDelete,
  onPrimary,
  onClick,
  isClickable = false,
  isLoading = false,
  isError = false,
  errorMessage = undefined,
  isActionBarDisabled = false,
  isLink = true,
  selectedItems = [],
}: ProcessRecordListProps): FunctionComponentElement<ProcessRecordListProps> {
  const isLoaded = isLoading === false && isError === false && processRecords;

  const intl = useIntl();
  const [searchParam, setSearchParam] = useState<string>(initialSearch);

  const filteredProcessRecords = filterProcessRecords(searchParam, intl, processRecords);

  const handleSearch = (val: string) => {
    const value = val.trim();
    setSearchParam(value);
    onSearch?.(value);
  };

  const handleChecked = (processRecordId: string, checked: boolean) => {
    onCheckedChange?.(processRecordId, checked);
  };

  const handleDelete = (processRecordId: string) => {
    onDelete?.(processRecordId);
  };

  const handlePrimary = (processRecordId: string) => {
    onPrimary?.(processRecordId);
  };

  const handleClick = (
    processRecordId: string,
    e: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent<HTMLLIElement>,
  ) => {
    onClick?.(processRecordId, e);
  };

  return (
    <List data-testid="ProcessRecordList">
      <List.Header sticky>
        <div className="flex min-w-[10rem] flex-row items-center justify-between gap-1 bg-white py-2">
          <div>{headerContent}</div>
          <SearchInput
            placeholder={intl.formatMessage({
              defaultMessage: 'Type to search...',
              description: 'Process record list search bar placeholder',
              id: '0afAdX',
            })}
            onDebouncedChange={handleSearch}
            initialValue={searchParam}
            expand="auto"
            disabled={!processRecords}
            data-testid="process-record-list-search"
          />
        </div>
      </List.Header>

      <List.Items>
        {isLoading && (
          <div data-testid="ProcessRecordListItemSkeletons">
            {many(
              (i) => (
                <ProcessRecordListItemSkeleton key={i} select="multi" isActionBarDisabled={false} />
              ),
              3,
            )}
          </div>
        )}

        {isLoaded && (
          <>
            {processRecords.length === 0 ? (
              // no process records available at all
              <div className="p-4">
                <NotificationMessage
                  title={intl.formatMessage({
                    defaultMessage: 'No Process Records Available.',
                    id: 'QbkV22',
                    description: 'process Record list: List is empty title',
                  })}
                  action={
                    <Link to={RouteDefinition.Welcome}>
                      <Button kind="ghost">
                        <FormattedMessage
                          defaultMessage="Learn More"
                          id="mMOOKh"
                          description="process Record list: List is empty action"
                        />
                      </Button>
                    </Link>
                  }
                >
                  <FormattedMessage
                    description="process Record list: List is empty message"
                    defaultMessage="Use the Upload DASWare Export button or the export to cloud option on your DASware control system to add process records."
                    id="3t2dFZ"
                  />
                </NotificationMessage>
              </div>
            ) : (
              // no process records could be found
              filteredProcessRecords.length === 0 && (
                <p className="flex justify-center p-6 italic text-gray-600">
                  <FormattedMessage
                    description="process Record list: No matching process records message"
                    defaultMessage="No matching process records."
                    id="S0ksit"
                  />
                </p>
              )
            )}

            {filteredProcessRecords.map((processRecord) => (
              <ProcessRecordListItem
                key={processRecord.processRecordId}
                processRecord={processRecord}
                isActionBarDisabled={isActionBarDisabled}
                onCheckedChange={(checked) => handleChecked(processRecord.processRecordId, checked)}
                onPrimary={() => handlePrimary(processRecord.processRecordId)}
                onDelete={() => handleDelete(processRecord.processRecordId)}
                isSelected={selectedItems.includes(processRecord.processRecordId)}
                isLink={isLink}
                isClickable={isClickable}
                onClick={(_, e) => handleClick(processRecord.processRecordId, e)}
              />
            ))}
          </>
        )}

        {isError && (
          <div className="p-4">
            <NotificationMessage status="error">
              <FormattedMessage
                description="Process record list view: error message when error occurred during loading."
                defaultMessage="An error occurred while loading the process record list: {error}"
                id="ozpAjw"
                values={{ error: errorMessage }}
              />
            </NotificationMessage>
          </div>
        )}
      </List.Items>
    </List>
  );
}

export default ProcessRecordList;
