import { QueryClient } from '@tanstack/react-query';

import { DataTrack, ProcessRecordId } from '../../../shared/common/types/process-record';
import QKey from '../../../shared/common/hooks/keys';

/**
 * add the newly added data track to the local data tracks query cache
 */
// eslint-disable-next-line import/prefer-default-export -- helper file
export function addDataTrackToReactQueryLocalCache(
  queryClient: QueryClient,
  processRecordId: ProcessRecordId,
  dataTrack: DataTrack,
) {
  const queryKey = [QKey.LOCAL_DATA_TRACKS, processRecordId];
  const old = queryClient.getQueryData<DataTrack[]>(queryKey);

  if (old === undefined) {
    queryClient.setQueryData<DataTrack[]>(queryKey, [dataTrack]);
  } else {
    queryClient.setQueryData<DataTrack[]>(queryKey, [...old, dataTrack]);
  }
}
