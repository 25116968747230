import React from 'react';
import { List, NotificationMessage, Tile } from '@biss/react-horizon-web';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import Footer from '../../../shared/components/footer';
import { CodeSnippet } from '../../../shared/components/code-snippet';
import useMarkdown from '../../../shared/common/hooks/use-markdown';
import LoadingIndicator from '../../../shared/components/loading-indicator';

import { CALCULATIONS_DOCUMENTATION_PATH } from './data-track-calculation-documentation.definitions';

function DataTrackCalculationDocumentation() {
  const {
    data: markdown,
    isError,
    isLoading,
    error,
  } = useMarkdown(CALCULATIONS_DOCUMENTATION_PATH);

  return (
    <>
      <Tile className="m-4">
        <Tile.Body>
          {markdown && (
            <article className="prose max-w-none">
              <ReactMarkdown
                components={{
                  /* eslint-disable react/jsx-no-useless-fragment -- overriding html elements */
                  /* eslint-disable react/no-unstable-nested-components -- overriding html elements */
                  code: CodeSnippet,
                  table: List,
                  thead: (e) => <List.Header className="!px-0">{e.children}</List.Header>,
                  tbody: (e) => <>{e.children}</>,
                  tr: (e) => (
                    <List.Item lining="zebra" isSelected={false} clickable={false} select="single">
                      <div className="grid w-full grid-cols-3 grid-rows-1 gap-4">{e.children}</div>
                    </List.Item>
                  ),
                  th: (e) => <span>{e.children}</span>,
                  td: (e) => <span>{e.children}</span>,
                  /* eslint-enable react/jsx-no-useless-fragment -- overriding html elements */
                  /* eslint-enable react/no-unstable-nested-components -- overriding html elements */
                }}
                remarkPlugins={[
                  // support for markdown tables
                  remarkGfm,
                ]}
              >
                {markdown}
              </ReactMarkdown>
            </article>
          )}

          {isLoading && <LoadingIndicator />}

          {isError && error && (
            <NotificationMessage status="error" title="Loading Failed">
              {error.message}
            </NotificationMessage>
          )}
        </Tile.Body>
      </Tile>
      {/* TODO(BIOCL-2732): Move to a central place when the page layout is done */}
      <Footer />
    </>
  );
}

export default DataTrackCalculationDocumentation;
