import { z } from 'zod';

export type CreteProcessRecordViaFormProps = {
  onChange?: (name: string, value: string | undefined) => void;
  onSubmit?: () => void;

  /** used in tests */
  defaultDirtyFields?: (typeof CREATE_PROCESS_RECORD_FIELD_NAMES)[number][];
};

// TODO (BIOCL-3942): use formatjs instead of strings for error messages
export const processRecordCreationDtoSchema = z
  .object({
    displayName: z.string().trim().min(1, { message: 'A title must be provided.' }),
    deviceName: z.string().trim().min(1, { message: 'A device name must be provided.' }),
    unit: z.string().trim().min(1, { message: 'A unit must be provided.' }),

    startTimestamp: z.coerce.date(),
    stopTimestamp: z.coerce.date(),
    inoculationTimestamp: z.date().optional(),

    operator: z.string().default(''),
    comment: z.string().default(''),
  })
  .refine((data) => data.stopTimestamp > data.startTimestamp, {
    message: 'The stop time cannot be earlier than the start time.',
    path: ['stopTimestamp'],
  })
  .refine(
    (data) =>
      // if inoculation time is set, the following need to be valid: start time < inoculation time < stop time
      data.inoculationTimestamp === undefined ||
      (data.stopTimestamp > data.inoculationTimestamp &&
        data.inoculationTimestamp > data.startTimestamp),
    {
      message: 'The inoculation time must be between the start and stop time.',
      path: ['inoculationTimestamp'],
    },
  );

export const CREATE_PROCESS_RECORD_FIELD_NAMES = [
  'displayName',
  'deviceName',
  'unit',
  'startTimestamp',
  'stopTimestamp',
  'inoculationTimestamp',
  'operator',
  'comment',
] as const satisfies string[];

export type ProcessRecordCreationDto = z.infer<typeof processRecordCreationDtoSchema>;
