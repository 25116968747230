import React from 'react';
import {
  AuthenticationTemplate,
  useAuthContext,
  isAdministrator,
  UnauthenticatedTemplate,
} from '@biss/react-auth-web';
import { PageLayout } from '@biss/react-horizon-web';

import Footer from '../../components/footer';

import WelcomeSkeleton from './welcome.skeleton';
import {
  WELCOME_ANALYTICS_SECTION,
  WELCOME_MONITORING_SECTION,
  WELCOME_USER_MANAGEMENT_SECTION,
} from './welcome.definitions';
import WelcomeSection from './welcome-sections';
import WhatsNew from './whats-new';

/**
 * Scene to show when a user tries to open a route that is not available.
 * It allows the user to redirect to the base scene.
 */
function Welcome() {
  const { account } = useAuthContext();

  const canViewUserManagement = account && isAdministrator(account);

  return (
    <PageLayout
      sidebarOpen="none"
      className="relative !h-[calc(100dvh-var(--header-bar-height,0))] !overflow-y-visible"
    >
      <PageLayout.Main>
        <UnauthenticatedTemplate>
          <WelcomeSkeleton />
        </UnauthenticatedTemplate>
        <AuthenticationTemplate>
          <PageLayout.Main.ActionBar sidePanelOpen="none" pageTitle="Welcome to BioNsight cloud!" />
          <PageLayout.Main.Content className="flex h-full flex-col px-4 lg:w-full">
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
              <div className="flex flex-col gap-4">
                <WelcomeSection
                  icon={WELCOME_ANALYTICS_SECTION.icon}
                  subsections={WELCOME_ANALYTICS_SECTION.subsections}
                  title={WELCOME_ANALYTICS_SECTION.title}
                />

                <WelcomeSection
                  icon={WELCOME_MONITORING_SECTION.icon}
                  subsections={WELCOME_MONITORING_SECTION.subsections}
                  title={WELCOME_MONITORING_SECTION.title}
                />

                {canViewUserManagement && (
                  <WelcomeSection
                    icon={WELCOME_USER_MANAGEMENT_SECTION.icon}
                    subsections={WELCOME_USER_MANAGEMENT_SECTION.subsections}
                    title={WELCOME_USER_MANAGEMENT_SECTION.title}
                  />
                )}
              </div>

              <WhatsNew />
            </div>
            <div className="mt-auto pt-4">
              <Footer />
            </div>
          </PageLayout.Main.Content>
        </AuthenticationTemplate>
      </PageLayout.Main>
    </PageLayout>
  );
}

export default Welcome;
