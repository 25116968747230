import { useAuthContext } from '@biss/react-auth-web';
import { useEffect } from 'react';

import useAppInsights from '../../common/hooks/use-app-insights';

function useTrackAccountStatus() {
  const authContext = useAuthContext();
  const appInsights = useAppInsights();

  // track authenticated user contesxt
  useEffect(() => {
    if (authContext.account) {
      appInsights.setAuthenticatedUserContext(authContext.account.accountId);
    } else {
      appInsights.clearAuthenticatedUserContext?.();
    }
  }, [authContext.account === null]);
}

export default useTrackAccountStatus;
