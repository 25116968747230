// overview configuration Time Span (hours)
export const TIME_SPANS: number[] = [1, 6, 12, 24] as const; // NOSONAR
export type TimeSpan = (typeof TIME_SPANS)[number];

// Automatic updates Intervals and Timeouts
export const SETUPS_INTERVAL = 60_000;
export const DATA_TRACKS_FROM_TIMESTAMP_INTERVAL = 30_000;
export const EVENTS_FROM_TIMESTAMP_INTERVAL = 30_000;
export const AUTO_UPDATE_NUMBER_DETAILS = 20;
export const AUTO_UPDATE_NUMBER_OVERVIEW: Record<TimeSpan, number> = {
  1: 20,
  6: 40,
  12: 80,
  24: 120,
};

// Errors refetch Intervals
export const RETRY_DELAY = 60_000;

// monitoring data tracks local storage key
export const MONITORING_DATA_TRACKS = 'monitoringDataTracks:';

// monitoring Y-axis range local storage key
export const Y_AXIS_RANGE = 'yAxisRange:';

// monitoring time span local storage key
export const TIME_SPAN = 'timeSpan:';

// url constants
export const CONTROL_PROCEDURES = 'control-procedures';
export const SETUP = 'setup';
export const SETUPS = 'setups';
export const DATA_TRACKS = 'data-tracks';
export const DATA_POINTS = 'data-points';
