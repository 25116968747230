import React, { FunctionComponentElement } from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * Component showing an indication that some state is currently loading.
 */
function LoadingIndicator(): FunctionComponentElement<unknown> {
  return (
    <div data-testid="LoadingIndicator">
      <FormattedMessage
        description="Loading indicator: content of the loading indicator"
        defaultMessage="Loading.."
        id="LdvhJ8"
      />
    </div>
  );
}

export default LoadingIndicator;
