import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { createUrlOrganizationById } from '../use-organization/use-organization.helpers';

async function putOrganization(
  organizationId: string,
  organizationName: string,
  accessToken: string,
): Promise<void> {
  await fetchData(createUrlOrganizationById(organizationId), {
    method: HttpMethods.Put,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      organizationName,
    }),
  });
}

export default putOrganization;
