import getCookies from '../../utils/get-cookies';

import { ConsentContext } from './consent-globals';

import {
  DEFAULT_CONSENT_CONTEXT,
  FUNCTIONAL_COOKIES_KEY,
  ONE_TRUST_CONSENT_COOKIE_KEY,
  ONE_TRUST_ENVIRONMENT,
  PERFORMANCE_COOKIES_KEY,
} from './consent.definitions';
import { consentCookieSchema } from './consent.validators';

export function parseConsentCookie(cookieValue: string) {
  const pairs = cookieValue.split('&');

  return Object.fromEntries(
    pairs.map((pair) => {
      const [key, value] = pair.split('=');
      return [key, decodeURIComponent(value.replace(/\+/g, ' '))];
    }),
  );
}

export function getConsentVariablesFromCookie(): ConsentContext | undefined {
  const cookies = getCookies();
  const consentCookie = cookies.get(ONE_TRUST_CONSENT_COOKIE_KEY);

  // early return if the cookie does not exist
  if (consentCookie === undefined) {
    return undefined;
  }

  const parsed = parseConsentCookie(consentCookie);

  const validated = consentCookieSchema.safeParse(parsed);

  // early return if the cookie was not in the expected shape
  if (validated.success === false) {
    return undefined;
  }

  const { groups } = validated.data;

  return {
    necessary: true, // assume necessary is always selected when there is a cookie
    performance: groups.includes(`${PERFORMANCE_COOKIES_KEY}:1`),
    functional: groups.includes(`${FUNCTIONAL_COOKIES_KEY}:1`),
  };
}

export function getConsentContext() {
  return getConsentVariablesFromCookie() ?? DEFAULT_CONSENT_CONTEXT;
}

export function updateStateOnConsentChangeEffect(setFlags: (ctx: ConsentContext) => void) {
  const handleConsentChange = () => setFlags(getConsentContext());
  window.addEventListener('consent.onetrust', handleConsentChange);
  return () => window.removeEventListener('consent.onetrust', handleConsentChange);
}

/**
 * Load the OneTrust script dynamically to allow using the test script in all
 *  environments except the production environments.
 *
 * Currently the frontend is available via biocloud and bionsight domains.
 *  For each of the domains, the respective one trust script is being loaded.
 */
export function addOneTrustScriptOnMountEffect(environment: string, host: string) {
  const domainScriptName = 'data-domain-script';
  const biocloudScriptId = '018fc35e-a7e3-7953-bbc4-b322b857b101';
  const bionsightScriptId = '01905334-0db5-7cd5-be7d-24441a9b1210';
  const requestFromBiocloudDomain = host.includes('biocloud.dasgip.de');

  const oneTrustScript = document.createElement('script');
  oneTrustScript.type = 'text/javascript';
  oneTrustScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
  oneTrustScript.id = 'one-trust-script';

  if (requestFromBiocloudDomain && environment === ONE_TRUST_ENVIRONMENT) {
    oneTrustScript.setAttribute(domainScriptName, biocloudScriptId);
  } else if (environment === ONE_TRUST_ENVIRONMENT) {
    oneTrustScript.setAttribute(domainScriptName, bionsightScriptId);
  } else if (requestFromBiocloudDomain) {
    oneTrustScript.setAttribute(domainScriptName, `${biocloudScriptId}-test`);
  } else {
    oneTrustScript.setAttribute(domainScriptName, `${bionsightScriptId}-test`);
  }

  document.getElementById('one-trust-placeholder')?.replaceWith(oneTrustScript);
}
