export const DEFAULT_TRUNCATE_MID_MAX_LENGTH = 20;
export const TRUNCATE_MID_ELLIPSES = '...';

/**
 * Truncates the input string by adding "..." in the middle if it exceeds the specified {@link maxLength}.
 * If the string is shorter or equal to {@link maxLength}, it returns the string unchanged.
 *
 * @param {string} text - The input string to be truncated.
 * @param {number} maxLength - The maximum length allowed for the resulting string, including the ellipsis.
 * @returns {string} The truncated string with "..." in the middle if necessary.
 *
 * @example
 * truncateMid('abcdefghij', 7); // Returns 'ab...ij'
 *
 * @example
 * truncateMid('abc', 5); // Returns 'abc'
 *
 * @example
 * truncateMid('abcdef', 2); // Returns 'ab'
 */
function truncateMid(text: string, maxLength = DEFAULT_TRUNCATE_MID_MAX_LENGTH): string {
  // if the string is already within the allowed length, return it unchanged
  if (text.length <= maxLength) {
    return text;
  }

  // if maxLength is too small to even fit "..."
  if (maxLength <= TRUNCATE_MID_ELLIPSES.length) {
    return text.substring(0, maxLength);
  }

  const half = (maxLength - TRUNCATE_MID_ELLIPSES.length) / 2;

  const leftLength = Math.ceil(half);
  const rightLength = Math.floor(half);

  const start = text.substring(0, leftLength);
  const end = text.substring(text.length - rightLength);

  return `${start}${TRUNCATE_MID_ELLIPSES}${end}`;
}

export default truncateMid;
