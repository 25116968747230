import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import { ProcessRecordId } from '../../../../shared/common/types/process-record';
import QKey from '../../../../shared/common/hooks/keys';
import { EventListItem } from '../../../../shared/components/event-list/event-list.definitions';
import useClient from '../../../../shared/common/hooks/use-client';
import {
  ProcessRecordEvent,
  PrsException,
} from '../../../../shared/common/types/generated/prs.generated';

export default function useProcessRecordEvents(
  processRecordId: ProcessRecordId,
): UseQueryResult<EventListItem[], Error> {
  const { acquireAccessToken } = useAuthentication();
  const { prsClient } = useClient();

  return useQuery<ProcessRecordEvent[], PrsException, EventListItem[], string[]>({
    queryKey: [QKey.PROCESS_RECORDS, processRecordId, QKey.EVENTS],
    queryFn: async () => {
      // fetch
      prsClient.authorize(await acquireAccessToken());

      return prsClient.getProcessRecordEventsById(processRecordId);
    },
  });
}
