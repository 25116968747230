import QKey from '../../../../shared/common/hooks/keys';
import useUpload from '../../../../shared/common/hooks/use-upload';

import { createUploadDataTrackUrl } from './use-upload-data-track.helpers';

export default function useUploadDataTrack(contentType = 'unknown') {
  return useUpload({
    key: [QKey.PARSED_DATA_TRACK],
    url: createUploadDataTrackUrl(),
    headers: {
      'Content-Type': contentType,
    },
  });
}
