import React, { FunctionComponentElement } from 'react';

import DataTrackValue from '../data-track-value';

import { DataTrackCurrentValueProps } from './data-track-current-value.definitions';
import { getStyles } from './data-track-current-value.helpers';

function DataTrackCurrentValue({
  dataTrack,
  disabled = false,
}: DataTrackCurrentValueProps): FunctionComponentElement<DataTrackCurrentValueProps> {
  const { dataTrackType, color, engineeringUnit, dataPoints, fractionalDigits } = dataTrack;
  const lastValue = dataPoints.length ? dataPoints[dataPoints.length - 1].v : undefined;

  return (
    <div
      className="flex rounded-ee-sm rounded-se-sm border-l-4 bg-gray-100 px-2 py-1"
      // TODO(BIOCL-1897): replace styles with transparent color when they are added to the react-horizon-web
      style={getStyles(color, disabled)}
      data-testid="DataTrackCurrentValue"
    >
      <span className="mr-2" data-testid="DataTrackCurrentValue-type">
        {dataTrackType}
      </span>
      <DataTrackValue
        value={lastValue}
        engineeringUnit={engineeringUnit}
        fractionalDigits={fractionalDigits}
      />
    </div>
  );
}

export default DataTrackCurrentValue;
