import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { DataHowLabConfiguration } from '../../types';
import { createGetDataHowLabConfigurationUrl } from '../use-datahowlab-configuration/use-datahowlab-configuration.helpers';

async function postDataHowLabConfiguration(
  organizationId: string,
  configuration: DataHowLabConfiguration,
  accessToken: string,
): Promise<boolean> {
  const isValid = await fetchData(createGetDataHowLabConfigurationUrl(organizationId), {
    method: HttpMethods.Post,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(configuration),
  });
  return isValid === true;
}

export default postDataHowLabConfiguration;
