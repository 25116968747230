import { Roles } from '@biss/react-auth-web';

import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { joinPaths } from '../../../../shared/utils';
import { projectConfigInstance } from '../../../../shared/globals/project-config/project-config-accessor';
import { MfaFactor } from '../../types/member/dto/invitation-dto';

export function createUpdateMemberUrl(memberId: string): string {
  return joinPaths(projectConfigInstance.get('UM_BACKEND_URL'), 'members', memberId);
}

export async function putMember(
  memberId: string,
  memberRoles: Roles,
  mfa: boolean,
  accessToken: string,
): Promise<void> {
  await fetchData(createUpdateMemberUrl(memberId), {
    method: HttpMethods.Put,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      memberRoles,
      mfaFactor: (mfa ? 'Email' : 'None') satisfies MfaFactor,
    }),
  });
}
