/* eslint-disable import/prefer-default-export -- helper file */

import {
  NO_RETRY_STATUS_CODES,
  QUERY_RETRY_COUNT,
  RetryFunction,
} from './query-client.definitions';

export const retryStrategy: RetryFunction = (failureCount, error) => {
  // if the error is a http error and is not supposed to be retried
  if ('status' in error && NO_RETRY_STATUS_CODES.includes(Number(error.status))) {
    return false;
  }

  // retry 3 times
  return failureCount <= QUERY_RETRY_COUNT;
};

/* eslint-enable import/prefer-default-export -- helper file */
