import { useMutation } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import { DataTrack } from '../../../../shared/common/types/process-record';
import QKey from '../../../../shared/common/hooks/keys';
import { CalculationRequest } from '../../../../shared/common/types/process-record/dto/calculator';
import useClient from '../../../../shared/common/hooks/use-client';
import { PrsException } from '../../../../shared/common/types/generated/prs.generated';

function useCalculateDataTrack() {
  const { acquireAccessToken } = useAuthentication();
  const { prsClient } = useClient();

  return useMutation<DataTrack, PrsException, CalculationRequest, unknown>({
    mutationKey: [QKey.CALCULATED_DATA_TRACK],
    async mutationFn(data) {
      // fetch
      prsClient.authorize(await acquireAccessToken());

      return prsClient.dataTrackCalculator(data);
    },
  });
}

export default useCalculateDataTrack;
