import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { Subscription } from '../../types';

import { fetchSubscriptions } from './use-subscriptions.helpers';

function useSubscriptions(organizationId: string): UseQueryResult<Subscription[] | null, Error> {
  const { acquireAccessToken } = useAuthentication();
  return useQuery({
    queryKey: [QKey.SUBSCRIPTIONS, organizationId],
    queryFn: async () => {
      const accessToken = await acquireAccessToken();
      return fetchSubscriptions(organizationId, accessToken);
    },
  });
}

export default useSubscriptions;
