import { DataTrackUploadStageUnion, StageKey } from './data-track-upload-modal.definitions';

// eslint-disable-next-line import/prefer-default-export -- helper file //NOSONAR
export const caclulateProgressFromStage = (stage: DataTrackUploadStageUnion): number => {
  switch (stage.key) {
    case StageKey.Uploading:
      return (stage.progress.progress ?? 0) * 100;
    case StageKey.Uploaded:
      return 100;
    default:
      return 0;
  }
};
