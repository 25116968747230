import { Select } from '@biss/react-horizon-web';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { DataTrackTimeAlignment } from '../../scenes/process-record-detail/process-record-visualization/process-record-visualization.definitions';

import { ProcessRecordAlignmentOptionsProps } from './process-record-alignment-options.definitions';

function ProcessRecordAlignmentOptions({
  onChangeAlignment,
  hasInoculationTime,
  value = '',
  defaultOpen = false,
}: ProcessRecordAlignmentOptionsProps) {
  const intl = useIntl();

  const handleAlignmentOption = (newValue: string) => {
    onChangeAlignment(Number(newValue));
  };

  const isRelativeToInoculationDisabled = hasInoculationTime === false;

  return (
    <Select
      className="!min-w-[12rem]"
      data-testid="alignment-combobox"
      placeholder="Select alignment option"
      onValueChange={handleAlignmentOption}
      value={value}
      defaultOpen={defaultOpen}
      expand="fit"
      label="Timestamp Alignment"
    >
      <Select.Item value={DataTrackTimeAlignment.RelativeToStartTime.toString()}>
        <FormattedMessage
          description="ProcessRecordAlignmentOptions: Button to align data tracks relative to start time"
          defaultMessage="Relative to start time"
          id="SMKJBH"
        />
      </Select.Item>
      <Select.Item
        disabled={isRelativeToInoculationDisabled}
        title={
          isRelativeToInoculationDisabled
            ? intl.formatMessage({
                description:
                  'ProcessRecordAlignmentOptions: Inoculation time is not available in all process records',
                defaultMessage:
                  'Not all of the selected process records have a set inoculation time. Therefore, alignment along inoculation time is not available.',
                id: 'hvFP2D',
              })
            : undefined
        }
        value={DataTrackTimeAlignment.RelativeToInoculationTime.toString()}
      >
        <FormattedMessage
          description="ProcessRecordAlignmentOptions: Button to align data tracks relative to inoculation time"
          defaultMessage="Relative to inoculation time"
          id="yAesx9"
        />
      </Select.Item>
      <Select.Item value={DataTrackTimeAlignment.Absolute.toString()}>
        <FormattedMessage
          description="ProcessRecordAlignmentOptions: Button to select absolute time"
          defaultMessage="Absolute time"
          id="kjhCT7"
        />
      </Select.Item>
    </Select>
  );
}

export default ProcessRecordAlignmentOptions;
