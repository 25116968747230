import { ModalProps } from '@biss/react-horizon-web';

import { ProgressState } from '../../../shared/common/hooks/use-upload';
import { DataTrack, ProcessRecordId } from '../../../shared/common/types/process-record';

export type DataTrackUploadModalProps = Pick<ModalProps, 'trigger' | 'open' | 'onOpenChange'> & {
  /** the id of the proccess record datatracks will be added to */
  processRecordId: ProcessRecordId;
};

/**
 * The state management within the {@link DatatrackUploadModal} is handeled using sum types.
 * This was concluded to be a nice fit due to the multi-stage nature of the upload process,
 * and that certain data is available in specific stages but not others.
 * Using a sum type and the {@link onStage} function, the typescript compiler can assist
 * in tracking which piece of state is available at every point in the process.
 * */

/**
 * A single object representing the component state in a specific stage identified by the @property {key}
 * */
type Stage<UKey extends StageKey, TData extends {}> = {
  key: UKey;
} & TData;

/**
 * Keys denoting every variant of the state object
 */
export enum StageKey {
  Unselected = 'unselected',
  Selected = 'selected',
  // csv
  Uploading = 'uploading',
  Uploaded = 'uploaded',
  UploadFailed = 'upload-failed',
  // datatrack & metadata
  Submitting = 'submitting',
  Submitted = 'submitted',
  SubmitFailed = 'submit-failed',
}

export type Unselected = Stage<StageKey.Unselected, {}>;
export type Selected = Stage<StageKey.Selected, { file: File }>;
export type Uploading = Stage<StageKey.Uploading, { file: File; progress: ProgressState }>;
export type Uploaded = Stage<
  StageKey.Uploaded,
  { file: File; data: DataTrack[]; isValid: boolean }
>;
export type UploadFailed = Stage<StageKey.UploadFailed, { file: File; error: Error }>;
export type Submitting = Stage<StageKey.Submitting, { data: DataTrack[] }>;
export type Submitted = Stage<StageKey.Submitted, {}>;
export type SubmitFailed = Stage<
  StageKey.SubmitFailed,
  { data: DataTrack[]; error: Error; isValid: boolean }
>;

export type StageMap = {
  [StageKey.Unselected]: Unselected;
  [StageKey.Selected]: Selected;
  [StageKey.Uploading]: Uploading;
  [StageKey.Uploaded]: Uploaded;
  [StageKey.UploadFailed]: UploadFailed;
  [StageKey.Submitting]: Submitting;
  [StageKey.Submitted]: Submitted;
  [StageKey.SubmitFailed]: SubmitFailed;
};

/** State object union type */
export type DataTrackUploadStageUnion = StageMap[keyof StageMap];

/**
 * Narrows down the type of the multi-variant state object using the passed in the variant (stage) keys
 * @param stage state object containing the stage key and the corresponding data
 * @param stages stage keys used to narrow down the type of the state object
 * @returns true if the state object is a variant denoted by any of the passed in stage keys
 */
export function onStage<
  T1 extends keyof StageMap,
  T2 extends keyof StageMap,
  T3 extends keyof StageMap,
  T4 extends keyof StageMap,
  T5 extends keyof StageMap,
  T6 extends keyof StageMap,
  T7 extends keyof StageMap,
  T8 extends keyof StageMap,
>(
  stage: DataTrackUploadStageUnion,
  ...stages: readonly (T1 | T2 | T3 | T4 | T5 | T6 | T7 | T8)[]
): stage is
  | StageMap[T1]
  | StageMap[T2]
  | StageMap[T3]
  | StageMap[T4]
  | StageMap[T5]
  | StageMap[T6]
  | StageMap[T7]
  | StageMap[T8] {
  return stages.some((key) => stage.key === key);
}

/**
 * The state of the stage when the component is first mounted
 * used extensively in tests
 */
export const INITIAL_STAGE_STATE: () => DataTrackUploadStageUnion = () => ({
  key: StageKey.Unselected,
});
