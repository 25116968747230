import React from 'react';
import { FormattedMessage } from 'react-intl';

import { SetupOverviewYAxisProps } from './setup-overview-y-axis.definitions';
import YAxisRangeControls from './y-axis-range-controls/y-axis-range-controls';

function SetupOverviewYAxis({
  dataTracks,
  yAxisRanges,
  onYAxisRangeChange,
}: SetupOverviewYAxisProps): React.FunctionComponentElement<SetupOverviewYAxisProps> {
  return (
    <div data-testid="setup-overview-y-axis-range" className="flex flex-col bg-white p-4">
      <div className="mb-2 self-start text-sm font-bold">
        <FormattedMessage
          description="Monitoring Overview Y Axis Range: Y-Axis Range title"
          defaultMessage="Y-Axis Range"
          id="zVMP/N"
        />
      </div>
      {Object.keys(dataTracks).map((dataTrackType) => (
        <YAxisRangeControls
          dataTrackType={dataTrackType}
          yAxisRange={yAxisRanges?.get(dataTrackType)}
          disabled={!dataTracks[dataTrackType].selected}
          onYAxisRangeChange={onYAxisRangeChange}
          key={dataTrackType}
          engineeringUnit={dataTracks[dataTrackType].engineeringUnit}
        />
      ))}
    </div>
  );
}

export default SetupOverviewYAxis;
