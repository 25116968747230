import { useEffect, useRef, useState } from 'react';

import useDocumentVisiblity from '../../../../shared/common/hooks/use-document-visibility';

import {
  AUTO_UPDATES_DOCUMENT_TIMEOUT,
  AUTO_UPDATES_TIMEOUT,
} from './use-automatic-updates.definitions';

/** The useAutomaticUpdates hook is used to enable automatic updates when browser tab is visible and disable it when hidden. */
const useAutomaticUpdates = () => {
  const [autoUpdate, setAutoUpdate] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const isDocumentVisible = useDocumentVisiblity();

  const clearTimeoutRef = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(
    () => () => {
      clearTimeoutRef();
    },
    [],
  );

  useEffect(() => {
    if (isDocumentVisible) {
      clearTimeoutRef();
      timeoutRef.current = setTimeout(() => {
        setAutoUpdate(true);
      }, AUTO_UPDATES_TIMEOUT);
    } else {
      timeoutRef.current = setTimeout(() => {
        setAutoUpdate(false);
      }, AUTO_UPDATES_DOCUMENT_TIMEOUT);
    }
  }, [isDocumentVisible]);

  return autoUpdate;
};

export default useAutomaticUpdates;
