import { iface, opt, ITypeSuite, union, indexKey } from 'ts-interface-checker';

const ErrorDescriptor = iface([], {
  id: 'string',
  defaultMessage: 'string',
});

const ValuesObject = iface([], {
  [indexKey]: union('string', 'number', 'boolean'),
});

const ApiErrorObject = iface([], {
  descriptor: 'ErrorDescriptor',
  message: 'string',
  values: opt('ValuesObject'),
  debugInfo: opt('any'),
  traceId: opt('string'),
});

const exportedTypeSuite: ITypeSuite = {
  ErrorDescriptor,
  ValuesObject,
  ApiErrorObject,
};

export default exportedTypeSuite;
