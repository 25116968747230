import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { Subscription, transformSubscriptionDTO } from '../../types';
import {
  createGetSubscriptionsUrl,
  isSubscriptionDTO,
} from '../use-subscriptions/use-subscriptions.helpers';

async function putSubscriptions(
  organizationId: string,
  subscriptions: Subscription[],
  accessToken: string,
): Promise<Subscription[]> {
  const updatedSubscriptions = await fetchData(createGetSubscriptionsUrl(organizationId), {
    method: HttpMethods.Put,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(subscriptions),
  });
  if (!Array.isArray(updatedSubscriptions)) {
    throw new Error('expected to receive an array of subscriptions.');
  }

  // throws an error when object is not in the correct format
  updatedSubscriptions.forEach(isSubscriptionDTO);
  return updatedSubscriptions.map((subscription) => transformSubscriptionDTO(subscription));
}

export default putSubscriptions;
