import React from 'react';
import { Modal } from '@biss/react-horizon-web';
import { FormattedMessage } from 'react-intl';

import { DataTrackFormulaModalSuccessProps } from './data-track-formula-modal.definitions';

function DataTrackFormulaModalSuccess({ open, onOpenChange }: DataTrackFormulaModalSuccessProps) {
  return (
    <Modal
      open={open}
      onOpenChange={onOpenChange}
      title={
        <FormattedMessage
          defaultMessage="Create Data Track with Formula"
          id="sw3zjq"
          description="Create Data Track with Formula: Title"
        />
      }
    >
      <Modal.Content>
        <FormattedMessage
          description="Calculate Data Track Success Message"
          defaultMessage="Data Track was successfully calculated. Select it from the Data Tracks List to view it in the graph."
          id="urDrVb"
        />
      </Modal.Content>
      <Modal.ButtonGroup>
        <Modal.Close asChild>
          <Modal.Button>
            <FormattedMessage
              defaultMessage="Close"
              id="QwTik9"
              description="Create Data Track with Formula: Close"
            />
          </Modal.Button>
        </Modal.Close>
      </Modal.ButtonGroup>
    </Modal>
  );
}

export default DataTrackFormulaModalSuccess;
