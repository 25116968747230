import React from 'react';

import { Navigate, Route } from 'react-router-dom';

import SetupOverview from '../../scenes/setup-overview';
import SetupDetails from '../../scenes/setup-details';
import Protected from '../../../shared/components/protected/protected';
import { Title } from '../../../shared/components/title';
import generateTitle from '../../../shared/utils/generate-title';

const routes = (
  <>
    <Route
      index
      element={
        <Title text="Redirecting to '/setups'">
          <Navigate to="setups" />
        </Title>
      }
    />
    <Route path="setups">
      <Route
        index
        element={
          <Protected>
            <Title text={generateTitle('Monitoring', 'Units')}>
              <SetupOverview />
            </Title>
          </Protected>
        }
      />
      <Route
        path=":id"
        element={
          <Protected>
            <Title text={generateTitle('Monitoring', 'Unit Details')}>
              <SetupDetails />
            </Title>
          </Protected>
        }
      />
    </Route>
  </>
);

export default routes;
