import { useQueries, useQueryClient, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import {
  DataTrack,
  ProcessRecordId,
  StructuredProcessRecordObject,
} from '../../types/process-record';
import QKey from '../keys';
import useClient from '../use-client';
import { PrsException } from '../../types/generated/prs.generated';
import { transformProcessRecordObject } from '../../types/process-record/transformers/process-record-dto-transformer';

export default function useMultipleProcessRecords(
  processRecordIds: ProcessRecordId[],
): UseQueryResult<StructuredProcessRecordObject, PrsException>[] {
  const queryClient = useQueryClient();
  const { acquireAccessToken } = useAuthentication();
  const { prsClient } = useClient();

  return useQueries({
    queries: processRecordIds.map<UseQueryOptions<StructuredProcessRecordObject, Error>>(
      (processRecordId) => ({
        queryKey: [QKey.PROCESS_RECORDS, processRecordId],
        queryFn: async () => {
          // fetch
          prsClient.authorize(await acquireAccessToken());
          const response = await prsClient.getProcessRecordById(undefined, processRecordId);

          // transform
          const transformed = transformProcessRecordObject(response);

          // update data tracks cache
          transformed.dataTracks
            // filter out data tracks that don't have data points
            .filter((dataTrack): dataTrack is DataTrack => dataTrack.dataPoints !== undefined)
            .forEach((dataTrack) =>
              queryClient.setQueryData<DataTrack>(
                [QKey.ANALYTICS_DATA_TRACK, processRecordId, dataTrack.dataTrackId],
                () => dataTrack,
              ),
            );

          return transformed;
        },
      }),
    ),
  });
}
