import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { Member, NewInvitation } from '../../types';
import { ApiError } from '../../../../shared/common/types/api-error/api-error';

import { postNewMemberInvitation } from './use-invite-new-member.helpers';

function useInviteNewMember(): UseMutationResult<Member, ApiError, NewInvitation, unknown> {
  const queryClient = useQueryClient();
  const { acquireAccessToken } = useAuthentication();
  return useMutation({
    mutationKey: [QKey.MEMBERS, 'new'],
    mutationFn: async (memberToInvite: NewInvitation) => {
      const accessToken = await acquireAccessToken();
      return postNewMemberInvitation(memberToInvite, accessToken);
    },
    onMutate: async (variables) => {
      // Cancel current queries for the member list
      await queryClient.cancelQueries({ queryKey: [QKey.MEMBERS] });

      const validUntil = new Date();
      validUntil.setDate(validUntil.getDate() + 1);

      // Create optimistic member invitation
      const optimisticMemberInvitation: Member = {
        ...variables,
        memberId: 'new-member',
        memberDisplayName: variables.memberDisplayName ?? variables.memberEmail,
        invitation: {
          invitationId: 'new-member',
          invitedAt: new Date(),
          mfa: variables.mfa,
          validUntil,
        },
      };

      // Add optimistic member invitation to member list
      queryClient.setQueryData<Member[]>([QKey.MEMBERS], (old) =>
        old?.concat([optimisticMemberInvitation]),
      );

      // Return context with the optimistic member invitation
      return { optimisticMemberInvitation };
    },
    onSuccess: (result, _, context) => {
      // Replace optimistic member invitation in the member list with the result
      queryClient.setQueryData<Member[]>([QKey.MEMBERS], (old) =>
        old?.map((member) =>
          member.memberId === context?.optimisticMemberInvitation.memberId ? result : member,
        ),
      );
    },
    onError: (_1, _2, context) => {
      // Remove optimistic member invitation from the invitations list
      queryClient.setQueryData<Member[]>([QKey.MEMBERS], (old) =>
        old?.filter((member) => member.memberId !== context?.optimisticMemberInvitation.memberId),
      );
    },
  });
}

export default useInviteNewMember;
