import { useQuery } from '@tanstack/react-query';

import QKey from '../keys';

import { fetchMarkdown } from './use-markdown.helpers';

const useMarkdown = (url: string) =>
  useQuery({
    queryKey: [QKey.DOCUMENTATION, url],
    queryFn: () => fetchMarkdown(url),
    staleTime: Infinity,
  });

export default useMarkdown;
