import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { ApiError } from '../../../../shared/common/types/api-error/api-error';
import {
  DataTrackId,
  ControlProcedureId,
  StructuredSetup,
  SetupId,
} from '../../../../shared/common/types/setup';

import useClient from '../../../../shared/common/hooks/use-client';

function useDeleteDataTrack(
  controlProcedureId: ControlProcedureId,
  setupId: SetupId,
  { onSuccess, ...options }: UseMutationOptions<unknown, ApiError, DataTrackId, unknown> = {},
) {
  const queryClient = useQueryClient();
  const sourceQueryKey = [QKey.SETUPS, setupId];
  const { acquireAccessToken } = useAuthentication();
  const { pmsClient } = useClient();

  return useMutation({
    ...options,
    mutationFn: async (dataTrackId: DataTrackId) => {
      pmsClient.authorize(await acquireAccessToken());
      return pmsClient.deleteCustomDataTrackAndDataPoints(controlProcedureId, setupId, dataTrackId);
    },
    onMutate: (dataTrackId: DataTrackId) => {
      const previousSetupData = queryClient.getQueryData<StructuredSetup>(sourceQueryKey);

      if (!previousSetupData) {
        throw new Error(`Expected query with key ${JSON.stringify(sourceQueryKey)} to exist.`);
      }

      queryClient.setQueryData<StructuredSetup>(sourceQueryKey, {
        ...previousSetupData,
        dataTracks: previousSetupData.dataTracks.filter((dt) => dt.dataTrackId !== dataTrackId),
      });

      return previousSetupData;
    },
    onError: (_, __, previous) => {
      queryClient.setQueryData(sourceQueryKey, previous);
    },
    onSuccess(data, variables, context) {
      onSuccess?.(data, variables, context);
    },
  });
}

export default useDeleteDataTrack;
