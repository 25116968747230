import React, { FunctionComponentElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import useProcessRecord from '../../../shared/common/hooks/use-process-record';

import ProcessRecordVisualization, {
  ProcessRecordVisualizationSkeleton,
} from './process-record-visualization';

/**
 * Scene to show details of a process record.
 *
 *  IMPORTANT: it is required to pass an id-property via the route (i.e. /analytics/:id)
 *    since this component loads the id via the 'useParams'-hook.
 */
function ProcessRecordDetail(): FunctionComponentElement<unknown> {
  const { id } = useParams();

  if (id === undefined) {
    throw new Error('An id has to be provided via the route.');
  }
  const { data: processRecord, error, isError, isLoading } = useProcessRecord(id);

  return (
    <div className="block h-[calc(100vh-var(--base-header-height))]">
      {isLoading && !processRecord && (
        <ProcessRecordVisualizationSkeleton totalProcessRecordCount={1} />
      )}

      {isError && (
        <FormattedMessage
          description="Process record detail view: error message when requested process record does not exist."
          defaultMessage="An error occurred while loading the process record with ID {processRecordId}: {error}"
          id="MfGpvG"
          values={{ processRecordId: id, error: error.message }}
        />
      )}

      {processRecord && (
        <div className="h-[calc(100%-var(--actionbar-height))]">
          <ProcessRecordVisualization
            processRecords={[processRecord]}
            totalProcessRecordCount={1}
            pageTitle={processRecord.displayName}
          />
        </div>
      )}
    </div>
  );
}

export default ProcessRecordDetail;
