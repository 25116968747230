import React from 'react';
import { Skeleton } from '@biss/react-horizon-web';

function SetupSkeleton() {
  return (
    <>
      <div className="mb-2 flex gap-2" data-testid="setup-skeleton">
        <Skeleton variant="text" width={100} className="p-1" />
        <Skeleton variant="text" width={100} className="p-1" />
        <Skeleton variant="text" width={100} className="p-1" />
      </div>
      <Skeleton variant="rectangular" height={209} />
    </>
  );
}

export default SetupSkeleton;
