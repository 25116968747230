import BKey from '../beta-feature-keys';

import { FeatureFlagType } from './feature-flag';
import ScopedFeatureFlag from './scoped-feature-flag';

class BetaFeatureFlag extends ScopedFeatureFlag {
  private readonly scopeIdField: string | undefined;

  private readonly packgeNameField: string;

  private readonly labelField = 'Beta package';

  private readonly typeField: FeatureFlagType = 'beta';

  constructor(packgeName: BKey, scopeId: string | undefined) {
    super();
    this.scopeIdField = scopeId;
    this.packgeNameField = packgeName;
  }

  public get scopeId(): string | undefined {
    return this.scopeIdField;
  }

  public get type(): FeatureFlagType {
    return this.typeField;
  }

  public get packgeName(): string {
    return this.packgeNameField;
  }

  public get label(): string {
    return this.labelField;
  }
}

export default BetaFeatureFlag;
