import { ProcessRecordId } from '../../../../../shared/common/types/process-record';

export type DraftAttributeState = {
  /** client side generated id to keep track of state */
  id: string;

  name: string;
  value: string;

  isBeingEdited: boolean;
  isBeingRemoved: boolean;

  /**
   * initial value before being edited,
   * used to restore the initial value when cancelling the edit,
   * used for dirty checking
   */
  initial: {
    name: string;
    value: string;
  };

  isNew: boolean;
};

export type AttributeEditorProps = {
  /** process record the attributes are associated with */
  processRecordId: ProcessRecordId;

  /** all available attributes for the given process record */
  attributes: {
    [x in string]: string;
  };

  /** used in tests */
  defaultDraftState?: DraftAttributeState[];
};

export const DUPLICATE_ATTRIBUTE_NAME_ERROR_MESSAGE =
  'The attribute key you have entered already exists, please choose another one';

export const NEW_ATTRIBUTE_NAME = 'new attribute';
