export enum ChartXAxisTimeStampFormat {
  Date,

  /** days hours */
  AccumulativeDay,
  StaticAccumulativeDay,

  /** hours minutes */
  AccumulativeHour,
  StaticAccumulativeHour,

  /** days hours minutes */
  Accumulative,
}

export const SECOND_IN_MILLISECONDS = 1_000;
export const MINUTE_IN_MILLISECONDS = 60 * SECOND_IN_MILLISECONDS;
export const HOUR_IN_MILLISECONDS = 60 * MINUTE_IN_MILLISECONDS;
export const DAY_IN_MILLISECONDS = 24 * HOUR_IN_MILLISECONDS;

export const ACCUMULATIVE_SPLIT_NUMBER = 10;
export const ACCUMULATIVE_DAY_SPLIT_NUMBER = 6;
export const ACCUMULATIVE_HOUR_SPLIT_NUMBER = 10;
