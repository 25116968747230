import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { DropzoneChooserProps } from './dropzone-chooser.definitions';

function DropzoneChooser({
  onFileChange,
  accept = '.csv',
  'data-testid': dataTestId = 'dropzone-input',
  error,
}: DropzoneChooserProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;

    if (inputRef.current === null) {
      return;
    }

    if (target.files === null) {
      return;
    }

    const file = target.files[0];

    onFileChange(file);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (inputRef.current === null) {
      return;
    }

    if (event.dataTransfer.files.length === 0) {
      return;
    }

    const file = event.dataTransfer.files[0];

    onFileChange(file);
  };

  return (
    <div className="flex flex-col">
      <div
        data-testid="dropzone-drop-area"
        role="presentation"
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
        className={classNames(
          'flex h-96 w-full flex-col items-center justify-center rounded  bg-gray-100 p-2',
          {
            'border border-solid border-warning': error,
            'border border-dashed border-gray-700': !error,
          },
        )}
      >
        <p className="m-auto">
          <span>
            <FormattedMessage
              description="Upload: Drag file message"
              defaultMessage="Drag a file here or"
              id="EBreGO"
            />
          </span>
          <button
            data-testid="file-chooser"
            type="button"
            onClick={() => inputRef.current?.click()}
            className="ml-1 text-blue"
          >
            <FormattedMessage
              description="Upload: Link to choose file from device"
              defaultMessage="select one from my device"
              id="qVhvgV"
            />
          </button>
        </p>
        <input
          accept={accept}
          onChange={handleFileInputChange}
          ref={inputRef}
          type="file"
          data-testid={dataTestId}
          className="hidden appearance-none"
        />
      </div>

      {error && <div className="mt-1 block max-w-fit text-xs font-bold text-warning">{error}</div>}
    </div>
  );
}

export default DropzoneChooser;
