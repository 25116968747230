import React from 'react';

import { ZoomYType } from './use-vertical-zoom.definitions';

function useVerticalZoom(seriesTypes: string[]) {
  return React.useRef<ZoomYType>(
    Object.fromEntries(seriesTypes.map((type) => [type, { start: 0, end: 100 }])),
  );
}

export default useVerticalZoom;
