import { z } from 'zod';

import { minLesserThanMax } from '../../../../../shared/components/time-series-chart/y-axis-range-inputs/y-axis-range-inputs.validation';

const fieldNumber = z.preprocess(
  (value) =>
    // interpret empty string as `undefined`
    typeof value === 'string' && value.trim().length === 0 ? undefined : value,
  z.coerce.number({ message: ' ' }).optional(),
);

export const yAxisRangeSchema = z
  .object({
    max: fieldNumber,
    min: fieldNumber,
  })
  .refine(minLesserThanMax, {
    path: ['min'],
    message: ' ',
  })
  .refine(minLesserThanMax, {
    path: ['max'],
    message: ' ',
  });

export type YAxisRange = Partial<z.infer<typeof yAxisRangeSchema>>;
