import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import { ProcessRecordId } from '../../../../shared/common/types/process-record';
import { ProcessRecordOptimizationVariable } from '../../types/process-record-optimization-variables';
import QKey from '../../../../shared/common/hooks/keys';

import { mapVariables } from './use-map-process-record-optimization-variable.helpers';

export default function useMapProcessRecipeOptimizationVariable({
  processRecordId,
}: {
  processRecordId: ProcessRecordId;
}) {
  const queryClient = useQueryClient();
  const { acquireAccessToken } = useAuthentication();

  return useMutation({
    mutationFn: async (data: ProcessRecordOptimizationVariable[]) => {
      const accessToken = await acquireAccessToken();
      return mapVariables(data, processRecordId, accessToken);
    },
    onSuccess: (_, variables) => {
      // update cache
      queryClient.setQueryData<ProcessRecordOptimizationVariable[]>(
        [QKey.RECIPE_OPTIMIZATION, processRecordId],
        () => variables,
      );
    },
  });
}
