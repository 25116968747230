import {
  Precision,
  MS_SEC,
  SEC_DAY,
  SEC_HOUR,
  SEC_MINUTE,
  PADDING,
  MS_PADDING,
} from './time-span.definitions';

/**
 * Converts a given time in milliseconds to a formatted string.
 *
 * @param {number} ms - The time in milliseconds to convert.
 *
 * @param {Precision} precision - Determines the level of precision in the resulting formatted string.
 *      'days': Only the number of days and hours will be included in the output (e.g. "1d 12h").
 *      'hours': The number of days, hours, minutes, and seconds will be included in the output (e.g. "1d 12:34:56").
 *      'ms': The number of days, hours, minutes, seconds, and milliseconds will be included in the output (e.g. "1d 12:34:56.789").
 * @returns {string} The time in milliseconds converted to the specified format.
 *
 * @example
 * ## Returns "1d 12h"
 * ## convertMsToTimeFormat(132000000, 'days')
 *
 * ## Returns "1d 12:34:56"
 * ## convertMsToTimeFormat(132000000, 'hours')
 *
 * ## Returns "1d 12:34:56.789"
 * ## convertMsToTimeFormat(132000789, 'ms')
 */
export function formatTimespan(ms: number, precision: Precision = 'days') {
  let seconds = Math.floor(ms / MS_SEC);
  const days = Math.floor(seconds / SEC_DAY);
  seconds -= days * SEC_DAY;

  const hours = Math.floor(seconds / SEC_HOUR);
  seconds -= hours * SEC_HOUR;

  const minutes = Math.floor(seconds / SEC_MINUTE);
  seconds -= minutes * SEC_MINUTE;
  const remainingMs = ms % MS_SEC;

  const formatNumber = (number: number, digits: number) => number.toString().padStart(digits, '0');

  switch (precision) {
    case 'days':
      return `${days}d ${hours}h`;
    case 'hours':
      return `${days}d ${formatNumber(hours, PADDING)}:${formatNumber(
        minutes,
        PADDING,
      )}:${formatNumber(seconds, PADDING)}`;
    case 'ms':
    default:
      return `${days}d ${formatNumber(hours, PADDING)}:${formatNumber(
        minutes,
        PADDING,
      )}:${formatNumber(seconds, PADDING)}.${formatNumber(remainingMs, MS_PADDING)}`;
  }
}

export default function timeSpan(ms: number, precision: Precision = 'days'): string {
  const formatted = formatTimespan(Math.abs(ms), precision);
  if (ms < 0) {
    return `- ${formatted}`;
  }

  return formatted;
}
