import React, { useMemo } from 'react';

import { PrsClient, PmsClient } from '../../common/types/clients';

import { ClientsProps } from './clients.definitions';
import ClientsGlobals from './clients-globals';

function Clients({ children, prsBackendUrl, pmsBackendUrl }: ClientsProps) {
  const clients = useMemo(
    () => ({
      prsClient: new PrsClient(prsBackendUrl),
      pmsClient: new PmsClient(pmsBackendUrl),
    }),
    [prsBackendUrl, pmsBackendUrl],
  );

  return <ClientsGlobals.Provider value={clients}>{children}</ClientsGlobals.Provider>;
}

export default Clients;
