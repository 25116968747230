import { v4 as uuid } from 'uuid';

import { DraftAttributeState } from './attribute-editor.definitions';

export const resetAttribute = (pill: DraftAttributeState): DraftAttributeState => ({
  ...pill,
  isBeingEdited: false,
  isBeingRemoved: false,
  name: pill.initial.name,
  value: pill.initial.value,
});

export const isAttributeDuplicate = (attributeName: string, attributes: DraftAttributeState[]) =>
  attributes.filter(
    (attr) => attributeName.trim().toLowerCase() === attr.name.trim().toLocaleLowerCase(),
  ).length > 1;

export const isNotNewAttribute = (pill: DraftAttributeState): boolean => pill.isNew === false;

export const createAttributeDraftState = (): DraftAttributeState => ({
  id: uuid(),

  name: '',
  value: '',

  isBeingEdited: false,
  isBeingRemoved: false,

  initial: {
    name: '',
    value: '',
  },

  isNew: false,
});

/** convert attribute data to pill state (with default values) */
export const convertAttributesToDraftState = (
  attrs: Record<string, string>,
): DraftAttributeState[] =>
  Object.entries(attrs).map(
    ([name, value]) =>
      ({
        ...createAttributeDraftState(),
        name,
        value,
        initial: { name, value },
      } satisfies DraftAttributeState),
  );

export const convertAttributeDraftStateToAttributes = (draft: DraftAttributeState[]) =>
  Object.fromEntries(draft.map(({ name, value }) => [name, value]));
