import { IntlShape } from 'react-intl';

import { ChartXAxisTimeStampFormat } from '../chart-formatters/chart-formatters.definitions';
import {
  createXAxisTimeStampDateFormatter,
  tooltipAccumulativeExactFormatter,
} from '../chart-formatters';

import { TooltipFormatterParam } from './chart-tooltip.definitions';

export const getTooltipTimeFormatter = (
  timeStampFormat: ChartXAxisTimeStampFormat,
  intl: IntlShape,
) => {
  if (timeStampFormat === ChartXAxisTimeStampFormat.Date) {
    return createXAxisTimeStampDateFormatter(intl);
  }

  return tooltipAccumulativeExactFormatter;
};

export const getTooltipFormatter = (
  timeStampFormat: ChartXAxisTimeStampFormat,
  intl: IntlShape,
) => {
  const xAxisFormatter = getTooltipTimeFormatter(timeStampFormat, intl);

  return (params: TooltipFormatterParam[]) => {
    const firstParam = params.at(0);
    if (firstParam === undefined) {
      return '<i>No data</i>';
    }

    return [
      xAxisFormatter(firstParam.axisValue),
      params
        .map((param) => [param.marker, param.seriesName, `<b>${param.data?.v}</b>`].join(' '))
        .join('<br>'),
    ].join('<br>');
  };
};

export function getTooltipEchartsConfig(
  timeStampFormat: ChartXAxisTimeStampFormat,
  intl: IntlShape,
) {
  const tooltipFormatter = getTooltipFormatter(timeStampFormat, intl);

  return {
    show: true,
    trigger: 'axis',
    order: 'seriesDesc',
    formatter: tooltipFormatter,
  };
}
