import { useQuery, UseQueryResult } from '@tanstack/react-query';

import QKey from '../../../common/hooks/keys';

import { CanvasSizeMaxReturn } from './use-max-allowed-canvas-size.definitions';
import { getMaxCanvasSize } from './use-max-allowed-canvas-size.helpers';

/**
 * browsers limit how wide or tall the canvas element can be,
 * if a canvas element exceeds the limit, all canvas api functions break
 * hook calculates the max size a canvas can be
 */
export default function useMaxAllowedCanvasSize(): UseQueryResult<CanvasSizeMaxReturn> {
  return useQuery({
    // max allowed canvas size is specific to every browser
    staleTime: Infinity,
    queryKey: [QKey.MAX_CANVAS_SIZE],
    queryFn: getMaxCanvasSize,
  });
}
