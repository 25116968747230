import { QueryKey } from '@tanstack/react-query';

import { HttpStatus } from '../../types/http';

export type UploadMutationInput = {
  formData: FormData | Blob | Buffer;
  onProgress: ProgressCallback;
};

export type UploadWithProgressResponse = {
  status: HttpStatus;
  statusText: string;
  response: Response;
};

export type ProgressState = {
  loaded?: number;
  total?: number;
  /**
   * float value between '0' and '1' describing how far the upload has progressed
   */
  progress?: number;
  uploading: boolean;
};

export enum ReadyState {
  /** Client has been created. open() not called yet */
  UNSENT = 0,
  /** open() has been called */
  OPENED = 1,
  /** send() has been called, and headers and status are available */
  HEADERS_RECEIVED = 2,
  /** Downloading; responseText holds partial data */
  LOADING = 3,

  DONE = 4,
}

export type ProgressCallback = (progress: ProgressState) => void;

export const CONTENT_TYPE_HEADER = 'Content-Type';

export interface UploadHeaders {
  [CONTENT_TYPE_HEADER]: string;
}

export type UseUploadInput = {
  key: QueryKey;
  url: string;
  headers?: UploadHeaders;
  onSuccess?: (data: UploadWithProgressResponse, variables: UploadMutationInput) => void;
};

export const NETWORK_ERROR_MESSAGE =
  'Upload failed due to a network error, please check your connection and try again.';
