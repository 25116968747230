import cn from 'classnames';

import { PillIsEditable, PillMood } from './pill.definitions';

export function calculateStringWidth(text: string) {
  // add temporary span to the DOM to calculate element width
  const tempSpan = document.createElement('span');
  tempSpan.textContent = text;
  tempSpan.style.visibility = 'hidden';
  tempSpan.style.position = 'absolute';
  document.body.appendChild(tempSpan);

  // Calculate the width of the input's current value
  const width = tempSpan.offsetWidth;

  // Remove the temporary span from the DOM
  document.body.removeChild(tempSpan);

  return width;
}

/**
 * determine whether the inputs should be disabled
 * @param input name of the current input
 * @param disabled whether the entire pill component is disabled
 * @param isBeingEdited whether the entire pill component is being edited
 * @param isBeingRemoved whether the entire pill component is being removed
 * @param isEditable whether any, all or a specific input can be edited
 * @returns whether the input should be disabled
 */
export const getIsInputDisabled = (
  input: 'name' | 'value',
  disabled: boolean,
  isBeingEdited: boolean,
  isBeingRemoved: boolean,
  isEditable: PillIsEditable,
) => {
  if (disabled) {
    // all controls are disabled
    return true;
  }

  if (isBeingEdited === false) {
    // the pill is not being edited at all
    return true;
  }

  if (isBeingRemoved) {
    // the pill is being removed
    return true;
  }

  if (isEditable === false) {
    // none of the inputs can be edited
    return true;
  }

  // only the other input can be edited
  if (typeof isEditable === 'string' && isEditable !== input) {
    return true;
  }

  return false;
};

/**
 * determine if the button should be disabled
 * @param disabled whether the entire pill component is disabled
 * @param isButtonDisabled whether this specific button should be disabled
 * @returns whether this button should be disabled
 */
export const getIsButtonDisabled = (disabled: boolean, isButtonDisabled: boolean) => {
  if (disabled) {
    // all component controls are disabled
    return true;
  }

  if (isButtonDisabled) {
    // this specific button is disabled
    return true;
  }

  return false;
};

/**
 * determine whether a single input is being edited
 * @param input name of the current input
 * @param isBeingEdited whether the entire pill component is being edited
 * @param isEditable whether any, all or a specific input can be edited
 * @returns whether this specific input is being edited
 */
export const getIsInputBeingEdited = (
  input: 'name' | 'value',
  isBeingEdited: boolean,
  isEditable: PillIsEditable,
) => {
  if (isBeingEdited === false) {
    // the pill is not being edited at all
    return false;
  }

  if (isEditable === true) {
    // all inputs can be edited
    return true;
  }

  if (isEditable === input) {
    // this specific input can be edited
    return true;
  }

  return false;
};

/** get the priority validation state of the component */
export const getMood = (isError: boolean, isWarning: boolean): PillMood => {
  if (isError) {
    return 'error';
  }

  if (isWarning) {
    return 'warning';
  }

  return 'normal';
};

export const getInputClassName = (isInputBeingEdited: boolean, mood: PillMood) =>
  cn(
    'fill-blue-700 rounded-t rounded-b-none border-b bg-transparent leading-4 outline-none text-base',
    {
      'border-b-transparent': isInputBeingEdited === false,
      'border-b-red-700 focus-within:bg-red-50': isInputBeingEdited && mood === 'error',
      'border-b-yellow-700 focus-within:bg-yellow-50': isInputBeingEdited && mood === 'warning',
      'border-b-blue-700 focus-within:bg-blue-50': isInputBeingEdited && mood === 'normal',
    },
  );

export const getButtonClassName = (mood: PillMood) =>
  cn(
    'ml-2 rounded-md outline-none',
    'disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-transparent',
    {
      'focus-within:bg-red-50 hover:bg-red-50 active:bg-red-100': mood === 'error',
      'focus-within:bg-yellow-50 hover:bg-yellow-50 active:bg-yellow-100': mood === 'warning',
      'focus-within:bg-blue-50 hover:bg-blue-50 active:bg-blue-100': mood === 'normal',
    },
  );
