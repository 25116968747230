import { createCheckers } from 'ts-interface-checker';

import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { joinPaths } from '../../../../shared/utils';
import { Subscription, SubscriptionDTO, transformSubscriptionDTO } from '../../types';
import { createUrlOrganizationById } from '../use-organization/use-organization.helpers';
import exportedTypeSuite from '../../types/organization/dto/organization-validators';

export function createGetSubscriptionsUrl(organizationId: string): string {
  return joinPaths(createUrlOrganizationById(organizationId), 'subscriptions');
}

export function isSubscriptionDTO(dto: unknown): asserts dto is SubscriptionDTO {
  const { SubscriptionDTO: subscriptionsChecker } = createCheckers(exportedTypeSuite);

  // throws an error when object is not in the correct format
  subscriptionsChecker.check(dto);
}

export async function fetchSubscriptions(
  organizationId: string,
  accessToken: string,
): Promise<Subscription[]> {
  const subscriptions = await fetchData(createGetSubscriptionsUrl(organizationId), {
    method: HttpMethods.Get,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (!subscriptions) {
    return [] satisfies Subscription[];
  }
  if (!Array.isArray(subscriptions)) {
    throw new Error('expected to receive an array of subscriptions.');
  }

  // throws an error when object is not in the correct format
  subscriptions.forEach(isSubscriptionDTO);
  return subscriptions.map((subscription) => transformSubscriptionDTO(subscription));
}
