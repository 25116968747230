import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { joinPaths } from '../../../../shared/utils';
import { createMembersUrl } from '../use-members/use-members.helpers';

export function createDeleteMemberUrl(memberId: string, invitationPending: boolean): string {
  return `${joinPaths(createMembersUrl(), memberId)}?invitationPending=${invitationPending}`;
}

export async function deleteMember(
  memberId: string,
  invitationPending: boolean,
  accessToken: string,
): Promise<void> {
  await fetchData(createDeleteMemberUrl(memberId, invitationPending), {
    method: HttpMethods.Delete,
    headers: {
      Accept: 'application/json',
      'Accept-Encoding': 'gzip, deflate, br',
      Authorization: `Bearer ${accessToken}`,
    },
  });
}
