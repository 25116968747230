import { useContext } from 'react';

import { LoggingGlobals } from '../../../globals/logging';

function useLogger() {
  const logger = useContext(LoggingGlobals);

  if (logger === undefined) {
    throw new Error(
      '[logger] was undefined, make sure useLogger is used inside <LoggingGlobals.Provider />',
    );
  }

  return logger;
}

export default useLogger;
