import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Icons32, PageLayout } from '@biss/react-horizon-web';

import useProcessRecordList from '../../../shared/common/hooks/use-process-record-list';
import { ProcessRecordId } from '../../../shared/common/types/process-record';
import ProcessRecordList from '../../../shared/components/process-record-list';
import useLogger from '../../../shared/common/hooks/use-logger/use-logger';
import TrackedEvent from '../../../shared/common/tracked-event';
import Footer from '../../../shared/components/footer';
import AddProcessRecordModal from '../../components/add-process-record-modal';

import { QUERY_KEY } from './process-records.definitions';

/**
 * Displays process record list.
 */
function ProcessRecords() {
  const navigate = useNavigate();
  const logger = useLogger();
  const { data: processRecords, error, isError, isLoading } = useProcessRecordList();

  const [selectedRecordIds, setSelectedRecordIds] = useState<ProcessRecordId[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const queryParam = searchParams.get(QUERY_KEY)?.trim() ?? '';

  const setProcessRecordSelected = useCallback(
    (processRecordId: ProcessRecordId, selected: boolean) => {
      logger.trackEvent(TrackedEvent.SelectProcessRecordForComparison);
      if (selected) {
        setSelectedRecordIds((prevState) => [processRecordId, ...prevState]);
      } else {
        setSelectedRecordIds((prevState) => prevState.filter((el) => el !== processRecordId));
      }
    },
    [setSelectedRecordIds],
  );

  const compareUrlParams = selectedRecordIds.map((id) => `pr=${id}`).join('&');
  const compareUrl = `compare?${compareUrlParams}`;

  const handleSearch = (val: string) => {
    logger.trackEvent(TrackedEvent.SearchForProcessRecords);
    return setSearchParams((prev) => {
      if (val === '') {
        prev.delete(QUERY_KEY);
        return prev;
      }

      prev.set(QUERY_KEY, val);
      return prev;
    });
  };

  const handlePrimary = (id: ProcessRecordId) => {
    logger.trackEvent(TrackedEvent.SelectProcessRecordFromList);
    navigate(`${id}`, { relative: 'route' });
  };

  const handleClick = (
    processRecordId: string,
    e: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent<HTMLLIElement>,
  ) => {
    // do nothing when clicked on a button
    if ([...e.nativeEvent.composedPath()].some((i) => (i as HTMLElement).tagName === 'BUTTON')) {
      return;
    }

    // log user event when a singe process record is selected
    if ([...e.nativeEvent.composedPath()].some((i) => (i as HTMLElement).tagName === 'A')) {
      logger.trackEvent(TrackedEvent.SelectProcessRecordFromList);
      return;
    }

    setProcessRecordSelected(processRecordId, !selectedRecordIds.includes(processRecordId));
  };

  return (
    <PageLayout sidebarOpen="none" className="!h-[calc(100dvh-var(--header-bar-height,0))]">
      <PageLayout.Main>
        <PageLayout.Main.ActionBar pageTitle="Process Record Overview" sidePanelOpen="none" />
        <PageLayout.Main.Content className="flex h-full flex-col gap-4 overflow-y-auto px-4 pb-4">
          <ProcessRecordList
            processRecords={processRecords}
            selectedItems={selectedRecordIds}
            onCheckedChange={setProcessRecordSelected}
            onPrimary={handlePrimary}
            onSearch={handleSearch}
            initialSearch={queryParam}
            isLoading={isLoading}
            isError={isError}
            errorMessage={error?.message}
            isClickable
            onClick={handleClick}
            headerContent={
              <div className="flex flex-row gap-2">
                <Link
                  to={compareUrl}
                  onClick={() => {
                    logger.trackEvent(TrackedEvent.Compare);
                  }}
                >
                  <div className="md:hidden">
                    <Button
                      disabled={selectedRecordIds.length <= 1}
                      kind="secondary"
                      leftIcon={<Icons32.ScadaIcon className="rotate-90" />}
                    />
                  </div>
                  <div className="hidden md:block">
                    <Button disabled={selectedRecordIds.length <= 1} kind="primary">
                      <FormattedMessage
                        description="Process Records Scene: Link to compare page"
                        defaultMessage="Compare"
                        id="VPm5WX"
                      />
                    </Button>
                  </div>
                </Link>

                <AddProcessRecordModal />
              </div>
            }
          />

          <Footer />
        </PageLayout.Main.Content>
      </PageLayout.Main>
    </PageLayout>
  );
}

export default ProcessRecords;
