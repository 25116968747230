import React, { FunctionComponentElement, useEffect, useRef, useState } from 'react';

import useDataTracks from '../../../common/hooks/use-data-tracks';
import DataTrackTimeAlignment from '../setup-details.definitions';
import ProcessRecordAlignment from '../../../components/process-record-alignment/process-record-alignment';
import ReferenceCurveSelector from '../reference-curve-selector';
import useProcessRecord from '../../../common/hooks/use-process-record/use-process-record';
import ProcessValues from '../process-values';
import FinishedUnitMessage from '../finished-unit-message';
import {
  DataTrack,
  DataTrackFromTimestampItem,
  LatestDataPointObject,
} from '../../../../shared/common/types/setup';
import {
  getDataTrackLastTimestamp,
  getFirstTimestamp,
  getGapRecoveryTimestamp,
  getLastTimestamp,
  isFetched,
  isSameFirstDataPoint,
  isStartGapRecovery,
} from '../../../common/helpers';
import SetupTimeSeriesChart from '../setup-time-series-chart';
import TileWithSkeleton from '../../../../shared/tile-with-skeleton';
import ConnectionLostMessage from '../../../components/connection-lost-message';

import { getDataTrackIds, isFromTimestamp, isGetDataPoints } from '../setup-details.helpers';

import { useDataTracksTimestamp } from '../../../common/hooks/use-data-tracks-from-timestamp';

import { DATA_TRACKS_FROM_TIMESTAMP_INTERVAL } from '../../../common/types/setup';

import {
  getSeries,
  getMarkLines,
  getTimeDifference,
  getProcessValues,
  getDataTracksLastTimestamps,
  isHideLoader,
  isGapRecovery,
  getSeriesData,
} from './setup-details-content.helpers';
import { SetupDetailsContentProps } from './setup-details-content.definitions';

function SetupDetailsContent({
  setupId,
  startTimestamp,
  stopTimestamp,
  inoculationTimestamp,
  requestedDataTrackIds,
  selectedDataTracks,
  referenceProcessId,
  seriesLegendSelected,
  autoUpdate,
  controlProcedureId,
  isLoadingSetup,
  isDisconnected = false,
  updateIsLoadingSetup,
  toggleSeriesLegendSelected,
  updateDataTrackColors,
  onReferenceProcessRecord,
}: SetupDetailsContentProps): FunctionComponentElement<SetupDetailsContentProps> {
  const [timeAlignment, setTimeAlignment] = useState(DataTrackTimeAlignment.RelativeToStartTime);
  const isFetchingPrev = useRef<boolean>(false);

  const firstTimestampPrev = useRef<number | null>();
  const isDisconnectedPrev = useRef<boolean>(false);
  const gapRecoveryTimestamp = useRef<string | null>();
  const autoUpdateCounter = useRef<number>(0);
  const dataTracksTimestamp = useRef<DataTrackFromTimestampItem[]>([]);

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const { mutate: getLastDataPoints } = useDataTracksTimestamp(setupId);

  const results = useDataTracks(setupId, requestedDataTrackIds || []);
  const dataTracks = results
    .filter((result) => result.isSuccess && result.data)
    .map((result) => result.data as DataTrack);

  const isFetching = results.some((result) => result.isFetching);

  dataTracksTimestamp.current = getDataTracksLastTimestamps(dataTracks, requestedDataTrackIds);

  const {
    data: referenceProcessRecord,
    isLoading: isLoadingReferenceProcessRecord,
    isError: isErrorReferenceProcessRecord,
    error: errorReferenceProcessRecord,
  } = useProcessRecord(referenceProcessId);

  const stopGapRecovery = () => {
    firstTimestampPrev.current = null;
    gapRecoveryTimestamp.current = null;
  };

  // uses to continue or stop gap recovery
  const gapRecovery = (data: LatestDataPointObject[]) => {
    if (!data.length) {
      stopGapRecovery();
    } else {
      const firstTimestamp = getFirstTimestamp(data);
      if (isSameFirstDataPoint(firstTimestamp, firstTimestampPrev.current)) {
        stopGapRecovery();
      } else {
        firstTimestampPrev.current = firstTimestamp;
      }
    }
  };

  async function getDataPoints() {
    try {
      let lastTimestamps;
      // if gap recovery or auto update request data points from timestamp
      // otherwise request all the data points
      if (isFromTimestamp(autoUpdateCounter.current, gapRecoveryTimestamp.current)) {
        lastTimestamps = getDataTrackLastTimestamp(
          dataTracksTimestamp.current,
          gapRecoveryTimestamp.current,
        );
      } else {
        lastTimestamps = getDataTrackIds(dataTracksTimestamp.current);
        autoUpdateCounter.current = 0;
      }

      if (!lastTimestamps.length || !controlProcedureId) {
        return;
      }
      getLastDataPoints(
        {
          dataTracks: lastTimestamps,
          controlProcedureId,
        },
        {
          onSuccess: (data) => {
            if (isGapRecovery(gapRecoveryTimestamp.current)) {
              gapRecovery(data);
            }
            autoUpdateCounter.current += 1;
          },
        },
      );
    } finally {
      timeoutRef.current = setTimeout(() => {
        getDataPoints();
      }, DATA_TRACKS_FROM_TIMESTAMP_INTERVAL);
    }
  }

  const clearTimeoutRef = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      autoUpdateCounter.current = 0;
    }
  };

  // uses to start or stop auto update of last data points
  useEffect(() => {
    // get last data points
    if (isGetDataPoints(autoUpdate, isDisconnected, stopTimestamp)) {
      getDataPoints();
    } else {
      clearTimeoutRef();
    }
    // start gap recovery when connection is restored
    if (isStartGapRecovery(isDisconnected, isDisconnectedPrev.current)) {
      gapRecoveryTimestamp.current = getGapRecoveryTimestamp(dataTracksTimestamp.current);
    }
    isDisconnectedPrev.current = isDisconnected;
    return () => {
      clearTimeoutRef();
    };
  }, [autoUpdate, isDisconnected, stopTimestamp]);

  // set time aligment when a reference curve is selected
  useEffect(() => {
    const alignment =
      inoculationTimestamp && referenceProcessRecord?.inoculationTimestamp
        ? DataTrackTimeAlignment.RelativeToInoculationTime
        : DataTrackTimeAlignment.RelativeToStartTime;
    setTimeAlignment(alignment);
  }, [referenceProcessRecord]);

  useEffect(() => {
    if (stopTimestamp) {
      // delete data tracks timestamps because finished setup is not updated automatically
      dataTracksTimestamp.current = [];
    }
  }, [stopTimestamp]);

  // set time aligment when a reference curve is selected
  useEffect(() => {
    if (isHideLoader(isLoadingSetup, requestedDataTrackIds)) {
      updateIsLoadingSetup(false);
    }
  }, [requestedDataTrackIds, isLoadingSetup]);

  useEffect(() => {
    if (isFetched(isFetching, isFetchingPrev.current) && isLoadingSetup) {
      updateIsLoadingSetup(false);
    }
    if (isFetchingPrev.current !== isFetching) {
      isFetchingPrev.current = isFetching;
    }
  }, [isFetching]);

  const isFinished = Boolean(stopTimestamp);

  const timeDifference = getTimeDifference(
    timeAlignment,
    startTimestamp,
    inoculationTimestamp,
    referenceProcessRecord,
  );
  const processValues = getProcessValues(dataTracks, selectedDataTracks, isFinished);

  const series = getSeries(
    dataTracks,
    selectedDataTracks,
    timeDifference,
    referenceProcessRecord?.dataTracks
      // TODO(BIOCL-4158)
      .map((i) => ({ ...i, dataPoints: i.dataPoints ?? [] })),
  );

  const seriesData = getSeriesData(
    dataTracks,
    selectedDataTracks,
    timeDifference,
    referenceProcessRecord?.dataTracks
      // TODO(BIOCL-4158)
      .map((i) => ({ ...i, dataPoints: i.dataPoints ?? [] })),
  );
  const lastTimestamp = getLastTimestamp(dataTracks);
  const seriesMarkLines = getMarkLines(
    timeAlignment,
    timeDifference,
    inoculationTimestamp,
    referenceProcessRecord?.inoculationTimestamp,
    isDisconnected,
    lastTimestamp,
    stopTimestamp,
  );

  return (
    <>
      <ConnectionLostMessage
        visible={isDisconnected && !isFinished && !isLoadingSetup}
        lastTimestamp={lastTimestamp}
      />
      <ProcessValues
        processValues={processValues}
        isShowLoading={isLoadingSetup}
        isDisconnected={isDisconnected}
      />
      <FinishedUnitMessage visible={isFinished && !isLoadingSetup} stopTimestamp={stopTimestamp} />

      <TileWithSkeleton
        className="min-h-[66px] p-4"
        isLoading={isLoadingSetup}
        data-testid="referenceCurve"
      >
        <ReferenceCurveSelector
          setupId={setupId}
          onReferenceProcessRecord={onReferenceProcessRecord}
          referenceProcessRecord={referenceProcessRecord}
          isLoading={isLoadingReferenceProcessRecord}
          isError={isErrorReferenceProcessRecord}
          error={errorReferenceProcessRecord}
        />
        {referenceProcessRecord && (
          <ProcessRecordAlignment
            onChangeAlignment={setTimeAlignment}
            defaultValue={timeAlignment.toString()}
            defaultOpen={false}
            disabled={!(inoculationTimestamp && referenceProcessRecord?.inoculationTimestamp)}
          />
        )}
      </TileWithSkeleton>
      <SetupTimeSeriesChart
        startTimestamp={startTimestamp}
        stopTimestamp={stopTimestamp}
        selectedDataTracks={selectedDataTracks}
        seriesLegendSelected={seriesLegendSelected}
        seriesMarkLines={seriesMarkLines}
        toggleSeriesLegendSelected={toggleSeriesLegendSelected}
        isShowLoading={isLoadingSetup}
        updateDataTrackColors={updateDataTrackColors}
        series={series}
        seriesData={seriesData}
        dataTracks={dataTracks}
      />
    </>
  );
}

export default SetupDetailsContent;
