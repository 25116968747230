import { defineMessages } from 'react-intl';
import { CloudIcon, ScadaIcon } from '@biss/react-horizon-web';

export const PRODUCT_INFO_URL = '//eppendorf.group/BioNsight-cloud';

export const LANDING_MESSAGES = defineMessages({
  landingHeaderProduct: {
    id: '08O281',
    defaultMessage: 'Product',
    description: 'Landing page header: product link',
  },
  landingHeaderFeatures: {
    id: 'oDr/w2',
    defaultMessage: 'Features',
    description: 'Landing page header: Features link',
  },
  landingHeaderWhereToBuy: {
    id: 'yV92qO',
    defaultMessage: 'Where to buy',
    description: 'Landing page header: Where to buy link',
  },
  landingHeaderAbout: {
    id: '5ARdBa',
    defaultMessage: 'About Eppendorf',
    description: 'Landing page header: About link',
  },
  landingScreenShotAlt: {
    id: 'KseEcS',
    defaultMessage:
      'Screenshot of BioNsight cloud showing the monitoring overview with 4 units being plotted.',
    description: 'Landing page screenshot alt text',
  },
  landingOpenMenuAlt: {
    id: 'dVuB68',
    defaultMessage: 'Open Navigation Menu',
    description: 'Landing page open menu screen reader alt text',
  },
});

export const PRODUCT_INFO = defineMessages({
  landingProductDesc: {
    id: 'ivVmXA',
    defaultMessage:
      'BioNsight cloud is a cloud-based software solution for bioprocess monitoring and analysis. It is fully integrated into the Eppendorf bioprocess control software DASware{tm} control 6 and enables you to consolidate your bioprocess data in one central location. This data storage is accessible from everywhere, by everyone you authorize. You can easily merge data from different runs, generated with different bioprocess controllers, to transfer data to knowledge. You and your colleagues are able to collaborate on the same data sets, without the need of being at the same place.',
    description: 'Landing page product description text',
  },
  landingMonitoringTitle: {
    id: 'V0HkFW',
    defaultMessage: 'Remote Monitoring',
    description: 'Landing page monitoring title',
  },
  landingMonitoringSubTitle: {
    id: '38r/vg',
    defaultMessage: 'For the peace of mind that your bioprocess is going well',
    description: 'Landing page monitoring subtitle',
  },
  landingMonitoringDesc: {
    id: '0X8MQ7',
    defaultMessage:
      'BioNsight cloud empowers you and your team with access to real-time bioprocess data straight from the cloud, whether you{apos}re in the lab, in the office or on a business trip. So you always precisely know how your bioprocess runs are performing and can make the right decisions faster, while your process is still running. Or simply sit back with the peace of mind that all is well.',
    description: 'Landing page monitoring description text',
  },
  landingIntegrationTitle: {
    id: 'oq5UN3',
    defaultMessage: 'Integrate your Data',
    description: 'Landing page integration title',
  },
  landingIntegrationSubTitle: {
    id: 'oCF5Dx',
    defaultMessage: 'Merge all your datasets at one place to see the entire picture',
    description: 'Landing page integration subtitle',
  },
  landingIntegrationDesc: {
    id: 'TeEXkF',
    defaultMessage:
      'BioNsight cloud allows you to instantly view all relevant bioprocess data in a vendor-agnostic manner. You can automatically transfer data from internal sensors during a running process, upload offline data from external analyzers, include data from historic runs, and integrate process data from non-Eppendorf bioprocess controllers. This accessibility maximizes the potential of your bioprocess data. Additionally, it makes it easy to grant data access to internal and external partners for discussion, analysis, and troubleshooting.',
    description: 'Landing page product integration text',
  },
  landingBuildTitle: {
    id: '/xhFga',
    defaultMessage: 'Build on your Data',
    description: 'Landing page Build on your data title',
  },
  landingBuildSubTitle: {
    id: 'axmsbi',
    defaultMessage:
      'Contextualize data across devices, runs, and sites - To optimize, transfer, scale-up',
    description: 'Landing page Build on your data subtitle',
  },
  landingBuildDesc: {
    id: 'zLUgpS',
    defaultMessage:
      'With BioNsight cloud you can easily synchronize and compare data from different batches, making it easier to identify patterns and anomalies across runs, accelerating your development process, and ensuring consistent quality and performance. Collaborate with colleagues, from researchers to production managers, regardless of their location. Discuss your process data when transferring a process to a different site, or compare performance between small-scale and bench-scale systems during scale-up.',
    description: 'Landing page Build on your data text',
  },
  landingInterestedTitle: {
    id: 'JOvPyS',
    defaultMessage: 'Interested?',
    description: 'Landing page interested title',
  },
  landingInterestedSubTitle: {
    id: 'Mb0WCV',
    defaultMessage: 'Find out more about BioNsight cloud',
    description: 'Landing page interested subtitle',
  },
  landingInterestedDesc: {
    id: 'QkUwbi',
    defaultMessage:
      'We{apos}d love to tell you more about our software, answer any questions you may have, or schedule a software demo for you! {br} Visit our website for more information',
    description: 'Landing page Build on your data text',
  },
});

export const DATA_FEATURES = [
  {
    name: 'Data transfer to the cloud in real-time',
    description:
      'Connect your existing DASware control 6 installationto BioNsight cloud with a few simple clicks.\nEasily automate the transfer of bioprocess data to the cloud. There is no need for manual uploads or exports using Excel sheets.',
    icon: CloudIcon,
  },
  {
    name: 'Simple upload of historic data to the cloud',
    description:
      'Easily upload historic bioprocess data from DASware control 5 or DASware control 6 software to the cloud.',
    icon: ScadaIcon,
  },
  {
    name: 'Upload of data from external analyzers',
    description:
      'Upload data generated with external analyzers, for example cell density, viability, and nutrient concentrations.',
    icon: CloudIcon,
  },
  {
    name: 'Upload of data from other SCADA software',
    description:
      'Upload bioprocess data from BioCommand® software to the cloud. Upload bioprocess data generated with non-Eppendorf bioprocess control software.',
    icon: ScadaIcon,
  },
];
