import { useNavigate } from 'react-router-dom';

import RouteDefinition from '../../routes/routes.definitions';

function useLogin() {
  const navigate = useNavigate();

  return () => {
    navigate(RouteDefinition.Welcome);
  };
}

export default useLogin;
