import React from 'react';

import TrackedEvent from '../../../common/tracked-event';
import useLogger from '../../../common/hooks/use-logger/use-logger';

import YAxisRangeInputs from '../y-axis-range-inputs';
import { YAxisRange } from '../y-axis-range-inputs/y-axis-range-inputs.validation';

import { SideToolboxProps } from './side-toolbox.definitions';

function SideToolbox({ seriesName, range, onRangeChange }: SideToolboxProps) {
  const logger = useLogger();

  const handleRangeChange = (yAxisRange: YAxisRange, seriesType: string) => {
    onRangeChange?.(yAxisRange, seriesType);
    logger.trackEvent(TrackedEvent.SetYAxisRange);
  };

  return (
    <div className="pb-4 pl-4 pt-[3.2rem] max-md:hidden">
      <YAxisRangeInputs
        className="flex flex-col justify-between gap-4 md:h-[290px]"
        range={range}
        labelPrefix={seriesName}
        onRangeChange={handleRangeChange}
        isReverse
      />
    </div>
  );
}

export default SideToolbox;
