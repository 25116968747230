import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ProgressState } from '../../../shared/common/hooks/use-upload/use-upload.definitions';
import useUploadProcedureResult from '../../common/hooks/use-upload-procedure-result';

import { FileUploadResult } from './file-upload.definitions';
import { UploadForm } from './upload-form';
import { UploadProgress } from './upload-progress';
import { UploadReceipt } from './upload-receipt';

/**
 * Upload Container to track upload progress of larger POSTs
 */
function FileUpload() {
  const { mutate: upload, isPending } = useUploadProcedureResult();

  const [file, setFile] = useState<File | undefined | null>(null);
  const [displayName, setDisplayName] = useState<string | null>(null);

  const [uploadState, setUploadState] = useState<ProgressState>();
  const [uploadResult, setUploadResult] = useState<FileUploadResult>();

  function handleDisplayName(target: EventTarget & HTMLInputElement) {
    setDisplayName(target.value);
  }

  function handleFile(target: EventTarget & HTMLInputElement) {
    // do not set to dirty if nothing was selected the first time
    if (file === null && target.files?.length === 0) {
      return;
    }

    setFile(target.files?.[0]);
  }

  function handleUploadClick() {
    if (!file || !displayName) {
      return;
    }

    setUploadResult(undefined);

    const formData = new FormData();

    formData.append('displayName', displayName);
    formData.append('fileContent', file, file.name);

    upload(
      {
        formData,
        onProgress: setUploadState,
      },
      {
        onSuccess() {
          setUploadResult({ status: 'ok' });

          // clear form elements and set to untouched
          setDisplayName(null);
          setFile(null);
        },
        onError(err) {
          setUploadResult({ status: 'error', message: err.message });
        },
      },
    );
  }

  return (
    <div className="p-4" data-testid="FileUpload">
      <h1>
        <FormattedMessage description="Headline" defaultMessage="BIOCL File Uploader" id="nOgd8R" />
      </h1>

      <UploadForm
        isLoading={isPending}
        displayName={displayName}
        onDisplayNameChange={(input) => handleDisplayName(input)}
        file={file}
        onFileChange={(input) => handleFile(input)}
        onUploadClick={() => handleUploadClick()}
      />

      {!isPending && uploadResult && <UploadReceipt uploadResult={uploadResult} />}

      {isPending && <UploadProgress progress={uploadState?.progress ?? 0} />}
    </div>
  );
}

export default FileUpload;
