import { createCheckers } from 'ts-interface-checker';

import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { joinPaths } from '../../../../shared/utils';
import {
  DataHowLabConfiguration,
  DataHowLabConfigurationDTO,
  transformDataHowLabConfigurationDTO,
} from '../../types';
import { createUrlOrganizationById } from '../use-organization/use-organization.helpers';
import exportedTypeSuite from '../../types/organization/dto/organization-validators';

export function createGetDataHowLabConfigurationUrl(organizationId: string): string {
  return joinPaths(createUrlOrganizationById(organizationId), 'datahowlabconfiguration');
}

export function isDataHowLabConfigurationDTO(
  dto: unknown,
): asserts dto is DataHowLabConfigurationDTO {
  const { DataHowLabConfigurationDTO: dataHowLabConfigurationChecker } =
    createCheckers(exportedTypeSuite);

  // throws an error when object is not in the correct format
  dataHowLabConfigurationChecker.check(dto);
}

export async function fetchDataHowLabConfiguration(
  organizationId: string,
  accessToken: string,
): Promise<DataHowLabConfiguration | null> {
  const dataHowLabConfiguration = await fetchData(
    createGetDataHowLabConfigurationUrl(organizationId),
    {
      method: HttpMethods.Get,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  if (!dataHowLabConfiguration) {
    return null;
  }

  // throws an error when object is not in the correct format
  isDataHowLabConfigurationDTO(dataHowLabConfiguration);
  return transformDataHowLabConfigurationDTO(dataHowLabConfiguration);
}
