import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { StructuredSetupDescriptor } from '../../../../shared/common/types/setup';
import useClient from '../../../../shared/common/hooks/use-client';

function useDeleteWorkflow(controlProcedureId: string) {
  /** query key of the list of setups */
  const sourceQueryKey = [QKey.SETUPS];

  const queryClient = useQueryClient();

  const { acquireAccessToken } = useAuthentication();
  const { pmsClient } = useClient();

  return useMutation({
    mutationFn: async () => {
      pmsClient.authorize(await acquireAccessToken());
      return pmsClient.deleteControlProcedure(controlProcedureId);
    },
    onMutate: () => {
      const previousSetupsData =
        queryClient.getQueryData<StructuredSetupDescriptor[]>(sourceQueryKey);

      if (!previousSetupsData) {
        throw new Error(`Expected query with key ${JSON.stringify(sourceQueryKey)} to exist.`);
      }

      queryClient.setQueryData<StructuredSetupDescriptor[]>(sourceQueryKey, [
        ...previousSetupsData.filter((setup) => setup.controlProcedureId !== controlProcedureId),
      ]);

      return previousSetupsData;
    },
    onError: (_, __, previous) => {
      queryClient.setQueryData(sourceQueryKey, previous);
    },
  });
}

export default useDeleteWorkflow;
