import { DEVICES, INITIALS, INITIAL_LENGTH } from './is-mobile.definitions';

/**
 * @warning use as last resort,
 * feature detection is generally be prefered over explicit checks
 * @see {@link https://developer.mozilla.org/en-US/docs/Learn/Tools_and_testing/Cross_browser_testing/Feature_detection}
 * @returns true if the user is on a mobile device
 */
function isMobile(): boolean {
  if ('userAgent' in navigator === false) {
    return false;
  }

  return (
    DEVICES.test(navigator.userAgent) ||
    INITIALS.test(navigator.userAgent.substring(0, INITIAL_LENGTH))
  );
}

export default isMobile;
