import {
  Series,
  SeriesMarkLine,
  markLineColor,
  markLineFinished,
  markLineInoculation,
  markLineLastUpdate,
} from '../../../../../shared/components/time-series-chart/time-series-chart.definitions';
import { getDataTrackColor, isMoreThanDay } from '../../setup-overview.helpers';
import { formatDataPoints } from '../../../setup-details/setup-details.helpers';
import {
  DataTrack,
  DataTrackDescriptor,
  DataTrackType,
  DataTrackFromTimestampItem,
  LatestDataPointObject,
} from '../../../../../shared/common/types/setup/data-track';
import { getGraphColors, subtractHours } from '../../../../common/helpers';

import { SetupDescriptorConnectionState } from '../../../../../shared/common/types/generated/pms.generated';

import { MONITORING_TIME_SPAN, LINE_WIDTH } from './setup-overview-unit.definitions';

export function getSeries(
  selectedDataTracks: Record<DataTrackType, string>,
  dataTracks?: DataTrack[],
): Record<DataTrackType, Series[]> | null {
  if (!dataTracks?.length) {
    return null;
  }
  dataTracks.sort((a, b) => a.dataTrackType.localeCompare(b.dataTrackType));
  const colors = getGraphColors();

  return dataTracks.reduce(
    (acc: Record<DataTrackType, Series[]>, track: DataTrack, index: number) => {
      const colorName = selectedDataTracks[track.dataTrackType] || colors[index % colors.length];
      const color = getDataTrackColor(colorName);
      const dataPoints = formatDataPoints(track.dataPoints, track.fractionalDigits);
      acc[track.dataTrackType] = [
        {
          ...track,
          dataPoints,
          color,
          width: LINE_WIDTH,
        },
      ];
      return acc;
    },
    {},
  );
}

export function getDataTrackTimestamp(
  selectedDataTracks: Record<DataTrackType, string>,
  dataTracks?: DataTrackDescriptor[],
): DataTrackFromTimestampItem[] {
  const dataTrackList: DataTrackFromTimestampItem[] = [];
  Object.keys(selectedDataTracks).forEach((track: DataTrackType) => {
    const timestamp = subtractHours(new Date(), MONITORING_TIME_SPAN);
    const dataTrack = dataTracks?.find((item) => item.dataTrackType === track);
    if (dataTrack) {
      dataTrackList.push({
        id: dataTrack.dataTrackId,
        timestamp: new Date(timestamp).toISOString(),
      });
    }
  });
  return dataTrackList;
}

export function getIsOutdated(startTime: Date, stopTime?: Date): boolean {
  if (!stopTime) {
    return false;
  }
  return startTime.getTime() > stopTime.getTime();
}

export function getMarkLines(
  inoculationTimestamp?: Date,
  stopTimestamp?: Date,
  disconnected = false,
  lastTimestamp?: number,
): SeriesMarkLine[] {
  const seriesMarkLines: SeriesMarkLine[] = [];
  if (inoculationTimestamp) {
    seriesMarkLines.push({
      name: markLineInoculation,
      timestamp: inoculationTimestamp.getTime(),
      color: markLineColor,
    });
  }
  if (stopTimestamp) {
    seriesMarkLines.push({
      name: markLineFinished,
      timestamp: stopTimestamp.getTime(),
      color: markLineColor,
    });
  }
  if (disconnected && lastTimestamp && !stopTimestamp) {
    seriesMarkLines.push({
      name: markLineLastUpdate,
      timestamp: lastTimestamp,
      color: markLineColor,
    });
  }
  return seriesMarkLines;
}

export function filterDataTracks(
  selectedDataTracks: Record<DataTrackType, string>,
  dataTracks: DataTrack[],
) {
  const selected = Object.keys(selectedDataTracks);
  return dataTracks.filter((item) => selected.includes(item.dataTrackType));
}

export function combineDataTracks(
  dataTracksInfo: DataTrackDescriptor[],
  dataTracks: DataTrack[],
  selected: Record<DataTrackType, string>,
  dataPoints?: LatestDataPointObject[],
): DataTrack[] {
  const newDataTracks: DataTrack[] = [];
  Object.keys(selected).forEach((trackType) => {
    const dataTrackInfo = dataTracksInfo.find((track) => track.dataTrackType === trackType);
    if (!dataTrackInfo) {
      return;
    }
    const dataTrack = dataTracks.find((track) => track.dataTrackType === trackType);
    const dataTrackPoints = dataPoints?.find(
      (track) => track.dataTrackId === dataTrackInfo.dataTrackId,
    );
    const points = dataTrackPoints ? dataTrackPoints.dataPoints : dataTrack?.dataPoints;

    newDataTracks.push({
      ...dataTrackInfo,
      dataPoints: points || [],
    });
  });
  return newDataTracks;
}

export function getNewDataTracks(
  selectedDataTracks: Record<DataTrackType, string>,
  selected: Record<DataTrackType, string>,
  dataTracks: DataTrackDescriptor[],
): Record<DataTrackType, string> {
  return Object.keys(selectedDataTracks).reduce(
    (acc: Record<DataTrackType, string>, track: DataTrackType) => {
      if (
        Object.keys(selected).every((item) => item !== track) &&
        dataTracks.find((item) => item.dataTrackType === track)
      ) {
        acc[track] = selectedDataTracks[track];
      }
      return acc;
    },
    {},
  );
}

export function getSetupNewDataTracks(
  dataTracks: DataTrack[],
  setupDataTracks: DataTrackDescriptor[],
  selected: Record<DataTrackType, string>,
): DataTrack[] {
  const newTracks: DataTrack[] = [];
  if (dataTracks.length === Object.keys(selected).length) {
    return newTracks;
  }

  return Object.keys(selected).reduce((acc: DataTrack[], track: DataTrackType) => {
    const dataTrack = dataTracks.find((item) => item.dataTrackType === track);
    if (!dataTrack) {
      const dataTrackDetails = setupDataTracks.find((item) => item.dataTrackType === track);
      if (dataTrackDetails) {
        acc.push({ ...dataTrackDetails, dataPoints: [] });
      }
    }

    return acc;
  }, []);
}

export function getDataTracksLastTimestamps(
  dataTracks: DataTrack[],
  timeSpan: number = MONITORING_TIME_SPAN,
): DataTrackFromTimestampItem[] {
  const startTime = subtractHours(new Date(), timeSpan);
  return dataTracks.map((track: DataTrack) => {
    const lastDataPoint = track.dataPoints.length
      ? track.dataPoints[track.dataPoints.length - 1]
      : undefined;

    // the timestamp in the database has greater precision
    // to avoid the duplicates request data points after the last timestamp plus 1 milliseconds
    const lastDataPointTimeStamp = lastDataPoint?.ts ? lastDataPoint.ts + 1 : startTime;
    const timestamp = new Date(lastDataPointTimeStamp).toISOString();
    return { id: track.dataTrackId, timestamp };
  });
}

export function getIsSetupIdleInitially(
  startTimestamp: Date,
  dataPoints?: LatestDataPointObject[],
) {
  if (dataPoints?.length) {
    return false;
  }
  return isMoreThanDay(startTimestamp);
}

export function getIsSetupIdle(startTimestamp: Date, dataTracks?: DataTrack[]) {
  if (!dataTracks?.length) {
    return false;
  }
  if (isMoreThanDay(startTimestamp)) {
    let isIdle = true;
    dataTracks.forEach((item) => {
      if (item.dataPoints.length) {
        const tsLast = item.dataPoints[item.dataPoints.length - 1].ts;
        if (!isMoreThanDay(new Date(tsLast))) {
          isIdle = false;
        }
      }
    });
    return isIdle;
  }
  return false;
}

export function isUpdateDataTracksTimestamp(isFetching: boolean, stopTimestamp?: Date) {
  return !stopTimestamp && !isFetching;
}

export function isAddToIdle(isSetupIdle: boolean, isIdle: boolean) {
  return isSetupIdle && !isIdle;
}

export function isRemoveFromIdle(isSetupIdle: boolean, isIdle: boolean) {
  return !isSetupIdle && isIdle;
}

export function getIsDisconnected(
  connectionState: SetupDescriptorConnectionState,
  isFinished: boolean,
) {
  return connectionState === 'Disconnected' && !isFinished;
}

export function getIsLoading(
  isLoadingSetups: boolean,
  isLoadingDataPoints: boolean,
  isLoading: boolean,
): boolean {
  return isLoadingSetups || isLoadingDataPoints || isLoading;
}
