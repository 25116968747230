import React, { useEffect } from 'react';
import { Input } from '@biss/react-horizon-web';
import { FormattedMessage } from 'react-intl';

import { DataTrack } from '../../../../shared/common/types/process-record';

import { MetadataFormProps } from './metadata-form.definitions';
import { validate } from './metadata-form.validation';

function MetadataForm({ datatracks, takenNames, onChange, isDisabled }: MetadataFormProps) {
  const change = (newDatatracks: DataTrack[]) => {
    const localNames = newDatatracks.map((dt) => dt.displayName);

    const isValid = newDatatracks.every((track) => validate(track, localNames, takenNames).isValid);
    onChange(newDatatracks, isValid);
  };

  useEffect(() => {
    // if the datatracks passed in by the parent are changed validate the new datatracks and notify the parent
    change(datatracks);
  }, [datatracks.map((dt) => dt.dataTrackId).join()]);

  return (
    <div className="flex flex-col">
      <h3 className="mb-4 rounded-md  bg-gray-100 p-3">
        <FormattedMessage
          description="Data track count"
          defaultMessage="Found {count} datatracks"
          values={{ count: datatracks.length }}
          id="yJJOAv"
        />
      </h3>
      <div className="grid grid-cols-2 gap-4">
        {datatracks.map(({ dataTrackId, displayName, dataTrackType, engineeringUnit }, index) => {
          const lens = (name: string | undefined, unit?: string) => {
            const draft = { ...datatracks[index] };
            draft.displayName = name ?? displayName;
            draft.dataTrackType = name ?? dataTrackType;

            draft.engineeringUnit = unit ?? engineeringUnit;

            change([...datatracks.slice(0, index), draft, ...datatracks.slice(index + 1)]);
          };

          const localNames = datatracks.map((dt) => dt.displayName);
          const { nameRes, unitRes } = validate(
            { displayName, engineeringUnit },
            localNames,
            takenNames,
          ).fields;

          return (
            <React.Fragment key={dataTrackId}>
              <Input
                expand="auto"
                data-testid="inputDisplayName"
                label="Display Name"
                type="text"
                value={displayName}
                error={nameRes.success ? undefined : nameRes.error.errors?.[0].message}
                onChange={(i) => lens(i.currentTarget.value)}
                disabled={isDisabled}
              />
              <Input
                expand="auto"
                data-testid="inputEngineeringUnit"
                label="Engineering Unit"
                type="text"
                value={engineeringUnit}
                error={unitRes.success ? undefined : unitRes.error.errors?.[0].message}
                onChange={(i) => lens(undefined, i.currentTarget.value)}
                disabled={isDisabled}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default MetadataForm;
