import React from 'react';
import { Button, DeleteIcon } from '@biss/react-horizon-web';
import { FormattedMessage } from 'react-intl';

import ConfirmAction from '../../../../../shared/components/confirm-action/confirm-action';
import useLogger from '../../../../../shared/common/hooks/use-logger/use-logger';
import TrackedEvent from '../../../../../shared/common/tracked-event';

import RemoveButtonProps from './remove-button.definitions';

function RemoveButton({ onClick }: RemoveButtonProps) {
  const logger = useLogger();

  const handleOnClick = () => {
    logger.trackEvent(TrackedEvent.StartRemovingReferenceCurve);
  };

  return (
    <ConfirmAction
      confirmButtonText={
        <FormattedMessage
          description="Confirm Action Modal: label for confirm button"
          defaultMessage="Remove"
          id="UFq1k+"
        />
      }
      title={
        <FormattedMessage
          description="Confirm Action Modal: title for confirmation modal"
          defaultMessage="Confirm Removal"
          id="N9SM5L"
        />
      }
      content={
        <FormattedMessage
          description="Confirm Action Modal: description for confirmation modal"
          defaultMessage="Are you sure you want to remove the reference curve from the graph?"
          id="c8Idau"
        />
      }
      onConfirm={onClick}
      trigger={
        <Button
          mood="destructive"
          kind="secondary"
          leftIcon={<DeleteIcon title="Remove" />}
          data-testid="remove-reference-curve-button"
          onClick={handleOnClick}
        />
      }
    />
  );
}

export default RemoveButton;
