import React from 'react';
import { FormattedMessage } from 'react-intl';

import LoadingIndicator from '../../components/loading-indicator';
import { useProjectConfiguration } from '../../common/hooks/use-project-configuration';

import { ProjectConfigWaterfallProps } from './project-config.definitions';
import ProjectConfigGlobals from './project-config-globals';

/**
 * this component makes sure its children are only rendered when the config object is available,
 * this way the children can safely assume {@link ProjectConfigAccessor} will not fail when returning a config parameter.
 */
function ProjectConfigWaterfall({ children }: ProjectConfigWaterfallProps) {
  const { data: configuration, isLoading, isError } = useProjectConfiguration();

  if (isError) {
    return (
      <div className="flex h-screen w-screen items-center justify-center overflow-hidden">
        <FormattedMessage
          defaultMessage="Apologies! We could not load the BioNsight cloud configuration. Please retry in a few minutes and contact support if the problem persists."
          id="LcoBaE"
          description="Failed to load config"
        />
      </div>
    );
  }

  if (isLoading || configuration === undefined) {
    return (
      <div className="flex h-screen w-screen items-center justify-center overflow-hidden">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <ProjectConfigGlobals.Provider value={configuration}>
      {children(configuration)}
    </ProjectConfigGlobals.Provider>
  );
}

export default ProjectConfigWaterfall;
