import { useMutation } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import { DataHowLabConfiguration } from '../../types';

import putDataHowLabConfiguration from './use-validate-datahowlab-configuration.helpers';

function useValidateDataHowLabConfiguration(
  organizationId: string,
  onMutated: (isValid: boolean) => void,
) {
  const { acquireAccessToken } = useAuthentication();
  return useMutation<boolean, unknown, DataHowLabConfiguration, unknown>({
    mutationFn: async (configuration) => {
      const accessToken = await acquireAccessToken();
      return putDataHowLabConfiguration(organizationId, configuration, accessToken);
    },
    // If the mutation fails, use the context we returned above
    onError: (_1, _2, _3) => {
      onMutated(false);
    },
    onSuccess: (result, _2, _3) => {
      onMutated(result);
    },
  });
}

export default useValidateDataHowLabConfiguration;
