import { useIntl } from 'react-intl';

import timeSpan, { Precision } from '../../../../../shared/utils/time-span';
import { DataPointObject } from '../../../../../shared/components/time-series-chart';
import { DataTrackTimeAlignment } from '../process-record-visualization.definitions';
import { calculateRelativeTimestamps } from '../process-record-visualization.helpers';
import { DataTrack } from '../../../../../shared/common/types/process-record';

export function getEarliestDate(dates: Date[]): Date {
  if (dates.length === 0) {
    throw new TypeError('The given dates may not be empty');
  }

  return dates.reduce((pre, cur) => (pre > cur ? cur : pre));
}

export function useFormatXAxisTicks(): (ts: number) => string {
  const intl = useIntl();

  return (ts: number) =>
    intl.formatDate(ts, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
}

export function useRelativeXAxisTimestamps(precision: Precision = 'hours'): (ts: number) => string {
  return (ts: number) => timeSpan(ts, precision);
}

export const calculateInoculationTime = (
  timeAlignment: DataTrackTimeAlignment,
  startTimeTimestamp?: number,
  inoculationTime?: number,
): number | undefined => {
  if (!inoculationTime) {
    return undefined;
  }

  if (timeAlignment === DataTrackTimeAlignment.Absolute) {
    return inoculationTime;
  }

  if (timeAlignment === DataTrackTimeAlignment.RelativeToInoculationTime) {
    return 0;
  }

  if (!startTimeTimestamp) {
    return 0;
  }

  return inoculationTime - startTimeTimestamp;
};

export const calculateDataTrackDataPoints = (
  rawDataPoints: DataPointObject[],
  timeAlignment: DataTrackTimeAlignment,
  startTimestamp: Date,
  inoculationTimestamp?: Date,
) => {
  if (timeAlignment === DataTrackTimeAlignment.RelativeToStartTime) {
    return calculateRelativeTimestamps(startTimestamp.getTime(), rawDataPoints);
  }

  if (timeAlignment === DataTrackTimeAlignment.RelativeToInoculationTime) {
    if (inoculationTimestamp === undefined) {
      throw new Error(
        `Expected inoculationTimestamp to be defined when timeAlignment=${DataTrackTimeAlignment.RelativeToInoculationTime}`,
      );
    }

    return calculateRelativeTimestamps(inoculationTimestamp.getTime(), rawDataPoints);
  }

  if (timeAlignment === DataTrackTimeAlignment.Absolute) {
    return rawDataPoints;
  }

  throw new Error(`Unhandled arm. Scenario timeAlignment=${timeAlignment} was not handled.`);
};

export const getDataTracksTimestamps = (dataTracks: Pick<DataTrack, 'dataPoints'>[]) =>
  dataTracks.flatMap((dataTrack) => dataTrack.dataPoints.map((dataPoint) => dataPoint.ts));

/**
 * get the oldest data point out of all the {@link dataTracks data tracks} passed in the input.
 * the oldest data point is the one with the smallest `ts` value.
 * this function assumes the passed in {@link dataTracks data tracks} have their `dataPoints` sorted by `timeStamp`.
 * @param dataTracks
 */
export const calculateOldestDataPointTimestamp = (dataTracks: DataTrack[]) =>
  Math.min(
    ...dataTracks
      // attempt to get the first data point of every data track
      .flatMap((dataTrack) => dataTrack.dataPoints.at(0)?.ts)
      // filter out empty data tracks
      .filter((maybeTs): maybeTs is number => maybeTs !== undefined),
  );

/**
 * get the youngest data point out of all the {@link dataTracks data tracks} passed in the input.
 * the youngest data point is the one with the greatest `ts` value.
 * this function assumes the passed in {@link dataTracks data tracks} have their `dataPoints` sorted by `timeStamp`.
 * @param dataTracks
 */
export const calculateYoungestDataPointTimestamp = (dataTracks: DataTrack[]) =>
  Math.max(
    ...dataTracks
      // attempt to get the last data point of every data track
      .flatMap((dataTrack) => dataTrack.dataPoints.at(-1)?.ts)
      // filter out empty data tracks
      .filter((maybeTs): maybeTs is number => maybeTs !== undefined),
  );
