import React from 'react';
import { Link } from 'react-router-dom';
import * as Collapsible from '@radix-ui/react-collapsible';
import { ChevronUpDownIcon, Tile } from '@biss/react-horizon-web';
import { useIntl } from 'react-intl';

import { Section } from './welcome.definitions';

function WelcomeSection({ icon, title, subsections }: Section) {
  const intl = useIntl();

  return (
    <Tile>
      <Tile.Body>
        {/* Icon in box */}
        <div className="mr-4 inline-flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10">
          {icon}
        </div>
        <h2 className="inline text-lg">{title}</h2>

        <ul className="m-4 list-outside list-['→'] space-y-4 pl-12 text-lg">
          {subsections.map((subsection) => {
            if (subsection.link) {
              return (
                <li key={subsection.title} className="pl-4">
                  <Link to={subsection.link} className="after:content-['_↗']">
                    {subsection.title}
                  </Link>
                </li>
              );
            }

            if (subsection.steps === undefined) {
              return null;
            }

            const steps =
              typeof subsection.steps === 'function' ? subsection.steps(intl) : subsection.steps;

            return (
              <li key={subsection.title} className="pl-4">
                <Collapsible.Root className="inline">
                  <Collapsible.Trigger className="text-left">
                    {subsection.title}
                    <ChevronUpDownIcon className="inline h-5" />
                  </Collapsible.Trigger>
                  <Collapsible.Content>
                    <ol style={{ listStyleType: 'disc' }} className="space-y-8 pl-7">
                      {steps.map((step) => (
                        <li key={step.title}>
                          {step.title}
                          <div className="text-sm">
                            {step.description}
                            {step.image && (
                              <img src={step.image} alt={step?.imageDesc} className="border-4" />
                            )}
                          </div>
                        </li>
                      ))}
                    </ol>
                  </Collapsible.Content>
                </Collapsible.Root>
              </li>
            );
          })}
        </ul>
      </Tile.Body>
    </Tile>
  );
}

export default WelcomeSection;
