import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { SETUPS_INTERVAL } from '../../types/setup';

import {
  SetupDescriptorDTO,
  StructuredSetupDescriptor,
} from '../../../../shared/common/types/setup';
import useClient from '../../../../shared/common/hooks/use-client';
import { transformSetupDescriptorDTO } from '../../../../shared/common/types/setup/dto';

export default function useSetupList(
  autoUpdate = false,
): UseQueryResult<StructuredSetupDescriptor[]> {
  const { acquireAccessToken } = useAuthentication();
  const { pmsClient } = useClient();

  return useQuery({
    queryKey: [QKey.SETUPS],
    refetchInterval: autoUpdate ? SETUPS_INTERVAL : false,
    refetchIntervalInBackground: autoUpdate,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      // fetch
      pmsClient.authorize(await acquireAccessToken());
      const result = await pmsClient.listSetups();

      // filter out setups without start timestamp and transform
      return result
        .filter((setup) => setup.startTimestamp)
        .map((dto) => transformSetupDescriptorDTO(dto as SetupDescriptorDTO));
    },
  });
}
