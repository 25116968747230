import { useSearchParams } from 'react-router-dom';

import { StructuredProcessRecordObject } from '../../../shared/common/types/process-record';

import {
  ColoredProcessRecord,
  PROCESS_RECORD_COLORS,
} from './process-record-comparison.definitions';

export default function useProcessRecordIdsFromSearchQuery(): string[] {
  const [searchParams] = useSearchParams();
  return searchParams.getAll('pr');
}

/**
 * @param records uncolored process records
 * @returns colored process records
 */
export function colorizeProcessRecords(
  records: StructuredProcessRecordObject[],
): ColoredProcessRecord[] {
  return records.map((record, index) => ({
    ...record,
    color: PROCESS_RECORD_COLORS[index % PROCESS_RECORD_COLORS.length],
  }));
}
