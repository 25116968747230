import { useIntl } from 'react-intl';

import {
  COMBINED_CHART_LEFT_OFFSET_PX,
  COMBINED_CHART_RIGHT_OFFSET_PX,
  COMBINED_CHART_X_AXIS_ZOOM_HEIGHT,
  COMBINED_CHART_X_AXIS_ZOOM_INSIDE_ID,
  COMBINED_CHART_X_AXIS_ZOOM_SLIDER_ID,
  COMBINED_CHART_Y_AXIS_ZOOM_SLIDER_ID,
} from '../combined-chart.config';
import { ChartXAxisTimeStampFormat } from '../../chart-formatters/chart-formatters.definitions';
import { getXAxisFormatter } from '../../chart-formatters/chart-formatters';
import useHorizontalZoom from '../../chart-zoom/use-horizontal-zoom';
import useVerticalZoom from '../../chart-zoom/use-vertical-zoom';

import { getMinValueSpan, getOnZoomCallback } from '../../chart-zoom';

/**
 * Configures the Echarts settings for the y axis
 * depending on the amount of displayed lines and current zoom level.
 *
 * @param {number} amountOfLines the amount of lines that are displayed in the combined chart.
 * @returns y axis configuration object to configure Echarts with
 */
function useEchartsDataZoomConfig(
  amountOfLines: number,
  timeStampFormat: ChartXAxisTimeStampFormat,
  zoomX: { start: number; end: number },
  zoomY: { start: number; end: number },
) {
  const intl = useIntl();

  const dateFormatter = getXAxisFormatter(timeStampFormat, intl);
  const minValueSpan = getMinValueSpan(timeStampFormat);

  return [
    {
      type: 'slider',
      id: COMBINED_CHART_X_AXIS_ZOOM_SLIDER_ID,
      orient: 'horizontal',
      realtime: true,
      // the combined chart only contains a single x axis
      xAxisIndex: [0, 0],
      filterMode: 'none',
      left: COMBINED_CHART_LEFT_OFFSET_PX * amountOfLines,
      right: COMBINED_CHART_RIGHT_OFFSET_PX,
      showDataShadow: false,
      height: COMBINED_CHART_X_AXIS_ZOOM_HEIGHT,
      bottom: 0,
      zoomLock: false,
      labelFormatter: dateFormatter,
      brushSelect: false,
      start: zoomX.start,
      end: zoomX.end,
      minValueSpan,
    },
    {
      type: 'inside',
      id: COMBINED_CHART_X_AXIS_ZOOM_INSIDE_ID,
      orient: 'horizontal',
      // the combined chart only contains a single x axis
      xAxisIndex: [0, 0],
      rangeMode: ['value', 'percent'],
      zoomOnMouseWheel: 'shift',
      moveOnMouseWheel: 'ctrl',
      moveOnMouseMove: true,
      preventDefaultMouseMove: false,
      filterMode: 'none',
      labelFormatter: dateFormatter,
      start: zoomX.start,
      end: zoomX.end,
      minValueSpan,
    },
    {
      type: 'slider',
      id: COMBINED_CHART_Y_AXIS_ZOOM_SLIDER_ID,
      orient: 'vertical',
      rangeMode: ['percent', 'percent'],
      // the combined chart contains a y axis for each line in the chart
      //  the slider controls all y axes [0, 1, ..., data.length]
      yAxisIndex: Array.from(Array(amountOfLines).keys()),
      filterMode: 'none',
      show: true,
      showDataShadow: false,
      brushSelect: false,
      showDetail: false,
      right: 5,
      start: zoomY.start,
      end: zoomY.end,
    },
  ];
}

/**
 * Configuration to set up the zoom for the combined chart.
 *
 * @param amountOfYAxes amount of currently displayed y axes.
 *  This is required to configure the charts layout and the echarts zoom configuration.
 * @param {ChartXAxisTimeStampFormat} timeStampFormat - decides the format of the generated time stamp strings
 */
function useZoom(amountOfYAxes: number, timeStampFormat: ChartXAxisTimeStampFormat) {
  // TODO: (BIOCL-4457) move zoom hook into chart "package"
  const zoomX = useHorizontalZoom();
  const zoomY = useVerticalZoom();

  // TODO: set zoom lock to false imperatively to enable shortcut keys
  const echartsZoomConfig = useEchartsDataZoomConfig(
    amountOfYAxes,
    timeStampFormat,
    zoomX.current,
    zoomY.current,
  );
  const onZoomCallback = getOnZoomCallback(zoomX, zoomY);

  return { onZoomCallback, echartsZoomConfig };
}

export default useZoom;
