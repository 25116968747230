import {
  ProcessRecordObject,
  ProcessRecordDescriptor,
  ProcessRecordPayload,
} from '../../generated/prs.generated';
import {
  StructuredProcessRecordDescriptor,
  StructuredProcessRecordObject,
  StructuredProcessRecordPayload,
} from '../process-record';

/**
 * structure the raw {@link ProcessRecordDescriptor} DTO object.
 * @param dto
 */
export function transformProcessRecordDescriptor(
  dto: ProcessRecordDescriptor,
): StructuredProcessRecordDescriptor {
  return {
    ...dto,
    startTimestamp: new Date(dto.startTimestamp),
    stopTimestamp: new Date(dto.stopTimestamp),
    uploadTimestamp: dto.uploadTimestamp ? new Date(dto.uploadTimestamp) : undefined,
    inoculationTimestamp: dto.inoculationTimestamp ? new Date(dto.inoculationTimestamp) : undefined,
  };
}

/**
 * structure the raw {@link ProcessRecordObject} DTO object.
 * @param dto
 */
export function transformProcessRecordObject(
  dto: ProcessRecordObject,
): StructuredProcessRecordObject {
  const descriptor = transformProcessRecordDescriptor(dto);

  return {
    ...dto,
    ...descriptor,
    // disambiguation
    inoculationTimestamp: descriptor.inoculationTimestamp,
    uploadTimestamp: descriptor.uploadTimestamp,
  };
}

/**
 * structure the raw {@link ProcessRecordDescriptor} DTO object.
 * @param dto
 */
export function transformProcessRecordPayload(
  dto: ProcessRecordPayload,
): StructuredProcessRecordPayload {
  return {
    ...dto,
    startTimestamp: new Date(dto.startTimestamp),
    stopTimestamp: new Date(dto.stopTimestamp),
    inoculationTimestamp: dto.inoculationTimestamp ? new Date(dto.inoculationTimestamp) : undefined,
  };
}
