import React, { useEffect, useState } from 'react';

import { ConsentContext, ConsentGlobals } from './consent-globals';
import { ConsentProps } from './consent.definitions';
import {
  addOneTrustScriptOnMountEffect,
  getConsentContext,
  updateStateOnConsentChangeEffect,
} from './consent.helpers';

function Consent({ children, env }: ConsentProps) {
  const host = window.location.hostname;

  // initialize cookies
  const [flags, setFlags] = useState<ConsentContext>(getConsentContext());

  // only keep the one trust script in the beta environment
  useEffect(() => {
    addOneTrustScriptOnMountEffect(env, host);
  }, [env, host]);

  // update context when cookie settings change
  // this listens to the event, triggered by the One Trust dialog when cookie preferences are changed
  useEffect(() => updateStateOnConsentChangeEffect(setFlags), []);

  return <ConsentGlobals.Provider value={flags}>{children(flags)}</ConsentGlobals.Provider>;
}

export default Consent;
