import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { Uuid } from '../../../../shared/common/types/uuid';
import { joinPaths } from '../../../../shared/utils';
import { projectConfigInstance } from '../../../../shared/globals/project-config/project-config-accessor';
import { ProcessRecordOptimizationVariable } from '../../types/process-record-optimization-variables';

export function createRecipeOptimizationVariableMappingUrl(processRecordId: Uuid): string {
  return joinPaths(
    projectConfigInstance.get('ROS_BACKEND_URL'),
    'process-records/',
    processRecordId,
    'variablesMapping',
  );
}

export async function getMappedVariables(
  processRecordId: Uuid,
  accessToken: string,
): Promise<ProcessRecordOptimizationVariable[]> {
  const response = await fetchData(createRecipeOptimizationVariableMappingUrl(processRecordId), {
    method: HttpMethods.Get,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  // TODO (BIOCL-2912): add validation
  return response as ProcessRecordOptimizationVariable[];
}
