import React from 'react';
import { Route } from 'react-router-dom';
import { useAuthContext, isAdministrator, isEppendorfAdministrator } from '@biss/react-auth-web';

import NewInvitation from '../../scenes/new-invitation/new-invitation';
import Protected from '../../../../shared/components/protected/protected';
import Members from '../../scenes/members/members';
import MemberDetails from '../../scenes/member-details/member-details';
import { Title } from '../../../../shared/components/title';
import generateTitle from '../../../../shared/utils/generate-title';

function useMemberRoutes(): React.ReactElement | null {
  const { account } = useAuthContext();
  const section = 'User Management';
  const memberManagementEnabled =
    account === null || isAdministrator(account) || isEppendorfAdministrator(account);
  return memberManagementEnabled ? (
    <Route
      path="members"
      element={
        <Protected>
          <Title text={generateTitle(section, 'Users')}>
            <Members />
          </Title>
        </Protected>
      }
    >
      <Route
        path="new"
        element={
          <Title text={generateTitle(section, 'Invite New User')}>
            <NewInvitation />
          </Title>
        }
      />
      <Route
        path=":id"
        element={
          <Title text={generateTitle(section, 'User Details')}>
            <MemberDetails />
          </Title>
        }
      />
    </Route>
  ) : null;
}

export default useMemberRoutes;
