import React, { useState } from 'react';
import { isEppendorfAdministrator, isEppendorfService, useAuthContext } from '@biss/react-auth-web';
import { ProfilePicture, Button, MenuAlternativeIcon } from '@biss/react-horizon-web';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { LANDING_MESSAGES, PRODUCT_INFO_URL } from '../landing.definitions';
import RouteDefinition from '../../../common/routes/routes.definitions';
import { createInitials } from '../../../components/account-bar/account-bar.helpers';
import useLogin from '../../../common/hooks/use-login';

function LandingHeader() {
  const intl = useIntl();

  const { account } = useAuthContext();
  const login = useLogin();

  const [isHeaderOpen, setIsHeaderOpen] = useState<boolean>(false);

  const navigation = [
    { name: intl.formatMessage(LANDING_MESSAGES.landingHeaderProduct), href: '#product' },
    { name: intl.formatMessage(LANDING_MESSAGES.landingHeaderFeatures), href: '#features' },
    { name: intl.formatMessage(LANDING_MESSAGES.landingHeaderWhereToBuy), href: PRODUCT_INFO_URL },
    { name: intl.formatMessage(LANDING_MESSAGES.landingHeaderAbout), href: '//www.eppendorf.com' },
  ];

  const handleClick = () => {
    setIsHeaderOpen(false);
  };

  return (
    <header className="absolute inset-x-0 top-0 z-20 flex flex-col bg-textGray-200 text-white">
      {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx -- No Translation needed, aria label */}
      <nav className="flex flex-row items-center justify-between p-6 lg:px-8" aria-label="Global">
        <Button
          className="lg:hidden"
          data-testid="MobileNavigationTrigger"
          mood="neutral"
          kind="inverted"
          onClick={() => {
            setIsHeaderOpen((prev) => !prev);
          }}
          leftIcon={<MenuAlternativeIcon />}
        />

        <a href="https://eppendorf.com">
          {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx -- No Translation needed */}
          <span className="sr-only">Eppendorf</span>
          <img
            className="h-8 w-auto"
            src="/assets/images/eppendorf.svg"
            alt={intl.formatMessage({
              defaultMessage: 'Eppendorf Logo',
              description: 'Eppendorf Logo',
              id: 'EY+PFb',
            })}
            width={156}
            height={32}
          />
        </a>

        {/* Navigation for large screens */}
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <a key={item.name} href={item.href} className="leading-6">
              {item.name}
            </a>
          ))}
        </div>

        {account ? (
          <Link
            data-testid="header-avatar"
            to={
              isEppendorfAdministrator(account) || isEppendorfService(account)
                ? `${RouteDefinition.UserManagment}/members`
                : `${RouteDefinition.Analytics}/process-records`
            }
          >
            <ProfilePicture fallbackText={createInitials(account.name ?? account.userName)} />
          </Link>
        ) : (
          <Button
            data-testid="header-login"
            kind="primary"
            mood="highlight"
            onClick={() => login()}
          >
            <FormattedMessage
              defaultMessage="Login"
              description="Public Base: Login Button Title"
              id="wPTWVl"
            />
          </Button>
        )}
      </nav>

      {/* Navigation menu that onfolds on smaller screens */}
      <nav
        aria-hidden="true"
        onClick={handleClick}
        data-testid="MobileNavigation"
        className={classNames(
          'ease flex w-full flex-col overflow-hidden transition-[height,opacity] duration-200 lg:hidden',
          // 'max-h-screen' is used for smooth transitions, as 'h-full' or '100%' don't support them effectively.
          isHeaderOpen ? 'max-h-screen opacity-100' : 'hidden max-h-0 opacity-0',
        )}
      >
        <div className="space-y-2 px-6 pb-6">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-textGray-900 hover:bg-gray-50"
            >
              {item.name}
            </a>
          ))}
        </div>
      </nav>
    </header>
  );
}

export default LandingHeader;
