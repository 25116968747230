import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import { Organization } from '../../types';
import QKey from '../../../../shared/common/hooks/keys';

import deleteOrganization from './use-delete-organization.helpers';

function useDeleteOrganization() {
  const queryClient = useQueryClient();

  const { acquireAccessToken } = useAuthentication();

  return useMutation<void, unknown, string, unknown>({
    mutationFn: async (organizationId) => {
      const accessToken = await acquireAccessToken();
      return deleteOrganization(organizationId, accessToken);
    },
    onMutate: (organizationId) =>
      queryClient.setQueryData<Organization[]>([QKey.ORGANIZATIONS], (prev) =>
        prev?.filter((organization) => organization.organizationId !== organizationId),
      ),
    onSettled: (_, __, organizationId: unknown) => {
      queryClient.invalidateQueries({
        queryKey: [QKey.ORGANIZATIONS, organizationId],
      });

      queryClient.invalidateQueries({
        queryKey: [QKey.ORGANIZATIONS],
      });
    },
  });
}

export default useDeleteOrganization;
