import React from 'react';
import { useAuthContext } from '@biss/react-auth-web';
import { Button } from '@biss/react-horizon-web';
import { FormattedMessage, useIntl } from 'react-intl';

import { Link } from 'react-router-dom';

import RouteDefinition from '../../common/routes/routes.definitions';
import useLogin from '../../common/hooks/use-login';

import {
  DATA_FEATURES,
  LANDING_MESSAGES,
  PRODUCT_INFO,
  PRODUCT_INFO_URL,
} from './landing.definitions';
import LandingHeader from './landing-header/landing-header';

function Landing() {
  const { account } = useAuthContext();
  const login = useLogin();

  const intl = useIntl();

  return (
    <>
      <LandingHeader />
      <main className="isolate bg-gray-50">
        <div className="relative mt-20">
          <div
            className="pointer-events-none absolute inset-x-0 top-40 -z-10 transform-gpu overflow-hidden blur-2xl sm:-top-80"
            aria-hidden="true"
          >
            <img
              src="/assets/images/bionsight_cloud.svg"
              className="relative left-[calc(50%-11rem)] aspect-square w-[70rem] -translate-x-1/2 opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              alt=""
            />
          </div>
          <div className="py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl text-center">
                <h1
                  className="text-4xl font-bold tracking-tight text-textGray-900 sm:text-6xl"
                  data-testid="landingHeader"
                >
                  <FormattedMessage
                    defaultMessage="BioNsight cloud {br} Your data, always available"
                    description="Landing page: title"
                    values={{ br: <br /> }}
                    id="bvMfVq"
                  />
                </h1>
                <p className="mt-6 text-lg leading-8 text-textGray-500 lg:text-center">
                  <FormattedMessage
                    defaultMessage="All your bioprocess data available - anytime, with just one click."
                    description="Landing page: subheading"
                    id="8WTjMW"
                  />
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  {account ? (
                    <Link to={RouteDefinition.Welcome}>
                      <Button kind="primary" mood="highlight" data-testid="landingLoginButton">
                        <FormattedMessage
                          defaultMessage="Get Started"
                          description="Landing page: Get Started Button Title"
                          id="DSL2eI"
                        />
                      </Button>
                    </Link>
                  ) : (
                    <Button
                      kind="primary"
                      mood="highlight"
                      data-testid="landingLoginButton"
                      onClick={login}
                    >
                      <FormattedMessage
                        defaultMessage="Login"
                        description="Landing page: Login Button Title"
                        id="zVj1zy"
                      />
                    </Button>
                  )}
                  <a
                    href="https://eppendorf.group/BioNsight-cloud"
                    className="text-sm font-semibold leading-6 text-textGray-900"
                  >
                    <FormattedMessage
                      defaultMessage="Learn more"
                      description="Landing page: Learn more link"
                      id="/nQlyb"
                    />
                    &nbsp;
                    {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx -- Just an arrow, no translation needed */}
                    <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
              <div className="mt-16 flow-root sm:mt-24">
                <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                  <img
                    src="/assets/images/BioNsight_Screen.png"
                    alt={intl.formatMessage(LANDING_MESSAGES.landingScreenShotAlt)}
                    width="2432"
                    height="1442"
                    className="shadow-1xl rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-2xl sm:top-[calc(100%-30rem)]"
            aria-hidden="true"
          >
            <img
              src="/assets/images/eppendorf.svg"
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
              // eslint-disable-next-line formatjs/no-literal-string-in-jsx -- No Need to Translate Eppendorf Logo
              alt="Eppendorf Logo"
            />
          </div>
        </div>
        <div className="sm:mt-18 mx-auto mt-16 max-w-4xl px-6 lg:px-8">
          <div className="mx-auto max-w-3xl lg:text-justify">
            <p className="mt-2 text-lg text-textGray-900 " id="product">
              {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx -- ® is no emoji.. */}
              {intl.formatMessage(PRODUCT_INFO.landingProductDesc, { tm: <sup>®</sup> })}
            </p>
          </div>
        </div>
        {/* Feature section */}
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8" id="features">
          <div className="mx-auto max-w-2xl">
            <h2 className="text-base font-semibold leading-7 text-blue-600 lg:text-center">
              {intl.formatMessage(PRODUCT_INFO.landingMonitoringTitle)}
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-textGray-900 sm:text-4xl lg:text-center">
              {intl.formatMessage(PRODUCT_INFO.landingMonitoringSubTitle)}
            </p>
            <p className="mt-6 text-lg leading-8 text-textGray-500 lg:text-justify">
              {intl.formatMessage(PRODUCT_INFO.landingMonitoringDesc, { apos: "'" })}
            </p>
          </div>
        </div>
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
          <div className="mx-auto max-w-2xl">
            <h2 className="text-base font-semibold leading-7 text-blue-600 lg:text-center">
              {intl.formatMessage(PRODUCT_INFO.landingIntegrationTitle)}
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-textGray-900 sm:text-4xl lg:text-center">
              {intl.formatMessage(PRODUCT_INFO.landingIntegrationSubTitle)}
            </p>
            <p className="mt-6 text-lg leading-8 text-textGray-500 lg:text-justify">
              {intl.formatMessage(PRODUCT_INFO.landingIntegrationDesc)}
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {DATA_FEATURES.map((feature) => (
                <div key={feature.name} className="relative pl-16">
                  <dt className="text-base font-semibold leading-7 text-textGray-900">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-500">
                      <feature.icon aria-hidden="true" className="h-6 w-6 text-white" />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-2 whitespace-pre-line text-base leading-7 text-textGray-500">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-32 lg:px-8">
          <div className="mx-auto max-w-2xl">
            <h2 className="text-base font-semibold leading-7 text-blue-600 lg:text-center">
              {intl.formatMessage(PRODUCT_INFO.landingBuildTitle)}
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-textGray-900 sm:text-4xl lg:text-center">
              {intl.formatMessage(PRODUCT_INFO.landingBuildSubTitle)}
            </p>
            <p className="mt-6 text-lg leading-8 text-textGray-500 lg:text-justify">
              {intl.formatMessage(PRODUCT_INFO.landingBuildDesc)}
            </p>
          </div>
        </div>
        {/* Get in Touch */}
        <div className="mx-auto my-24 max-w-7xl px-6 sm:mt-24 lg:px-8">
          <div className="mx-auto max-w-2xl">
            <h2 className="text-base font-semibold leading-7 text-blue-600 lg:text-center">
              {intl.formatMessage(PRODUCT_INFO.landingInterestedTitle)}
            </h2>

            <p className="text-3xl font-bold lg:text-center">
              {intl.formatMessage(PRODUCT_INFO.landingInterestedSubTitle)}
            </p>
            <p className="mt-6 text-lg leading-8 text-textGray-500 lg:text-justify">
              {intl.formatMessage(PRODUCT_INFO.landingInterestedDesc, { apos: "'", br: <br /> })}
              &nbsp;
              <a
                href={PRODUCT_INFO_URL}
                target="_blank"
                rel="noreferrer"
                className="text-blue-500"
                // eslint-disable-next-line formatjs/no-literal-string-in-jsx -- That, formatJS, is a URL, good luck translating that
              >
                www.eppendorf.group/BioNsight-cloud
              </a>
            </p>
          </div>
        </div>
      </main>
    </>
  );
}

export default Landing;
