import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';

import { Organization } from '../../types';

import { changeStateOrganization } from './use-change-state-organization.helpers';

function useChangeStateOrganization() {
  const queryClient = useQueryClient();
  const { acquireAccessToken } = useAuthentication();

  return useMutation<
    void,
    unknown,
    { organizationId: string; active: boolean },
    { previousOrganization: Organization; newOrganization: Organization }
  >({
    mutationFn: async ({ organizationId, active }) => {
      const accessToken = await acquireAccessToken();
      return changeStateOrganization(organizationId, active, accessToken);
    },
    // When mutate is called:
    onMutate: async (organization) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [QKey.ORGANIZATIONS, organization.organizationId],
      });

      // Snapshot the previous value
      const previousOrganization = queryClient.getQueryData([
        QKey.ORGANIZATIONS,
        organization.organizationId,
      ]) as Organization;

      const newOrganization = {
        ...previousOrganization,
        ...organization,
      };
      // Optimistically update to the new value
      queryClient.setQueryData([QKey.ORGANIZATIONS, organization.organizationId], newOrganization);

      // Return a context with the previous and new member
      return { previousOrganization, newOrganization };
    },
    onSuccess: (_1, _2, context) => {
      queryClient.invalidateQueries({
        queryKey: [QKey.ORGANIZATIONS],
      });
      queryClient.invalidateQueries({
        queryKey: [QKey.ORGANIZATIONS, context?.newOrganization.organizationId],
      });
    },
  });
}

export default useChangeStateOrganization;
