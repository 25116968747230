import React from 'react';

import { Skeleton } from '@biss/react-horizon-web';

import Tile from '../../../components/tile';
import { many } from '../../../../shared/common/utils';

function ProcessValuesSkeleton() {
  return (
    <div
      className="grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] justify-center gap-2"
      data-testid="process-value-skeleton"
    >
      {many(
        (i) => (
          <Tile color="#B9BDC4" key={`process-value-skeleton-${i}`}>
            <div className="flex w-full flex-col bg-white text-lg">
              <Skeleton variant="text" width="108px" fontSize="lg" />
              <div className="flex items-end justify-between">
                <span>
                  <Skeleton variant="text" width="80px" />
                </span>
                <span className="text-3xl font-bold">
                  <Skeleton variant="text" width="108px" fontSize="3xl" />
                </span>
              </div>
            </div>
          </Tile>
        ),
        3,
      )}
    </div>
  );
}

export default ProcessValuesSkeleton;
