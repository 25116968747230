import React from 'react';
import { NotificationMessage } from '@biss/react-horizon-web';
import { FormattedMessage, useIntl } from 'react-intl';

import { ErrorMessageProps } from './error.message.definitions';

const INTERNALE_SERVER_ERROR = 500;

function ErrorMessage({
  error,
  message = 'An internal error occurred while loading the data.',
  variant,
}: ErrorMessageProps): React.FunctionComponentElement<ErrorMessageProps> | null {
  const intl = useIntl();
  if (
    typeof error === 'object' &&
    'status' in error &&
    (error.status as number) >= INTERNALE_SERVER_ERROR
  ) {
    return (
      <NotificationMessage
        status="error"
        variant={variant}
        title={intl.formatMessage({
          description: 'Internal server error',
          defaultMessage:
            'An internal error in the software system has occurred. Please wait a moment and try again.',
          id: 'WfI+xx',
        })}
      />
    );
  }
  return (
    <NotificationMessage
      status="error"
      variant={variant}
      title={intl.formatMessage(
        {
          description: 'Internal server error',
          defaultMessage: '{error}',
          id: 'cZruSX',
        },
        { error: message },
      )}
    >
      <FormattedMessage
        description="Internal server error"
        defaultMessage="{error}"
        values={{ error: error.message }}
        id="cZruSX"
      />
    </NotificationMessage>
  );
}

export default ErrorMessage;
