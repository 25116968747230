import React, { useEffect, useRef, useState } from 'react';
import { EChartsType, init } from 'echarts';
import { useIntl } from 'react-intl';

import { SPLIT_CHART_TOTAL_HEIGHT } from '../split-chart.config';
import useZoom from '../split-chart-zoom-setup';

import { DataZoomCallbackParam } from '../../chart-zoom/chart-zoom.definitions';
import { getTooltipEchartsConfig } from '../../chart-tooltip';
import { getXAxisEchartsConfig } from '../../chart-x-axis';
import { getDatasetEchartsConfig } from '../../chart-dataset';

import { SplitChartItemProps } from './split-chart-item.definitions';
import {
  getGridEchartsConfig,
  getLegendEchartsConfig,
  getSeriesEchartsConfig,
  getTitleConfig,
} from './split-chart.helpers';

function SplitChartItem({
  data,
  startTime,
  stopTime,
  echartsYAxisConfig,
  yAxisClickCallback,
  timeStampFormat,
  markLines,
  width,
}: SplitChartItemProps) {
  const intl = useIntl();

  const root = useRef<HTMLDivElement>(null);
  const [chart, setChart] = useState<EChartsType>();

  const { onZoomCallback, echartsZoomConfig, echartsResetZoomButtonGraphic } = useZoom(
    timeStampFormat,
    // reset the zoom when the corresponding graphic button is clicked
    () =>
      chart?.setOption(
        {
          dataZoom: echartsZoomConfig.map((zoom) => ({
            ...zoom,
            start: 0,
            end: 100,
          })),
        },
        { lazyUpdate: true },
      ),
  );

  /**
   * Construct a cache key that can be used in hooks that depend on the data prop.
   *
   *  The key should contain of unique features of the data prop and should therefore only
   *    trigger dependent code when the data actually changes.
   *  See https://www.benmvp.com/blog/object-array-dependencies-react-useEffect-hook/
   */

  const dataCacheKey = data
    .map((entry) => [
      entry.id,
      entry.dataPoints.length,
      entry.dataPoints.at(0)?.ts,
      entry.dataPoints.at(0)?.v,
      entry.dataPoints.at(-1)?.ts,
      entry.dataPoints.at(-1)?.v,
    ])
    .join()
    .concat(data.length.toString());

  /**
   * Construct a cache key that can be used in hooks that depend on the data prop.
   */
  const yAxisRangeCacheKey = `${echartsYAxisConfig.name}${echartsYAxisConfig.min}${echartsYAxisConfig.max}`;

  /** Returns all echarts options that are dependent on the data prop. */
  const getDataOptions = () => ({
    legend: getLegendEchartsConfig(data),
    grid: getGridEchartsConfig(),
    tooltip: getTooltipEchartsConfig(timeStampFormat, intl),
    dataset: getDatasetEchartsConfig(data),
    series: getSeriesEchartsConfig(data, markLines),
    dataZoom: echartsZoomConfig,
    yAxis: echartsYAxisConfig,
    graphic: [echartsResetZoomButtonGraphic],
    title: getTitleConfig(data),
  });

  /** Returns all echarts options that are dependent on the startTime and stopTime props. */
  const getStartAndStopTimeOptions = () => ({
    xAxis: getXAxisEchartsConfig({ startTime, stopTime }, timeStampFormat, intl),
  });

  // initialize
  useEffect(() => {
    // this will attempt to initialize the chart twice in strict mode but fail gracefully with a warning
    const initializedChart = init(root.current, 'horizon-web', {
      height: SPLIT_CHART_TOTAL_HEIGHT,
    });

    // set initial chart options
    initializedChart.setOption(
      {
        animation: false,
        ...getStartAndStopTimeOptions(),
        ...getDataOptions(),
      },
      { lazyUpdate: true },
    );
    // add event listeners
    initializedChart.on('datazoom', (e) => {
      onZoomCallback(e as DataZoomCallbackParam);
    });

    initializedChart.on('click', (params: object) => {
      yAxisClickCallback(params);
    });

    setChart(initializedChart);
  }, []);

  // update width of the chart if the screen size changes
  useEffect(() => {
    chart?.resize({ width });
  }, [width]);

  // update settings dependent on the provided data
  useEffect(() => {
    chart?.setOption(
      {
        ...getDataOptions(),
      },
      // When data is selected/deselected all y axis related options need to be overridden.
      //  Otherwise y axes are rendered even though the related data is not shown.
      { lazyUpdate: true, replaceMerge: ['yAxis', 'series', 'dataZoom'] },
    );
  }, [dataCacheKey]);

  // update chart settings dependent on the start and stop timestamps
  useEffect(() => {
    chart?.setOption(
      {
        ...getStartAndStopTimeOptions(),
      },
      { lazyUpdate: true },
    );
  }, [startTime, stopTime, timeStampFormat]);

  // update chart settings dependent on the start and stop timestamps
  useEffect(() => {
    chart?.setOption(
      {
        dataZoom: echartsZoomConfig,
      },
      { lazyUpdate: true },
    );
  }, [echartsZoomConfig]);

  // update the y axis and reset zoom when the y axis configuration changes
  useEffect(() => {
    chart?.setOption(
      {
        yAxis: echartsYAxisConfig,
        // reset y axis zoom if Y axis range is changed
        dataZoom: echartsZoomConfig.map((zoom) => ({
          ...zoom,
          start: 0,
          end: 100,
        })),
      },
      { lazyUpdate: true },
    );
  }, [yAxisRangeCacheKey]);

  return (
    <div
      ref={root}
      style={{
        // has to be set so that certain elements do not need to be offset manually
        height: `${SPLIT_CHART_TOTAL_HEIGHT}px`,
        width: '100%',
      }}
    />
  );
}

export default SplitChartItem;
