import { useEffect, useRef, useState } from 'react';

import { KeyboardState, OBSERVED_KEYS, ObservedKey } from './use-keyboard.definitions';

function useKeyboard() {
  const [keyboard, setKeyboard] = useState<KeyboardState>(
    OBSERVED_KEYS.reduceRight(
      (acc, cur) => ({ ...acc, [cur]: { held: false } }),
      {} as KeyboardState,
    ),
  );
  const keyboardDup = useRef<KeyboardState>(keyboard);

  const handleKeyDown = (e: KeyboardEvent) => {
    const key = e.key as ObservedKey;
    if (!OBSERVED_KEYS.includes(key)) {
      return;
    }

    if (keyboardDup.current[key].held) {
      return;
    }

    keyboardDup.current[key].held = true;
    setKeyboard({ ...keyboardDup.current });
  };

  const handleKeyUp = (e: KeyboardEvent) => {
    const key = e.key as ObservedKey;
    if (!OBSERVED_KEYS.includes(key)) {
      return;
    }

    keyboardDup.current[key].held = false;
    setKeyboard({ ...keyboardDup.current });
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  return keyboard;
}

export default useKeyboard;
