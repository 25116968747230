import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import TrackedEvent from '../../common/tracked-event';

import {
  APP_INSIGHTS_SESSION_COOKIE_KEY,
  APP_INSIGHTS_USER_COOKIE_KEY,
} from './insights.definitions';

// eslint-disable-next-line import/prefer-default-export -- helper file //NOSONAR
export const manageAppInsightsCookiesEffect = (
  appInsights: ApplicationInsights,
  isCookieEnabled: boolean,
) => {
  const cookieManager = appInsights.getCookieMgr();
  cookieManager.setEnabled(isCookieEnabled);

  // remove cookies if set to disabled
  if (isCookieEnabled === false) {
    cookieManager.purge(APP_INSIGHTS_SESSION_COOKIE_KEY);
    cookieManager.purge(APP_INSIGHTS_USER_COOKIE_KEY);
    appInsights.trackEvent({ name: TrackedEvent.RevokeConsentToAppInsightCookie });
  }

  // update user and session context when enabled
  if (isCookieEnabled === true) {
    appInsights.context.user.update();
    appInsights.context.sessionManager.update();
    appInsights.trackEvent({ name: TrackedEvent.ConsentToAppInsightCookie });
  }
};
