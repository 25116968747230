import { Tile } from '@biss/react-horizon-web';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Footer from '../../../shared/components/footer';

function DatatrackFormat() {
  return (
    <>
      <Tile className="m-4">
        <Tile.Header>
          <Tile.Title title="Uploading External Datatracks" />
        </Tile.Header>
        <Tile.Body>
          <article className="prose max-w-none">
            <FormattedMessage
              description="Data track Format Description"
              defaultMessage="You can add additional data tracks to an existing process record by uploading a {csvFileWikiLink}. This file must contain a column with timestamps and one or more columns with the respective values."
              id="/Kirvb"
              values={{
                csvFileWikiLink: (
                  <a
                    href="https://wikipedia.org/wiki/Comma-separated_values"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FormattedMessage
                      description="Wikipedia link to the CSV page"
                      defaultMessage="CSV file"
                      id="MQqGDt"
                    />
                  </a>
                ),
              }}
            />
            <ul>
              <li>
                <FormattedMessage
                  description="Datatrack Format Rule One"
                  defaultMessage="A CSV-File with semicolon “;” as separator."
                  id="koil28"
                />
                <ul>
                  <li>
                    <FormattedMessage
                      description="Datatrack Format Rule One A"
                      defaultMessage="The file size limit is 30 MB."
                      id="/j1x6J"
                    />
                  </li>
                </ul>
              </li>
              <li>
                <FormattedMessage
                  description="Datatrack Format Rule Two"
                  defaultMessage="The file must have a header-row with the respective data track names. The first column containing the timestamps must have the title “Timestamp”."
                  id="aRtzfq"
                />
              </li>
              <li>
                <FormattedMessage
                  description="Datatrack Format Rule Three"
                  defaultMessage='The column titles in the header row should be enclosed in double quotation marks, for example "Timestamp" or "Co2OffGasAnalyzer".'
                  id="v6YK0H"
                />
              </li>
              <li>
                <FormattedMessage
                  description="Datatrack Format Rule Four"
                  defaultMessage='The decimal separator to be used is a period "."'
                  id="UHk5X5"
                />
              </li>
              <li>
                <FormattedMessage
                  description="Datatrack Format Rule Five"
                  defaultMessage="Timestamps need to be in the {iso8601WikiLink} format (e.g., 2023-09-30T14:45:00Z) and contain the timezone in which the data was recorded in. In the following some examples are shown:"
                  id="zrx0p0"
                  values={{
                    iso8601WikiLink: (
                      <a
                        href="https://wikipedia.org/wiki/ISO_8601"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FormattedMessage
                          description="Wikipedia link to the ISO 8601 page"
                          defaultMessage="ISO 8601"
                          id="5uMzBE"
                        />
                      </a>
                    ),
                  }}
                />
                <ul>
                  <li>
                    <FormattedMessage
                      description="Datatrack Format Rules: Example timestamp in UTC"
                      defaultMessage="2023-09-30T14:45:00Z is in the UTC timezone."
                      id="B04+O3"
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      description="Datatrack Format Rules: Example timestamp in UTC+2"
                      defaultMessage="2023-09-30T14:45:00+02 is in the UTC+2 timezone (e.g. Berlin, Germany)."
                      id="7YQuUO"
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      description="Datatrack Format Rules: Example timestamp in UTC+2"
                      defaultMessage="2023-09-30T14:45:00-07 is in the UTC-7 timezone (e.g. Los Angeles, USA)."
                      id="DpTGOK"
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <FormattedMessage
              description="Datatrack Format Timestamps"
              defaultMessage="Note, that it is not required that the timestamps of the added datatracks lie within the experiment's runtime, but it is recommended."
              id="L7N7jP"
            />
          </article>
        </Tile.Body>
      </Tile>
      {/* TODO(BIOCL-2732): Move to a central place when the page layout is done */}
      <Footer />
    </>
  );
}

export default DatatrackFormat;
