const seekMember = (query: string, memberDisplayName: string, memberEmail: string) => {
  const queryLower = query.toLocaleLowerCase();

  if (memberDisplayName.toLowerCase().includes(queryLower)) {
    return true;
  }

  if (memberEmail.toLowerCase().includes(queryLower)) {
    return true;
  }

  return false;
};

export default seekMember;
