import { ILogger } from '../../../globals/logging';
import { ApiError } from '../../types/api-error/api-error';
import { isApiErrorDTO } from '../../types/api-error/dto/validators';
import { isSuccessStatus } from '../../types/http';

import {
  ProgressCallback,
  UploadHeaders,
  UploadWithProgressResponse,
  ReadyState,
  CONTENT_TYPE_HEADER,
  ProgressState,
  NETWORK_ERROR_MESSAGE,
} from './use-upload.definitions';

/**
 * @throws { ApiError }
 */
// eslint-disable-next-line import/prefer-default-export -- helper file
export function uploadWithProgress(
  url: string,
  data: XMLHttpRequestBodyInit,
  progressCallback: ProgressCallback,
  logger: ILogger,
  accessToken?: string,
  headers?: UploadHeaders,
): Promise<UploadWithProgressResponse> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    const onprogress = (e: ProgressEvent, uploading = true) => {
      const update: ProgressState = {
        loaded: e.loaded,
        uploading,
      };

      if (e.lengthComputable) {
        update.total = e.total;
        update.progress = e.loaded / e.total;
      }

      progressCallback(update);
    };

    const onload = (/* e: ProgressEvent */) => {
      if (xhr.readyState !== ReadyState.DONE) {
        return;
      }

      progressCallback({
        progress: 1,
        uploading: false,
      });

      const response = new Response(xhr.response);

      if (isSuccessStatus(xhr.status)) {
        resolve({
          status: xhr.status,
          statusText: xhr.statusText,
          response,
        });

        return;
      }

      // error
      response
        .json()
        .then((json) => {
          isApiErrorDTO(
            json,
          ); /** assume all json error messages to be of type {@link ApiErrorDTO} */

          reject(new ApiError(json, response.status));
        })
        .catch(() => {
          reject(
            new Error(
              `XHR request responed with an unexpected error,
                  status: ${xhr.status}
                  type ${xhr.responseType}`,
            ),
          );
        });
    };

    const onerror = () => {
      progressCallback({
        progress: 0,
        uploading: false,
      });

      // log error and diagnostics data to app insights
      logger.error(
        `${NETWORK_ERROR_MESSAGE} - ${JSON.stringify({
          status: xhr.status,
          statusText: xhr.statusText,
          responseType: xhr.responseType,
          responseText: xhr.responseText,
        })}`,
      );

      reject(new Error(NETWORK_ERROR_MESSAGE));
    };

    xhr.onerror = onerror;
    xhr.onload = onload;
    xhr.upload.onprogress = onprogress;

    xhr.open('POST', url, true);
    xhr.setRequestHeader('Accept', 'application/json');

    if (headers) {
      xhr.setRequestHeader(CONTENT_TYPE_HEADER, headers[CONTENT_TYPE_HEADER]);
    }

    if (accessToken) {
      xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
    }

    xhr.send(data);
  });
}
