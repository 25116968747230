import { UNIQUE_KEY_GENERATOR_RADIX } from './unique-key-generator.definitions';

/**
 * generator function
 * @returns a function that generates a key that is always different from the last generated key
 */
function uniqueKeyGenerator() {
  let pastKey: string;

  const createKey = () => {
    let maybeUniqueKey = Math.random().toString(UNIQUE_KEY_GENERATOR_RADIX);

    if (maybeUniqueKey === pastKey) {
      maybeUniqueKey = createKey();
    }

    pastKey = maybeUniqueKey;
    return maybeUniqueKey;
  };

  return createKey;
}

export default uniqueKeyGenerator;
