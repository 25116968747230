import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, DeleteIcon, Select } from '@biss/react-horizon-web';

import { VARIABLE_RENAME_MAP } from '../recipe-optimization-transfer.definitions';
import Pill from '../../../../shared/components/pill';

import {
  decodeVariableIdentifier,
  encodeVariableIdentifier,
  formatVariable,
  isSameVariable,
} from '../recipe-optimization-transfer.helpers';

import { AttributeMappingProps, AttributeVariableMapDiff } from './attribute-mapping.definitions';

function AttributeMapping({
  attributes,
  mappings: currentAttributeMappings,
  onUpdate,
  allowDeletion = false,
  onDelete,
  disabled = false,
  defaultOpen = false,
}: Readonly<AttributeMappingProps>) {
  /**
   * when an attribute is selected
   * check if the variable was mapped before
   * if so update the mapping, otherwise add the variable and the attribute key to the mappings and call the endpoint
   * @param variableIdentifier - variable identifier
   * @param attributeName - attribute key
   */
  const handleAttributeSelect = (variableIdentifier: string, attributeName: string) => {
    const { variableCode, variableGroupCode } = decodeVariableIdentifier(variableIdentifier);

    // attempt to get previous mapping
    const previousMapIndex = currentAttributeMappings.findIndex((attr) =>
      isSameVariable(attr, { variableCode, variableGroupCode }),
    );

    const newMapping: AttributeVariableMapDiff = {
      attributeName,
      variableCode,
      variableGroupCode,
    };

    const newMappings: AttributeVariableMapDiff[] = [...currentAttributeMappings];
    if (previousMapIndex >= 0) {
      // update previous mapping
      newMappings[previousMapIndex] = newMapping;
    } else {
      // add new mapping
      newMappings.push(newMapping);
    }

    onUpdate(newMappings);
  };

  const handleDelete = (variableIdentifier: string) => {
    const { variableCode, variableGroupCode } = decodeVariableIdentifier(variableIdentifier);

    onDelete?.(variableCode, variableGroupCode);
  };

  return currentAttributeMappings.map(({ variableCode, variableGroupCode, attributeName }) => {
    const variableIdentifier = encodeVariableIdentifier(variableCode, variableGroupCode);

    // if there is a mapping and the mapping references an existing attribute
    const currentValue =
      attributeName && Object.keys(attributes).includes(attributeName) ? attributeName : undefined;

    // hoist the attributes that contain the mappable key up in the list
    const attrs = Object.entries(attributes);
    attrs.sort(([key]) => (key.toLowerCase().includes(variableCode.toLowerCase()) ? -1 : 1));

    return (
      <div className="flex flex-col gap-2" key={variableIdentifier}>
        <span>
          <FormattedMessage
            defaultMessage="{label}"
            description="Mappable Attribute Key"
            id="F8tn8u"
            values={{
              label: formatVariable(
                VARIABLE_RENAME_MAP.get(variableCode) ?? variableCode,
                variableGroupCode,
              ),
            }}
          />
        </span>

        <div className="flex flex-row gap-2">
          <div className="flex-auto">
            <Select
              expand="auto"
              disabled={disabled}
              placeholder="Select an Attribute"
              onValueChange={(key) => handleAttributeSelect(variableIdentifier, key)}
              value={currentValue}
              data-testid="select-attribute-mapping"
              defaultOpen={defaultOpen}
            >
              {attrs.map(([key, value]) => (
                <Select.Item key={key} value={key}>
                  <Pill
                    name={key}
                    value={value}
                    disabled={disabled}
                    isEditable={false}
                    isRemovable={false}
                    isSmall
                    nameInputLabel="attribute-name"
                    valueInputLabel="attribute-value"
                  />
                </Select.Item>
              ))}
            </Select>
          </div>

          {allowDeletion && (
            <Button
              data-testid="remove-attribute-mapping"
              mood="destructive"
              leftIcon={<DeleteIcon />}
              onClick={() => handleDelete(variableIdentifier)}
            />
          )}
        </div>
      </div>
    );
  });
}

export default AttributeMapping;
