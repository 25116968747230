import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import useClient from '../../../../shared/common/hooks/use-client';
import { ApiError } from '../../../../shared/common/types/api-error/api-error';
import {
  ControlProcedureId,
  SetupId,
  StructuredSetup,
  SystemId,
} from '../../../../shared/common/types/setup';

function useSubscribeToDwcEvents(
  {
    systemId,
    setupId,
  }: {
    systemId: SystemId;
    setupId: SetupId;
  },
  { onSuccess, ...options }: UseMutationOptions<unknown, ApiError, unknown, unknown> = {},
) {
  const { pmsClient } = useClient();
  const queryClient = useQueryClient();
  const sourceQuerySetupsKey = [QKey.SETUPS, setupId];
  const { acquireAccessToken } = useAuthentication();

  return useMutation({
    ...options,
    mutationKey: [QKey.EMAIL, QKey.DWC_EVENTS, setupId],
    mutationFn: async ({
      enableSubscription,
      controlProcedureId,
    }: {
      enableSubscription: boolean;
      controlProcedureId: ControlProcedureId;
    }) => {
      pmsClient.authorize(await acquireAccessToken());
      return pmsClient.updateEmailNotificationsForDwcEvents({
        systemId,
        setupId,
        controlProcedureId,
        enableSubscription,
      });
    },
    onMutate: ({ enableSubscription }) => {
      const previousSetupData = queryClient.getQueryData<StructuredSetup>(sourceQuerySetupsKey);

      if (!previousSetupData) {
        throw new Error(
          `Expected query with key ${JSON.stringify(sourceQuerySetupsKey)} to exist.`,
        );
      }

      queryClient.setQueryData<StructuredSetup>(sourceQuerySetupsKey, {
        ...previousSetupData,
        isSubscribedToDwcEvents: enableSubscription,
      });

      return previousSetupData;
    },
    onError: (_, __, previous) => {
      queryClient.setQueryData(sourceQuerySetupsKey, previous);
    },
    onSuccess: (data, variables, context) => {
      onSuccess?.(data, variables, context);
    },
  });
}

export default useSubscribeToDwcEvents;
