import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import { StructuredProcessRecordDescriptor } from '../../types/process-record';
import QKey from '../keys';
import useClient from '../use-client';
import { transformProcessRecordDescriptor } from '../../types/process-record/transformers/process-record-dto-transformer';

function useProcessRecordList(): UseQueryResult<StructuredProcessRecordDescriptor[], Error> {
  const { acquireAccessToken } = useAuthentication();
  const { prsClient } = useClient();

  return useQuery({
    queryKey: [QKey.PROCESS_RECORDS],
    queryFn: async () => {
      // fetch
      prsClient.authorize(await acquireAccessToken());
      const result = await prsClient.getProcessRecords();

      // transform
      return result.map(transformProcessRecordDescriptor);
    },
  });
}

export default useProcessRecordList;
