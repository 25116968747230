import { WorkflowInfo, WorkflowSetupInfo } from './setup-navigation.definitions';

function getNextPreviousSetups(setupId: string, workflowInfo?: WorkflowInfo) {
  let previousSetup: WorkflowSetupInfo | undefined;
  let nextSetup: WorkflowSetupInfo | undefined;

  if (workflowInfo) {
    const unitIndex = workflowInfo?.setups.findIndex((unit) => unit.setupId === setupId);
    previousSetup = workflowInfo?.setups[unitIndex - 1];
    nextSetup = workflowInfo?.setups[unitIndex + 1];
  }
  return { previousSetup, nextSetup };
}

export default getNextPreviousSetups;
