/**
 * This module was automatically generated by `ts-interface-builder`
 */
import { iface, ITypeSuite, opt, name, array } from 'ts-interface-checker';

export const DataPointObject = iface([], {
  ts: 'number',
  v: 'number',
});

export const DataTrackId = name('string');
export const DataTrackType = name('string');

export const DataTrackDescriptor = iface([], {
  dataTrackId: 'DataTrackId',
  dataTrackType: 'DataTrackType',
  displayName: 'string',
  engineeringUnit: 'string',
  fractionalDigits: opt('number'),
  isCustom: opt('boolean'),
});

export const DataTrack = iface(['DataTrackDescriptor'], {
  dataPoints: array('DataPointObject'),
});

const Uuid = name('string');
const Timestamp = name('string');

const exportedTypeSuite: ITypeSuite = {
  DataPointObject,
  DataTrackId,
  DataTrackType,
  DataTrackDescriptor,
  DataTrack,
  Uuid,
  Timestamp,
};

export default exportedTypeSuite;
