import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import {
  DataTrackId,
  ProcessRecordId,
  StructuredProcessRecordObject,
} from '../../types/process-record';
import QKey from '../keys';
import useClient from '../use-client';
import { PrsException } from '../../types/generated/prs.generated';

function useDeleteCustomDataTrack(processRecordId: ProcessRecordId) {
  /** query key for the process record to which this data track belongs and is stored */
  const sourceQueryKey = [QKey.PROCESS_RECORDS, processRecordId];

  const queryClient = useQueryClient();
  const { prsClient } = useClient();
  const { acquireAccessToken } = useAuthentication();

  return useMutation<void, PrsException, string, StructuredProcessRecordObject>({
    mutationFn: async (dataTrackId: DataTrackId) => {
      prsClient.authorize(await acquireAccessToken());
      return prsClient.deleteDataTrackById(processRecordId, dataTrackId);
    },
    onMutate: (dataTrackId: DataTrackId) => {
      const previousProcessRecordData =
        queryClient.getQueryData<StructuredProcessRecordObject>(sourceQueryKey);

      if (!previousProcessRecordData) {
        throw new Error(`Expected query with key ${JSON.stringify(sourceQueryKey)} to exist.`);
      }

      queryClient.setQueryData<StructuredProcessRecordObject>(sourceQueryKey, {
        ...previousProcessRecordData,
        dataTracks: previousProcessRecordData.dataTracks.filter(
          (dt) => dt.dataTrackId !== dataTrackId,
        ),
      });

      return previousProcessRecordData;
    },
    onError: (_, __, previous) => {
      queryClient.setQueryData(sourceQueryKey, previous);
    },
  });
}

export default useDeleteCustomDataTrack;
