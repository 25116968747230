import { GenericError } from './generic-error';

export type ClientErrorObject = {
  status: number;
  message: string;
};

export class ClientError<
  T extends { message: string; status: number } = ClientErrorObject,
> extends GenericError<T> {
  status!: number;

  constructor(cause: T) {
    super(cause);
    this.status = cause.status;
  }
}
