import React, { FunctionComponentElement } from 'react';

import { TileProps } from './tile.definitions';

function Tile({
  children,
  testId,
  color,
  variant = 'default',
}: TileProps): FunctionComponentElement<TileProps> {
  return (
    <div data-testid={testId} className="flex min-h-[96px] min-w-[300px]">
      <div
        data-testid={`${testId}-border`}
        className="w-2.5 rounded-bl rounded-tl"
        style={{
          backgroundColor: color,
        }}
      />
      <div
        className={`flex w-full justify-between rounded-ee rounded-se border-y border-r ${
          variant === 'error' ? 'border-danger' : 'border-light'
        } bg-white p-4`}
      >
        {children}
      </div>
    </div>
  );
}

export default Tile;
