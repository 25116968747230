import React, { FunctionComponentElement } from 'react';
import { Skeleton, Tile } from '@biss/react-horizon-web';

import { TileWithSkeletonProps } from './tile-with-skeleton.definitions';

function TileWithSkeleton({
  children,
  'data-testid': testId,
  isLoading,
  className,
}: TileWithSkeletonProps): FunctionComponentElement<TileWithSkeletonProps> {
  return (
    <Tile data-testid={testId} className={className}>
      {isLoading ? (
        <Skeleton variant="rectangular" data-testid={`${testId}-skeleton`} height="100%" />
      ) : (
        children
      )}
    </Tile>
  );
}

export default TileWithSkeleton;
