import { ColumnDef } from '@tanstack/react-table';

export type TValue = string | number | unknown;
export type EditableTableData = Record<string, TValue>;

/**
 * Sticky column configuration
 * @property left - array of column accessorKey to stick to the left
 * @property right - array of column accessorKey to stick to the right
 */
export type StickyColumn = {
  left?: string[];
  right?: string[];
};

export interface EditableTableProps<T extends object> {
  /** table data */
  data: T[];

  /** table columns */
  columns: ColumnDef<T>[];

  showDeleteButton?: boolean;

  /** table error message */
  errorMessage?: React.ReactNode;

  /** array of disabled rows */
  disabledRows?: number[];

  /** calls when editable cell is updated */
  updateTableData: (rowIndex: number, columnId: string, value: TValue) => void;

  /** renders Add Row button and calls when the button is clicked */
  addRow?: () => void;

  /** renders Delete Row button and calls when the button is clicked */
  deleteRow?: () => void;

  /** Remove the user selected row in the table */
  deleteSelectedRow?: (rowId: number) => void;

  /** sticky columns configuration */
  stickyColumns?: StickyColumn;
}

export const CELL_ERROR_STYLES = 'border-2 border-warning';
