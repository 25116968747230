import React, { FunctionComponentElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';
import { Button } from '@biss/react-horizon-web';

import SearchInput from '../../search-input';

import { DataTrackListHeaderProps } from './data-track-list-header.definitions';

function DataTrackListHeader({
  searchParam,
  disabled,
  isDeselectAllDisabled,
  actions,
  showTitle = true,
  notification,
  onSearch,
  onDeselectAll,
  onReset,
}: DataTrackListHeaderProps): FunctionComponentElement<DataTrackListHeaderProps> {
  const intl = useIntl();
  const showDeselectAllButton = Boolean(onDeselectAll);

  return (
    <div data-testid="DataTrackList-header">
      {showTitle && (
        <div className="flex h-10 items-center justify-between border-b border-gray pl-4">
          <FormattedMessage
            description="data track list header: Data Track Selection title"
            defaultMessage="Data Track Selection"
            id="4gImDx"
          />
        </div>
      )}
      <div>{notification}</div>
      <div className="flex gap-2">
        <SearchInput
          placeholder={intl.formatMessage({
            defaultMessage: 'Type to search...',
            description: 'Data track list header: search placeholder',
            id: 'wVFBVi',
          })}
          onDebouncedChange={onSearch}
          initialValue={searchParam}
          disabled={disabled}
          expand="auto"
          data-testid="data-track-list-search"
          // setting fixed with as otherwise the input field grows too large on firefox
          className={cn({
            'w-[calc(var(--dual-column-aside-width)-96px)]': actions,
            'w-full': !actions,
          })}
        />
        {actions}
      </div>
      <div className={`${showDeselectAllButton ? 'block' : 'md:flex md:gap-1'}`}>
        <div
          className={`grid gap-2 ${
            showDeselectAllButton ? 'grid-cols-2 justify-between' : 'auto-cols-1 justify-end'
          } py-1 md:grow`}
        >
          {showDeselectAllButton && (
            <Button
              kind="ghost"
              onClick={onDeselectAll}
              disabled={disabled || isDeselectAllDisabled}
              data-testid="data-track-list-deselect-button"
            >
              <FormattedMessage
                description="data track list header: Button to deselect all elements: label"
                defaultMessage="Deselect All"
                id="bpKrv9"
              />
            </Button>
          )}
          <Button
            kind="ghost"
            onClick={onReset}
            disabled={disabled}
            data-testid="data-track-list-reset-button"
          >
            <FormattedMessage
              description="data track list header: Button to reset to default selection: label"
              defaultMessage="Reset"
              id="HQqFoS"
            />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default DataTrackListHeader;
