import { InputProps } from '@biss/react-horizon-web';

export const DEFAULT_DEBOUNCE_THRESHOLD = 324;

export type DebouncedInputProps = {
  initialValue?: string;
  onDebouncedChange?: (val: string) => void;
  debounceThreshold?: number;
  'data-testid'?: string;
} & Omit<InputProps, 'onChange' | 'value'>;

export type DebouncedInputRef = { setValue: (value: string) => void };
