import { createCheckers } from 'ts-interface-checker';

import { ApiErrorObjectDTO } from '../api-error-dto';

import ApiErrorTI from './api-error-dto-validator-types';

// eslint-disable-next-line import/prefer-default-export -- consistency
export function isApiErrorDTO(object: unknown): asserts object is ApiErrorObjectDTO {
  const { ApiErrorObject } = createCheckers(ApiErrorTI);

  ApiErrorObject.check(object);
}
