export type PillProps = {
  /** the name of this pill. */
  name?: string;

  /** the value of this pill. */
  value?: string;

  /** signifies whether the pill is in an error state. */
  isError?: boolean;

  /** signifies whether the pill is disabled */
  disabled?: boolean;

  /**
   * signifies whether the pill is in a warning state.
   * is overridden by `isError`.
   */
  isWarning?: boolean;

  /**
   * signifies that the pill is in edit mode.
   * renders the confirm and cancel buttons, and shows the value input if it was hidden.
   */
  isBeingEdited?: boolean;

  /**
   * signifies that the pill is in edit mode.
   * renders the confirm and cancel buttons, and shows the value input if it was hidden.
   * keeps the inputs as readonly
   */
  isBeingRemoved?: boolean;

  /**
   * specifies whether the pill component can be edited.
   * specifying either `name` or `value` will make only one of those fields editable
   * will not render the edit button if `false`.
   */
  isEditable?: PillIsEditable;

  /**
   * specifies whether the pill can be removed, does not render the delete icon if `false`
   */
  isRemovable?: boolean;

  /**
   * determines whether the pill should be rendered small.
   */
  isSmall?: boolean;

  nameInputLabel?: string;
  onNameChange?: (name: string) => void;

  valueInputLabel?: string;
  onValueChange?: (value: string) => void;

  /**
   * specify which input to focus on when entering edit mode.
   * when `undefined` will not focus on any elements.
   */
  focusOnEdit?: 'name' | 'value';

  isEditDisabled?: boolean;
  isDeleteDisabled?: boolean;
  isConfirmDisabled?: boolean;
  isCancelDisabled?: boolean;

  onEditClick?: (name: string, value: string) => void;
  onDeleteClick?: (name: string, value: string) => void;
  onConfirmClick?: (name: string, value: string) => void;
  onCancelClick?: (name: string, value: string) => void;
};

export type PillMood = 'error' | 'warning' | 'normal';
export type PillIsEditable = boolean | 'name' | 'value';

export const MIN_PILL_INPUT_WIDTH_EMPTY = '6ch';
export const MIN_PILL_INPUT_WIDTH = '1ch';
export const SEPARATOR = ':';
