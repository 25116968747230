import React, { KeyboardEvent, useEffect } from 'react';
import { Input } from '@biss/react-horizon-web';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useIntl } from 'react-intl';

import { YAxisRangeInputsProps } from './y-axis-range-inputs.definitions';
import { YAxisRange, yAxisRangeSchema } from './y-axis-range-inputs.validation';

function YAxisRangeInputs({
  labelPrefix,
  labelSuffix,
  range,
  onRangeChange,
  updateValidRanges,
  setIsDirty,
  isReverse = false,
  className,
  style,
}: YAxisRangeInputsProps) {
  type Inputs = YAxisRange;
  const intl = useIntl();
  const {
    control,
    formState: { errors, isDirty, isValid },
    handleSubmit,
  } = useForm<Inputs>({
    values: range,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    resolver: zodResolver(yAxisRangeSchema),
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    onRangeChange?.(data, labelPrefix);
  };

  const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && e.code === 'Enter') {
      e.currentTarget.blur();
    }
  };

  // update validation state of the series y-axis range
  useEffect(() => {
    updateValidRanges?.(labelPrefix, isValid);
  }, [isValid]);

  // set form is dirty if a value has been changed
  useEffect(() => {
    if (isDirty && setIsDirty) {
      setIsDirty(isDirty);
    }
  }, [isDirty]);

  const minControl = (
    <Controller
      control={control}
      name="min"
      render={({ field }) => (
        <Input
          // eslint-disable-next-line react/jsx-props-no-spreading -- component controlled by <Controller />
          {...field}
          type="number"
          expand="auto"
          data-testid="y-axis-min-input"
          error={errors.min?.message}
          value={field.value ?? ''}
          label={intl.formatMessage(
            {
              defaultMessage: '{labelPrefix} Min {labelSuffix}',
              description: 'Overview configuration Y Axis Range: Min value',
              id: 'oatWKq',
            },
            { labelPrefix, labelSuffix },
          )}
          onKeyDown={handleOnKeyDown}
          // fix issue with input value not being cleared
        />
      )}
    />
  );

  const maxControl = (
    <Controller
      control={control}
      name="max"
      render={({ field }) => (
        <Input
          // eslint-disable-next-line react/jsx-props-no-spreading -- component controlled by <Controller />
          {...field}
          type="number"
          expand="auto"
          data-testid="y-axis-max-input"
          error={errors.max?.message}
          value={field.value ?? ''}
          label={intl.formatMessage(
            {
              defaultMessage: '{seriesName} Max {engineeringUnit}',
              description: 'Overview configuration Y Axis Range: Min value',
              id: 'n5Aqf+',
            },
            { seriesName: labelPrefix, engineeringUnit: labelSuffix },
          )}
          onKeyDown={handleOnKeyDown}
          // fix issue with input value not being cleared
        />
      )}
    />
  );

  return (
    <form
      style={style}
      className={className}
      onBlur={handleSubmit(onSubmit)}
      key={JSON.stringify(range)}
    >
      {isReverse ? (
        <>
          {maxControl}
          {minControl}
        </>
      ) : (
        <>
          {minControl}
          {maxControl}
        </>
      )}
    </form>
  );
}

export default YAxisRangeInputs;
