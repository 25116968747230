import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import { ApiError } from '../../types/api-error/api-error';
import useLogger from '../use-logger/use-logger';

import {
  UploadMutationInput,
  UploadWithProgressResponse,
  UseUploadInput,
} from './use-upload.definitions';
import mutationFnFactory from './use-upload-mutation';

function useUpload({ key, url, headers: uploadHeaders, onSuccess }: UseUploadInput) {
  const queryClient = useQueryClient();
  const { acquireAccessToken } = useAuthentication();
  const logger = useLogger();

  return useMutation<UploadWithProgressResponse, ApiError, UploadMutationInput, void>({
    mutationKey: key,
    mutationFn: mutationFnFactory(acquireAccessToken, url, logger, uploadHeaders),
    onSuccess: (data, vars) => {
      queryClient.invalidateQueries({ queryKey: key });
      onSuccess?.(data, vars);
    },
  });
}

export default useUpload;
