import React, { ChangeEvent, useState } from 'react';
import { Input, Modal, NotificationMessage } from '@biss/react-horizon-web';
import { FormattedMessage, useIntl } from 'react-intl';

import useCreateProcessRecord from '../../../common/hooks/use-create-process-record';

import DateTimeInput from '../../../../shared/components/date-time-input/date-time-input';

import {
  CREATE_PROCESS_RECORD_FIELD_NAMES,
  CreteProcessRecordViaFormProps,
  ProcessRecordCreationDto,
} from './create-process-record-via-form.definitions';
import getInitialProcessRecordDto, {
  validateProcessRecordCreationDto,
} from './create-process-record-via-form.helpers';

function CreateProcessRecordViaForm({
  onChange,
  onSubmit,
  defaultDirtyFields = [],
}: CreteProcessRecordViaFormProps) {
  const intl = useIntl();
  const {
    mutate: createProcessRecord,
    error,
    isError,
    isSuccess,
    isPending,
  } = useCreateProcessRecord();

  const [processRecordCreateDto, setProcessRecordCreateDto] = useState<ProcessRecordCreationDto>(
    getInitialProcessRecordDto(),
  );

  const [dirtyFields, setDirtyFields] = useState<string[]>(defaultDirtyFields);

  const validation = validateProcessRecordCreationDto(processRecordCreateDto);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    onChange?.(name, value);
    setDirtyFields((fields) => [...fields, name]);
    setProcessRecordCreateDto((values) => ({ ...values, [name]: value }));
  };

  const handleCommentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget;
    onChange?.(name, value);
    setProcessRecordCreateDto((values) => ({ ...values, [name]: value }));
    setDirtyFields((fields) => [...fields, name]);
  };

  const handleDateChange = (name: string, date: Date | null) => {
    onChange?.(name, date?.toString());
    setDirtyFields((fields) => [...fields, name]);
    setProcessRecordCreateDto((values) => ({ ...values, [name]: date ?? undefined }));
  };

  const handleSubmit = () => {
    // show validation messages for all fields
    setDirtyFields(CREATE_PROCESS_RECORD_FIELD_NAMES);

    if (validation.success) {
      createProcessRecord(processRecordCreateDto, {
        onSuccess() {
          // notify the parent of submission
          onSubmit?.();
          // reset field dirty flags
          setDirtyFields([]);
          // clear form fields
          setProcessRecordCreateDto(getInitialProcessRecordDto());
        },
      });
    }
  };

  /**
   * get the error message for a field if an error exists and the field is dirty
   */
  const getErrorIfExisting = (fieldName: keyof ProcessRecordCreationDto) => {
    // if the field is not dirty
    if (dirtyFields.includes(fieldName) === false) {
      return undefined;
    }

    if (validation.success) {
      return undefined;
    }

    // eslint-disable-next-line no-underscore-dangle -- this is zod error formatting
    return validation.error.format()[fieldName]?._errors[0];
  };

  return (
    <>
      <Modal.Content>
        <div className="flex flex-grow flex-col gap-4">
          {isSuccess && (
            <NotificationMessage status="information">
              <FormattedMessage
                defaultMessage="Successfully created a new process record."
                description="Process Record Creation: Successfully created a process record message"
                id="mmnXFM"
              />
            </NotificationMessage>
          )}

          {isError && (
            <NotificationMessage
              title={intl.formatMessage({
                defaultMessage: 'Error Creating the Process Record',
                description: 'Process Record Creation: Error while creation',
                id: 'QnIWtf',
              })}
              status="error"
            >
              <FormattedMessage
                defaultMessage="{message}"
                description="Process Record Creation: Error"
                id="zRXJyX"
                values={{ message: error.message }}
              />
            </NotificationMessage>
          )}

          <div className="flex flex-col gap-2">
            <Input
              name="displayName"
              expand="auto"
              disabled={isPending}
              placeholder={intl.formatMessage({
                defaultMessage: 'e.g. Control Procedure 12',
                description: 'Process Record Creation: Placeholder for display name input',
                id: 'OImedj',
              })}
              value={processRecordCreateDto.displayName}
              error={getErrorIfExisting('displayName')}
              onChange={handleInputChange}
              type="text"
              label={intl.formatMessage({
                defaultMessage: 'Name*',
                id: 'g6kYoF',
                description: 'Process Record Creation: Display Name Label',
              })}
            />
            <div className="grid grid-cols-2 gap-4">
              <Input
                id="deviceName"
                name="deviceName"
                expand="auto"
                disabled={isPending}
                placeholder={intl.formatMessage({
                  defaultMessage: 'e.g. DASGIP Plant DASbox-8',
                  description: 'Process Record Creation: Placeholder for device name input',
                  id: 'v7kOyB',
                })}
                value={processRecordCreateDto.deviceName}
                error={getErrorIfExisting('deviceName')}
                onChange={handleInputChange}
                type="text"
                label={intl.formatMessage({
                  defaultMessage: 'Device Name*',
                  id: 't5sLva',
                  description: 'Process Record Creation: Device Name Label',
                })}
              />
              <Input
                name="unit"
                expand="auto"
                disabled={isPending}
                placeholder={intl.formatMessage({
                  defaultMessage: 'e.g. Unit 2',
                  description: 'Process Record Creation: Placeholder unit input',
                  id: '7AvLVM',
                })}
                value={processRecordCreateDto.unit}
                error={getErrorIfExisting('unit')}
                onChange={handleInputChange}
                type="text"
                label={intl.formatMessage({
                  defaultMessage: 'Unit*',
                  id: 'dD4biC',
                  description: 'Process Record Creation: Unit Label',
                })}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <DateTimeInput
              id="startTimestamp"
              initialDate={processRecordCreateDto.startTimestamp}
              error={getErrorIfExisting('startTimestamp')}
              disabled={isPending}
              label={intl.formatMessage({
                defaultMessage: 'Start Time*',
                description: 'Process Record Creation: Start Time',
                id: 'lDwYZZ',
              })}
              onDateChange={(date) => date && handleDateChange('startTimestamp', date)}
              required
            />
            <DateTimeInput
              id="stopTimestamp"
              initialDate={processRecordCreateDto.stopTimestamp}
              error={getErrorIfExisting('stopTimestamp')}
              disabled={isPending}
              label={intl.formatMessage({
                defaultMessage: 'Stop Time*',
                description: 'Process Record Creation: Stop Time',
                id: 'Sx2mBk',
              })}
              onDateChange={(date) => date && handleDateChange('stopTimestamp', date)}
              required
            />
            <DateTimeInput
              id="inoculationTimestamp"
              initialDate={processRecordCreateDto.inoculationTimestamp}
              disabled={isPending}
              error={getErrorIfExisting('inoculationTimestamp')}
              label={intl.formatMessage({
                defaultMessage: 'Inoculation Time',
                description: 'Process Record Creation: Inoculation Time',
                id: 'j3FVtp',
              })}
              onDateChange={(date) => handleDateChange('inoculationTimestamp', date)}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Input
              id="operator"
              name="operator"
              disabled={isPending}
              placeholder={intl.formatMessage({
                defaultMessage: 'e.g. Jane Doe',
                description: 'Process Record Creation: Placeholder operator input',
                id: 'uA8dvc',
              })}
              expand="auto"
              value={processRecordCreateDto.operator}
              error={getErrorIfExisting('operator')}
              onChange={handleInputChange}
              type="text"
              label={intl.formatMessage({
                defaultMessage: 'Operator',
                id: '/jO2eT',
                description: 'Process Record Creation: Operator Label',
              })}
            />
            <label className="flex flex-col text-xs font-light text-gray-900" htmlFor="comment">
              <FormattedMessage
                defaultMessage="Comment"
                description="Process Record Creation: Comment"
                id="xXmN6/"
              />
              <textarea
                value={processRecordCreateDto.comment}
                disabled={isPending}
                onChange={handleCommentChange}
                className="rounded border border-gray-300 p-1.5 font-normal text-primary placeholder:text-gray-700 focus-within:border-accent focus:outline-none focus-visible:border-accent lg:text-sm"
                name="comment"
                id="comment"
                rows={5}
              />
            </label>
          </div>
          <div className="flex justify-end text-sm font-light text-gray-900">
            <FormattedMessage
              id="p+iONE"
              defaultMessage="* required information"
              description="Process Record Creation: Indicator that the asterisk indicates required fields."
            />
          </div>
        </div>
      </Modal.Content>

      <Modal.ButtonGroup>
        <Modal.Close asChild>
          <Modal.Button variant="neutral" disabled={isPending}>
            <FormattedMessage
              description="Process Record Creation: Cancel Button"
              defaultMessage="Cancel"
              id="+8Qp/w"
            />
          </Modal.Button>
        </Modal.Close>
        <Modal.Button
          variant="highlight"
          onClick={handleSubmit}
          disabled={isPending || validation.success === false}
        >
          <FormattedMessage
            description="Process Record Creation: Create Process Record Button"
            defaultMessage="Create"
            id="D+8N4O"
          />
        </Modal.Button>
      </Modal.ButtonGroup>
    </>
  );
}

export default CreateProcessRecordViaForm;
