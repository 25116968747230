import React from 'react';

import { CodeSnippetProps } from './code-snippet.definitions';

export function CodeSnippet({ children }: CodeSnippetProps) {
  return (
    <code className="mx-1 inline-block rounded-sm border border-gray-100 bg-gray-50 px-2 text-primary before:content-none after:content-none">
      {children}
    </code>
  );
}

export default CodeSnippet;
