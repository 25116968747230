import { UseMutateFunction } from '@tanstack/react-query';

import {
  UploadWithProgressResponse,
  UploadMutationInput,
} from '../../../../shared/common/hooks/use-upload';
import { ApiError } from '../../../../shared/common/types/api-error/api-error';

type OnChangeValue = string | File | undefined;

export const displayNameChangeHandler = (
  e: React.FormEvent<HTMLInputElement>,
  setDisplayName: (displayName: string) => void,
  setDisplayNameDirty: (isDirty: boolean) => void,
  onChange?: (name: string, value: OnChangeValue) => void,
) => {
  setDisplayName(e.currentTarget.value);
  setDisplayNameDirty(true);
  onChange?.('displayName', e.currentTarget.value);
};

export const fileHandler = (
  newFile: File,
  setFile: (file: File | undefined) => void,
  setIsFileDirty: (isDirty: boolean) => void,
  setProgress: (progress: number | undefined) => void,
  onChange?: (name: string, value: OnChangeValue) => void,
) => {
  setFile(newFile);
  setIsFileDirty(true);
  setProgress(undefined);
  onChange?.('fileContent', newFile);
};

export const deselectFileHandler = (
  setFile: (file: File | undefined) => void,
  setProgress: (progress: number | undefined) => void,
  onChange?: (name: string, value: OnChangeValue) => void,
) => {
  setFile(undefined);
  setProgress(undefined);
  onChange?.('fileContent', undefined);
};

export const uploadHandler = (
  setDisplayNameDirty: (isDirty: boolean) => void,
  setIsFileDirty: (isDirty: boolean) => void,
  upload: UseMutateFunction<UploadWithProgressResponse, ApiError, UploadMutationInput, void>,
  setProgress: (progress: number | undefined) => void,
  [displayName, setDisplayName]: [string, (displayName: string) => void],
  [file, setFile]: [File | undefined, (file: File | undefined) => void],
  onSubmit?: () => void,
) => {
  setDisplayNameDirty(true);
  setIsFileDirty(true);

  if (file === undefined) {
    return;
  }

  const formData = new FormData();

  formData.append('displayName', displayName);
  formData.append('fileContent', file, file.name);

  upload(
    {
      formData,
      onProgress: (e) => setProgress(e.progress !== undefined ? e.progress * 100 : undefined),
    },
    {
      onSuccess() {
        // clear form elements and set to untouched
        setDisplayName('');
        setDisplayNameDirty(false);

        setFile(undefined);
        setIsFileDirty(false);

        setProgress(undefined);

        onSubmit?.();
      },
      onError() {
        setProgress(undefined);
      },
    },
  );
};
