import { ProjectConfig, ConfigParameter } from './project-config.definitions';

export class ProjectConfigAccessor {
  protected projectConfigData: ProjectConfig | null = null;

  // Initialize method to set the ProjectConfiguration data
  initialize(ProjectConfigData: ProjectConfig | null): void {
    this.projectConfigData = ProjectConfigData;
  }

  /**
   * Get method to access fields
   * @note Using this method assumes the initialization of {@link projectConfigData}
   * @param key config key
   * @throws if the config object is initialized or the value for the specified key is null or undefined
   */
  get<T extends ConfigParameter>(key: T): ProjectConfig[T] {
    if (this.projectConfigData === null) {
      throw new Error(`projectConfigData is not initialized. Call initialize() first.`);
    }

    const value = this.projectConfigData[key];
    if (value === null || value === undefined || !value.length) {
      throw new Error(`config parameter "${key}" is null or undefined.`);
    }

    return value;
  }
}

export const projectConfigInstance = new ProjectConfigAccessor();
