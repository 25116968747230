import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { ApiError } from '../../../../shared/common/types/api-error/api-error';
import { ControlProcedureId, SetupId } from '../../../../shared/common/types/setup';
import { CreateDataPointRequest } from '../../../../shared/common/types/setup/data-track';
import useClient from '../../../../shared/common/hooks/use-client';

function useAddDataPoints(
  { controlProcedureId, setupId }: { controlProcedureId: ControlProcedureId; setupId: SetupId },
  {
    onSuccess,
    ...options
  }: UseMutationOptions<unknown, ApiError, CreateDataPointRequest[], unknown> = {},
) {
  const { acquireAccessToken } = useAuthentication();
  const { pmsClient } = useClient();

  return useMutation({
    ...options,
    mutationKey: [QKey.SETUPS, setupId, QKey.DATA_POINTS],
    mutationFn: async (dataTracks: CreateDataPointRequest[]) => {
      pmsClient.authorize(await acquireAccessToken());
      return pmsClient.addDataPointsToCustomDataTracks(controlProcedureId, setupId, dataTracks);
    },
    onSuccess: (data, variables, context) => {
      onSuccess?.(data, variables, context);
    },
  });
}

export default useAddDataPoints;
