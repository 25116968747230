import React from 'react';

import getGridColumnCssClasses from './process-record-header-list.helpers';
import ProcessRecordHeaderSkeleton from './process-record-header/process-record-header.skeleton';

function ProcessRecordHeaderListSkeleton({ headerCount }: { headerCount: number }) {
  const gridColumnStyle = getGridColumnCssClasses(headerCount);

  const arrayOfHeaderLength = [...Array.from({ length: headerCount }, (_, index) => index)];

  return (
    <div
      className={`grid w-full gap-4 ${gridColumnStyle}`}
      data-testid="ProcessRecordHeaderListSkeleton"
    >
      {arrayOfHeaderLength.map((header) => (
        <div key={header} className="flex flex-col items-start justify-between">
          <ProcessRecordHeaderSkeleton />
        </div>
      ))}
    </div>
  );
}

export default ProcessRecordHeaderListSkeleton;
