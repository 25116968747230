import { Truthy } from './truthy.definition';

/**
 * Asserts that the passed in {@link value} is truthy
 * @param value the value whose truthiness should be checked
 * @returns {true} if the value is truthy
 * @example
 * const allTruthy = [truthy, maybeTruthy, undefined].filter(truthy)
 */
function truthy<T>(value: T): value is Truthy<T> {
  return Boolean(value);
}

export default truthy;
