import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { RETRY_DELAY } from '../../types/setup';
import { StructuredSetup } from '../../../../shared/common/types/setup';

import useClient from '../../../../shared/common/hooks/use-client';
import { transformSetupDTO } from '../../../../shared/common/types/setup/dto';
import { SetupDTO } from '../../../../shared/common/types/setup/setup';

import retryStrategy from './use-setup.helpers';

function useSetup(id: string): UseQueryResult<StructuredSetup, Error> {
  const { acquireAccessToken } = useAuthentication();
  const { pmsClient } = useClient();

  return useQuery({
    queryKey: [QKey.SETUPS, id],
    retry: retryStrategy,
    retryDelay: RETRY_DELAY,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      // fetch
      pmsClient.authorize(await acquireAccessToken());
      const result = await pmsClient.getSetup(id);

      // transform
      return transformSetupDTO(result as SetupDTO);
    },
  });
}

export default useSetup;
