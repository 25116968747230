import uniqueKeyGenerator from '../../../shared/utils/unique-key-generator';
import {
  AttributeVariable,
  DataTrackVariable,
  ProcessRecordOptimizationVariable,
} from '../../common/types';

export const predicateDataTrack = (
  variable: ProcessRecordOptimizationVariable,
): variable is DataTrackVariable => variable.type === 'dataTrack';

export const predicateAttribute = (
  variable: ProcessRecordOptimizationVariable,
): variable is AttributeVariable => variable.type === 'attribute';

export const predicateMandatory = <T extends ProcessRecordOptimizationVariable>(
  variable: T,
): variable is T & { mandatory: true } => variable.mandatory === true;

export const predicateOptional = <T extends ProcessRecordOptimizationVariable>(
  variable: T,
): variable is T & { mandatory: false } => variable.mandatory === false;

export const formatVariable = <Code extends string, Group extends string>(
  variableCode: Code,
  variableGroupCode: Group,
) => `${variableCode} (${variableGroupCode})` as const;

export const isSameVariable = <
  AVariable extends Pick<ProcessRecordOptimizationVariable, 'variableCode' | 'variableGroupCode'>,
  BVariable extends Pick<ProcessRecordOptimizationVariable, 'variableCode' | 'variableGroupCode'>,
>(
  aVariable: AVariable,
  bVariable: BVariable,
) =>
  aVariable.variableCode === bVariable.variableCode &&
  aVariable.variableGroupCode === bVariable.variableGroupCode;

const VARIABLE_IDENTIFIER_ENCODE_SEPARATOR = ' >>> ';

/**
 * @param variableCode variable name on DataHow
 * @param variableGroupCode variable group on DataHow
 */
export const encodeVariableIdentifier = <Code extends string, Group extends string>(
  variableCode: Code,
  variableGroupCode: Group,
) => `${variableCode}${VARIABLE_IDENTIFIER_ENCODE_SEPARATOR}${variableGroupCode}` as const;

/**
 * @param encoded encoded string with the variableCode and variableGroupCode
 * @returns object containing the {@link variableCode} and the {@link variableGroupCode}
 */
export const decodeVariableIdentifier = (
  encoded: string,
): { variableCode: string; variableGroupCode: string } => {
  const [variableCode, variableGroupCode] = encoded.split(VARIABLE_IDENTIFIER_ENCODE_SEPARATOR);

  return {
    variableCode,
    variableGroupCode,
  };
};

export const selectParameterKeyGenerator = uniqueKeyGenerator();
