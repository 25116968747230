import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { ApiError } from '../../../../shared/common/types/api-error/api-error';
import { DataTrackId } from '../../../../shared/common/types/process-record';
import { ControlProcedureId, SetupId } from '../../../../shared/common/types/setup';

import useClient from '../../../../shared/common/hooks/use-client';

function useDeleteDataPoints(
  {
    controlProcedureId,
    setupId,
    dataTrackId,
  }: { controlProcedureId: ControlProcedureId; setupId: SetupId; dataTrackId: DataTrackId },
  { onSuccess, ...options }: UseMutationOptions<unknown, ApiError, unknown, unknown> = {},
) {
  const { acquireAccessToken } = useAuthentication();
  const { pmsClient } = useClient();

  return useMutation({
    ...options,
    mutationKey: [QKey.SETUPS, setupId, QKey.DATA_POINTS],
    mutationFn: async (dataPoints: string[]) => {
      pmsClient.authorize(await acquireAccessToken());
      return pmsClient.deleteCustomDataPoints(controlProcedureId, setupId, dataTrackId, dataPoints);
    },
    onSuccess: (data, variables, context) => {
      onSuccess?.(data, variables, context);
    },
  });
}

export default useDeleteDataPoints;
