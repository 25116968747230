import * as configObject from 'echarts/core'; // NOSONAR - as prescribed in https://github.com/hustcc/echarts-for-react#usage
import {
  GridComponent,
  GraphicComponent,
  ToolboxComponent,
  TooltipComponent,
  BrushComponent,
  TitleComponent,
  MarkLineComponent,
  LegendComponent,
  DataZoomComponent,
  DatasetComponent,
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

import { echartsHorizon as theme } from '../theme/graph';

// Register the required components
configObject.use([
  GridComponent,
  GraphicComponent,
  BrushComponent,
  ToolboxComponent,
  TooltipComponent,
  MarkLineComponent,
  LegendComponent,
  DataZoomComponent,
  TitleComponent,
  DatasetComponent,
  //
  LineChart,
  //
  CanvasRenderer,
]);

configObject.registerTheme('horizon-web', theme);

export default configObject;
