import React, { CSSProperties, FunctionComponentElement, useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';

import HeaderBar from '../../components/header-bar';

import useTrackAccountStatus from './base.helpers';

/**
 * Base scene rendering a header bar at the pages top and showing the page content below.
 */
function Base(): FunctionComponentElement<unknown> {
  useTrackAccountStatus();
  const [headerBarHeight, setHeaderBarHeigth] = useState<string>();

  const headerRef = useRef<HTMLDivElement>(null);

  // Only on mount
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const newHeight = entries[0].contentRect.height;
      setHeaderBarHeigth(`${newHeight}px`);
    });

    if (headerRef.current) {
      resizeObserver.observe(headerRef.current);
    }

    return () => {
      if (headerRef.current) {
        resizeObserver.unobserve(headerRef.current);
      }
    };
  }, []);

  return (
    <div
      className="flex flex-col"
      data-testid="Base"
      style={{ '--header-bar-height': headerBarHeight } as CSSProperties}
    >
      <header ref={headerRef} className="fixed left-0 right-0 top-0 z-[15] h-fit">
        <HeaderBar />
      </header>
      <main className="mt-[var(--header-bar-height)] flex h-[calc(100dvh-var(--header-bar-height))] min-h-[calc(100dvh-var(--header-bar-height))] flex-col overflow-auto bg-gray-50">
        <Outlet />
      </main>
    </div>
  );
}

export default Base;
