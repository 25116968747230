import {
  PROJECT_CONFIG_URL,
  ProjectConfig,
  projectConfigSchema,
} from '../../../globals/project-config/project-config.definitions';
import { projectConfigInstance } from '../../../globals/project-config/project-config-accessor';
import fetchData from '../../fetch-data';
import { HttpMethods } from '../../types/http';
import { joinPaths } from '../../../utils';

export const CONFIG_URL = joinPaths(PROJECT_CONFIG_URL, `/`);

export async function fetchProjectConfiguration() {
  const result: ProjectConfig = await fetchData(CONFIG_URL, {
    method: HttpMethods.Get,
    headers: {
      Accept: 'application/json',
    },
  });

  const validationResult = projectConfigSchema.safeParse(result);

  if (validationResult.success === false) {
    throw new Error(`The configuration being received is incorrect.`);
  }

  projectConfigInstance.initialize(validationResult.data);

  return validationResult.data;
}
