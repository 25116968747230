import { NotificationMessage } from '@biss/react-horizon-web';
import React, { FunctionComponentElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFormatDate, useFormatTime } from '../../../shared/common/hooks/use-format-time';

import { ConnectionLostMessageProps } from './connection-lost-message.defiinitions';

function ConnectionLostMessage({
  visible,
  lastTimestamp,
}: ConnectionLostMessageProps): FunctionComponentElement<ConnectionLostMessageProps> | null {
  const intl = useIntl();
  const timeFormatter = useFormatTime();
  const dateFormatter = useFormatDate();

  if (!visible) {
    return null;
  }

  return (
    <NotificationMessage
      data-testid="connection-lost-message"
      status="error"
      variant="highlighted"
      title={intl.formatMessage({
        description: 'Connection Lost message title',
        defaultMessage: 'Connection Lost.',
        id: 'XBZi0r',
      })}
    >
      {lastTimestamp && (
        <FormattedMessage
          description="Connection Lost last update date"
          defaultMessage="Last update on {date} at {time}. "
          id="aZqX7M"
          values={{
            date: dateFormatter(lastTimestamp),
            time: timeFormatter(lastTimestamp),
          }}
        />
      )}
      <FormattedMessage
        description="Connection Lost message description"
        defaultMessage="Please check whether the DASware system has network connection and try again."
        id="y/eemC"
      />
    </NotificationMessage>
  );
}

export default ConnectionLostMessage;
