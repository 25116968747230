import { Series, SeriesName } from '../time-series-chart.definitions';

import { SeriesDescriptor } from './axis-detail-modal.definitions';

export function transformSeriesToSeriesDescriptor(
  input: Record<string, Series[]>,
): Record<string, SeriesDescriptor[]> {
  return Object.entries(input).reduce((acc, [key, value]) => {
    acc[key] = value.map((item) => ({
      name: item.displayName,
      label: item.engineeringUnit,
    }));
    return acc;
  }, {} as Record<string, SeriesDescriptor[]>);
}

export function getSeriesLabel(seriesList: Array<SeriesDescriptor>): string {
  const label = seriesList.at(0)?.label;

  return label ? `(${label})` : '';
}

export function getIsRangesValid(validRanges: Record<SeriesName, boolean>): boolean {
  return Object.values(validRanges).every((item) => item);
}
