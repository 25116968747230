import { Modal } from '@biss/react-horizon-web';
import React from 'react';

import { ImageOverrideProps } from './image-override.definitions';

function ImageOverride({ defaultOpen, alt, ...props }: ImageOverrideProps) {
  return (
    <Modal
      size="lg"
      defaultOpen={defaultOpen}
      trigger={
        <img
          // eslint-disable-next-line react/jsx-props-no-spreading -- overriding html element
          {...props}
          className="max-h-[400px] max-w-[400px] cursor-pointer"
          alt={alt}
          data-testid="ImageOverride-small-image"
        />
      }
    >
      <Modal.Content className="flex flex-col items-center">
        <img
          // eslint-disable-next-line react/jsx-props-no-spreading -- overriding html element
          {...props}
          alt={alt}
          data-testid="ImageOverride-full-image"
          className="h-[80vh] object-contain"
        />
      </Modal.Content>
    </Modal>
  );
}

export default ImageOverride;
