import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { Member } from '../../types/member/member';

import fetchMembers from './use-members.helpers';

function useMembers(): UseQueryResult<Member[], Error> {
  const { acquireAccessToken } = useAuthentication();

  return useQuery({
    queryKey: [QKey.MEMBERS],
    queryFn: async () => {
      const accessToken = await acquireAccessToken();
      return fetchMembers(accessToken);
    },
  });
}

export default useMembers;
