import { Tile, Skeleton } from '@biss/react-horizon-web';
import React from 'react';

import { many } from '../../common/utils';

function EventListSkeleton() {
  return (
    <Tile data-testid="EventListSkeleton">
      <Tile.Body>
        <div className="flex flex-col gap-4">
          <div className="flex flex-row flex-wrap justify-between gap-4">
            <Skeleton variant="rectangular" width="20rem" />
            <Skeleton variant="rectangular" width="16rem" />
          </div>

          <div className="flex flex-row gap-4">
            {many(
              (i) => (
                <div key={i}>
                  <Skeleton variant="text" />
                </div>
              ),
              5,
            )}
          </div>

          <div className="mt-4 flex flex-col gap-4">
            {many(
              (i) => (
                <div key={i}>
                  <Skeleton variant="rectangular" />
                </div>
              ),
              3,
            )}
          </div>
        </div>
      </Tile.Body>
    </Tile>
  );
}

export default EventListSkeleton;
