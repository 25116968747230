import React from 'react';
import { FormattedNumber } from 'react-intl';

import { UploadProgressProps } from './upload-progress.definitions';

function UploadProgress({ progress }: UploadProgressProps) {
  return (
    <div className="w-full">
      <progress max="1" value={progress} />
      <span className="inline-block w-8 p-1">
        {/* eslint-disable-next-line react/style-prop-object -- TODO: adapt eslint rules to excempt formatjs components */}
        <FormattedNumber value={progress} style="percent" />
      </span>
    </div>
  );
}

export default UploadProgress;
