/**
 * Downloads a file with the provided data, filename, and content type.
 *
 * @param data - The data to be converted into JSON format and downloaded.
 * @param fileName - The name of the file to be downloaded. Defaults to "BioNsight.json".
 * @param contentType - The MIME type of the file being downloaded. Defaults to 'text/json'.
 */
function downloadFile<T>(data: T, fileName = `BioNsight.json`, contentType = 'text/json') {
  const blob = new Blob([JSON.stringify(data)], { type: contentType });
  const link = document.createElement('a');

  link.download = fileName;
  link.href = window.URL.createObjectURL(blob);
  link.dataset.downloadurl = [contentType, link.download, link.href].join(':');

  const evt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  link.dispatchEvent(evt);
  link.remove();
}

export default downloadFile;
