import { useIntl } from 'react-intl';

export function useFormatTime(): (ts: number) => string {
  const intl = useIntl();

  return (ts: number) =>
    intl.formatTime(ts, {
      hour: 'numeric',
      minute: 'numeric',
      hourCycle: 'h23',
    });
}

export function useFormatDate(): (ts: number) => string {
  const intl = useIntl();

  return (ts: number) =>
    intl.formatDate(ts, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
}

export function useFormatDateTime(): (ts: number) => string {
  const intl = useIntl();

  return (ts: number) =>
    intl.formatDate(ts, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
}

export function useFormatDateTimeSecond(): (ts: number) => string {
  const intl = useIntl();

  return (ts: number) =>
    intl.formatDate(ts, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    });
}
