import React from 'react';
import { PageLayout, Skeleton, Tile } from '@biss/react-horizon-web';

import Footer from '../../../../shared/components/footer';
import { ProcessRecordHeaderListSkeleton } from '../../../components/process-record-header-list';
import EventListSkeleton from '../../../../shared/components/event-list/event-list.skeleton';

function ProcessRecordVisualizationSkeleton({
  totalProcessRecordCount,
}: {
  totalProcessRecordCount: number;
}) {
  return (
    <div data-testid="ProcessRecordVisualizationSkeleton">
      <PageLayout
        sidebarOpen={false}
        className="relative !h-[calc(100dvh-var(--header-bar-height,0))] !overflow-y-visible"
      >
        <PageLayout.Main>
          <PageLayout.Main.ActionBar
            sidePanelOpen={false}
            closeText={null}
            openText={null}
            pageTitle={<Skeleton variant="rectangular" width="16rem" />}
            backButton={<Skeleton variant="rectangular" width="12rem" />}
          />

          <PageLayout.Main.Content className="flex flex-col gap-2 px-4">
            <ProcessRecordHeaderListSkeleton headerCount={totalProcessRecordCount} />

            <Tile data-testid="ChartsSkeleton">
              <Tile.Body>
                <div className="flex flex-col gap-4">
                  <Skeleton variant="rectangular" width="12rem" />

                  <div className="flex flex-col gap-2">
                    <Skeleton variant="rectangular" height="16rem" />
                    <Skeleton variant="rectangular" height="16rem" />
                    <Skeleton variant="rectangular" height="16rem" />
                  </div>

                  <div className="flex flex-col gap-2">
                    <Skeleton variant="text" width="20rem" />
                    <Skeleton variant="text" width="20rem" />
                  </div>
                </div>
              </Tile.Body>
            </Tile>

            {totalProcessRecordCount === 1 && ( // TODO(BIOCL-1587): event-list in comparison view will be implemented in
              <EventListSkeleton />
            )}

            {/* TODO(BIOCL-2732): Move to a central place when the page layout is done */}
            <Footer />
          </PageLayout.Main.Content>
        </PageLayout.Main>
        <PageLayout.Sidebar isOpen={false} openText={<Skeleton variant="text" width="10rem" />}>
          <PageLayout.Sidebar.Title>
            <Skeleton variant="rectangular" />
          </PageLayout.Sidebar.Title>
          <div className="flex flex-row gap-2 p-4">
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" />
          </div>
        </PageLayout.Sidebar>
      </PageLayout>
    </div>
  );
}

export default ProcessRecordVisualizationSkeleton;
