import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, DeleteIcon, Select } from '@biss/react-horizon-web';

import { VARIABLE_RENAME_MAP } from '../recipe-optimization-transfer.definitions';
import {
  decodeVariableIdentifier,
  encodeVariableIdentifier,
  formatVariable,
  isSameVariable,
} from '../recipe-optimization-transfer.helpers';

import { DataTrackMappingProps, DataTrackVariableMapDiff } from './data-track-mapping.definitions';

function DataTrackMapping({
  dataTracks,
  mappings: currentDataTrackMappings,
  onUpdate,
  allowDeletion = false,
  onDelete,
  disabled,
  defaultOpen = false,
}: Readonly<DataTrackMappingProps>) {
  /**
   * when a data track is selected
   * check if the variable was mapped before
   * if so update the mapping, otherwise add the variable to the mappings and call the endpoint
   * @param variableIdentifier - variable identifier
   * @param dataTrackId - data track id
   */
  const handleValueChange = (variableIdentifier: string, dataTrackId: string) => {
    const { variableCode, variableGroupCode } = decodeVariableIdentifier(variableIdentifier);

    // attempt to get previous mapping
    const previousMapIndex = currentDataTrackMappings.findIndex((entry) =>
      isSameVariable(entry, { variableCode, variableGroupCode }),
    );

    const newMapping: DataTrackVariableMapDiff = {
      dataTrackId,
      variableCode,
      variableGroupCode,
    };

    const newMappings: DataTrackVariableMapDiff[] = [...currentDataTrackMappings];
    if (previousMapIndex >= 0) {
      // update previous mapping
      newMappings[previousMapIndex] = newMapping;
    } else {
      // add new mapping
      newMappings.push(newMapping);
    }

    onUpdate(newMappings);
  };

  const handleDelete = (variableIdentifier: string) => {
    const { variableCode, variableGroupCode } = decodeVariableIdentifier(variableIdentifier);

    onDelete?.(variableCode, variableGroupCode);
  };

  return currentDataTrackMappings.map(({ variableCode, variableGroupCode, dataTrackId }) => {
    const variableIdentifier = encodeVariableIdentifier(variableCode, variableGroupCode);

    return (
      <div className="flex flex-col gap-2" key={variableIdentifier}>
        <span>
          <FormattedMessage
            defaultMessage="{label}"
            description="Mappable Data Track Label"
            id="ZnO7mT"
            values={{
              label: formatVariable(
                VARIABLE_RENAME_MAP.get(variableCode) ?? variableCode,
                variableGroupCode,
              ),
            }}
          />
        </span>

        <div className="flex flex-row gap-2">
          <div className="flex-auto">
            <Select
              expand="auto"
              disabled={disabled}
              placeholder="Select a Data Track"
              onValueChange={(selectedDataTrackId) =>
                handleValueChange(variableIdentifier, selectedDataTrackId)
              }
              value={dataTrackId ?? undefined}
              data-testid="select-datatrack-mapping"
              defaultOpen={defaultOpen}
            >
              {dataTracks.map((dataTrack) => (
                <Select.Item key={dataTrack.dataTrackId} value={dataTrack.dataTrackId}>
                  {dataTrack.dataTrackType}
                  {dataTrack.engineeringUnit && (
                    <FormattedMessage
                      description="data track list item: engineering unit"
                      defaultMessage=" ({engineeringUnit})"
                      values={{
                        engineeringUnit: dataTrack.engineeringUnit,
                      }}
                      id="pHlDa4"
                    />
                  )}
                </Select.Item>
              ))}
            </Select>
          </div>

          {allowDeletion && (
            <Button
              data-testid="remove-datatrack-mapping"
              mood="destructive"
              leftIcon={<DeleteIcon />}
              onClick={() => handleDelete(variableIdentifier)}
            />
          )}
        </div>
      </div>
    );
  });
}

export default DataTrackMapping;
