import React, { FunctionComponentElement, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import { Card, HORIZON_COLORS as colors } from '@biss/react-horizon-web';

import { useFormatTime } from '../../../../../shared/common/hooks/use-format-time';
import TimeSeriesChart from '../../../../../shared/components/time-series-chart';
import QKey from '../../../../../shared/common/hooks/keys';
import DataTrackCurrentValue from '../../../../components/data-track-current-value';
import useSetup from '../../../../common/hooks/use-setup';
import { RETRY_DELAY } from '../../../../common/types/setup';
import useLogger from '../../../../../shared/common/hooks/use-logger/use-logger';
import TrackedEvent from '../../../../../shared/common/tracked-event';
import {
  useDataTracksFromTimestamp,
  useDataTracksLastTimestamp,
} from '../../../../common/hooks/use-data-tracks-from-timestamp';
import {
  DataTrack,
  DataTrackDescriptor,
  DataTrackType,
} from '../../../../../shared/common/types/setup/data-track';
import {
  combineDataPoints,
  getIsSetupSetting,
  getLastTimestamp,
  isFetched,
  subtractHours,
} from '../../../../common/helpers';

import { yAxisFormatter } from '../../../setup-details/setup-details-content/setup-details-content.helpers';

import { MONITORING_TIME_SPAN, SetupOverviewItemProps } from './setup-overview-unit.definitions';
import {
  getIsOutdated,
  getMarkLines,
  getSeries,
  getDataTrackTimestamp,
  filterDataTracks,
  getNewDataTracks,
  combineDataTracks,
  getSetupNewDataTracks,
  getDataTracksLastTimestamps,
  getIsSetupIdle,
  getIsSetupIdleInitially,
  isUpdateDataTracksTimestamp,
  isRemoveFromIdle,
  isAddToIdle,
  getIsLoading,
  getIsDisconnected,
} from './setup-overview-unit.helpers';
import SetupMessage from './setup-message';
import SetupSkeleton from './setup-skeleton';

function SetupOverviewUnit({
  setupItem,
  isIdle,
  updateDataTracksTimestamp,
  deleteDataTracksTimestamp,
  updateDataTrackList,
  lastDataPoints,
  selectedDataTracks,
  isLoadingSetups,
  setIsLoadingSetups,
  addIdleSetup,
  removeIdleSetup,
  timeSpan,
  yAxisRanges,
}: SetupOverviewItemProps): FunctionComponentElement<SetupOverviewItemProps> {
  const logger = useLogger();
  const showTooltip = useMediaQuery('(hover: hover)');
  const queryClient = useQueryClient();
  const selectedDataTracksPrev = useRef<Record<DataTrackType, string>>(selectedDataTracks);
  const isFetchingPrev = useRef<boolean>(false);
  const {
    setupId,
    unit,
    inoculationTimestamp,
    startTimestamp,
    stopTimestamp,
    controlProcedureId,
    connectionState,
  } = setupItem;
  const { data: setupDetails, isLoading } = useSetup(setupId);
  const [dataTracks, setDataTracks] = useState<DataTrack[]>([]);
  const { getLastDataPoints } = useDataTracksLastTimestamp();
  const {
    data: dataPoints,
    isLoading: isLoadingDataPoints,
    isFetching,
  } = useDataTracksFromTimestamp(
    controlProcedureId,
    getDataTrackTimestamp(selectedDataTracks, setupDetails?.dataTracks),
    setupId,
  );

  async function getDataPoints(
    newDataTracks: Record<DataTrackType, string>,
    dataTrackList: DataTrackDescriptor[],
  ) {
    try {
      const newDataTrackList = getDataTrackTimestamp(newDataTracks, dataTrackList);
      const data = await getLastDataPoints(controlProcedureId, newDataTrackList);
      if (data) {
        setDataTracks((prev) =>
          combineDataTracks(dataTrackList, prev, selectedDataTracksPrev.current, data),
        );
      }
    } catch (e) {
      throw new Error('Intentional Error');
    }
  }

  function updateIsSetupIdleInitially() {
    const isSetupIdle = getIsSetupIdleInitially(startTimestamp, dataPoints);
    if (isAddToIdle(isSetupIdle, isIdle)) {
      addIdleSetup(setupId);
    }
    if (isRemoveFromIdle(isSetupIdle, isIdle)) {
      removeIdleSetup(setupId);
    }
  }

  function updateIsSetupIdle() {
    if (lastDataPoints?.length && isIdle) {
      removeIdleSetup(setupId);
    }
    if (!lastDataPoints?.length) {
      const isSetupIdle = getIsSetupIdle(startTimestamp, dataTracks);
      if (isSetupIdle && !isIdle) {
        addIdleSetup(setupId);
      }
    }
  }

  useEffect(() => {
    if (isUpdateDataTracksTimestamp(isFetching, stopTimestamp)) {
      const dataTracksLastTimestamps = getDataTracksLastTimestamps(dataTracks);
      updateDataTracksTimestamp(setupId, dataTracksLastTimestamps);
    }
  }, [dataTracks, isFetching]);

  useEffect(() => {
    if (isFetched(isFetching, isFetchingPrev.current)) {
      setIsLoadingSetups(false);
      updateIsSetupIdleInitially();

      if (setupDetails?.dataTracks.length && dataPoints?.length) {
        setDataTracks((prev) =>
          combineDataTracks(
            setupDetails?.dataTracks,
            prev,
            selectedDataTracksPrev.current,
            dataPoints,
          ),
        );
      }
    }

    if (isFetchingPrev.current !== isFetching) {
      isFetchingPrev.current = isFetching;
    }
  }, [dataPoints, isFetching]);

  useEffect(() => {
    const isSetupSetting = getIsSetupSetting(setupDetails?.startTimestamp);
    let timeout: ReturnType<typeof setTimeout>;
    if (isSetupSetting) {
      // invalidate setup details every 1 min for the first 5 min after setup start time
      timeout = setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: [QKey.SETUPS, setupId],
        });
      }, RETRY_DELAY);
    }

    if (setupDetails?.dataTracks.length) {
      const newDataTracks = getSetupNewDataTracks(
        dataTracks,
        setupDetails?.dataTracks,
        selectedDataTracksPrev.current,
      );
      if (newDataTracks.length) {
        setDataTracks((prev) => [...prev, ...newDataTracks]);
      }
      updateDataTrackList(setupDetails.dataTracks);
    }
    // do not show skeleton if there is no selected data tracks
    if (isLoadingSetups && !Object.keys(selectedDataTracks).length) {
      setIsLoadingSetups(false);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [setupDetails]);

  // update data tracks when selected data track list is changed
  useEffect(() => {
    if (!setupDetails?.dataTracks) {
      return;
    }
    const newDataTracks = getNewDataTracks(
      selectedDataTracks,
      selectedDataTracksPrev.current,
      setupDetails?.dataTracks,
    );
    if (Object.keys(newDataTracks).length) {
      getDataPoints(newDataTracks, setupDetails?.dataTracks);
    } else {
      setDataTracks((prev) => filterDataTracks(selectedDataTracks, prev));
    }
    selectedDataTracksPrev.current = selectedDataTracks;
  }, [selectedDataTracks]);

  // combine data points when receive new data points
  useEffect(() => {
    if (!lastDataPoints) {
      return;
    }
    updateIsSetupIdle();
    if (dataTracks.length && lastDataPoints?.length) {
      setDataTracks((prev) => combineDataPoints(prev, lastDataPoints));
    }
  }, [lastDataPoints]);

  useEffect(() => {
    if (stopTimestamp) {
      // delete data tracks timestamps because finished setup is not updated automatically
      deleteDataTracksTimestamp(setupId);
    }
  }, [stopTimestamp]);

  const xAxisFormatter = useFormatTime();
  const stopTime = new Date();
  const startTime = subtractHours(stopTime, timeSpan);
  const isOutdated = getIsOutdated(startTime, stopTimestamp);
  const isFinished = !!stopTimestamp;
  const isDisconnected = getIsDisconnected(connectionState, isFinished);
  const cardVariant = isFinished ? 'positive' : 'default';
  const indicatorVariant = isDisconnected ? 'error' : cardVariant;
  const tooltipColor = colors.gray['100'];
  const series = getSeries(selectedDataTracks, dataTracks);
  const lastTimestamp = getLastTimestamp(dataTracks);
  const seriesMarkLines = getMarkLines(
    inoculationTimestamp,
    stopTimestamp,
    isDisconnected,
    lastTimestamp,
  );

  return (
    <Link
      data-testid="setup-card"
      to={setupId}
      relative="path"
      className="grid"
      onClick={() => {
        logger.trackEvent(TrackedEvent.SelectSingleUnit);
      }}
    >
      <Card
        variant={cardVariant}
        header={
          <Card.Header
            title={unit}
            indicator={
              <Card.Header.Indicator variant={indicatorVariant}>
                {isFinished && (
                  <FormattedMessage
                    description="Monitoring overview screen: Unit process finished message"
                    defaultMessage="Process Finished"
                    id="FeOtnk"
                  />
                )}
                {isDisconnected && (
                  <FormattedMessage
                    description="Monitoring overview screen: Unit not Connected message"
                    defaultMessage="Not Connected"
                    id="FqWL6Q"
                  />
                )}
              </Card.Header.Indicator>
            }
          />
        }
      >
        <Card.Content>
          <>
            {isLoadingSetups && <SetupSkeleton />}
            {!isLoadingSetups && !isOutdated && series && (
              <div className="flex flex-col">
                <div className="flex min-h-[32px] w-full flex-wrap gap-1">
                  {Object.values(series).map((seriesItem) => (
                    <DataTrackCurrentValue
                      key={seriesItem[0].dataTrackId}
                      dataTrack={seriesItem[0]}
                      disabled={isFinished || isDisconnected}
                    />
                  ))}
                </div>
                <div className="-mb-12 -ml-2 justify-self-end">
                  <TimeSeriesChart
                    series={series}
                    startTime={startTime.getTime()}
                    stopTime={stopTime.getTime()}
                    variant="small"
                    combinedGraph
                    showTooltip={showTooltip}
                    xAxisFormatter={xAxisFormatter}
                    seriesMarkLines={seriesMarkLines}
                    tooltipStyles={{ backgroundColor: tooltipColor, borderColor: tooltipColor }}
                    yAxisRanges={yAxisRanges}
                    yAxisFormatter={yAxisFormatter}
                  />
                </div>
              </div>
            )}
            <SetupMessage
              isLoading={getIsLoading(isLoadingSetups, isLoadingDataPoints, isLoading)}
              isOutdated={isOutdated}
              isDataTracks={!!setupDetails?.dataTracks.length}
              isDataTracksSelected={!!Object.keys(selectedDataTracks).length}
              timeSpan={MONITORING_TIME_SPAN}
            />
          </>
        </Card.Content>
      </Card>
    </Link>
  );
}

export default SetupOverviewUnit;
