import React from 'react';

import { DropzoneProps } from './dropzone.definitions';
import { DropzoneChooser } from './dropzone-chooser';
import { DropzoneList } from './dropzone-list';

function Dropzone({
  onFileChange,
  onFileRemove,
  file,
  accept = '.csv',
  error,
  progress,
  isProcessing = false,
  'data-testid': dataTestId,
}: DropzoneProps) {
  return file ? (
    <DropzoneList
      file={file}
      onFileRemove={onFileRemove}
      isProcessing={isProcessing}
      progress={progress}
    />
  ) : (
    <DropzoneChooser
      onFileChange={onFileChange}
      error={error}
      accept={accept}
      data-testid={dataTestId}
    />
  );
}

export default Dropzone;
