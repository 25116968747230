import {
  DataTrackDescriptor,
  DataTrackId,
} from '../../../../../shared/common/types/setup/data-track';
import {
  DataTrackType,
  DataTrackFromTimestampItem,
  LatestDataPointObject,
  StructuredSetupDescriptor,
} from '../../../../../shared/common/types/setup';
import { SeriesName } from '../../../../../shared/components/time-series-chart';
import { TimeSpan } from '../../../../common/types/setup';
import { YAxisRange } from '../../setup-overview-y-axis/y-axis-range-controls/y-axis-range-controls.validation';

export const LINE_WIDTH = 1.5;
export const SETUP_TIME_IN_MINUTES = 5;
export const MONITORING_TIME_SPAN = 24;

export interface SetupOverviewItemProps {
  setupItem: StructuredSetupDescriptor;
  selectedDataTracks: Record<DataTrackType, string>;
  lastDataPoints?: LatestDataPointObject[];
  isLoadingSetups: boolean;
  isIdle: boolean;
  timeSpan: TimeSpan;
  yAxisRanges?: Map<SeriesName, YAxisRange>;
  updateDataTracksTimestamp: (setupId: string, tracks: DataTrackFromTimestampItem[]) => void;
  deleteDataTracksTimestamp: (setupId: string) => void;
  updateDataTrackList: (dataTracks: DataTrackDescriptor[]) => void;
  setIsLoadingSetups: (value: boolean) => void;
  addIdleSetup: (setupId: DataTrackId) => void;
  removeIdleSetup: (setupId: DataTrackId) => void;
}

export type SetupStatus = 'default' | 'finished';
