import React from 'react';

import useProcessRecordEvents from '../../common/hooks/use-process-record-events/use-process-record-events';
import EventList from '../../../shared/components/event-list';

import { ProcessRecordEventListProps } from './process-record-event-list.definitions';

function ProcessRecordEventList({ processRecordId }: ProcessRecordEventListProps) {
  const { data, isLoading, isError } = useProcessRecordEvents(processRecordId);

  return <EventList events={data} isLoading={isLoading} isError={isError} />;
}

export default ProcessRecordEventList;
