import { useMutation } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import { ProcessRecordId } from '../../../../shared/common/types/process-record';

import { transferProcessRecord } from './use-recipe-optimization-transfer.helpers';

export default function useRecipeOptimizationTransfer(id: ProcessRecordId) {
  const { acquireAccessToken } = useAuthentication();

  return useMutation({
    mutationFn: async () => {
      const accessToken = await acquireAccessToken();
      return transferProcessRecord(id, accessToken);
    },
  });
}
