import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { joinPaths } from '../../../../shared/utils';
import { createOrganizationsUrl } from '../use-create-new-organization/use-create-new-organization.helpers';

export function createChangeStateOrganizationUrl(organizationId: string, active: boolean): string {
  if (active) {
    return `${joinPaths(createOrganizationsUrl(), organizationId)}/activate`;
  }
  return `${joinPaths(createOrganizationsUrl(), organizationId)}/deactivate`;
}

export async function changeStateOrganization(
  organizationId: string,
  active: boolean,
  accessToken: string,
): Promise<void> {
  await fetchData(createChangeStateOrganizationUrl(organizationId, active), {
    method: HttpMethods.Put,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}
