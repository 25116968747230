import { AuthConfig } from '@biss/react-auth-web';

export function buildAuthority(accountUrl: string, tenantId: string, policyName: string): string {
  return `${accountUrl}${tenantId}/${policyName}`;
}

export function generateAuthConfig(accountUrl: string, clientId: string, tenantId: string) {
  const b2c = {
    signIn: 'B2C_1A_SIGNUP_SIGNIN',
    editProfile: 'B2C_1A_PROFILEEDIT',
    changePassword: 'B2C_1A_PASSWORDCHANGE',
    forgotPassword: 'B2C_1A_PASSWORDRESET',
    redeemInvitation: 'B2C_1A_REDEEM_INVITATION',
    accountUrl,
    clientId,
    tenantId,
  };

  const authorities = {
    signInAuthority: buildAuthority(b2c.accountUrl, b2c.tenantId, b2c.signIn),
    passwordChangeAuthority: buildAuthority(b2c.accountUrl, b2c.tenantId, b2c.changePassword),
    profileEditAuthority: buildAuthority(b2c.accountUrl, b2c.tenantId, b2c.editProfile),
    forgotPasswordAuthority: buildAuthority(b2c.accountUrl, b2c.tenantId, b2c.forgotPassword),
    redeemInvitationAuthority: buildAuthority(b2c.accountUrl, b2c.tenantId, b2c.redeemInvitation),
  };

  const authConfig: AuthConfig = {
    authorityDomain: new URL(b2c.accountUrl).hostname,
    clientId: b2c.clientId,
    loginAuthority: authorities.signInAuthority,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
    passwordChangePolicy: b2c.changePassword,
    profileEditPolicy: b2c.editProfile,
    scopes: [`${accountUrl}backend/biocloud`],
    forgotPasswordPolicy: b2c.forgotPassword,
    forgotPasswordAuthority: authorities.forgotPasswordAuthority,
    signInPolicy: b2c.signIn,
    redeemPolicy: b2c.redeemInvitation,
    redeemAuthority: authorities.redeemInvitationAuthority,
    passwordChangeAuthority: authorities.passwordChangeAuthority,
    profileEditAuthority: authorities.profileEditAuthority,
  };

  return authConfig;
}
