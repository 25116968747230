/**
 * @param name the users name
 * @returns the users initials if a valid {@link name} is passed in
 * @returns an empty string if {@link name} is all whitespaces or empty
 */
// eslint-disable-next-line import/prefer-default-export -- helper file
export const createInitials = (name: string) => {
  const trimmed = name.trim();

  // all whitespace or empty string
  if (trimmed === '') {
    return '';
  }

  const upper = trimmed.toUpperCase();

  // separate by whitespace
  const parts = upper.split(/\s+/); // treat subsequent whitespaces as one

  // get first letter of each part
  const begginings = parts
    .map((beggining) => beggining.at(0))
    .filter(
      (beggining): beggining is string => typeof beggining === 'string' && beggining.trim() !== '',
    );

  // return the only character if there is just one part
  if (begginings.length === 1) {
    return begginings[0];
  }

  // get the first and last letters
  const [first, last] = [begginings.at(0), begginings.at(-1)].filter(
    (beggining): beggining is string => typeof beggining === 'string' && beggining.trim() !== '',
  );

  const joined = first + last;

  return joined;
};
