import { ILogger } from '../../../globals/logging';

import { UploadHeaders, UploadMutationInput } from './use-upload.definitions';
import { uploadWithProgress } from './use-upload.helpers';

/**
 * separated for mocking purposes
 */
const mutationFnFactory =
  (
    acquireAccessToken: () => Promise<string>,
    url: string,
    logger: ILogger,
    uploadHeaders?: UploadHeaders,
  ) =>
  async ({ formData, onProgress }: UploadMutationInput) => {
    const token = await acquireAccessToken();
    return uploadWithProgress(url, formData, onProgress, logger, token, uploadHeaders);
  };

export default mutationFnFactory;
