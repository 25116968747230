import { useIntl } from 'react-intl';

import useHorizontalZoom from '../../../components/time-series-chart/use-horizontal-zoom';
import { useKeyboard } from '../../../common/hooks/use-keyboard';
import useShouldEnableTouch from '../../../components/time-series-chart/use-should-enable-touch';
import {
  SPLIT_CHART_RESET_ZOOM_BUTTON_DIMENSIONS,
  SPLIT_CHART_RIGHT_OFFSET_PX,
  SPLIT_CHART_TOTAL_HEIGHT,
  SPLIT_CHART_X_AXIS_ZOOM_HEIGHT,
  SPLIT_CHART_X_AXIS_ZOOM_INSIDE_ID,
  SPLIT_CHART_X_AXIS_ZOOM_SLIDER_ID,
  SPLIT_CHART_Y_AXIS_ZOOM_SLIDER_ID,
  SPLIT_CHART_ZOOM_OFFSET_PX,
} from '../split-chart.config';
import { getMinValueSpan, getOnZoomCallback } from '../../chart-zoom';
import { ChartXAxisTimeStampFormat } from '../../chart-formatters/chart-formatters.definitions';
import { getXAxisFormatter } from '../../chart-formatters/chart-formatters';

/**
 * Configures the Echarts settings for the y axis
 *
 * @returns y axis configuration object to configure Echarts with
 */
function useEchartsDataZoomConfig(timeStampFormat: ChartXAxisTimeStampFormat) {
  const keyboard = useKeyboard();
  const shouldEnableTouch = useShouldEnableTouch();
  const intl = useIntl();

  // TODO: (BIOCL-4457) fix naming and import
  const dateFormatter = getXAxisFormatter(timeStampFormat, intl);

  const minValueSpan = getMinValueSpan(timeStampFormat);

  return [
    {
      id: SPLIT_CHART_X_AXIS_ZOOM_SLIDER_ID,
      type: 'slider',
      orient: 'horizontal',
      // the split chart only contains a single x axis
      xAxisIndex: [0, 0],
      filterMode: 'none',
      left: SPLIT_CHART_ZOOM_OFFSET_PX,
      right: SPLIT_CHART_RIGHT_OFFSET_PX,
      showDataShadow: false,
      height: SPLIT_CHART_X_AXIS_ZOOM_HEIGHT,
      bottom: 10,
      zoomLock: false,
      labelFormatter: dateFormatter,
      brushSelect: false,
      minValueSpan,
    },
    {
      type: 'inside',
      id: SPLIT_CHART_X_AXIS_ZOOM_INSIDE_ID,
      orient: 'horizontal',
      // the split chart only contains a single x axis
      xAxisIndex: [0, 0],
      rangeMode: ['value', 'percent'],
      zoomLock: !(shouldEnableTouch || keyboard.Shift.held || keyboard.Control.held),
      zoomOnMouseWheel: 'shift',
      moveOnMouseWheel: 'ctrl',
      moveOnMouseMove: true,
      preventDefaultMouseMove: false,
      filterMode: 'none',
      labelFormatter: dateFormatter,
      minValueSpan,
    },
    {
      type: 'slider',
      id: SPLIT_CHART_Y_AXIS_ZOOM_SLIDER_ID,
      rangeMode: ['percent', 'percent'],
      // the split chart contains a y axis for each line in the chart
      yAxisIndex: 0,
      orient: 'vertical',
      filterMode: 'none',
      show: true,
      showDataShadow: false,
      brushSelect: false,
      showDetail: false,
      right: 5,
    },
  ];
}

/**
 * Configures a button to reset the zoom level. Intended to be included in the echarts configuration.
 *
 *  This is required to calculate the correct distance to the left border of the chart
 * @param resetZoom callback that is called when the reset button is clicked
 * @returns graphics object that can be used to configure echarts with
 */
function getResetZoomButtonGraphic(resetZoom: () => void) {
  const buttonDistanceFactor = 1.5;
  return {
    type: 'image',
    style: {
      image: '/assets/icons/resetZoom.png',
      x:
        SPLIT_CHART_ZOOM_OFFSET_PX -
        buttonDistanceFactor * SPLIT_CHART_RESET_ZOOM_BUTTON_DIMENSIONS.width,
      y: SPLIT_CHART_TOTAL_HEIGHT - SPLIT_CHART_X_AXIS_ZOOM_HEIGHT,
      width: SPLIT_CHART_RESET_ZOOM_BUTTON_DIMENSIONS.width,
      height: SPLIT_CHART_RESET_ZOOM_BUTTON_DIMENSIONS.height,
    },
    onclick() {
      resetZoom();
    },
  };
}

/**
 * Configuration to set up the zoom for the split chart.
 *
 *  This is required to configure the charts layout and the echarts zoom configuration.
 */
function useZoom(timeStampFormat: ChartXAxisTimeStampFormat, resetZoom: () => void) {
  // TODO: (BIOCL-4457) move zoom hook into chart "package" and fix naming (zoomY -> useHORIZONTALZoom)
  const zoomX = useHorizontalZoom();
  const zoomY = useHorizontalZoom();

  const echartsZoomConfig = useEchartsDataZoomConfig(timeStampFormat);
  const echartsResetZoomButtonGraphic = getResetZoomButtonGraphic(resetZoom);
  const onZoomCallback = getOnZoomCallback(zoomX, zoomY);

  return { onZoomCallback, echartsZoomConfig, echartsResetZoomButtonGraphic, resetZoom };
}

export default useZoom;
