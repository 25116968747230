import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { DataHowLabConfiguration } from '../../types';

import putDataHowLabConfiguration from './use-update-datahowlab-configuration.helpers';

function useUpdateDataHowLabConfiguration(
  organizationId: string,
  onMutated: (isValid: boolean) => void,
) {
  const { acquireAccessToken } = useAuthentication();
  const queryClient = useQueryClient();
  return useMutation<
    void,
    unknown,
    DataHowLabConfiguration,
    {
      previousConfiguration: DataHowLabConfiguration;
      newConfiguration: DataHowLabConfiguration;
    }
  >({
    mutationFn: async (configuration) => {
      const accessToken = await acquireAccessToken();
      await putDataHowLabConfiguration(organizationId, configuration, accessToken);
    },
    // When mutate is called:
    onMutate: async (configuration) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [QKey.DATAHOWLAB_CONFIGURATION, organizationId],
      });

      // Snapshot the previous value
      const previousConfiguration = queryClient.getQueryData([
        QKey.DATAHOWLAB_CONFIGURATION,
        organizationId,
      ]) as DataHowLabConfiguration;
      const newConfiguration = { ...configuration };
      // Optimistically update to the new value
      queryClient.setQueryData([QKey.DATAHOWLAB_CONFIGURATION, organizationId], newConfiguration);

      // Return a context with the previous and new member
      return { previousConfiguration, newConfiguration };
    },
    // If the mutation fails, use the context we returned above
    onError: (_1, _2, context) => {
      queryClient.setQueryData(
        [QKey.DATAHOWLAB_CONFIGURATION, organizationId],
        context?.previousConfiguration,
      );
      onMutated(false);
    },
    onSuccess: (_1, _2, _3) => {
      onMutated(true);
    },
    // Always refetch after error or success:
    onSettled: (_1, _2, _3, _4) => {
      queryClient.invalidateQueries({
        queryKey: [QKey.DATAHOWLAB_CONFIGURATION, organizationId],
      });
    },
  });
}

export default useUpdateDataHowLabConfiguration;
