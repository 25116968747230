import { DataTrackTimeAlignment } from '../process-record-visualization.definitions';
import { SeriesName } from '../../../../../shared/components/time-series-chart';
import { YAxisRange } from '../../../../../shared/components/time-series-chart/y-axis-range-inputs/y-axis-range-inputs.validation';
import { ColoredProcessRecord } from '../../../process-record-comparison/process-record-comparison.definitions';
import { ChartXAxisTimeStampFormat } from '../../../../../shared/charts/chart-formatters/chart-formatters.definitions';

export const YAXIS_LABEL_DECIMALS = 2;

export interface ProcessRecordVisualizationChartProps {
  inSingleView: boolean;
  timeAlignment: DataTrackTimeAlignment;
  xAxisFormat: ChartXAxisTimeStampFormat;
  selectedDataTrackTypes: string[];
  coloredProcessRecords: ColoredProcessRecord[];

  /** should be `undefined` when {@link FKey.ANALYTICS_REFACTORING_COMBINED_CHART} is `false` */
  chartType: 'combined' | 'split' | undefined;

  defaultYAxisRanges?: Map<SeriesName, YAxisRange>;
}
