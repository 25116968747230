import React, { useState, FunctionComponentElement, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DocumentMoveGenericIcon, Modal, NotificationMessage } from '@biss/react-horizon-web';

import useLogger from '../../../shared/common/hooks/use-logger/use-logger';
import TrackedEvent from '../../../shared/common/tracked-event';
import DataTrackList from '../../../shared/components/data-track-list';
import { DataTrackType } from '../../../shared/common/types/setup';
import { MAX_SERIES_LENGTH } from '../../../shared/components/time-series-chart/time-series-chart.helpers';

import { DataTrackModalProps } from './data-track-modal.definitions';
import { dataTrackSelectionHandler, resetHandler } from './data-track-modal.handlers';

function DataTrackModal({
  trigger,
  selectedDataTracks = [],
  disabledDataTracks,
  dataTracks,
  onSelect,
  defaultOpen = false,
}: DataTrackModalProps): FunctionComponentElement<DataTrackModalProps> {
  const logger = useLogger();
  const intl = useIntl();
  const [open, setOpen] = useState(defaultOpen);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isLimit, setIsLimit] = useState(false);
  const [selectedItems, setSelectedItems] = React.useState<DataTrackType[]>(selectedDataTracks);

  useEffect(() => {
    setIsLimit(selectedItems.length > MAX_SERIES_LENGTH);
  }, [selectedItems]);

  const handleResetClick = () => resetHandler(setSelectedItems, setIsUpdated);

  const handleDataTrackSelected = (type: DataTrackType, selected: boolean) =>
    dataTrackSelectionHandler(type, selected, setSelectedItems, setIsUpdated);

  const handleOnOpenChange = (isOpen: boolean) => {
    setIsUpdated(false);
    setSelectedItems(selectedDataTracks);
    setOpen(isOpen);
  };

  const handleOnClick = useCallback(() => {
    onSelect(selectedItems);

    logger.trackEvent(TrackedEvent.AddDataTracksToMonitoringOverview);

    setOpen(!open);
  }, [setOpen, selectedItems]);

  return (
    <Modal
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={handleOnOpenChange}
      size="xs"
      trigger={trigger}
      title={
        <FormattedMessage
          description="Monitoring overview Data track modal: Configure Data Track Quick Selection"
          defaultMessage="Configure Data Track Quick Selection"
          id="C/aWyo"
        />
      }
    >
      {open && (
        <div className="relative mb-0.5 h-[70vh] overflow-y-auto px-2">
          <DataTrackList
            onReset={handleResetClick}
            items={dataTracks}
            selectedItems={selectedItems}
            disabledItems={disabledDataTracks}
            onSelect={handleDataTrackSelected}
            showTitle={false}
            notification={
              isLimit && (
                <div className="mb-2">
                  <NotificationMessage
                    title={intl.formatMessage({
                      defaultMessage: 'Selection Limit',
                      id: 'emUwIo',
                      description: 'Data Track List Modal: Selection Limit title',
                    })}
                    status="warning"
                    variant="reduced"
                    data-testid="DataTrackListModal-warning"
                  >
                    <FormattedMessage
                      description="Data Track List Modal: Selection Limit description"
                      defaultMessage="You can select up to {value} data tracks."
                      id="iKwrVI"
                      values={{ value: MAX_SERIES_LENGTH }}
                    />
                  </NotificationMessage>
                </div>
              )
            }
          />
        </div>
      )}

      <Modal.ButtonGroup>
        <Modal.Button
          variant="positive"
          onClick={handleOnClick}
          disabled={!isUpdated || isLimit}
          data-testid="dataTrack-confirmButton"
          leadIcon={<DocumentMoveGenericIcon />}
        >
          <FormattedMessage
            description="Data track list confirm button"
            defaultMessage="Add to Overview"
            id="9z29R5"
          />
        </Modal.Button>
      </Modal.ButtonGroup>
    </Modal>
  );
}

export default DataTrackModal;
