import { DataHowLabConfiguration } from '../datahowlab-configuration';

import { DataHowLabConfigurationDTO } from './datahowlab-configuration-dto';

function transformDataHowLabConfigurationDTO(
  dto: DataHowLabConfigurationDTO,
): DataHowLabConfiguration {
  return {
    ...dto,
  };
}

export default transformDataHowLabConfigurationDTO;
