import React from 'react';
import { ProgressBar, DeleteIcon } from '@biss/react-horizon-web';

import { DropzoneListProps } from './dropzone-list.definitions';

function DropzoneList({ file, onFileRemove, isProcessing = false, progress }: DropzoneListProps) {
  const handleRemoveFile = () => onFileRemove(file);

  return (
    <div
      data-testid="dropzone-file-list"
      className="flex h-96 flex-col items-start rounded border border-gray-600 bg-gray-100 p-2"
    >
      <div className="flex w-full flex-row justify-between gap-2 rounded border border-gray-700 bg-white p-2">
        <div className="grid grid-rows-2 items-center gap-0">
          <span
            data-testid="file-name"
            className="overflow-hidden text-ellipsis whitespace-nowrap"
            key={file.name}
          >
            {file.name}
          </span>

          {isProcessing === false && progress && (
            <ProgressBar
              data-testid="upload-progress"
              label="Uploading..."
              max={100}
              value={progress}
            />
          )}

          {isProcessing && (
            <ProgressBar data-testid="process-progress" label="Processing..." isIndeterminate />
          )}
        </div>

        {progress === undefined && isProcessing === false && (
          <button
            data-testid="remove-selected-file-button"
            onClick={handleRemoveFile}
            type="button"
            className="p-2"
          >
            <DeleteIcon className="h-8 w-8 hover:text-red-600" />
          </button>
        )}
      </div>
    </div>
  );
}

export default DropzoneList;
