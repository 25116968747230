import { SetupDTO, StructuredSetup, StructuredSetupDescriptor, SetupDescriptorDTO } from '../setup';

export function transformSetupDescriptorDTO(dto: SetupDescriptorDTO): StructuredSetupDescriptor {
  return {
    ...dto,
    startTimestamp: new Date(dto.startTimestamp),
    stopTimestamp: dto.stopTimestamp ? new Date(dto.stopTimestamp) : undefined,
    inoculationTimestamp: dto.inoculationTimestamp ? new Date(dto.inoculationTimestamp) : undefined,
    // TODO BIOCL-3935 remove connectionState when state is returned
    connectionState:
      !dto.connectionState || dto.connectionState === 'Unknown' ? 'Connected' : dto.connectionState,
  };
}

export function transformSetupDTO(dto: SetupDTO): StructuredSetup {
  if (!dto.startTimestamp) {
    throw new Error('Expected to receive start timestamp');
  }

  return {
    ...dto,
    dataTracks: dto.dataTracks,
    startTimestamp: new Date(dto.startTimestamp),
    stopTimestamp: dto.stopTimestamp ? new Date(dto.stopTimestamp) : undefined,
    inoculationTimestamp: dto.inoculationTimestamp ? new Date(dto.inoculationTimestamp) : undefined,
    // TODO BIOCL-3935 remove connectionState when state is returned
    connectionState:
      !dto.connectionState || dto.connectionState === 'Unknown' ? 'Connected' : dto.connectionState,
  };
}
