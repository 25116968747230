import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tile, SaveIcon, Button } from '@biss/react-horizon-web';

import useProcessRecord from '../../../../shared/common/hooks/use-process-record';
import { downloadFile } from '../../../../shared/common/utils';

import { AttributeEditor } from './attribute-editor';
import { ProcessRecordHeaderProps } from './process-record-header.definitions';

/**
 * Process record header used to display basic process record information.
 */
function ProcessRecordHeader({
  processRecordId,
  displayName,
  attributes,
  color,
  title,
  deviceName,
  unit,
}: ProcessRecordHeaderProps) {
  const { data: processRecord, isLoading, isError } = useProcessRecord(processRecordId);

  const intl = useIntl();

  const handleDownload = () => {
    if (processRecord === undefined) {
      return;
    }

    downloadFile(processRecord, `BioNsight-${displayName}.json`);
  };

  return (
    <Tile className="w-full">
      <Tile.Header className="flex flex-row">
        <div className="flex flex-col">
          <h3 className="overflow-hidden overflow-ellipsis whitespace-nowrap text-lg font-semibold">
            {displayName}
          </h3>
          <span className="flex flex-row flex-wrap gap-x-2">
            <span
              className="overflow-hidden overflow-ellipsis whitespace-nowrap"
              data-testid="ProcessRecord-title"
            >
              {title}
            </span>
            <FormattedMessage
              description="Process record header: bullet point icon separator"
              defaultMessage="&#8226;"
              id="hU73LC"
            />
            <span
              className="overflow-hidden overflow-ellipsis whitespace-nowrap"
              data-testid="ProcessRecord-deviceName"
            >
              {deviceName}
            </span>
            <FormattedMessage
              description="Process record header: bullet point icon separator"
              defaultMessage="&#8226;"
              id="hU73LC"
            />
            <span
              className="overflow-hidden overflow-ellipsis whitespace-nowrap"
              data-testid="ProcessRecord-unit"
            >
              {unit}
            </span>
          </span>
        </div>

        <Button
          onClick={handleDownload}
          disabled={isLoading || isError}
          kind="ghost"
          leftIcon={<SaveIcon className="h-6 w-6" />}
          className="ml-auto"
          data-testid="ProcessRecord-DownloadButton"
          aria-label={intl.formatMessage({
            id: 'da86Qj',
            description: 'Download aria-label in the process record header (not visible)',
            defaultMessage: 'Download',
          })}
        />
      </Tile.Header>

      <div data-testid="ProcessRecord-attributes" className="p-4">
        <AttributeEditor processRecordId={processRecordId} attributes={attributes} />
      </div>

      <div
        className="h-1.5 w-full rounded-b"
        style={{ backgroundColor: color }}
        data-testid="ProcessRecordHeader-colorDisplay"
      />
    </Tile>
  );
}

export default ProcessRecordHeader;
