import { ChangeEvent, FocusEvent } from 'react';
import { useIntl } from 'react-intl';

import { FormValidationState } from '../../types';

function useFormValidation() {
  const { formatMessage } = useIntl();
  const requiredInputError = formatMessage({
    defaultMessage: 'Required Input',
    description: 'Error message for required input',
    id: '6kCrUC',
  });

  const invalidFormatError = formatMessage({
    defaultMessage: 'Invalid Format',
    description: 'Error message for invalid format',
    id: 'pDz9IZ',
  });

  const updateErrorsFromFocusEvent = (
    state: FormValidationState,
    event: FocusEvent<HTMLInputElement>,
  ): Map<string, string> => {
    const { name } = event.target;
    const newErrors = new Map<string, string>(state.errors);
    if (event.target.validity.patternMismatch || event.target.validity.typeMismatch) {
      newErrors.set(name, invalidFormatError);
    } else if (event.target.validity.valueMissing) {
      newErrors.set(name, requiredInputError);
    } else {
      newErrors.delete(name);
    }

    return newErrors;
  };

  const updateErrorsAndValidityFromChangeEvent = (
    state: FormValidationState,
    event: ChangeEvent<HTMLInputElement>,
  ): FormValidationState => {
    const { name } = event.target;
    const validity = new Map<string, boolean>(state.validity);
    validity.set(name, event.target.validity.valid);
    const errors = new Map<string, string>(state.errors);
    errors.delete(name);
    return { validity, errors };
  };

  return { updateErrorsFromFocusEvent, updateErrorsAndValidityFromChangeEvent };
}

export default useFormValidation;
