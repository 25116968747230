/**
 * App config feature keys
 */
const enum FKey {
  // monitoring-detail-view
  MONITORING_DETAIL_VIEW_PROTOTYPING = 'BIOCL-2237/monitoring-detail-view-prototyping',

  MONITORING_ADD_EDIT_OFFLINE_DATA = 'rel_add-edit-offline-data',

  MONITORING_DWC_EVENT_NOTIFICATION = 'rel_dwc-event-notification',

  MONITORING_REFACTORING_SPLIT_CHART = 'dev_BIOCL-4458/refactor-split-chart-component',

  // process-record-visualization
  ANALYTICS_REFACTORING_COMBINED_CHART = 'dev_BIOCL-4457/refactor-combined-chart-component',

  /** this key is only used within tests */
  TEST_KEY = 'TEST_KEY',
}

export default FKey;
