import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import {
  StructuredProcessRecordDescriptor,
  ProcessRecordId,
  StructuredProcessRecordObject,
} from '../../types/process-record';
import QKey from '../keys';
import useClient from '../use-client';

import { PrsException } from '../../types/generated/prs.generated';

import { UseDeleteProcessRecordPreviousData } from './use-delete-process-record.definitions';

function useDeleteProcessRecord(
  onSuccess?: (data: void, variables: string, context: unknown) => unknown,
) {
  const queryClient = useQueryClient();
  const { prsClient } = useClient();
  const { acquireAccessToken } = useAuthentication();

  return useMutation<void, PrsException, ProcessRecordId, UseDeleteProcessRecordPreviousData>({
    mutationFn: async (processRecordId) => {
      prsClient.authorize(await acquireAccessToken());
      return prsClient.deleteProcessRecordById(processRecordId);
    },
    onMutate: (processRecordId) => {
      const previousData = {
        list: queryClient.getQueryData<StructuredProcessRecordDescriptor[]>([QKey.PROCESS_RECORDS]),
        record: queryClient.getQueryData<StructuredProcessRecordObject>([
          QKey.PROCESS_RECORDS,
          processRecordId,
        ]),
      };

      queryClient.setQueryData<StructuredProcessRecordDescriptor[]>(
        [QKey.PROCESS_RECORDS],
        (prev) => prev?.filter((pr) => pr.processRecordId !== processRecordId),
      );

      queryClient.removeQueries({
        queryKey: [QKey.PROCESS_RECORDS, processRecordId],
      });

      return previousData;
    },
    onError: (_, processRecordId, previous) => {
      queryClient.setQueryData([QKey.PROCESS_RECORDS], previous?.list);
      queryClient.setQueryData([QKey.PROCESS_RECORDS, processRecordId], previous?.record);
    },
    onSuccess: (...params) => {
      queryClient.invalidateQueries({
        queryKey: [QKey.PROCESS_RECORDS],
      });
      onSuccess?.(...params);
    },
  });
}

export default useDeleteProcessRecord;
