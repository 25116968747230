import FKey from '../feature-keys';

import { FeatureFlag, FeatureFlagType } from './feature-flag';

class ReleaseFeatureFlag extends FeatureFlag {
  private readonly labelField: string | undefined = undefined;

  private readonly typeField: FeatureFlagType = 'release';

  private readonly key: FKey;

  private readonly readyForQueryField = true;

  constructor(fkey: FKey) {
    super();
    this.key = fkey;
  }

  public get name(): string {
    return this.key;
  }

  public get type(): FeatureFlagType {
    return this.typeField;
  }

  public get label(): string | undefined {
    return this.labelField;
  }

  public get readyForQuery(): boolean {
    return this.readyForQueryField;
  }
}

export default ReleaseFeatureFlag;
