import { createCheckers } from 'ts-interface-checker';

import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import exportedTypeSuite from '../../types/member/dto/member-validators';
import { Member } from '../../types/member/member';
import { joinPaths } from '../../../../shared/utils';
import { projectConfigInstance } from '../../../../shared/globals/project-config/project-config-accessor';

export function createMembersUrl(): string {
  return joinPaths(projectConfigInstance.get('UM_BACKEND_URL'), 'members');
}

async function fetchMembers(accessToken: string): Promise<Member[]> {
  const result = await fetchData(createMembersUrl(), {
    method: HttpMethods.Get,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!Array.isArray(result)) {
    throw new Error('expected to receive an array of members.');
  }

  const { MemberDTO: memberChecker } = createCheckers(exportedTypeSuite);

  // throws an error when object is not in the correct format
  result.forEach((member) => memberChecker.check(member));
  return result;
}

export default fetchMembers;
