import React from 'react';

import { useReactTable, getCoreRowModel, flexRender, RowData } from '@tanstack/react-table';

import { DeleteIcon, MinusIcon, PlusIcon } from '@biss/react-horizon-web';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import { EditableTableProps } from './editable-data-table.definitions';
import StickyColumnStyle from './editable-data-table.helpers';

// adds updateData method to update value
declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- from documentation
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
  }
}

// adds array of disabled rows, rows with error and type of the cell to column meta
declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- from documentation
  interface ColumnMeta<TData extends RowData, TValue> {
    disabled?: number[];
    error?: number[];
    type?: 'text' | 'number';
  }
}

function EditableDataTable<TData extends object>({
  columns,
  data,
  showDeleteButton = false,
  updateTableData,
  addRow,
  deleteRow,
  deleteSelectedRow,
  errorMessage,
  disabledRows,
  stickyColumns = {
    left: [],
    right: [],
  },
}: EditableTableProps<TData>): React.FunctionComponentElement<EditableTableProps<TData>> {
  const table = useReactTable({
    data,
    columns,
    enableRowSelection: (row) => disabledRows?.includes(row.index) ?? false,
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      columnPinning: stickyColumns,
    },
    // Provide our updateTableData function to our table meta
    meta: {
      updateData: (rowIndex, columnId, value) => {
        updateTableData(rowIndex, columnId, value);
      },
    },
    debugTable: true,
  });

  const handleDeleteRow = (idx: number) => (event: React.MouseEvent) => {
    event.stopPropagation();
    if (deleteSelectedRow) {
      deleteSelectedRow(idx);
      table.resetRowSelection();
    }
  };
  // Render the UI for the table
  return (
    <div className="w-full">
      <table className="w-full border border-light bg-white">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className="h-14 border border-light bg-gray-100 p-4"
                  style={{ ...StickyColumnStyle(header.column) }}
                >
                  {header.isPlaceholder ? null : (
                    <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              data-testid="data-row"
              key={row.id}
              onClick={() => {
                table.resetRowSelection();
                row.toggleSelected();
              }}
              className={classNames({
                'bg-table-hover': table.getSelectedRowModel().rows.includes(row),
              })}
            >
              {row.getVisibleCells().map((cell, idx) => (
                <td
                  key={cell.id}
                  className={classNames('vertical-center h-14 border border-light bg-white p-0', {
                    'hover:bg-table-hover': !disabledRows?.includes(row.index),
                  })}
                  style={{ ...StickyColumnStyle(cell.column) }}
                >
                  <div className="group flex h-full w-full items-center">
                    {idx === 0 && row.getIsSelected() && (
                      <button
                        onClick={handleDeleteRow(row.index)}
                        data-testid="delete-row-button"
                        type="button"
                        className=" flex h-14 items-center border-l-4 border-l-danger"
                      >
                        <DeleteIcon className="ml-2 h-6 w-6 text-danger" />
                      </button>
                    )}
                    <span className="h-full w-full">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </span>
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex flex-col border-x border-b border-light bg-gray-100 px-4 pb-2">
        <div className="flex">
          {addRow && (
            <button
              className="mr-2 box-border flex h-8 items-center justify-center gap-x-2 whitespace-nowrap rounded-b  border-x border-b border-light bg-white px-4 text-sm text-textGray-700 hover:bg-textGray-25 active:border-textGray-500 active:bg-textGray-500 active:text-white"
              type="button"
              onClick={addRow}
            >
              <PlusIcon className="h-6 w-6" />
              <FormattedMessage
                description="Editable data table: Add Row button label"
                defaultMessage="Add Row"
                id="HGiL5F"
              />
            </button>
          )}
          {deleteRow && showDeleteButton && (
            <button
              className="box-border flex h-8 items-center justify-center gap-x-2 whitespace-nowrap rounded-b  border-x border-b border-light bg-white px-4 text-sm text-textGray-700 hover:bg-textGray-25 active:border-textGray-500 active:bg-textGray-500 active:text-white"
              type="button"
              onClick={deleteRow}
            >
              <MinusIcon className="h-8 w-8" />
              <FormattedMessage
                description="Editable data table: Delete Row button label"
                defaultMessage="Delete Row"
                id="/muhFJ"
              />
            </button>
          )}
        </div>
        {errorMessage && (
          <div data-testid="editable-data-table-error" className="mb-1 mt-4 text-warning">
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
}

export default EditableDataTable;
