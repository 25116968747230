/**
 * attempts to get all the cookies in the browser
 * if a cookie is mal-shaped, it will be ignored in the return value
 */
function getCookies() {
  // get the string with all the cookies
  const cookieBox = document.cookie;

  if (cookieBox.trim() === '') {
    return new Map();
  }

  // split the string into individual cookies
  const cookies = cookieBox.split('; ');

  const cookiePairs = cookies
    .map((cookie) => {
      const indexOfDelimiter = cookie.indexOf('=');

      if (indexOfDelimiter < 0) {
        // cookie could not be parsed
        return undefined;
      }

      return [cookie.slice(0, indexOfDelimiter), cookie.slice(indexOfDelimiter + 1)];
    })
    .filter((entry): entry is [string, string] => entry !== undefined);

  return new Map<string, string>(cookiePairs);
}

export default getCookies;
