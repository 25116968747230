import { DataTrackId, LatestDataPointObject } from '../../../../../shared/common/types/setup';
import { CreateDataPointObject } from '../../../../../shared/common/types/setup/data-track';
import { DataPoint } from '../../../../../shared/components/data-point-create-modal/data-point-create-modal.definitions';

export function formatDataPoints(dataPoints: DataPoint[]): CreateDataPointObject[] {
  return dataPoints.map((item) => ({
    timestamp: new Date(item.ts).toISOString(),
    value: +item.v,
  }));
}

export function formatRemovedDataPoints(dataPoints: string[]): string[] {
  return dataPoints.map((item) => new Date(+item).toISOString());
}

export function getDataPoints(dataTrackId: DataTrackId, dataTracks?: LatestDataPointObject[]) {
  const dataTrack = dataTracks?.find((item) => item.dataTrackId === dataTrackId);
  return dataTrack ? dataTrack.dataPoints : [];
}
