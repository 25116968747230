import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { DataHowLabConfiguration, transformDataHowLabConfigurationDTO } from '../../types';
import {
  createGetDataHowLabConfigurationUrl,
  isDataHowLabConfigurationDTO,
} from '../use-datahowlab-configuration/use-datahowlab-configuration.helpers';

async function putDataHowLabConfiguration(
  organizationId: string,
  configuration: DataHowLabConfiguration,
  accessToken: string,
): Promise<DataHowLabConfiguration | null> {
  const dataHowLabConfiguration = await fetchData(
    createGetDataHowLabConfigurationUrl(organizationId),
    {
      method: HttpMethods.Put,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(configuration),
    },
  );
  // throws an error when object is not in the correct format
  isDataHowLabConfigurationDTO(dataHowLabConfiguration);
  return transformDataHowLabConfigurationDTO(dataHowLabConfiguration);
}

export default putDataHowLabConfiguration;
