import React, { useEffect, useState, useCallback, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import { PageLayout } from '@biss/react-horizon-web';
import { useLocalStorage } from 'usehooks-ts';

import useSetupList from '../../common/hooks/use-setup-list';
import QKey from '../../../shared/common/hooks/keys';
import useDocumentVisiblity from '../../../shared/common/hooks/use-document-visibility';
import useAutomaticUpdates from '../../common/hooks/use-automatic-updates';
import Footer from '../../../shared/components/footer';
import { MONITORING_DATA_TRACKS, TIME_SPAN, Y_AXIS_RANGE } from '../../common/types/setup';
import { DataTrackType, DataTrackDescriptor } from '../../../shared/common/types/setup/data-track';

import { SeriesName } from '../../../shared/components/time-series-chart';

import { convertYAxisRangesToMap } from '../../common/helpers';

import useLogger from '../../../shared/common/hooks/use-logger/use-logger';

import TrackedEvent from '../../../shared/common/tracked-event';

import {
  getWorkflows,
  getDataTrackFilter,
  getSelectedDataTracks,
  updateSelectedDataTracks,
  getDataTracksStorage,
  updateYAxisRange,
  deleteYAxisRange,
} from './setup-overview.helpers';
import SetupOverviewWorkflow from './setup-overview-workflow';
import { FilterDataTrack, SetupOverviewProps } from './setup-overview.definitions';
import SetupOverviewMessage from './setup-overview-message';
import SetupOverviewConfiguration from './setup-overview-configuration/setup-overview-configuration';
import { MONITORING_TIME_SPAN } from './setup-overview-workflow/setup-overview-unit/setup-overview-unit.definitions';
import { YAxisRange } from './setup-overview-y-axis/y-axis-range-controls/y-axis-range-controls.validation';

/**
 * Overview of all current running setups / units
 */
function SetupOverview({ defaultSidebarOpen = false }: SetupOverviewProps) {
  const intl = useIntl();
  const logger = useLogger();

  const queryClient = useQueryClient();
  const isDocumentVisible = useDocumentVisiblity();
  const autoUpdate = useAutomaticUpdates();
  const isDocumentVisiblePrev = useRef<boolean>(isDocumentVisible);

  const [storageDataTracks, setStorageDataTracks] = useLocalStorage<Record<DataTrackType, boolean>>(
    MONITORING_DATA_TRACKS,
    {},
  );
  const [storageTimeSpan, setStorageTimeSpan] = useLocalStorage<number>(
    TIME_SPAN,
    MONITORING_TIME_SPAN,
  );

  const [isLoadingSetups, setIsLoadingSetups] = useState<boolean>(true);
  const [dataTrackList, setDataTrackList] = useState<DataTrackDescriptor[]>([]);
  const [filterDataTracks, setFilterDataTracks] = useState<Record<string, FilterDataTrack>>(
    getDataTrackFilter(storageDataTracks),
  );

  const { data: setupItems, isLoading, isError, error } = useSetupList(autoUpdate);

  // controls whether the sidebar is opened or closed
  const [open, setOpen] = useState(defaultSidebarOpen);

  // range of the viewable and zoom-able area on the y axis of the chart
  const [yAxisRanges, setYAxisRanges] = useLocalStorage<Record<SeriesName, YAxisRange>>(
    Y_AXIS_RANGE,
    {},
  );

  // invalidate queries if document is visible after hiding and auto update is disabled
  useEffect(() => {
    if (!isDocumentVisiblePrev.current && isDocumentVisible && !autoUpdate) {
      setIsLoadingSetups(true);
      queryClient.invalidateQueries({ queryKey: [QKey.SETUPS] });
    }
    isDocumentVisiblePrev.current = isDocumentVisible;
  }, [isDocumentVisible]);

  // save data tracks selected in configuration in local storage
  useEffect(() => {
    const dataTracks = getDataTracksStorage(filterDataTracks);
    setStorageDataTracks(dataTracks);
  }, [filterDataTracks, setStorageDataTracks]);

  const updateDataTrackList = useCallback((dataTracks: DataTrackDescriptor[]) => {
    if (dataTracks.length) {
      setDataTrackList((prev) => {
        const newDataTracks = dataTracks.filter((el) =>
          prev.every((f) => f.dataTrackType !== el.dataTrackType),
        );
        return [...prev, ...newDataTracks];
      });
    }
  }, []);

  const selectDataTracks = useCallback(
    (types: DataTrackType[]) => {
      const tracks = updateSelectedDataTracks(types, filterDataTracks);
      setFilterDataTracks(tracks);
    },
    [filterDataTracks],
  );

  const toggleDataTrack = useCallback((type: DataTrackType) => {
    setFilterDataTracks((prev: Record<DataTrackType, FilterDataTrack>) => ({
      ...prev,
      [type]: { ...prev[type], selected: !prev[type].selected },
    }));
  }, []);

  const removeDataTrack = useCallback((type: DataTrackType) => {
    setFilterDataTracks((prev: Record<DataTrackType, FilterDataTrack>) => {
      const tracks = { ...prev };
      delete tracks[type];
      return { ...tracks };
    });
    // remove y-axis range from local storage
    setYAxisRanges((prev) => deleteYAxisRange(prev, type));
  }, []);

  const onYAxisRangeChange = useCallback((range: YAxisRange, seriesType: SeriesName) => {
    setYAxisRanges((prev: Record<SeriesName, YAxisRange>) =>
      updateYAxisRange(prev, range, seriesType),
    );
    logger.trackEvent(TrackedEvent.SetYAxisRangeInMonitoringOverview);
  }, []);

  const workflows = getWorkflows(setupItems);
  const isWorkflows = workflows.length > 0;

  const sidebarButtonsText = intl.formatMessage({
    defaultMessage: 'Configure Overview',
    id: 'jPIrEf',
    description: 'Configure Overview Sidebar Open And Close Text',
  });

  const pageTitle = intl.formatMessage({
    defaultMessage: 'Monitoring Overview',
    id: 'vcTLmj',
    description: 'Monitoring Overview Page Title',
  });

  const yAxisRangesMap = convertYAxisRangesToMap(yAxisRanges);

  return (
    <PageLayout sidebarOpen={open} className="!h-[calc(100dvh-var(--header-bar-height,0))]">
      <PageLayout.Main>
        <PageLayout.Main.ActionBar
          pageTitle={pageTitle}
          openText={sidebarButtonsText}
          closeText={sidebarButtonsText}
          sidePanelOpen={open}
          onSidePanelOpenChange={setOpen}
        />

        <PageLayout.Main.Content className="flex h-full flex-col overflow-y-auto px-4">
          {isWorkflows && (
            <div data-testid="setup-overview">
              {workflows.map((workflow) => (
                <div key={workflow[0].workflowId} className="mb-4">
                  <div
                    className="mb-2 border-b border-textGray-900 pb-1"
                    data-testid="workflow-title"
                  >
                    <span className="mr-6 font-bold">{workflow[0].deviceName}</span>
                    {workflow[0].title}
                  </div>
                  <SetupOverviewWorkflow
                    autoUpdate={autoUpdate}
                    workflow={workflow}
                    timeSpan={storageTimeSpan}
                    selectedDataTracks={getSelectedDataTracks(filterDataTracks)}
                    updateDataTrackList={updateDataTrackList}
                    isLoadingSetups={isLoadingSetups}
                    setIsLoadingSetups={setIsLoadingSetups}
                    yAxisRanges={yAxisRangesMap}
                  />
                </div>
              ))}
            </div>
          )}

          {!isWorkflows && !isLoading && <SetupOverviewMessage isError={isError} error={error} />}

          <div className="mx-auto mt-auto flex flex-row gap-4 py-2">
            <Footer />
          </div>
        </PageLayout.Main.Content>
      </PageLayout.Main>

      <PageLayout.Sidebar
        onOpenChange={setOpen}
        isOpen={open}
        openText={sidebarButtonsText}
        closeText={sidebarButtonsText}
      >
        <PageLayout.Sidebar.Title className="whitespace-nowrap">
          <FormattedMessage
            defaultMessage="Overview Configuration"
            id="l2FKt3"
            description="Monitoring Overview Sidebar title"
          />
        </PageLayout.Sidebar.Title>
        <SetupOverviewConfiguration
          dataTracks={dataTrackList}
          filterDataTracks={filterDataTracks}
          selectDataTracks={selectDataTracks}
          toggleDataTrack={toggleDataTrack}
          removeDataTrack={removeDataTrack}
          timeSpan={storageTimeSpan}
          setTimeSpan={setStorageTimeSpan}
          yAxisRanges={yAxisRangesMap}
          onYAxisRangeChange={onYAxisRangeChange}
        />
      </PageLayout.Sidebar>
    </PageLayout>
  );
}

export default SetupOverview;
