import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { Member } from '../../types/member/member';
import { joinPaths } from '../../../../shared/utils';
import { createMembersUrl } from '../use-members/use-members.helpers';
import { transformMemberDTO } from '../../types';
import { isMemberDTO } from '../use-invite-new-member/use-invite-new-member.helpers';

export function createGetMemberUrl(memberId: string): string {
  return joinPaths(createMembersUrl(), memberId);
}

export async function fetchMember(memberId: string, accessToken: string): Promise<Member> {
  const member = await fetchData(createGetMemberUrl(memberId), {
    method: HttpMethods.Get,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  // throws an error when object is not in the correct format
  isMemberDTO(member);
  return transformMemberDTO(member);
}
