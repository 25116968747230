import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { Subscription } from '../../types';

import putSubscriptions from './use-update-subscriptions.helpers';

function useUpdateSubscriptions(organizationId: string, onMutated: (successful: boolean) => void) {
  const { acquireAccessToken } = useAuthentication();
  const queryClient = useQueryClient();
  return useMutation<
    void,
    unknown,
    Subscription[],
    {
      previousSubscriptions: Subscription[];
      newSubscriptions: Subscription[];
    }
  >({
    mutationFn: async (subscriptions) => {
      const accessToken = await acquireAccessToken();
      await putSubscriptions(organizationId, subscriptions, accessToken);
    },
    // When mutate is called:
    onMutate: async (subscriptions) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [QKey.SUBSCRIPTIONS, organizationId],
      });

      // Snapshot the previous value
      const previousSubscriptions = queryClient.getQueryData([
        QKey.SUBSCRIPTIONS,
        organizationId,
      ]) as Subscription[];
      const newSubscriptions = { ...subscriptions };
      // Optimistically update to the new value
      queryClient.setQueryData([QKey.SUBSCRIPTIONS, organizationId], newSubscriptions);

      // Return a context with the previous and new member
      return { previousSubscriptions, newSubscriptions };
    },
    // If the mutation fails, use the context we returned above
    onError: (_1, _2, context) => {
      queryClient.setQueryData(
        [QKey.SUBSCRIPTIONS, organizationId],
        context?.previousSubscriptions,
      );
      onMutated(false);
    },
    onSuccess: (_1, _2, _3) => {
      onMutated(true);
    },
    // Always refetch after error or success:
    onSettled: (_1, _2, _3, _4) => {
      queryClient.invalidateQueries({
        queryKey: [QKey.SUBSCRIPTIONS, organizationId],
      });
    },
  });
}

export default useUpdateSubscriptions;
