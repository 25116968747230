import { DataPointObject } from '../../components/time-series-chart';

// eslint-disable-next-line import/prefer-default-export -- helper file
export function getDatasetEchartsConfig(
  data: Array<{ id: string; dataPoints: Array<DataPointObject> }>,
) {
  return data.flatMap((entry) => ({
    id: entry.id,
    source: entry.dataPoints,
    dimensions: ['ts', 'v'],
  }));
}
