import { Series } from './split-chart.definitions';

// eslint-disable-next-line import/prefer-default-export -- helper file
export function groupSeries(data: Array<Series>): Record<string, Array<Series>> {
  return data.reduce((acc: Record<string, Array<Series>>, item: Series) => {
    acc[item.group] = acc[item.group] || [];
    acc[item.group].push(item);

    return acc;
  }, {});
}
