const MANY_DEFAULT_LENGTH = 10;

/**
 * Returns an array of a specified length, where each element is either the result of calling
 * a given predicate function or a given constant value.
 *
 * @template T - The type of the elements in the resulting array.
 * @param predicate - The function to call to determine the value of each element.
 * If a constant value is provided instead of a function, that value is used for all elements.
 * @param length - The number of elements to include in the resulting array.
 * @returns An array of the specified length, where each element is either the result of calling
 * the given predicate function or the given constant value.
 *
 * @example
 * many(2, 5); // returns [2, 2, 2, 2, 2]
 *
 * const values = many((i) => i + 1, 3); // returns [1, 2, 3]
 *
 * const names = many("John", 2); // returns ["John", "John"]
 */
function many<T>(predicate?: T | ((i: number) => T), length: number = MANY_DEFAULT_LENGTH): T[] {
  const res = Array.from<T>({ length });

  if (predicate === undefined) {
    return res;
  }

  if (predicate instanceof Function) {
    for (let i = 0; i < length; i += 1) {
      res[i] = predicate(i);
    }
  } else {
    for (let i = 0; i < length; i += 1) {
      res[i] = predicate;
    }
  }

  return res;
}

export default many;
