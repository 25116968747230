/**
 * Determines if the current environment is likely a mobile device.
 *
 * This is a workaround for specific scenarios where Safari on non-mobile devices fails to correctly identify itself as a desktop browser.
 * In such cases, it may lead to the mobile version of a website being displayed instead of the desktop version.
 * This function helps to detect those situations and apply necessary adjustments.
 *
 */
export default function overrideUserAgent() {
  if (
    navigator.userAgent.includes('Safari') &&
    !navigator.userAgent.includes('Mobile') &&
    'ontouchstart' in window
  ) {
    Object.defineProperty(navigator, 'userAgent', {
      get: () =>
        'Mozilla/5.0 (iPhone; CPU iPhone OS 13_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.1.1 Mobile/15E148 Safari/604.1',
    });
  }
}
