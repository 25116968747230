import React from 'react';
import { Modal } from '@biss/react-horizon-web';
import { FormattedMessage } from 'react-intl';

import useDeleteProcessRecord from '../../../common/hooks/use-delete-process-record';

import { ConfirmDeleteProps } from './confirm-delete.definitions';

function ConfirmDelete({
  record,
  trigger,
  defaultOpen,
  onOpenChange,
  onConfirm,
}: ConfirmDeleteProps) {
  const [open, setOpen] = React.useState(defaultOpen);
  const { mutate: deleteRecord, isPending } = useDeleteProcessRecord(() => setOpen(false));

  const handleDelete = () => {
    if (record) {
      deleteRecord(record.processRecordId);
      onConfirm();
    }
  };

  return (
    <Modal
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={(isOpen: boolean) => {
        setOpen(isOpen);
        onOpenChange?.(isOpen);
      }}
      trigger={trigger}
      title={
        <FormattedMessage
          description="Process Record Delete Modal: title for delete modal"
          defaultMessage="Confirm Deletion"
          id="/xgmUb"
        />
      }
    >
      <Modal.Content>
        <FormattedMessage
          description="Process Record Delete Modal: description for delete modal"
          defaultMessage={`The process record with the name "{recordName}" and all its associated information will be deleted from BioNsight cloud. This action cannot be undone. Are you sure you want to delete this process record?`}
          values={{
            recordName: record?.displayName,
          }}
          id="MYbJhX"
        />
      </Modal.Content>
      <Modal.ButtonGroup>
        <Modal.Close asChild role="button">
          <Modal.Button disabled={isPending}>
            <FormattedMessage
              description="Process Record Cancel Button: label for cancel button"
              defaultMessage="Cancel"
              id="ZQ4iVM"
            />
          </Modal.Button>
        </Modal.Close>
        <Modal.Button
          disabled={isPending}
          variant="destructive"
          onClick={handleDelete}
          data-testid="confirmDelete"
        >
          <FormattedMessage
            description="Process Record Delete Button: label for delete button"
            defaultMessage="Delete"
            id="htFCGu"
          />
        </Modal.Button>
      </Modal.ButtonGroup>
    </Modal>
  );
}

export default ConfirmDelete;
