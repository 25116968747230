import React, { useEffect, useState } from 'react';
import { Button, Input } from '@biss/react-horizon-web';
import { FormattedMessage, useIntl } from 'react-intl';

import { UploadFormProps } from './upload-form.definitions';

function UploadForm({
  isLoading,
  displayName,
  onDisplayNameChange,
  file,
  onFileChange,
  onUploadClick,
}: UploadFormProps) {
  const [fileInputKey, setFileInputKey] = useState(0);
  const intl = useIntl();
  const isDisplayNameDirty = displayName !== null;
  const isFileDirty = file !== null;

  const isDisplayNameValid = displayName && displayName.trim().length !== 0;
  const isFileValid = file !== null && file !== undefined;

  // recreate the file input element when the form is reset
  // so the internal selected file disappears
  useEffect(() => {
    if (file === null) {
      setFileInputKey((prev) => prev + 1);
    }
  }, [file]);

  return (
    <div className="p-8">
      <div data-testid="displayName">
        <Input
          label={intl.formatMessage({
            defaultMessage: 'Display name',
            id: 'eINP6K',
            description: 'File upload container: Label for the DisplayName input.',
          })}
          labelPlacement="left"
          disabled={isLoading}
          id="displayNameInput"
          type="text"
          value={displayName ?? ''}
          onChange={(e) => onDisplayNameChange(e.currentTarget)}
          onBlur={(e) => onDisplayNameChange(e.currentTarget)}
          error={
            isDisplayNameDirty && !isDisplayNameValid
              ? intl.formatMessage({
                  defaultMessage: 'Display Name Missing.',
                  id: 'dOz9eQ',
                  description:
                    'File upload container - displayname input: Message on error during form validation - display name not provided',
                })
              : ''
          }
        />
      </div>
      <div data-testid="file">
        <label htmlFor="fileInput">
          <FormattedMessage
            description="File upload container: Label for the File Input"
            defaultMessage="Export file"
            id="ZylUy4"
          />
          <input
            disabled={isLoading}
            id="fileInput"
            className="mb-2 ml-1 mr-1 mt-2 p-1"
            type="file"
            key={fileInputKey}
            onChange={(e) => onFileChange(e.target)}
            onBlur={(e) => onFileChange(e.target)}
          />
        </label>
        <span className="text-red">
          {isFileDirty && !isFileValid && (
            <FormattedMessage
              description="File upload container - file input: Message on error during form validation - file not provided"
              defaultMessage="File missing."
              id="vroW2u"
            />
          )}
        </span>
      </div>
      <div className="mt-2">
        <Button
          kind="primary"
          onClick={() => onUploadClick()}
          disabled={isLoading || !isDisplayNameValid || !isFileValid}
          data-testid="uploadButton"
        >
          <FormattedMessage
            description="File upload container: Submit Button label"
            defaultMessage="Upload"
            id="ZEDD5r"
          />
        </Button>
      </div>
    </div>
  );
}

export default UploadForm;
