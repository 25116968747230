export enum HttpStatus {
  Undefined = 0,
  Ok = 200,
  Created = 201,
  NoContent = 204,
  BadRequest = 400,
  Unauthorized = 401,
  PaymentRequired = 402,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  ServerError = 500,
  NotImplemented = 501,
  BadGateway = 502,
  ServiceUnavailable = 503,
  GatewayTimeout = 504,
}

export enum HttpMethods {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE',
}

export const INFO_RANGE = 100;
export const SUCCESS_RANGE = 200;
export const REDIRECT_RANGE = 300;
export const CLIENT_ERROR_RANGE = 400;
export const SERVER_ERROR_RANGE = 500;

export const isSuccessStatus = (status: number | HttpStatus) =>
  status < REDIRECT_RANGE && status >= SUCCESS_RANGE;
