import React, { useState } from 'react';
import { Tile, List } from '@biss/react-horizon-web';
import { FormattedDate, FormattedTime, useIntl } from 'react-intl';

import SearchInput from '../search-input';
import LoadingIndicator from '../loading-indicator';

import { EventListProps } from './event-list.definitions';
import { seekEvent, formatTitles, iconMap } from './event-list.helpers';
import EventListMessage from './event-list-message';

function EventList({ events, isLoading, isError, footer }: EventListProps) {
  const intl = useIntl();
  const tableTitles = formatTitles(intl);
  const [query, setQuery] = useState<string | undefined>(undefined);

  const eventList = query ? events?.filter((event) => seekEvent(query, event, intl)) : events;
  const isEventList = events && eventList && eventList.length > 0;

  return (
    <Tile data-testid="EventList">
      <div className="overflow-hidden rounded-lg">
        <List>
          <List.Header>
            <div className="flex flex-col justify-between gap-2 pt-2 md:flex-row">
              <Tile.Title
                title={intl.formatMessage({
                  defaultMessage: 'DASware control Events',
                  id: '86Kvfk',
                  description: 'DASware control Events title',
                })}
              />
              <SearchInput
                autoComplete="events"
                name="Events search"
                disabled={isLoading || isError}
                onDebouncedChange={setQuery}
                onClearClick={() => setQuery(undefined)}
                data-testid="event-list-search"
              />
            </div>
            <div className="mt-2 hidden w-full gap-1 pr-4 md:grid md:grid-cols-[1.5rem,8rem,2fr,1fr,1fr,1fr] md:gap-4">
              {Object.values(tableTitles).map((title) => (
                <span key={title} className="text-left font-bold">
                  {title}
                </span>
              ))}
            </div>
          </List.Header>

          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <EventListMessage
              isError={isError}
              isEvents={Boolean(events?.length)}
              isEventList={isEventList}
            />
          )}

          {isEventList && (
            <div className="relative max-h-[40rem] overflow-y-auto overflow-x-hidden">
              <List.Items className="overflow-hidden">
                {eventList.map(
                  ({
                    id,
                    level,
                    timestamp,
                    message,
                    logicalReferenceComment,
                    physicalReferenceComment,
                    actorName,
                  }) => (
                    <List.Item key={id} select="single" lining="zebra">
                      <div
                        className="relative grid w-full gap-1 md:grid-cols-[1.5rem,8rem,2fr,1fr,1fr,1fr] md:gap-4"
                        data-testid="event-list-item"
                      >
                        <span className="absolute right-0 top-0 h-6 w-6 break-words break-all md:relative">
                          {iconMap[level]}
                        </span>
                        <span className="break-words break-all">
                          <FormattedDate
                            value={timestamp}
                            year="numeric"
                            month="short"
                            day="numeric"
                          />
                          <br />
                          <span className="text-secondary">
                            <FormattedTime
                              value={timestamp}
                              hour="numeric"
                              minute="numeric"
                              second="numeric"
                            />
                          </span>
                        </span>
                        {message && (
                          <span className="font-bold md:hidden">
                            {Object.values(tableTitles)[2]}
                          </span>
                        )}
                        <span
                          className="break-words break-all"
                          data-testid="event-list-item-description"
                        >
                          {message}
                        </span>
                        {logicalReferenceComment && (
                          <span className="font-bold md:hidden">
                            {Object.values(tableTitles)[3]}
                          </span>
                        )}
                        <span className="break-words break-all">{logicalReferenceComment}</span>
                        {physicalReferenceComment && (
                          <span className="font-bold md:hidden">
                            {Object.values(tableTitles)[4]}
                          </span>
                        )}
                        <span className="break-words break-all">{physicalReferenceComment}</span>
                        {actorName && (
                          <span className="font-bold md:hidden">
                            {Object.values(tableTitles)[5]}
                          </span>
                        )}
                        <span className="break-words break-all">{actorName}</span>
                      </div>
                    </List.Item>
                  ),
                )}
              </List.Items>
            </div>
          )}
          {footer && (
            <div className="border-t  border-light bg-white p-4" data-testid="event-list-footer">
              {footer}
            </div>
          )}
        </List>
      </div>
    </Tile>
  );
}

export default EventList;
