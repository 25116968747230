import { createCheckers } from 'ts-interface-checker';

import { Invitation } from '../../member/invitation';

import { isValidIsoTimestamp } from '../../../../../shared/common/types/timestamp/dto/validators';

import exportedTypeSuite from './organization-validators';

export interface OrganizationDTO {
  organizationId: string;
  organizationName: string;
  administratorDisplayName?: string;
  administratorEmail: string;
  invitation: Invitation;
  deactivatedAt: string;
  active: boolean;
}
function timestampsAreValid(dto: OrganizationDTO): boolean {
  return dto.deactivatedAt ? isValidIsoTimestamp(dto.deactivatedAt) : true;
}
export function isOrganizationDTO(dto: unknown): asserts dto is OrganizationDTO {
  const { OrganizationDTO: organizationChecker } = createCheckers(exportedTypeSuite);

  // throws an error when object is not in the correct format
  organizationChecker.check(dto);
  if (!timestampsAreValid(dto as OrganizationDTO)) {
    throw new Error('Received invalid timestamp format');
  }
}
