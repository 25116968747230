import { DataTrackItem } from './data-track-list.definitions';

export default function filterItems(dataTracks: DataTrackItem[], searchParam: string) {
  const dataTracksFiltered =
    searchParam.length > 0
      ? dataTracks.filter((type) => {
          // search filters data tracks as they appear in the list [dataTrackType] ([engineeringUnit])
          const dataTrack = `${type.dataTrackType.toLowerCase()}${
            type.engineeringUnit && ` (${type.engineeringUnit.toLowerCase()})`
          }`;
          return dataTrack.includes(searchParam.toLowerCase());
        })
      : dataTracks;

  return dataTracksFiltered.sort((a, b) => a.dataTrackType.localeCompare(b.dataTrackType));
}
