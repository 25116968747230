import { UseQueryResult, UseQueryOptions, useQueries } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { DataTrack } from '../../../../shared/common/types/setup';
import useClient from '../../../../shared/common/hooks/use-client';

function useDataTracks(
  setupId: string,
  dataTrackIds: string[],
): UseQueryResult<DataTrack, Error>[] {
  const { acquireAccessToken } = useAuthentication();
  const { pmsClient } = useClient();

  return useQueries({
    queries: dataTrackIds.map<UseQueryOptions<DataTrack, Error>>((dataTrackId) => ({
      queryKey: [QKey.SETUPS, setupId, dataTrackId],
      queryFn: async () => {
        pmsClient.authorize(await acquireAccessToken());
        return pmsClient.getSetupDataTrack(setupId, dataTrackId);
      },
      refetchOnWindowFocus: false,
      retry: false,
    })),
  });
}

export default useDataTracks;
