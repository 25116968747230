import React, { useState } from 'react';

import { useEditProcessRecordAttributes } from '../../../common/hooks';
import { attributeValueSchema } from '../../process-record-header-list/process-record-header/attribute-editor/attribute-editor.validation';
import Pill from '../../../../shared/components/pill';

import { PRODUCT_KEY, ProductMappingProps } from './product-mapping.definitions';

function ProductMapping({ attributes, processRecordId, disabled = false }: ProductMappingProps) {
  const { mutate, isPending } = useEditProcessRecordAttributes({ processRecordId });

  // product key value pair in the process records attributes
  // accessing the product attributes key and value should be done through this variable as it is case insensitive
  const productPair = Object.entries(attributes).find(
    ([key]) => key.toLowerCase() === PRODUCT_KEY.toLocaleLowerCase(),
  );

  // used when adding the attribute in the transfer area
  const [text, setText] = useState<string | undefined>(productPair?.[1]);

  // validate and format product string and add it to the attributes list
  const handleAddProduct = () => {
    const validatedValue = attributeValueSchema.safeParse(text);
    if (!validatedValue.success) {
      return;
    }

    mutate(
      { ...attributes, [PRODUCT_KEY]: validatedValue.data },
      { onSuccess: () => setText(undefined) },
    );
  };

  return (
    <div className="flex flex-row items-center gap-2">
      {productPair /** if  product is in attributes disable editing */ ? (
        <Pill
          key="readonly"
          isEditable={false}
          isRemovable={false}
          name={PRODUCT_KEY}
          value={productPair[1]}
          nameInputLabel="attribute-name"
          valueInputLabel="attribute-value"
        />
      ) : (
        <Pill
          key="editable"
          isEditable="value"
          isRemovable={false}
          focusOnEdit="value"
          name={PRODUCT_KEY}
          value={text}
          disabled={isPending || disabled}
          isError={attributeValueSchema.safeParse(text).success === false}
          isWarning
          onEditClick={() => setText('')}
          isBeingEdited={text !== undefined}
          onValueChange={setText}
          onConfirmClick={handleAddProduct}
          onCancelClick={() => setText(undefined)}
          nameInputLabel="attribute-name"
          valueInputLabel="attribute-value"
        />
      )}
    </div>
  );
}

export default ProductMapping;
