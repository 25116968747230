/**
 * This module was automatically generated by `ts-interface-builder`
 */
import { ITypeSuite, array, iface, lit, opt, union } from 'ts-interface-checker';
// tslint:disable:object-literal-key-quotes

export const AccountInfo = iface([], {
  userName: 'string',
  name: 'string',
  roles: 'Roles',
});

export const EppendorfRole = union(lit('EppendorfAdministrator'), lit('EppendorfService'));

export const MemberRole = union(lit('Member'), lit('Administrator'));

export const MemberRoles = array('MemberRole');

export const EppendorfRoles = array('EppendorfRole');

export const Roles = union(array('MemberRole'), array('EppendorfRole'));

export const InvitationDTO = iface([], {
  invitationId: 'string',
  invitedAt: 'string',
  validUntil: 'string',
  mfaFactor: opt('string'),
});

export const MemberDTO = iface([], {
  memberId: 'string',
  memberDisplayName: 'string',
  memberEmail: 'string',
  memberRoles: 'Roles',
  invitation: opt('InvitationDTO'),
  mfaFactor: opt('string'),
});

const exportedTypeSuite: ITypeSuite = {
  AccountInfo,
  EppendorfRole,
  MemberRole,
  MemberRoles,
  EppendorfRoles,
  Roles,
  InvitationDTO,
  MemberDTO,
};
export default exportedTypeSuite;
