import { useQuery } from '@tanstack/react-query';

import QKey from '../keys';

import { fetchProjectConfiguration } from './use-project-configuration.helpers';

function useProjectConfiguration() {
  return useQuery({
    queryKey: [QKey.CONFIG],
    queryFn: async () => fetchProjectConfiguration(),
    staleTime: 300_000, // 5min
  });
}

export default useProjectConfiguration;
