import { palettes } from '@biss/react-horizon-web';

import { GraphColorKeys } from '../../common/types/graph-colors';
import { MILLISECONDS_IN_HOUR, filterColors, getGraphColors } from '../../common/helpers';
import { DataTrackType, StructuredSetupDescriptor } from '../../../shared/common/types/setup';

import { SeriesName } from '../../../shared/components/time-series-chart';

import {
  DEFAULT_COLOR_KEY,
  DEFAULT_DATA_TRACK_TYPES,
  FilterDataTrack,
} from './setup-overview.definitions';
import { MONITORING_TIME_SPAN } from './setup-overview-workflow/setup-overview-unit/setup-overview-unit.definitions';
import { YAxisRange } from './setup-overview-y-axis/y-axis-range-controls/y-axis-range-controls.validation';

export function createFilterDataTrack(index: number, isSelected = true): FilterDataTrack {
  return {
    color: Object.keys(palettes.categorical_unique)[index],
    selected: isSelected,
  };
}

export function getSelectedDataTracks(filterDataTracks: Record<DataTrackType, FilterDataTrack>) {
  return Object.keys(filterDataTracks).reduce(
    (acc: Record<DataTrackType, string>, type: DataTrackType) => {
      if (filterDataTracks[type].selected) {
        acc[type] = filterDataTracks[type].color;
      }
      return acc;
    },
    {},
  );
}

export function getDataTrackFilter(
  dataTracks: Record<DataTrackType, boolean>,
): Record<DataTrackType, FilterDataTrack> {
  if (!Object.keys(dataTracks).length) {
    return DEFAULT_DATA_TRACK_TYPES.reduce(
      (acc: Record<DataTrackType, FilterDataTrack>, type: DataTrackType, index: number) => {
        acc[type] = createFilterDataTrack(index);
        return acc;
      },
      {},
    );
  }
  return Object.keys(dataTracks).reduce(
    (acc: Record<DataTrackType, FilterDataTrack>, type: DataTrackType, index: number) => {
      acc[type] = createFilterDataTrack(index, dataTracks[type]);
      return acc;
    },
    {},
  );
}

export const updateFilterDataTracks = (
  dataTrackTypes: DataTrackType[],
  filterDataTracks: Record<DataTrackType, FilterDataTrack>,
) =>
  dataTrackTypes.reduce(
    (acc: Record<DataTrackType, FilterDataTrack>, type: DataTrackType, index: number) => {
      if (filterDataTracks[type]) {
        acc[type] = filterDataTracks[type];
      } else {
        acc[type] = {
          color: Object.keys(palettes.categorical_unique)[index],
          selected: false,
        };
      }
      return acc;
    },
    {},
  );

export function getDataTrackColor(colorKey: string, color = DEFAULT_COLOR_KEY) {
  const graphColors = palettes.categorical_unique;
  return graphColors[colorKey as keyof typeof graphColors][color as GraphColorKeys];
}

export function sortUnits(units: StructuredSetupDescriptor[]): StructuredSetupDescriptor[] {
  units.sort((a, b) =>
    a.unit.localeCompare(b.unit, undefined, { numeric: true, sensitivity: 'base' }),
  );

  return units;
}

export function sortWorkflows(
  workflows: Array<StructuredSetupDescriptor[]>,
): Array<StructuredSetupDescriptor[]> {
  workflows.sort((a, b) => a[0].title.localeCompare(b[0].title));
  workflows.sort((a, b) => a[0].deviceName.localeCompare(b[0].deviceName));
  return workflows;
}

export function getWorkflows(
  setups?: StructuredSetupDescriptor[],
): Array<StructuredSetupDescriptor[]> {
  if (!setups) {
    return [];
  }
  const workflows = setups.reduce(
    (acc: { [key: string]: StructuredSetupDescriptor[] }, current: StructuredSetupDescriptor) => {
      if (!acc[current.workflowId]) {
        acc[current.workflowId] = [];
      }
      acc[current.workflowId].push(current);
      return acc;
    },
    {},
  );

  const workflowsWithSortedUnits = Object.values(workflows).map((item) => sortUnits(item));

  return sortWorkflows(workflowsWithSortedUnits);
}

export function getUniqueColorName(selectedDataTracks: Record<DataTrackType, FilterDataTrack>) {
  const colors = getGraphColors();
  const dataTrackColors = Object.values(selectedDataTracks).map((item) => item.color);

  const availableColors = filterColors(colors, dataTrackColors);
  return availableColors.length
    ? availableColors[0]
    : colors[dataTrackColors.length % colors.length];
}

export const updateSelectedDataTracks = (
  dataTrackTypes: DataTrackType[],
  filterDataTracks: Record<DataTrackType, FilterDataTrack>,
) =>
  dataTrackTypes.reduce((acc: Record<DataTrackType, FilterDataTrack>, type: DataTrackType) => {
    if (filterDataTracks[type]) {
      acc[type] = filterDataTracks[type];
    } else {
      acc[type] = {
        color: getUniqueColorName(acc),
        selected: true,
      };
    }
    return acc;
  }, {});

export const getDataTracksStorage = (filterDataTracks: Record<DataTrackType, FilterDataTrack>) =>
  Object.keys(filterDataTracks).reduce(
    (acc: Record<DataTrackType, boolean>, trackType: DataTrackType) => {
      acc[trackType] = filterDataTracks[trackType].selected;

      return acc;
    },
    {},
  );

export function isMoreThanDay(timestamp: Date) {
  const millisecondsDiff = new Date().getTime() - timestamp.getTime();
  const hoursDiff = Math.round(millisecondsDiff / MILLISECONDS_IN_HOUR);
  return hoursDiff > MONITORING_TIME_SPAN;
}

export function updateYAxisRange(
  yAxisRanges: Record<SeriesName, YAxisRange>,
  range: YAxisRange,
  seriesType: SeriesName,
) {
  const newYAxisRanges = { ...yAxisRanges };
  newYAxisRanges[seriesType] = range;
  return newYAxisRanges;
}

export function deleteYAxisRange(
  yAxisRanges: Record<SeriesName, YAxisRange>,
  seriesType: SeriesName,
) {
  const newMap = { ...yAxisRanges };
  delete newMap[seriesType];
  return { ...newMap };
}
