import { DataTrackCustomForm } from './data-track-create-modal.definitions';
import { validate } from './metadata-form/metadata-form.validation';

export function filterDataTracks(dataTracks: DataTrackCustomForm[]) {
  return dataTracks.filter((item) => item.dataTrackType.trim() || item.engineeringUnit?.trim());
}

export function getIsValid(
  dataTracks: DataTrackCustomForm[],
  dataTrackTypes: string[],
  localTypes: string[],
): boolean {
  const dataTracksFiltered = filterDataTracks(dataTracks);
  return (
    !!dataTracksFiltered.length &&
    dataTracksFiltered.every((track) => validate(track, localTypes, dataTrackTypes).isValid)
  );
}
