import { IntlShape } from 'react-intl';

import { ChartXAxisTimeStampFormat } from './chart-formatters.definitions';

export const accumulativeFormatter = (timeSpan: number) => {
  const absoluteTimeSpan = Math.abs(timeSpan);
  const isNegative = absoluteTimeSpan > timeSpan;

  const totalSeconds = Math.floor(absoluteTimeSpan / 1000);
  const totalMinutes = Math.floor(totalSeconds / 60);
  const totalHours = Math.floor(totalMinutes / 60);
  const days = Math.floor(totalHours / 24);
  const hours = totalHours % 24;
  const minutes = totalMinutes % 60;
  const seconds = totalSeconds % 60;

  return {
    days,
    hours,
    totalHours,
    minutes,
    totalMinutes,
    seconds,
    totalSeconds,
    isNegative,
  };
};

/**
 * Constructs a callback to format UNIX timestamps in a human readable format.
 * The timestamps get formatted depending on the users locale.
 *
 * @example
 * "29 Oct 2024 16:00"     // locale: de_DE
 * "29 Oct 2024 4:00 P.M." // locale: en_US
 */
export const createXAxisTimeStampDateFormatter = (intl: IntlShape) => (timeStamp: number) =>
  intl.formatDate(timeStamp, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h24',
  });

/**
 * A formatter function to format timestamps in the format of `D# HHh`.
 *
 * @example
 * "8d 16h"
 * "-8d 16h"
 */
export const xAxisTimeStampAccumulativeDayFormatter = (timeSpan: number) => {
  const { isNegative, days, hours } = accumulativeFormatter(timeSpan);
  return `${isNegative ? '-' : ''}${days}d ${hours.toString().padStart(2, '0')}h`;
};

/**
 * A formatter function to format timestamps in the format of `D#`.
 *
 * @example
 * "8"
 */
export const xAxisTimeStampStaticAccumulativeDayFormatter = (timeSpan: number) => {
  const { isNegative, days } = accumulativeFormatter(timeSpan);
  return `${isNegative ? '-' : ''}${days}`;
};

/**
 * A formatter function to format timestamps in the format of `H#h MMm`.
 *
 * @example
 * "16h 35m"
 * "130h 10m"
 * "-130h 10m"
 */
export const xAxisTimeStampAccumulativeHourFormatter = (timeSpan: number) => {
  const { isNegative, totalHours, minutes } = accumulativeFormatter(timeSpan);
  return `${isNegative ? '-' : ''}${totalHours}h ${minutes.toString().padStart(2, '0')}m`;
};

/**
 * A formatter function to format timestamps in the format of `H#`.
 *
 * @example
 * "16"
 * "130"
 */
export const xAxisTimeStampStaticAccumulativeHourFormatter = (timeSpan: number) => {
  const { isNegative, totalHours } = accumulativeFormatter(timeSpan);
  return `${isNegative ? '-' : ''}${totalHours}`;
};

/**
 * A formatter function to format timestamps in the format of `D# HH MM`.
 * Insignificant Segments are excluded.
 *
 * @example
 * "16d 23h 30m 15s"
 * "16d 23h 30m"
 * "130d 12h"
 * "130d"
 */
export const xAxisTimeStampAccumulativeFormatter = (timeSpan: number) => {
  const { isNegative, days, hours, minutes, seconds } = accumulativeFormatter(timeSpan);

  const negativeSegment = isNegative ? '-' : '';
  const daysSegment = `${days}d`;
  const hoursSegment = `${hours.toString().padStart(2, '0')}h`;
  const minutesSegment = `${minutes.toString().padStart(2, '0')}m`;
  const secondsSegment = `${seconds.toString().padStart(2, '0')}s`;

  const maybeSeconds = secondsSegment === '00s' ? '' : ` ${secondsSegment}`;
  const maybeMinutes = minutesSegment === '00m' ? '' : ` ${minutesSegment}`;
  const maybeHours = hoursSegment === '00h' ? '' : ` ${hoursSegment}`;

  return `${negativeSegment}${daysSegment}${maybeHours}${maybeMinutes}${maybeSeconds}`;
};

export const tooltipAccumulativeExactFormatter = (timeSpan: number) => {
  const { isNegative, days, hours, minutes, seconds } = accumulativeFormatter(timeSpan);

  const negativeSegment = isNegative ? '-' : '';
  const daysSegment = `${days}d`;
  const hoursSegment = `${hours.toString().padStart(2, '0')}h`;
  const minutesSegment = `${minutes.toString().padStart(2, '0')}m`;
  const secondsSegment = `${seconds.toString().padStart(2, '0')}s`;

  return `${negativeSegment}${daysSegment} ${hoursSegment} ${minutesSegment} ${secondsSegment}`;
};

/**
 * decides the type of the formatter depending on the users locale and selected format option
 */
export function getXAxisFormatter(timeStampFormat: ChartXAxisTimeStampFormat, intl: IntlShape) {
  if (timeStampFormat === ChartXAxisTimeStampFormat.AccumulativeDay) {
    return xAxisTimeStampAccumulativeDayFormatter;
  }

  if (timeStampFormat === ChartXAxisTimeStampFormat.StaticAccumulativeDay) {
    return xAxisTimeStampStaticAccumulativeDayFormatter;
  }

  if (timeStampFormat === ChartXAxisTimeStampFormat.AccumulativeHour) {
    return xAxisTimeStampAccumulativeHourFormatter;
  }

  if (timeStampFormat === ChartXAxisTimeStampFormat.StaticAccumulativeHour) {
    return xAxisTimeStampStaticAccumulativeHourFormatter;
  }

  if (timeStampFormat === ChartXAxisTimeStampFormat.Accumulative) {
    return xAxisTimeStampAccumulativeFormatter;
  }

  return createXAxisTimeStampDateFormatter(intl);
}
