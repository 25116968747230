import { FeatureFlag, FeatureFlagType } from './feature-flag';

abstract class ScopedFeatureFlag extends FeatureFlag {
  public abstract get type(): FeatureFlagType;
  public abstract get scopeId(): string | undefined;
  public abstract get packgeName(): string;

  public get name(): string {
    return `${this.type}/${this.scopeId}/${this.packgeName}`;
  }

  public get readyForQuery(): boolean {
    return this.scopeId !== undefined;
  }
}

export default ScopedFeatureFlag;
