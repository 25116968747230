import { DataTrackType } from '../../../shared/common/types/process-record';
import { DEFAULT_DATA_TRACK_TYPES } from '../../scenes/setup-overview/setup-overview.definitions';

export const resetHandler = (
  setSelectedItems: (value: string[]) => void,
  setIsUpdated: (value: boolean) => void,
) => {
  setSelectedItems(DEFAULT_DATA_TRACK_TYPES);
  setIsUpdated(true);
};

export const dataTrackSelectionHandler = (
  type: DataTrackType,
  selected: boolean,
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>,
  setIsUpdated: (value: boolean) => void,
) => {
  if (selected) {
    setSelectedItems((prevState) => [...prevState, type]);
  } else {
    setSelectedItems((prevState) => prevState.filter((trackType) => trackType !== type));
  }
  setIsUpdated(true);
};
