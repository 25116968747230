import React from 'react';
import { Logout, Redeem } from '@biss/react-auth-web';
import { Routes as ReactRouterRoutes, Route } from 'react-router-dom';

import PageNotFound from '../../scenes/page-not-found';
import analyticsRoutes from '../../../analytics';
import monitoringRoutes from '../../../monitoring';
import useUserManagementRoutes from '../../../user-management';
import { Title } from '../../components/title';
import generateTitle from '../../utils/generate-title';
import Base from '../../scenes/base';
import PublicBase from '../../scenes/public-base';
import Welcome from '../../scenes/welcome';

import RouteDefinition from './routes.definitions';
import publicRoutes from './public-routes';

/**
 * Configuration of the biocloud routes.
 */
function Routes() {
  const userManagementRoutes = useUserManagementRoutes();

  return (
    <ReactRouterRoutes>
      <Route element={<PublicBase />}>
        {/* has the index route */}
        {publicRoutes}
      </Route>

      <Route element={<Base />}>
        <Route
          path="welcome"
          element={
            <Title text={generateTitle('Authentication', 'Welcome')}>
              <Welcome />
            </Title>
          }
        />
      </Route>

      <Route path={RouteDefinition.Analytics} element={<Base />}>
        {analyticsRoutes}
      </Route>

      <Route path={RouteDefinition.Monitoring} element={<Base />}>
        {monitoringRoutes}
      </Route>

      {/* renders element <Base /> */}
      {userManagementRoutes}

      {/* authentication routes */}
      <Route
        path="logout"
        element={
          <Title text={generateTitle('Authentication', 'Logout')}>
            <Logout />
          </Title>
        }
      />

      <Route
        path="redeem"
        element={
          <Title text={generateTitle('Authentication', 'Redeem')}>
            <Redeem />
          </Title>
        }
      />

      {/* wildcard */}
      <Route
        path="*"
        element={
          <Title text="Page Not Found">
            <PageNotFound />
          </Title>
        }
      />
    </ReactRouterRoutes>
  );
}

export default Routes;
