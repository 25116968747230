// eslint-disable-next-line eslint-comments/disable-enable-pair -- Seems to be required to disable translations
/* eslint-disable formatjs/no-literal-string-in-jsx -- The licences are only avaialable in english */
import React from 'react';
import { Link } from 'react-router-dom';
import { Modal } from '@biss/react-horizon-web';
import { FormattedMessage } from 'react-intl';

import { IMPRINT_LINK, PRIVACY_POLICY_LINK } from './footer.definitions';

function Footer() {
  return (
    <footer className="mt-auto flex h-9 w-full items-center justify-center gap-4 text-sm font-light text-secondary">
      <Modal
        size="sm"
        title={
          <FormattedMessage
            description="About: Title"
            defaultMessage="About BioNsight cloud"
            id="LdThIb"
          />
        }
        subtitle={
          <FormattedMessage
            description="Footer: Copyright"
            // eslint-disable-next-line formatjs/no-emoji -- Not an emoji, but a Copyright char
            defaultMessage="© 2024 Eppendorf SE"
            id="bTWXpO"
          />
        }
        trigger={
          // eslint-disable-next-line jsx-a11y/anchor-is-valid -- Should be a link, not a button
          <Link to="#">
            <FormattedMessage
              description="Footer: About"
              defaultMessage="About BioNsight cloud"
              id="BpbaeD"
            />
          </Link>
        }
        defaultOpen={false}
      >
        <Modal.Content>
          <h1 className="font-bold">
            <FormattedMessage description="About: Support" defaultMessage="Support" id="0O6OCf" />
          </h1>
          <div className="text-sm">
            <FormattedMessage
              description="About: Support description"
              defaultMessage="If you require support to use BioNsight cloud, please contact your local sales representative or service contact, or consult the "
              id="sRtU43"
            />
            <a
              href="https://www.eppendorf.com/us-en/service-support/services-by-product-groups/bioprocess-service/"
              target="_blank"
              rel="noreferrer"
              className="text-blue"
            >
              <FormattedMessage
                description="About: 'Bioprocess Service Page' name"
                defaultMessage="Bioprocess Service Page"
                id="GN3wyE"
              />
            </a>
            .
          </div>
          <h1 className="mt-4 font-bold">
            <FormattedMessage
              description="About: Open Source License File title"
              defaultMessage="Open Source Licenses"
              id="tKiuzw"
            />
          </h1>
          <iframe
            src="/licenses.html"
            title="Licenses"
            className="h-60 overflow-y-scroll"
            width="100%"
          />
        </Modal.Content>
      </Modal>

      <a href={PRIVACY_POLICY_LINK} className="ml-1" target="_blank" rel="noreferrer">
        <FormattedMessage
          description="Footer: Privacy Policy"
          defaultMessage="Privacy Policy"
          id="C+zMyf"
        />
      </a>

      <button onClick={() => window.OneTrust?.ToggleInfoDisplay()} type="button">
        <FormattedMessage
          description="Footer: Cookie Settings"
          defaultMessage="Cookie Settings"
          id="tL+YYG"
        />
      </button>

      <Link to={IMPRINT_LINK}>
        <FormattedMessage description="Footer: Imprint" defaultMessage="Imprint" id="C1MyRV" />
      </Link>
      <FormattedMessage
        description="Footer: Copyright"
        // eslint-disable-next-line formatjs/no-emoji -- Not an emoji, but a Copyright char
        defaultMessage="© 2024 Eppendorf SE"
        id="bTWXpO"
      />
    </footer>
  );
}

export default Footer;
