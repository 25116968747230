// eslint-disable-next-line import/prefer-default-export -- definitions file
export const DEFAULT_DWC_ATTRIBUTES = [
  'Acid',
  'Base',
  'Nutrients A',
  'Nutrients C',
  'Nutrients B',
  'Nutrients D',
  'Medium',
  'Product',
  'CellLine',
  'Purpose',
];
