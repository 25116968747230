import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { Organization } from '../../types';

import { fetchOrganization } from './use-organization.helpers';

function useOrganization(organizationId: string): UseQueryResult<Organization, Error> {
  const { acquireAccessToken } = useAuthentication();

  return useQuery({
    queryKey: [QKey.ORGANIZATIONS, organizationId],
    queryFn: async () => {
      const accessToken = await acquireAccessToken();
      return fetchOrganization(organizationId, accessToken);
    },
  });
}

export default useOrganization;
