import { useContext } from 'react';

import { InsightsGlobals } from '../../../globals/insights';

function useAppInsights() {
  const globals = useContext(InsightsGlobals);

  if (globals === undefined) {
    throw new Error(
      '[globals] was undefined, make sure useAppInsights is used inside <InsightsGlobals.Provider />',
    );
  }

  return globals;
}

export default useAppInsights;
