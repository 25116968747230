import { useContext } from 'react';

import ClientsGlobals from '../../../globals/clients/clients-globals/clients-globals';

export default function useClient() {
  const res = useContext(ClientsGlobals);

  if (res === undefined) {
    throw new Error('useClient should be used within the <Clients /> provider.');
  }

  return res;
}
