import {
  ProcessRecordCreationDto,
  processRecordCreationDtoSchema,
} from './create-process-record-via-form.definitions';

export default function getInitialProcessRecordDto(): ProcessRecordCreationDto {
  const now = new Date();
  now.setSeconds(0);
  now.setMilliseconds(0);

  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1); // will move to the previous month when calculated in the first day of the month

  return {
    displayName: '',

    deviceName: '',
    unit: '',

    startTimestamp: yesterday,
    stopTimestamp: now,

    operator: '',
    comment: '',
  };
}

export function validateProcessRecordCreationDto(dto: ProcessRecordCreationDto) {
  return processRecordCreationDtoSchema.safeParse(dto);
}
