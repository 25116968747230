import { ChangeEvent, FocusEvent } from 'react';
import { EppendorfRole, MemberRole, Roles } from '@biss/react-auth-web';

import { Member } from '../../common/types';

export default interface MemberGeneralInfoProps {
  roles: Roles;
  member?: Member;
  onPropertyChanged?: (event: ChangeEvent<HTMLInputElement>) => void;
  onValueEntered?: (event: FocusEvent<HTMLInputElement>) => void;
  onRolesChanged: (name: MemberRole | EppendorfRole, checked: boolean) => void;
  onMfaChanged: (checked: boolean) => void;
  defaultMfaValue?: boolean;
  errors: Map<string, string>;
}

export const ROLE_NAME_MAP = new Map([
  ['EppendorfAdministrator', 'Eppendorf Administrator'],
  ['Administrator', 'Administrator'],
  ['Member', 'Data Access'],
]);
