import React, { useState, FunctionComponentElement, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from '@biss/react-horizon-web';

import { ProcessRecordId } from '../../../shared/common/types/process-record';
import ProcessRecords from '../process-records/process-records';

import useLogger from '../../../shared/common/hooks/use-logger/use-logger';
import TrackedEvent from '../../../shared/common/tracked-event';

import { ProcessRecordModalProps } from './process-record-modal.definitions';

function ProcessRecordModal({
  defaultOpen,
  trigger,
  onSelect,
}: ProcessRecordModalProps): FunctionComponentElement<ProcessRecordModalProps> {
  const logger = useLogger();
  const [open, setOpen] = useState(defaultOpen);
  const [selectedRecordId, setSelectedProcessId] = React.useState<ProcessRecordId | null>(null);
  const setProcessRecordSelected = useCallback(
    (processRecordId: ProcessRecordId) => {
      if (selectedRecordId === processRecordId) {
        setSelectedProcessId(null);
      } else {
        setSelectedProcessId(processRecordId);
      }
    },
    [setSelectedProcessId, selectedRecordId],
  );

  const handleOnClick = useCallback(() => {
    if (selectedRecordId) {
      onSelect(selectedRecordId);

      logger.trackEvent(TrackedEvent.ConfirmSelectingReferenceCurve);
    }
    setOpen(!open);
  }, [setOpen, selectedRecordId]);

  const onChange = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  return (
    <Modal
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={onChange}
      size="lg"
      trigger={trigger}
      title={
        <FormattedMessage
          description="Select a Process Record for Reference"
          defaultMessage="Select a Process Record for Reference"
          id="Wp8w2d"
        />
      }
    >
      {open && (
        <div className="relative h-[60vh] overflow-y-auto border-b">
          <ProcessRecords onSelect={setProcessRecordSelected} selectedRecordId={selectedRecordId} />
        </div>
      )}
      <Modal.ButtonGroup>
        <Modal.Close asChild role="button">
          <Modal.Button data-testid="reference-curve-cancel-button">
            <FormattedMessage
              description="Process Record List cancel button"
              defaultMessage="Cancel"
              id="DQS97k"
            />
          </Modal.Button>
        </Modal.Close>
        <Modal.Button
          variant="positive"
          onClick={handleOnClick}
          disabled={!selectedRecordId}
          data-testid="reference-curve-confirm-button"
        >
          <FormattedMessage
            description="Process Record List confirm button"
            defaultMessage="Confirm"
            id="bipJ5h"
          />
        </Modal.Button>
      </Modal.ButtonGroup>
    </Modal>
  );
}

export default ProcessRecordModal;
