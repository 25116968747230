import React from 'react';

import { ProcessRecordHeaderListProps } from './process-record-header-list.definitions';
import ProcessRecordHeader, { ProcessRecordHeaderSkeleton } from './process-record-header';
import getGridColumnCssClasses from './process-record-header-list.helpers';

/**
 * Composes multiple process record headers as a list.
 */
function ProcessRecordHeaderList({
  headers,
  totalNumberOfHeaders = 1,
}: ProcessRecordHeaderListProps) {
  const gridColumnStyle = getGridColumnCssClasses(headers.length);

  // array of headers and header placeholders (`undefined`)
  const maybeHeaders = [
    ...headers,
    ...Array.from<undefined>({ length: totalNumberOfHeaders - headers.length }),
  ];

  return (
    <div className={`grid w-full gap-4 rounded ${gridColumnStyle}`}>
      {maybeHeaders.map((header, index) => (
        <div
          key={header?.processRecordId ?? index}
          className="flex flex-col items-start justify-between"
        >
          {header ? (
            <ProcessRecordHeader
              attributes={header.attributes}
              color={header.color}
              displayName={header.displayName}
              processRecordId={header.processRecordId}
              deviceName={header.deviceName}
              title={header.title}
              unit={header.unit}
            />
          ) : (
            <ProcessRecordHeaderSkeleton />
          )}
        </div>
      ))}
    </div>
  );
}

export default ProcessRecordHeaderList;
