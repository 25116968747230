import React from 'react';
import { Skeleton } from '@biss/react-horizon-web';

function NavigationSkeleton() {
  return (
    <div className="flex flex-row gap-4 p-4" data-testid="NavigationSkeleton">
      <Skeleton variant="text" className="my-auto" width="96px" />
      <Skeleton variant="text" className="my-auto" width="96px" />
      <Skeleton variant="text" className="my-auto" width="96px" />
    </div>
  );
}

export default NavigationSkeleton;
