import React from 'react';
import { Route } from 'react-router-dom';

import Protected from '../../components/protected';
import { Title } from '../../components/title';
import generateTitle from '../../utils/generate-title';
import Imprint from '../../scenes/imprint';
import Landing from '../../scenes/landing';

const routes = (
  <>
    <Route
      index
      element={
        <Title text="BioNsight cloud">
          <Landing />
        </Title>
      }
    />

    <Route
      path="imprint"
      element={
        <Title text={generateTitle(undefined, 'Imprint')}>
          <Imprint />
        </Title>
      }
    />

    <Route
      path="product"
      element={
        <Protected>
          <Title text={generateTitle(undefined, 'Product')} />
        </Protected>
      }
    />

    <Route
      path="features"
      element={
        <Protected>
          <Title text={generateTitle(undefined, 'Features')} />
        </Protected>
      }
    />

    <Route
      path="where-to-buy"
      element={
        <Protected>
          <Title text={generateTitle(undefined, 'Where to buy')} />
        </Protected>
      }
    />

    <Route
      path="about-eppendorf"
      element={
        <Protected>
          <Title text={generateTitle(undefined, 'About eppendorf')} />
        </Protected>
      }
    />
  </>
);

export default routes;
