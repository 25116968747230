import { ProcessRecordPayload } from '../../../../shared/common/types/generated/prs.generated';
import { StructuredProcessRecordPayload } from '../../../../shared/common/types/process-record';

// eslint-disable-next-line import/prefer-default-export -- helpers file
export function prepareProcessRecordPayload(data: StructuredProcessRecordPayload) {
  // set milliseconds and seconds to 0 as they cannot be edited in the frontend
  data.startTimestamp.setMilliseconds(0);
  data.stopTimestamp.setMilliseconds(0);
  data.inoculationTimestamp?.setMilliseconds(0);

  data.startTimestamp.setSeconds(0);
  data.stopTimestamp.setSeconds(0);
  data.inoculationTimestamp?.setSeconds(0);

  const payload: ProcessRecordPayload = {
    ...data,
    startTimestamp: data.startTimestamp.toISOString(),
    stopTimestamp: data.stopTimestamp.toISOString(),
    inoculationTimestamp: data.inoculationTimestamp?.toISOString(),
  };

  return payload;
}
