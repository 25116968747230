import { AppSection, PAGE_TITLE_POSTFIX } from './generate-title.definitions';

export default function generateTitle(section?: AppSection, page?: string, info?: string) {
  const segments = [];

  if (info) {
    segments.push(info);
  }

  if (page) {
    segments.push(page);
  }

  if (section) {
    segments.push(section);
  }

  if (segments.length === 0) {
    return PAGE_TITLE_POSTFIX;
  }

  return `${segments.join(' ')} - ${PAGE_TITLE_POSTFIX}` as const;
}
