import React from 'react';
import { Select } from '@biss/react-horizon-web';

import { DataTrackTimeAlignment } from '../../scenes/process-record-detail/process-record-visualization/process-record-visualization.definitions';

import { ChartXAxisTimeStampFormat } from '../../../shared/charts/chart-formatters/chart-formatters.definitions';

import { ProcessRecordXAxisFormatOptionsProps } from './process-record-x-axis-format-options.definitions';

function ProcessRecordXAxisFormatOptions({
  alignment,
  onChange,
  value = '',
  defaultOpen = false,
}: ProcessRecordXAxisFormatOptionsProps) {
  const handleValueChange = (newValue: string) => {
    onChange(Number(newValue));
  };

  const areRelativeOptionsDisabled = alignment === DataTrackTimeAlignment.Absolute;
  const areAbsoluteOptionsDisabled = alignment !== DataTrackTimeAlignment.Absolute;

  const alignmentName = alignment.toString();

  const disabledTitle = `This option is not available when alignment is set to ${alignmentName}`;

  const options = [
    {
      value: ChartXAxisTimeStampFormat.Date.toString(),
      disabled: areAbsoluteOptionsDisabled,
      label: 'Full',
    },
    {
      value: ChartXAxisTimeStampFormat.AccumulativeDay.toString(),
      disabled: areRelativeOptionsDisabled,
      label: 'Days (DDd HHh)',
    },
    {
      value: ChartXAxisTimeStampFormat.StaticAccumulativeDay.toString(),
      disabled: areRelativeOptionsDisabled,
      label: 'Days (D#)',
    },
    {
      value: ChartXAxisTimeStampFormat.AccumulativeHour.toString(),
      disabled: areRelativeOptionsDisabled,
      label: 'Hours (HHh MMm)',
    },
    {
      value: ChartXAxisTimeStampFormat.StaticAccumulativeHour.toString(),
      disabled: areRelativeOptionsDisabled,
      label: 'Hours (H#)',
    },
  ];

  return (
    <Select
      className="!min-w-[9rem]"
      data-testid="x-axis-format-combobox"
      placeholder="Select X-Axis format option"
      onValueChange={handleValueChange}
      value={value}
      defaultOpen={defaultOpen}
      label="Timestamp Format"
    >
      {options.map((option) => (
        <Select.Item
          key={option.label}
          value={option.value}
          disabled={option.disabled}
          title={option.disabled ? disabledTitle : undefined}
        >
          {option.label}
        </Select.Item>
      ))}
    </Select>
  );
}

export default ProcessRecordXAxisFormatOptions;
