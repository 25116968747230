import { Event } from '../../../../../shared/common/types/event';

export function sortEvents(events?: Event[]): Event[] {
  if (events?.length) {
    const eventList = [...events];
    eventList.sort((x, y) => new Date(y.timestamp).getTime() - new Date(x.timestamp).getTime());
    return eventList;
  }
  return [];
}

export function addOneMillisecond(timestamp: string) {
  const timesInMiliseconds = new Date(timestamp).getTime() + 1;
  return new Date(timesInMiliseconds).toISOString();
}
