import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  useAuthContext,
  isAdministrator,
  isEppendorfAdministrator,
  isEppendorfService,
  useIsAuthenticated,
} from '@biss/react-auth-web';
import { NavigationItem } from '@biss/react-horizon-web';

import RouteDefinition from '../../../common/routes/routes.definitions';

import { NavigationProps, NavigationDefinition } from './navigation.definitions';
import NavigationSkeleton from './navigation.skeleton';

function Navigation({ onNavlinkClick }: NavigationProps) {
  const { account } = useAuthContext();
  const isAuthenticated = useIsAuthenticated();

  if (account === null || isAuthenticated === false) {
    return <NavigationSkeleton />;
  }

  const memberManagementEnabled = isAdministrator(account) || isEppendorfAdministrator(account);

  const organizationManagementEnabled = isEppendorfAdministrator(account);

  const analyticsAndMonitoringEnabled =
    isEppendorfAdministrator(account) === false && isEppendorfService(account) === false;

  const links: NavigationDefinition[] = [];

  if (analyticsAndMonitoringEnabled) {
    links.push({
      name: 'Analytics',
      to: `${RouteDefinition.Analytics}/process-records`,
    });

    links.push({
      name: 'Monitoring',
      to: `${RouteDefinition.Monitoring}/setups`,
    });
  }

  if (memberManagementEnabled) {
    links.push({
      name: 'User Management',
      to: `${RouteDefinition.UserManagment}/members`,
    });
  }

  if (organizationManagementEnabled) {
    links.push({
      name: 'Organization Management',
      to: `${RouteDefinition.UserManagment}/organizations`,
    });
  }

  return links.map(({ to, name }) => (
    <NavLink key={name} onClick={(e) => onNavlinkClick?.(e, to)} to={to}>
      {({ isActive }) => <NavigationItem isActive={isActive}>{name}</NavigationItem>}
    </NavLink>
  ));
}

export default Navigation;
