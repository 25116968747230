import { GenericError } from '../generic-error';

export interface ErrorDescriptor {
  id: string;
  defaultMessage: string;
}

export interface ValuesObject {
  [key: string]: string | number | boolean;
}

export interface ApiErrorObject {
  descriptor: ErrorDescriptor;
  message: string;
  values?: ValuesObject;
  debugInfo?: unknown;
  traceId?: string;
}

export class ApiError<
  T extends { message: string; values?: ValuesObject } = ApiErrorObject,
> extends GenericError<T> {
  status: number;

  values: ValuesObject;

  constructor(cause: T, status: number) {
    super(cause);
    this.message = cause.message;
    this.status = status;
    this.values = cause.values || {};
  }
}
