import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Button, NotificationMessage, PlusIcon } from '@biss/react-horizon-web';
import { useIntl } from 'react-intl';

import useLogger from '../../../../../shared/common/hooks/use-logger/use-logger';
import TrackedEvent from '../../../../../shared/common/tracked-event';
import Pill from '../../../../../shared/components/pill';
import { useEditProcessRecordAttributes } from '../../../../common/hooks';

import {
  AttributeEditorProps,
  DraftAttributeState,
  DUPLICATE_ATTRIBUTE_NAME_ERROR_MESSAGE,
  NEW_ATTRIBUTE_NAME,
} from './attribute-editor.definitions';
import {
  convertAttributeDraftStateToAttributes,
  convertAttributesToDraftState,
  createAttributeDraftState,
  isAttributeDuplicate,
  isNotNewAttribute,
  resetAttribute,
} from './attribute-editor.helpers';
import { attributeNameSchema, attributeValueSchema } from './attribute-editor.validation';

/**
 * Editor that allows to add, edit and delete process record attributes.
 * Adding and deleting attributes can be deactivated in case a less powerful editor is required.
 */
function AttributeEditor({ processRecordId, attributes, defaultDraftState }: AttributeEditorProps) {
  const logger = useLogger();
  const intl = useIntl();

  const { mutate, isPending, error, isError } = useEditProcessRecordAttributes({
    processRecordId,
  });

  /** draft editable attribute data, is passed down to every attribute pill */
  const [draftAttributes, setDraftAttributes] = useState<DraftAttributeState[]>(
    defaultDraftState ?? convertAttributesToDraftState(attributes),
  );

  // update draft state when an attribute was added or removed from outside of the component
  useEffect(() => {
    if (defaultDraftState === undefined) {
      setDraftAttributes(convertAttributesToDraftState(attributes));
    }
  }, [
    Object.entries(attributes)
      .map((pair) => pair.join(':'))
      .join(),
  ]);

  const isAddingNewAttribute = draftAttributes.some((attr) => attr.isNew);

  const isThereADuplicateName = draftAttributes.some((attr) =>
    isAttributeDuplicate(attr.name, draftAttributes),
  );

  const validateAttribute = (name: string, value: string) => {
    const isNameValid =
      attributeNameSchema.safeParse(name).success &&
      isAttributeDuplicate(name, draftAttributes) === false;

    const isValueValid = attributeValueSchema.safeParse(value).success;

    return isNameValid && isValueValid;
  };

  /**
   * attempts to push changes to the backend,
   * updates the visible draft UI in the meantime,
   * rolls back to {@link oldDraft} on failure.
   */
  const saveAttributes = (newDraft: DraftAttributeState[], oldDraft: DraftAttributeState[]) => {
    setDraftAttributes(newDraft);

    mutate(convertAttributeDraftStateToAttributes(newDraft), {
      onSuccess(savedAttributes) {
        setDraftAttributes(convertAttributesToDraftState(savedAttributes));
      },
      onError() {
        setDraftAttributes(oldDraft);
      },
    });
  };

  const editAttribute = () => {
    logger.trackEvent(TrackedEvent.UpdateOrDeleteAnAttribute);

    const oldAttributes = [...draftAttributes];

    const newAttributes = draftAttributes.map((pill) => ({
      ...pill,
      isBeingEdited: false,
      isBeingRemoved: false,
    }));

    saveAttributes(newAttributes, oldAttributes);
  };

  const deleteAttribute = (id: string) => {
    logger.trackEvent(TrackedEvent.UpdateOrDeleteAnAttribute);

    const oldAttributes = [...draftAttributes];

    const newAttributes = draftAttributes.filter((pill) => pill.id !== id);

    saveAttributes(newAttributes, oldAttributes);
  };

  const handleConfirmClick = (id: string, isBeingEdited: boolean, isBeingRemoved: boolean) => {
    if (isBeingEdited) {
      editAttribute();
      return;
    }

    if (isBeingRemoved) {
      deleteAttribute(id);
    }
  };

  const handleCancelClick = (id: string) => {
    setDraftAttributes((prev) =>
      prev
        .map((pill) =>
          pill.id === id
            ? // reset the state of the current attribute
              resetAttribute(pill)
            : pill,
        )
        // remove the attribute being added
        .filter(isNotNewAttribute),
    );
  };

  const handleDeleteAttribute = (id: string) => {
    setDraftAttributes((prev) =>
      prev
        .map((pill) =>
          pill.id === id
            ? { ...pill, isBeingRemoved: true }
            : // reset the state of other attributes
              resetAttribute(pill),
        )
        // remove the attribute being added
        .filter(isNotNewAttribute),
    );
  };

  const handleEditClick = (id: string) => {
    setDraftAttributes((prev) =>
      prev
        .map((pill) =>
          pill.id === id
            ? { ...pill, isBeingEdited: true }
            : // reset the state of other attributes
              resetAttribute(pill),
        )
        // remove the attribute being added
        .filter(isNotNewAttribute),
    );
  };

  const handleNameChange = (id: string, newName: string) => {
    setDraftAttributes((prev) =>
      prev.map((pill) => (pill.id === id ? { ...pill, name: newName } : pill)),
    );
  };

  const handleValueChange = (id: string, newValue: string) => {
    setDraftAttributes((prev) =>
      prev.map((pill) => (pill.id === id ? { ...pill, value: newValue } : pill)),
    );
  };

  const handleAddAttribute = () => {
    setDraftAttributes((prev) => [
      ...prev.map(resetAttribute),
      {
        ...createAttributeDraftState(),
        isNew: true,
        name: NEW_ATTRIBUTE_NAME,
        isBeingEdited: true,
      },
    ]);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row flex-wrap gap-2">
        {draftAttributes.map(({ id, name, value, isBeingEdited, isBeingRemoved, initial }) => {
          const isValid = validateAttribute(name, value);

          return (
            <Pill
              focusOnEdit={isBeingEdited ? 'name' : undefined}
              key={id}
              name={name}
              value={value}
              isBeingEdited={isBeingEdited}
              isBeingRemoved={isBeingRemoved}
              isConfirmDisabled={isValid === false}
              isError={isValid === false}
              isWarning={name !== initial.name || value !== initial.value || isBeingRemoved}
              onNameChange={(newName) => handleNameChange(id, newName)}
              onValueChange={(newValue) => handleValueChange(id, newValue)}
              onEditClick={() => handleEditClick(id)}
              onCancelClick={() => handleCancelClick(id)}
              onDeleteClick={() => handleDeleteAttribute(id)}
              onConfirmClick={() => handleConfirmClick(id, isBeingEdited, isBeingRemoved)}
              disabled={isPending}
              nameInputLabel="attribute-name"
              valueInputLabel="attribute-value"
            />
          );
        })}

        {(isPending || isAddingNewAttribute === false) && (
          <Button
            className="rounded-full"
            data-testid="add-attribute-button"
            onClick={handleAddAttribute}
            disabled={isPending}
            leftIcon={<PlusIcon />}
          />
        )}
      </div>

      {isPending && (
        <ActivityIndicator
          size="sm"
          showLabel
          label={intl.formatMessage({
            defaultMessage: 'Updating...',
            id: 'zZXRP/',
            description: 'Apply Attribute Updating Message',
          })}
        />
      )}

      {isError && error && (
        <NotificationMessage status="error">{error.message}</NotificationMessage>
      )}

      {isThereADuplicateName && (
        <NotificationMessage status="error">
          {DUPLICATE_ATTRIBUTE_NAME_ERROR_MESSAGE}
        </NotificationMessage>
      )}
    </div>
  );
}

export default AttributeEditor;
