import React, { FunctionComponentElement } from 'react';

import { ActionBarProps } from './action-bar.definitions';

/**
 * Adds a margin to the provided child elements.
 */
function ActionBar({
  children,
  textAlign = 'right',
}: ActionBarProps): FunctionComponentElement<unknown> {
  return (
    <div
      className="mb-auto flex h-[var(--actionbar-height)] flex-row items-center gap-4 bg-white px-4 text-blue"
      style={{ textAlign }}
    >
      {children}
    </div>
  );
}

export default ActionBar;
