import { z } from 'zod';

// eslint-disable-next-line import/prefer-default-export -- validator file
export const consentCookieSchema = z.object({
  groups: z.string({ description: 'example: "C0003:1,C0001:1,C0002:0"' }),
  isGpcEnabled: z.string().optional(),
  datestamp: z.string().optional(),
  version: z.string().optional(),
  browserGpcFlag: z.string().optional(),
  isIABGlobal: z.string().optional(),
  hosts: z.string().optional(),
  landingPath: z.string().optional(),
  AwaitingReconsent: z.string().optional(),
  geolocation: z.string().optional(),
});
