import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { Member } from '../../types/member/member';

import { fetchMember } from './use-member.helpers';

function useMember(memberId: string): UseQueryResult<Member, Error> {
  const { acquireAccessToken } = useAuthentication();

  return useQuery({
    queryKey: [QKey.MEMBERS, memberId],
    queryFn: async () => {
      const accessToken = await acquireAccessToken();
      return fetchMember(memberId, accessToken);
    },
  });
}

export default useMember;
