import { many } from '../../common/utils';
import { TValue } from '../editable-data-table/editable-data-table.definitions';

import { DataPoint } from './data-point-create-modal.definitions';

export function getNewDataPoints(newDataPoints: DataPoint[], dataPointsLength: number) {
  return newDataPoints.slice((newDataPoints.length, dataPointsLength));
}

export function validateNewDataPoints(newDataPoints: DataPoint[], dataPointsLength: number) {
  const newPoints = getNewDataPoints(newDataPoints, dataPointsLength);
  return Boolean(newPoints.length) && newPoints.every((item) => item.ts && item.v !== '');
}

export function createNewDataPoint(): DataPoint {
  // set milliseconds to zero to catch duplicates
  const date = new Date().setMilliseconds(0);
  return {
    ts: new Date(date).getTime(),
    v: '',
  };
}

export function updateDataPoints(
  dataPoints: DataPoint[],
  rowIndex: number,
  columnId: string,
  value: TValue,
) {
  return dataPoints.map((row, index) => {
    if (index === rowIndex) {
      return {
        ...dataPoints[rowIndex],
        [columnId]: value,
      };
    }
    return row;
  });
}

export function getSavedRowsIndexes(dataPointsLength: number) {
  return many((i) => i, dataPointsLength);
}
