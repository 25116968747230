import { DebounceOptions } from './debounce.definitions';

function debounce<Params = undefined>(options: DebounceOptions<Params>) {
  const { threshold, callback } = options;
  let timeout: ReturnType<typeof setTimeout> | null = null;

  return (e: Params) => {
    // Reset the trailing timeout when function is called
    if (timeout !== null) {
      clearTimeout(timeout);
    }

    if (threshold !== undefined) {
      // Set up a trailing timeout to call trailingCallback after threshold time of no calls
      timeout = setTimeout(() => {
        callback(e);
      }, threshold);
    }
  };
}

export default debounce;
