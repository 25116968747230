import React from 'react';
import { Icons24 } from '@biss/react-horizon-web';
import { FormattedMessage } from 'react-intl';

function ProcessValueIndicator() {
  return (
    <div className="flex flex-row">
      <span className="text-base text-danger">
        <FormattedMessage
          description="Monitoring process value: Not Connected indicator"
          defaultMessage="Not Connected"
          id="HVcgcT"
        />
      </span>
      <div className="ml-1 h-6 w-6 text-danger">
        <Icons24.IndicatorErrorIcon />
      </div>
    </div>
  );
}
export default ProcessValueIndicator;
