import React from 'react';
import { useIntl } from 'react-intl';

import NoDataTracksPlaceHolder from '../../assets/no-data-tracks-placeholder.svg';

function PlaceholderChart() {
  const intl = useIntl();

  return (
    <div className="flex flex-row items-center justify-start p-4">
      <img
        data-testid="placeholder-chart"
        className="max-h-72"
        src={NoDataTracksPlaceHolder}
        alt={intl.formatMessage({
          description: 'Placeholder Chart: No data track provided.',
          defaultMessage: 'Please select a data track from the list',
          id: 'up8xO0',
        })}
      />
    </div>
  );
}

export default PlaceholderChart;
