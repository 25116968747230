import { z } from 'zod';

export const attributeNameSchema = z
  .string({ required_error: 'attribute name should be entered' })
  .min(1, 'attribute name is empty')
  .refine((val) => val.trim().length !== 0, {
    message: 'attribute name cannot be all whitespace',
  });

export const attributeValueSchema = z.string({
  required_error: 'attribute value should be entered',
});
