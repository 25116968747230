import ContentType from 'content-type';

import { HttpStatus } from '../types/http';
import { isApiErrorDTO } from '../types/api-error/dto/validators';
import { transformApiErrorObjectDTO } from '../types/api-error/dto';
import { ApiError } from '../types/api-error/api-error';
import { ClientError } from '../types/client-error';

import { Result } from './fetch-data.definitions';

export function hasContent(response: Response): boolean {
  return response.status !== HttpStatus.NoContent;
}

export async function evaluateBody(response: Response): Promise<Result> {
  if (!hasContent(response)) {
    return {};
  }

  const contentTypeHeader = response.headers.get('Content-Type');
  const contentType = contentTypeHeader && ContentType.parse(contentTypeHeader);
  const { type } = contentType || {};

  switch (type) {
    case 'application/json':
      return { json: await response.json() };
    case 'text/plain':
      return { text: await response.text() };
    // edge case: no body for other status codes than 204
    case undefined:
      return {};
    default:
      return {
        error: new Error(`unknown content type: ${type}`),
      };
  }
}

export async function fetchData<T = unknown>(
  input: RequestInfo | URL,
  init?: RequestInit | undefined,
): Promise<T> {
  const response = await fetch(input, init);
  const body = await evaluateBody(response);

  if (!response.ok) {
    if (body.text) {
      throw new Error(body.text);
    }

    if (body.json) {
      try {
        isApiErrorDTO(body.json);
      } catch {
        // Fallback if the response is not an error object: use default response status message
        throw new ClientError({
          status: response.status,
          message: `${response.status} - ${response.statusText}`,
        });
      }

      const error = transformApiErrorObjectDTO(body.json);
      throw new ApiError(error, response.status);
    }
    // Fallback if there is no response body: use default response status message
    throw new ClientError({
      status: response.status,
      message: `${response.status} - ${response.statusText}`,
    });
  }

  if (body.error) {
    throw body.error;
  }

  if (body.text) {
    return body.text as T;
  }

  if (body.json) {
    return body.json as T;
  }

  return undefined as T;
}

export default fetchData;
