import { useQuery } from '@tanstack/react-query';

import QKey from '../keys';

import { fetchChangelog } from './use-changelog.helpers';

const useChangelog = () =>
  useQuery({
    queryKey: [QKey.CHANGELOG],
    queryFn: fetchChangelog,
    staleTime: Infinity,
  });

export default useChangelog;
