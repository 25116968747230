import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import { Member } from '../../types';
import QKey from '../../../../shared/common/hooks/keys';

import { deleteMember } from './use-delete-member.helpers';

function useDeleteMember() {
  const queryClient = useQueryClient();

  const { acquireAccessToken } = useAuthentication();

  return useMutation<void, unknown, { memberId: string; invitationPending: boolean }, unknown>({
    mutationFn: async ({ memberId, invitationPending }) => {
      const accessToken = await acquireAccessToken();
      return deleteMember(memberId, invitationPending, accessToken);
    },
    onMutate: ({ memberId }) =>
      queryClient.setQueryData<Member[]>([QKey.MEMBERS], (prev) =>
        prev?.filter((member) => member.memberId !== memberId),
      ),
    onError: (_1, _2) => {
      queryClient.invalidateQueries({
        queryKey: [QKey.MEMBERS],
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QKey.MEMBERS],
      });
    },
  });
}

export default useDeleteMember;
