import React from 'react';
import { useIntl } from 'react-intl';
import { NotificationMessage } from '@biss/react-horizon-web';

import { InlineMessageProps } from './inline-message.definitions';

function InlineMessage({
  error,
  isDataHowLabConnectionValid,
}: InlineMessageProps): React.FunctionComponentElement<InlineMessageProps> {
  const { formatMessage } = useIntl();
  return (
    <>
      {error !== null && (
        <NotificationMessage
          title={formatMessage({
            description: 'This message is displayed if the update of subscriptions is failed.',
            defaultMessage: 'Updating subscriptions failed.',
            id: 'GjD9hx',
          })}
          status="error"
        />
      )}
      {isDataHowLabConnectionValid === false && (
        <NotificationMessage
          title={formatMessage({
            description:
              'This message is displayed if the connection to DataHowLab could not be established.',
            defaultMessage: 'DataHowLab connection failed.',
            id: 'Upte39',
          })}
          status="error"
        />
      )}
      {isDataHowLabConnectionValid === true && (
        <NotificationMessage
          title={formatMessage({
            description:
              'This message is displayed if the connection to DataHowLab could be established.',
            defaultMessage: 'DataHowLab connection established.',
            id: 'sNhSuW',
          })}
          status="success"
        />
      )}
    </>
  );
}

export default InlineMessage;
