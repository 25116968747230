import React from 'react';
import { Tile, NotificationMessage } from '@biss/react-horizon-web';
import { useIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';

import useChangelog from '../../../common/hooks/use-changelog';
import LoadingIndicator from '../../../components/loading-indicator';

import ImageOverride from './image-override';

function WhatsNew() {
  const intl = useIntl();
  const {
    data: changelog,
    isLoading: isChangelogLoading,
    isError: isChangelogError,
    error: changelogError,
  } = useChangelog();

  return (
    <Tile>
      <Tile.Header>
        <Tile.Title title="What's new in BioNsight cloud" />
      </Tile.Header>
      <Tile.Body>
        {changelog && (
          <ReactMarkdown
            components={{
              img: ImageOverride,
            }}
            className="prose max-w-none"
          >
            {changelog}
          </ReactMarkdown>
        )}

        {isChangelogLoading && <LoadingIndicator />}

        {isChangelogError && (
          <NotificationMessage
            title={intl.formatMessage({
              defaultMessage: 'Changelog could not be loaded',
              id: 'BoIZKR',
              description: 'Welcome Page Changelog Error Message',
            })}
          >
            {changelogError?.message}
          </NotificationMessage>
        )}
      </Tile.Body>
    </Tile>
  );
}

export default WhatsNew;
