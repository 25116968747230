// A Horizon-based Theme for Echarts.
import {
  HORIZON_COLORS as colors,
  DATA_COLORS as dataColors,
  palettes,
} from '@biss/react-horizon-web';

const brushBackgroundColor = 'rgba(198, 202, 208, 0)'; // gray_400
const brushDataBackgroundColor = 'rgba(198, 202, 208, 0.7)'; // gray_400
const brushFillerColor = 'rgba(20, 90, 221, 0.05)'; // blue
const areaBackgroundLight = 'rgba(248,249,250,0.3)'; // gray_50
const areaBackgroundDark = 'rgba(198,202,208,0.3)'; // gray_400

const commonItemStyle = {
  itemStyle: {
    borderWidth: 0,
    borderColor: colors.gray['700'],
  },
};

const commonAxisStyle = {
  axisLine: {
    show: true,
    lineStyle: {
      color: colors.gray['700'],
    },
  },
  axisTick: {
    show: true,
    lineStyle: {
      color: colors.gray['300'],
    },
  },
  axisLabel: {
    show: true,
    color: colors.gray['800'],
  },
  splitLine: {
    show: true,
    lineStyle: {
      color: [colors.gray['400']],
    },
  },
  splitArea: {
    show: false,
    areaStyle: {
      color: [areaBackgroundLight, areaBackgroundDark],
    },
  },
};

const echartsHorizon = {
  color: palettes.categorical,
  backgroundColor: 'rgba(0,0,0,0)',
  textStyle: {},
  title: {
    textStyle: {
      color: colors.gray['900'],
    },
    subtextStyle: {
      color: colors.gray['800'],
    },
  },
  line: {
    itemStyle: {
      borderWidth: '2',
    },
    lineStyle: {
      width: 2,
    },
    symbolSize: '4',
    symbol: 'emptyCircle',
    smooth: false,
  },
  radar: {
    itemStyle: {
      borderWidth: '2',
    },
    lineStyle: {
      width: 2,
    },
    symbolSize: '4',
    symbol: 'emptyCircle',
    smooth: false,
  },
  bar: {
    itemStyle: {
      barBorderWidth: 0,
      barBorderColor: colors.gray['700'],
    },
  },
  pie: commonItemStyle,
  scatter: commonItemStyle,
  boxplot: commonItemStyle,
  parallel: commonItemStyle,
  sankey: commonItemStyle,
  funnel: commonItemStyle,
  gauge: commonItemStyle,
  candlestick: {
    itemStyle: {
      color: colors.red.DEFAULT,
      color0: colors.green.DEFAULT,
      borderColor: colors.red['700'],
      borderColor0: colors.green['700'],
      borderWidth: 1,
    },
  },
  graph: {
    itemStyle: {
      borderWidth: 0,
      borderColor: colors.gray['700'],
    },
    lineStyle: {
      width: '2',
      color: colors.gray['300'],
    },
    symbolSize: '4',
    symbol: 'emptyCircle',
    smooth: false,
    color: [
      dataColors.blue,
      dataColors.green,
      dataColors.orange,
      dataColors.pink,
      dataColors.purple,
      dataColors.turquoise,
      dataColors.salmon,
      dataColors['yellow-light'],
    ],
    label: {
      color: colors.gray['700'],
    },
  },
  map: {
    itemStyle: {
      areaColor: '#ddd',
      borderColor: '#eee',
      borderWidth: 0.5,
    },
    label: {
      color: '#c12e34',
    },
    emphasis: {
      itemStyle: {
        areaColor: '#e6b600',
        borderColor: '#ddd',
        borderWidth: 1,
      },
      label: {
        color: '#c12e34',
      },
    },
  },
  geo: {
    itemStyle: {
      areaColor: '#ddd',
      borderColor: '#eee',
      borderWidth: 0.5,
    },
    label: {
      color: '#c12e34',
    },
    emphasis: {
      itemStyle: {
        areaColor: '#e6b600',
        borderColor: '#ddd',
        borderWidth: 1,
      },
      label: {
        color: '#c12e34',
      },
    },
  },
  categoryAxis: commonAxisStyle,
  valueAxis: commonAxisStyle,
  logAxis: commonAxisStyle,
  timeAxis: { ...commonAxisStyle, ...{ splitLine: { show: false } } },
  toolbox: {
    iconStyle: {
      borderColor: colors.blue.DEFAULT,
    },
    emphasis: {
      iconStyle: {
        borderColor: dataColors.blue,
      },
    },
  },
  legend: {
    textStyle: {
      color: colors.gray['900'],
    },
  },
  tooltip: {
    axisPointer: {
      lineStyle: {
        color: colors.blue.DEFAULT,
        width: '1',
      },
      crossStyle: {
        color: colors.blue.DEFAULT,
        width: '1',
      },
    },
  },
  timeline: {
    lineStyle: {
      color: dataColors.blue,
      width: 1,
    },
    itemStyle: {
      color: dataColors.blue,
      borderWidth: 1,
    },
    controlStyle: {
      color: dataColors.blue,
      borderColor: dataColors.blue,
      borderWidth: 0.5,
    },
    checkpointStyle: {
      color: dataColors.blue,
      borderColor: dataColors['blue-light'],
    },
    label: {
      color: dataColors.blue,
    },
    emphasis: {
      itemStyle: {
        color: dataColors.blue,
      },
      controlStyle: {
        color: dataColors.blue,
        borderColor: dataColors.blue,
        borderWidth: 0.5,
      },
      label: {
        color: dataColors.blue,
      },
    },
  },
  visualMap: {
    color: [dataColors.blue, 'rgba(185,210,245,0.73)'],
  },
  dataZoom: {
    backgroundColor: brushBackgroundColor,
    dataBackgroundColor: brushDataBackgroundColor,
    fillerColor: brushFillerColor,
    handleColor: colors.gray['700'],
    handleSize: '100%',
  },
  markPoint: {
    label: {
      color: colors.gray['700'],
    },
    emphasis: {
      label: {
        color: colors.gray['700'],
      },
    },
  },
};

const areaSettings = {
  timeAxis: {
    splitArea: {
      show: true,
    },
  },
  categoryAxis: {
    splitArea: {
      show: true,
    },
  },
};

const echartsHorizonArea = { ...echartsHorizon, ...areaSettings };

export { echartsHorizon, echartsHorizonArea };
