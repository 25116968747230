// implemented with full tailwind class names as suggested here:
// https://tailwindcss.com/docs/content-configuration#dynamic-class-names
export default function getGridColumnCssClasses(amountOfItems: number): string {
  const tailwindClasses = [
    'grid-cols-1',
    'sm:grid-cols-2',
    'md:grid-cols-3',
    'lg:grid-cols-4',
    'xl:grid-cols-5',
  ];

  return tailwindClasses.slice(0, amountOfItems).join(' ');
}
