import { Member } from '../member';

import { MemberDTO } from './member-dto';

function transformMemberDTO(dto: MemberDTO): Member {
  const { mfaFactor, invitation, ...member } = dto;
  return {
    ...member,
    invitation: invitation
      ? {
          ...invitation,
          mfa: invitation.mfaFactor === 'Email',
          invitedAt: new Date(invitation.invitedAt),
          validUntil: new Date(invitation.validUntil),
        }
      : undefined,
    mfa: mfaFactor === 'Email',
  };
}

export default transformMemberDTO;
