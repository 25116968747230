import React from 'react';
import { Modal } from '@biss/react-horizon-web';
import { FormattedMessage } from 'react-intl';

import { ConfirmDialogProps } from './confirm-dialog.definitions';

function ConfirmDialog({
  title,
  subtitle,
  trigger,
  defaultOpen,
  defaultMessage,
  onOpenChange,
  onConfirm,
}: ConfirmDialogProps) {
  const [open, setOpen] = React.useState(defaultOpen);
  const confirm = () => {
    setOpen(false);
    onConfirm();
  };
  return (
    <Modal
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={(isOpen: boolean) => {
        setOpen(isOpen);
        onOpenChange?.(isOpen);
      }}
      trigger={trigger}
      title={title}
    >
      <Modal.Content>{subtitle}</Modal.Content>
      <Modal.ButtonGroup>
        <Modal.Close asChild role="button">
          <Modal.Button>
            <FormattedMessage
              description="Cancel Button: label for cancel button"
              defaultMessage="Cancel"
              id="f4OIxJ"
            />
          </Modal.Button>
        </Modal.Close>
        <Modal.Button variant="destructive" onClick={confirm}>
          {defaultMessage}
        </Modal.Button>
      </Modal.ButtonGroup>
    </Modal>
  );
}

export default ConfirmDialog;
