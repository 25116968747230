/**
 * Beta feature keys
 */
const enum BKey {
  // Monitoring detail view
  MONITORING_ADD_EDIT_OFFLINE_DATA_BETA_PACKAGE_NAME = 'add-edit-offline-data',

  /** this key is only used within tests */
  TEST_KEY = 'TEST_KEY',
}

export default BKey;
