import React, { useState } from 'react';
import { Button, NotificationMessage, Select, Tile, Icons32 } from '@biss/react-horizon-web';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';

import { useRecipeOptimizationTransfer } from '../../common/hooks';
import { ProcessRecordOptimizationVariable } from '../../common/types';
import useMapProcessRecipeOptimizationVariable from '../../common/hooks/use-map-process-record-optimization-variable';

import { RecipeOptimizationTransferTileProps } from './recipe-optimization-transfer.definitions';
import DataTrackMapping from './data-track-mapping';
import AttributeMapping, { AttributeVariableMapPair } from './attribute-mapping';
import ProductMapping from './product-mapping';
import {
  decodeVariableIdentifier,
  encodeVariableIdentifier,
  formatVariable,
  isSameVariable,
  predicateAttribute,
  predicateDataTrack,
  predicateMandatory,
  predicateOptional,
  selectParameterKeyGenerator,
} from './recipe-optimization-transfer.helpers';
import { DataTrackVariableMapDiff } from './data-track-mapping/data-track-mapping.definitions';
import RecipeOptimizationTransferSkeleton from './recipe-optimization-transfer.skeleton';

function RecipeOptimizationTransfer({
  processRecordId,
  dataTracks,
  attributes,
  mappings,
  isLoading: isMappingLoading = false,
  isError: isMappingError = false,
  selectVariableDefaultOpen,
}: Readonly<RecipeOptimizationTransferTileProps>) {
  const intl = useIntl();

  const {
    mutate: transfer,
    isError: isTransferError,
    error,
    isSuccess: isTransferSuccess,
    isPending: isTransferPending,
  } = useRecipeOptimizationTransfer(processRecordId);

  const { mutate: map, isPending: isMappingPending } = useMapProcessRecipeOptimizationVariable({
    processRecordId,
  });

  const [chosenOptionalVariables, setChosenOptionalVariables] = useState<
    ProcessRecordOptimizationVariable[]
  >([]);

  const chosenOptionalDataTrackVariables = chosenOptionalVariables.filter(predicateDataTrack);
  const chosenOptionalAttributeVariables = chosenOptionalVariables.filter(predicateAttribute);

  // variable code and group of the optional variable selected in the variable selector encoded into a single string
  const [selectedOptionalVariableIdentifier, setSelectedOptionalVariableIdentifier] = useState<
    string | undefined
  >(undefined);

  // whether the value in the state is already selected
  const isSelectedOptionalVariableIdentifierChosen =
    chosenOptionalVariables.find((i) => {
      if (selectedOptionalVariableIdentifier === undefined) {
        return false;
      }

      return isSameVariable(i, decodeVariableIdentifier(selectedOptionalVariableIdentifier));
    }) !== undefined;

  if (isMappingLoading) {
    return <RecipeOptimizationTransferSkeleton />;
  }

  if (isMappingError) {
    return (
      <NotificationMessage status="error">
        <FormattedMessage
          defaultMessage="Sorry, we could not load the mapping for the DataHowLab transfer. Please try again in a few minutes."
          id="Q4ZjHZ"
          description="Loading Previous Mappings Failed Message"
        />
      </NotificationMessage>
    );
  }

  // early return to assert mapping is never undefined
  if (mappings === undefined) {
    return <div className="hidden" data-testid="UndefinedMappingsStub" />;
  }

  const allInputsDisabled = isMappingPending || isTransferPending;

  const currentDataTrackMappings = mappings.filter(predicateDataTrack);
  const currentMandatoryDataTrackMappings = currentDataTrackMappings.filter(predicateMandatory);
  const currentOptionalDataTrackMappings = currentDataTrackMappings.filter(predicateOptional);

  const currentAttributeMappings = mappings.filter(predicateAttribute);
  const currentMandatoryAttributeMappings = currentAttributeMappings.filter(predicateMandatory);
  const currentOptionalAttributeMappings = currentAttributeMappings.filter(predicateOptional);

  const currentOptionalVariables = [
    ...currentOptionalDataTrackMappings,
    ...currentOptionalAttributeMappings,
  ];

  const handleDataTracksUpdate = (updatedDataTrackMaps: DataTrackVariableMapDiff[]) => {
    map([
      // go through all the current data track mappings and update the map values with the new values
      ...currentDataTrackMappings.map((currentDt) => ({
        ...currentDt,
        ...updatedDataTrackMaps.find((dt) => isSameVariable(dt, currentDt)),
      })),
      ...currentAttributeMappings,
    ]);
  };

  const handleAttributesUpdate = (updatedAttributeMaps: AttributeVariableMapPair[]) => {
    map([
      ...currentDataTrackMappings,
      // go through all the current attribute mappings and update the map values with the new values
      ...currentAttributeMappings.map((currentAttr) => ({
        ...currentAttr,
        ...updatedAttributeMaps.find((attr) => isSameVariable(attr, currentAttr)),
      })),
    ]);
  };

  // when a new optional variable is chosen out of the list to be configured
  const handleChooseOptionalVariable = (): void => {
    if (selectedOptionalVariableIdentifier === undefined) {
      return;
    }

    const { variableCode, variableGroupCode } = decodeVariableIdentifier(
      selectedOptionalVariableIdentifier,
    );

    const variable = currentOptionalVariables.find((i) =>
      isSameVariable(i, { variableCode, variableGroupCode }),
    );

    if (variable === undefined) {
      throw new Error(
        `Selected variable [${variableCode}] that does not exist in the mappable variables`,
      );
    }

    setChosenOptionalVariables((prev) => [...prev, variable]);
    setSelectedOptionalVariableIdentifier(undefined);
  };

  // delete an entire mapping
  const handleDelete = (variableCode: string, variableGroupCode: string) => {
    // set the value of the mapping to null
    const updatedMappings = mappings.map((mapping) =>
      isSameVariable(mapping, { variableCode, variableGroupCode })
        ? {
            ...mapping,
            dataTrackId: null,
            attributeName: null,
          }
        : mapping,
    );

    map(updatedMappings, {
      onSuccess: () =>
        setChosenOptionalVariables((prev) =>
          prev.filter((chosen) => !isSameVariable(chosen, { variableCode, variableGroupCode })),
        ),
    });
  };

  // items shown in the optional attribute mapper
  const exclusiveOptionalAttributeMappings = currentOptionalAttributeMappings.filter((mapping) => {
    // already has a value
    if (mapping.attributeName !== null) {
      return true;
    }

    // is chosen
    return chosenOptionalAttributeVariables.some((attr) => isSameVariable(attr, mapping));
  });

  // items shown in the optional data track mapper
  const exclusiveOptionalDataTrackMappings = currentOptionalDataTrackMappings.filter((mapping) => {
    // already has a value
    if (mapping.dataTrackId !== null) {
      return true;
    }

    // is chosen
    return chosenOptionalDataTrackVariables.some((attr) => isSameVariable(attr, mapping));
  });

  // variables that can be optionally selected for mapping
  const selectableOptionalVariables = currentOptionalVariables.filter((variable) => {
    // already chosen
    if (chosenOptionalVariables.some((chosen) => isSameVariable(chosen, variable))) {
      return false;
    }

    // already has a value
    if (variable.type === 'dataTrack' && variable.dataTrackId !== null) {
      return false;
    }

    // already has a value
    if (variable.type === 'attribute' && variable.attributeName !== null) {
      return false;
    }

    return true;
  });

  return (
    <div className="overflow-y-auto" data-testid="TransferToDataHowLabArea">
      <Tile.Header>
        <Tile.Title
          title={intl.formatMessage({
            defaultMessage: 'Transfer to DataHowLab',
            id: 'mwlrtx',
            description: 'Transfer to DataHowLab Title',
          })}
        />
      </Tile.Header>
      <Tile.Body className="flex flex-col gap-y-6">
        <div className="flex flex-col gap-2">
          <h3>
            <FormattedMessage
              description="Recipe Optimization Transfer: Mandatory information to transfer data to DataHowLab"
              defaultMessage="Mandatory Information"
              id="BH1wjN"
            />
          </h3>
          <div className="font-light">
            <FormattedMessage
              description="Recipe Optimization Transfer: Description that some information is required"
              defaultMessage="Before you can send data from this process record to DataHowLab for further analysis, please provide the following information: "
              id="ctdngv"
            />
          </div>
        </div>

        <div className="flex flex-col gap-y-4">
          <ProductMapping
            attributes={attributes}
            processRecordId={processRecordId}
            disabled={allInputsDisabled}
          />

          <DataTrackMapping
            mappings={currentMandatoryDataTrackMappings}
            onUpdate={handleDataTracksUpdate}
            dataTracks={dataTracks}
            disabled={allInputsDisabled}
          />
          <DataTrackMapping
            mappings={exclusiveOptionalDataTrackMappings}
            onUpdate={handleDataTracksUpdate}
            dataTracks={dataTracks}
            disabled={allInputsDisabled}
            allowDeletion
            onDelete={handleDelete}
          />

          <AttributeMapping
            mappings={currentMandatoryAttributeMappings}
            onUpdate={handleAttributesUpdate}
            attributes={attributes}
            disabled={allInputsDisabled}
          />
          <AttributeMapping
            mappings={exclusiveOptionalAttributeMappings}
            onUpdate={handleAttributesUpdate}
            attributes={attributes}
            disabled={allInputsDisabled}
            allowDeletion
            onDelete={handleDelete}
          />
        </div>

        <h3>
          <FormattedMessage
            description="Recipe Optimization Transfer: Optional information to transfer data to DataHowLab"
            defaultMessage="Optional Information"
            id="zUVKvX"
          />
        </h3>

        <div className="flex flex-col gap-2">
          <Select
            className="max-w-lg"
            expand="auto"
            onValueChange={setSelectedOptionalVariableIdentifier}
            value={
              isSelectedOptionalVariableIdentifierChosen
                ? undefined
                : selectedOptionalVariableIdentifier
            }
            disabled={allInputsDisabled || selectableOptionalVariables.length === 0}
            placeholder={
              selectableOptionalVariables.length === 0
                ? 'All optional Parameters were chosen'
                : 'Select a Parameter'
            }
            data-testid="select-optional-parameter"
            defaultOpen={selectVariableDefaultOpen}
            // this is to fix issue with the placeholder disappearing after the first selection
            key={selectParameterKeyGenerator()}
          >
            {selectableOptionalVariables.map((variable) => {
              const variableIdentifier = encodeVariableIdentifier(
                variable.variableCode,
                variable.variableGroupCode,
              );

              return (
                <Select.Item value={variableIdentifier} key={variableIdentifier}>
                  <div className="flex flex-row gap-2">
                    {variable.type === 'dataTrack' ? (
                      <Icons32.RampUpIcon className="w-4" />
                    ) : (
                      <Icons32.OptionsIcon className="w-4" />
                    )}
                    <span>{formatVariable(variable.variableCode, variable.variableGroupCode)}</span>
                  </div>
                </Select.Item>
              );
            })}
          </Select>

          <Button
            kind="secondary"
            data-testid="add-optional-parameter"
            disabled={
              allInputsDisabled ||
              isSelectedOptionalVariableIdentifierChosen ||
              selectedOptionalVariableIdentifier === undefined
            }
            onClick={handleChooseOptionalVariable}
          >
            <FormattedMessage
              defaultMessage="Add Variable"
              id="mRrYCL"
              description="RecipeOptimizationTransfer: Button to add optional variable for selection"
            />
          </Button>
        </div>
      </Tile.Body>
      <Tile.Footer className="flex flex-col gap-4">
        <Button
          disabled={isTransferPending || isMappingLoading || isMappingPending}
          kind="primary"
          mood="highlight"
          onClick={() => transfer()}
        >
          <FormattedMessage
            defaultMessage="Transfer to DataHowLab"
            id="EE7o1m"
            description="RecipeOptimizationTransfer: Button to trigger the recipe optimization service"
          />
        </Button>

        {isTransferError && (
          <NotificationMessage status="error">
            <ReactMarkdown className="[&_a]:text-blue-500">{error.message}</ReactMarkdown>
          </NotificationMessage>
        )}

        {isTransferSuccess && (
          <NotificationMessage status="success" data-testid="DataHowLab-transfer-success-message">
            <FormattedMessage
              description="The process record has been transferred to DataHowLab"
              defaultMessage="The process record was sent to DataHowLab successfully."
              id="hwF6h1"
            />
          </NotificationMessage>
        )}

        {isTransferPending && (
          <NotificationMessage status="information">
            <FormattedMessage
              description="The process record is currently being transferred to DataHowLab"
              defaultMessage="Transfer in progress..."
              id="ylOVn6"
            />
          </NotificationMessage>
        )}
      </Tile.Footer>
    </div>
  );
}

export default RecipeOptimizationTransfer;
