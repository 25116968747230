import React, { useEffect, useMemo, useState } from 'react';
import { AuthProvider, AuthService } from '@biss/react-auth-web';

import LoadingIndicator from '../../components/loading-indicator';

import { AuthProps } from './auth.definitions';
import { generateAuthConfig } from './auth-config';

function Auth({ children, logger, accountUrl, clientId, tenantId }: AuthProps) {
  const authConfig = useMemo(
    () => generateAuthConfig(accountUrl, clientId, tenantId),
    [accountUrl, clientId, tenantId],
  );

  // initialized authService
  const [authService, setAuthService] = useState<AuthService>();

  // initialize the authService
  useEffect(() => {
    const service = new AuthService(authConfig, logger);
    service.initialize().then(() => setAuthService(service));
  }, []);

  // load until initialized
  if (authService === undefined) {
    return <LoadingIndicator />;
  }

  return <AuthProvider authService={authService}>{children}</AuthProvider>;
}

export default Auth;
