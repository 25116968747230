import React, { useState } from 'react';
import { NotificationMessage, Input, Modal } from '@biss/react-horizon-web';
import { FormattedMessage, useIntl } from 'react-intl';

import { Dropzone } from '../../dropzone';
import { useUploadProcedureResult } from '../../../common/hooks';

import { displayNameSchema } from './add-process-record-by-file.validators';
import {
  deselectFileHandler,
  displayNameChangeHandler,
  fileHandler,
  uploadHandler,
} from './add-process-record-by-file.handlers';
import { AddProcessRecordByFileProps } from './add-process-record-by-file.definitions';

function AddProcessRecordByFile({ onChange, onSubmit }: AddProcessRecordByFileProps) {
  const intl = useIntl();

  const [file, setFile] = useState<File | undefined>(undefined);
  const [isFileDirty, setIsFileDirty] = useState(false);

  const [displayName, setDisplayName] = useState<string>('');
  const [isDisplayNameDirty, setDisplayNameDirty] = useState(false);

  const [progress, setProgress] = useState<number | undefined>(undefined);

  const { mutate: upload, isPending: isUploading, error, isSuccess } = useUploadProcedureResult();

  const displayNameValidation = displayNameSchema.safeParse(displayName);
  const isValid = file !== undefined && displayNameValidation.success;

  const handleUpload = () =>
    uploadHandler(
      setDisplayNameDirty,
      setIsFileDirty,
      upload,
      setProgress,
      [displayName, setDisplayName],
      [file, setFile],
      onSubmit,
    );

  const handleFile = (f: File) => fileHandler(f, setFile, setIsFileDirty, setProgress, onChange);

  const handleDeselectFile = () => deselectFileHandler(setFile, setProgress, onChange);

  const handleDisplayNameChange = (e: React.FormEvent<HTMLInputElement>) =>
    displayNameChangeHandler(e, setDisplayName, setDisplayNameDirty, onChange);

  return (
    <>
      <Modal.Content>
        <div className="flex flex-col gap-4">
          {isSuccess && (
            <NotificationMessage status="information">
              <FormattedMessage
                defaultMessage="Process Record(s) were successfully uploaded."
                description="Upload Modal: Sucessful process record upload"
                id="KTGuk9"
              />
            </NotificationMessage>
          )}
          {error && <NotificationMessage status="error">{error.message}</NotificationMessage>}
          <Dropzone
            file={file}
            onFileChange={handleFile}
            onFileRemove={handleDeselectFile}
            error={isFileDirty ? 'Please select a file.' : undefined}
            data-testid="process-record-file-input"
            progress={progress}
            isProcessing={progress === 100 && isUploading}
          />
          <Input
            label={intl.formatMessage({
              defaultMessage: 'Display Name*',
              id: '7cttyw',
              description: 'Upload Modal: Display Name Label',
            })}
            placeholder="e.g. Control Procedure 13"
            value={displayName}
            onChange={handleDisplayNameChange}
            error={
              isDisplayNameDirty && displayNameValidation.success === false
                ? displayNameValidation.error.errors[0].message
                : undefined
            }
            disabled={isUploading}
            expand="auto"
          />
        </div>
      </Modal.Content>

      <Modal.ButtonGroup>
        <Modal.Close asChild>
          <Modal.Button variant="neutral" disabled={isUploading}>
            <FormattedMessage
              description="Process Record Creation: Cancel Button"
              defaultMessage="Cancel"
              id="+8Qp/w"
            />
          </Modal.Button>
        </Modal.Close>
        <Modal.Button
          variant="highlight"
          onClick={handleUpload}
          disabled={isUploading || isValid === false}
        >
          <FormattedMessage
            defaultMessage="Upload"
            id="uxcSRU"
            description="Upload Modal: Upload Button"
          />
        </Modal.Button>
      </Modal.ButtonGroup>
    </>
  );
}

export default AddProcessRecordByFile;
