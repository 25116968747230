/* eslint-disable react/destructuring-assignment -- union type props */
import React from 'react';

import { CombinedChart } from '../../../../../shared/charts';
import PlaceholderChart from '../../../../../shared/charts/placeholder-chart';
import TimeSeriesChart from '../../../../../shared/components/time-series-chart';

import { ProcessRecordVisualizationChartSelectionProps } from './process-record-visualization-chart-selection.definitions';

function ProcessRecordVisualizationChartSelection(
  props: ProcessRecordVisualizationChartSelectionProps,
) {
  if (props.chartType === 'combined') {
    const selectedMarkedDataTracks = Object.values(props.series).flat();

    if (selectedMarkedDataTracks.length === 0) {
      return <PlaceholderChart />;
    }

    return (
      <CombinedChart
        data={selectedMarkedDataTracks
          .map((dataTrack) => ({
            color: dataTrack.color,
            dataPoints: dataTrack.dataPoints,
            id: dataTrack.dataTrackId,
            name: dataTrack.displayName,
            yAxisLabel: dataTrack.engineeringUnit,
          }))
          .toReversed()}
        markLines={props.seriesMarkLines}
        startTime={props.startTime}
        stopTime={props.stopTime}
        timeStampFormat={props.xAxisFormat}
      />
    );
  }

  return (
    <TimeSeriesChart
      variant="large"
      series={props.series}
      startTime={props.startTime}
      stopTime={props.stopTime}
      xAxisFormatter={props.xAxisFormatter}
      yAxisFormatter={props.yAxisFormatter}
      yAxisRanges={props.yAxisRanges}
      onYAxisRangeChange={props.onYAxisRangeChange}
      setYAxisRanges={props.setYAxisRanges}
      seriesMarkLines={props.seriesMarkLines}
      // the split/combined toggle button is managed outside this component
      showToggleSplit={false}
      useRelativeYAxis
      showTooltip
      showLegend
      showZoom
      showToolbox
      showSideToolbox
      showDetailModal
    />
  );
}

export default ProcessRecordVisualizationChartSelection;

/* eslint-enable react/destructuring-assignment -- union type props */
