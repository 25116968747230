import React from 'react';
import { useAuthContext, useIsAuthenticated } from '@biss/react-auth-web';
import { Popover, ProfilePicture, Skeleton } from '@biss/react-horizon-web';
import { FormattedMessage } from 'react-intl';

import { AccountBarProps } from './account-bar.definitions';
import { createInitials } from './account-bar.helpers';

function AccountBar({ debugDropdownOpen = undefined }: AccountBarProps) {
  const { account, logout, editProfile, changePassword } = useAuthContext();
  const isAuthenticated = useIsAuthenticated();

  if (account === null || isAuthenticated === false) {
    return (
      <Skeleton data-testid="AccountBarSkeleton" variant="circular" width="36px" height="36px" />
    );
  }

  return (
    <Popover
      open={debugDropdownOpen}
      defaultOpen={debugDropdownOpen}
      data-testid="UserMenu"
      contentClassName="z-20"
      trigger={
        <div data-testid="UserAvatar">
          <ProfilePicture fallbackText={createInitials(account.name ?? account.userName)} />
        </div>
      }
    >
      <Popover.Header title={account.name ?? ''} subtitle={account.userName} />
      <Popover.Separator />
      <Popover.Item onClick={editProfile}>
        <FormattedMessage
          defaultMessage="Edit Profile"
          description="Profile Menu: label for 'Edit profile' menu entry"
          id="8AiEni"
        />
      </Popover.Item>
      <Popover.Item onClick={changePassword}>
        <FormattedMessage
          defaultMessage="Change Password"
          description="Profile Menu: label for 'Change Password' menu entry"
          id="OOEJU+"
        />
      </Popover.Item>
      <Popover.Separator />
      <Popover.Item onClick={logout}>
        <FormattedMessage
          defaultMessage="Logout"
          description="Profile Menu: label for Logout menu entry"
          id="IIkgXO"
        />
      </Popover.Item>
    </Popover>
  );
}

export default AccountBar;
