import React, { FunctionComponentElement } from 'react';

import { ProcessValuesProps } from './process-values.definitions';
import ProcessValueTile from './process-value-tile';
import ProcessValuesSkeleton from './process-values.skeleton';

function ProcessValues({
  processValues,
  isShowLoading,
  isDisconnected,
}: ProcessValuesProps): FunctionComponentElement<ProcessValuesProps> {
  return (
    <>
      {isShowLoading && <ProcessValuesSkeleton />}
      {processValues && !isShowLoading && (
        <div
          className="grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] justify-center gap-2"
          data-testid="process-values"
        >
          {Object.keys(processValues).map((type) => (
            <ProcessValueTile
              processValue={processValues[type]}
              processValueType={type}
              key={type}
              isDisconnected={isDisconnected}
            />
          ))}
        </div>
      )}
    </>
  );
}

export default ProcessValues;
