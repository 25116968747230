import React from 'react';

import { ToggleSwitch } from '@biss/react-horizon-web';

import { FormattedMessage } from 'react-intl';

import useSubscribeToDwcEvents from '../../../../../common/hooks/use-subscribe-to-dwc-events';
import useLogger from '../../../../../../shared/common/hooks/use-logger/use-logger';
import TrackedEvent from '../../../../../../shared/common/tracked-event';

import { SetupEventListSupscriptionProps } from './setup-event-list-subscription.definitions';

function SetupEventListSubscription({
  systemId,
  setupId,
  isSubscribedToDwcEvents = false,
  controlProcedureId,
  disabled = false,
}: SetupEventListSupscriptionProps): React.FunctionComponentElement<SetupEventListSupscriptionProps> | null {
  const {
    mutate: updateSubscriptionsState,
    isPending,
    isError,
  } = useSubscribeToDwcEvents({
    setupId,
    systemId,
  });
  const logger = useLogger();

  const handleSwitchNotificationsChange = (checked: boolean) => {
    if (controlProcedureId) {
      updateSubscriptionsState({ enableSubscription: checked, controlProcedureId });
      const trackEvent = checked
        ? TrackedEvent.SubscribeToDWCEvents
        : TrackedEvent.UnsubscribeFromDWCEvents;
      logger.trackEvent(trackEvent);
    }
  };

  return (
    <div>
      <ToggleSwitch
        data-testid="email-notification-toggle-switch"
        id="email-notification"
        labelPlacement="tail"
        label={
          <FormattedMessage
            description="Label for toggle switch to enable e-mail notifications"
            defaultMessage="Enable e-mail notifications for all important events"
            id="rbJnpu"
          />
        }
        disabled={disabled || isPending}
        checked={isSubscribedToDwcEvents}
        onChange={handleSwitchNotificationsChange}
      />
      {isError && (
        <span className="p-1 text-xs text-red-500">
          <FormattedMessage
            description="Email notifications error message"
            defaultMessage="An error occurred while updating the e-mail notifications settings. Please wait a moment and try again."
            id="L93sMK"
          />
        </span>
      )}
    </div>
  );
}

export default SetupEventListSubscription;
