import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import {
  StructuredProcessRecordDescriptor,
  StructuredProcessRecordPayload,
} from '../../../../shared/common/types/process-record';
import useClient from '../../../../shared/common/hooks/use-client';
import { PrsException } from '../../../../shared/common/types/generated/prs.generated';
import { transformProcessRecordDescriptor } from '../../../../shared/common/types/process-record/transformers';

import { prepareProcessRecordPayload } from './use-create-process-record.helpers';

export default function useCreateProcessRecord() {
  const queryClient = useQueryClient();
  const { prsClient } = useClient();
  const { acquireAccessToken } = useAuthentication();

  return useMutation<
    StructuredProcessRecordDescriptor,
    PrsException,
    StructuredProcessRecordPayload,
    unknown
  >({
    mutationFn: async (data: StructuredProcessRecordPayload) => {
      // prepare
      const payload = prepareProcessRecordPayload(data);

      // fetch
      prsClient.authorize(await acquireAccessToken());
      const response = await prsClient.createProcessRecord(payload);

      // transform
      return transformProcessRecordDescriptor(response);
    },
    onSuccess(data: StructuredProcessRecordDescriptor) {
      const previousData = queryClient.getQueryData<StructuredProcessRecordDescriptor[]>([
        QKey.PROCESS_RECORDS,
      ]);

      if (previousData === undefined) {
        return;
      }

      queryClient.setQueryData([QKey.PROCESS_RECORDS], () => [data, ...previousData]);
    },
  });
}
