export const PAGE_TITLE_POSTFIX = 'BioNsight';

export const APP_SECTIONS = [
  'Analytics',
  'Monitoring',
  'Organization Management',
  'User Management',
  'Authentication',
] as const;

export type AppSection = (typeof APP_SECTIONS)[number];
