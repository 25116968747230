import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';

import { ILogger } from './logger.definitions';

function createApplicationInsightsLogger(appInsights: ApplicationInsights) {
  const applicationInsightsLogger: ILogger = {
    error: (message) => {
      appInsights.trackTrace({ message, severityLevel: SeverityLevel.Error });
    },
    warn: (message) => {
      appInsights.trackTrace({ message, severityLevel: SeverityLevel.Warning });
    },
    info: (message) => {
      appInsights.trackTrace({ message, severityLevel: SeverityLevel.Information });
    },
    debug: (message) => {
      appInsights.trackTrace({ message, severityLevel: SeverityLevel.Verbose });
    },
    trackEvent: (name) => {
      appInsights.trackEvent({ name });
    },
  };

  return applicationInsightsLogger;
}

export default createApplicationInsightsLogger;
