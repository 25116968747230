import { DataTrackDescriptor, DataTrackType } from '../../../../shared/common/types/setup';
import { FilterDataTrack } from '../setup-overview.definitions';

import { DataTrackWithEngineeringUnit } from './setup-overview-configuration.definitions';

// eslint-disable-next-line import/prefer-default-export -- further imports will be added
export function getDataTrackWithEngineeringUnit(
  filterDataTracks: Record<DataTrackType, FilterDataTrack>,
  dataTracks: DataTrackDescriptor[],
): Record<DataTrackType, DataTrackWithEngineeringUnit> {
  return Object.keys(filterDataTracks).reduce((acc, dataTrackType) => {
    const dataTrack = dataTracks.find((track) => track.dataTrackType === dataTrackType);
    return {
      ...acc,
      [dataTrackType]: {
        ...filterDataTracks[dataTrackType],
        engineeringUnit: dataTrack?.engineeringUnit ? `(${dataTrack.engineeringUnit})` : '',
      },
    };
  }, {});
}
