import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { Organization } from '../../types/organization/organization';
import { joinPaths } from '../../../../shared/utils';
import { createOrganizationsUrl } from '../use-create-new-organization/use-create-new-organization.helpers';
import { transformOrganizationDTO, isOrganizationDTO } from '../../types';

export function createUrlOrganizationById(organizationId: string): string {
  return joinPaths(createOrganizationsUrl(), organizationId);
}

export async function fetchOrganization(
  organizationId: string,
  accessToken: string,
): Promise<Organization> {
  const organization = await fetchData(createUrlOrganizationById(organizationId), {
    method: HttpMethods.Get,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  // throws an error when object is not in the correct format
  isOrganizationDTO(organization);
  return transformOrganizationDTO(organization);
}

export default createUrlOrganizationById;
