import { EChartSeriesConfig, getMarkLineSeries, MarkLine } from '../chart-markline';

import {
  COMBINED_CHART_GRID_HEIGHT,
  COMBINED_CHART_GRID_OFFSET,
  COMBINED_CHART_LEFT_OFFSET_PX,
  COMBINED_CHART_LEGEND_HEIGHT,
  COMBINED_CHART_RIGHT_OFFSET_PX,
  COMBINED_CHART_X_AXIS_LABEL_HEIGHT,
  COMBINED_CHART_X_AXIS_ZOOM_HEIGHT,
} from './combined-chart.config';

export function getGridEchartsConfig(amountOfLines: number) {
  return {
    // reserve space for each y-axis on the left of the chart
    left: amountOfLines * COMBINED_CHART_LEFT_OFFSET_PX,
    top: COMBINED_CHART_LEGEND_HEIGHT + COMBINED_CHART_GRID_OFFSET,
    right: COMBINED_CHART_RIGHT_OFFSET_PX,
    height: COMBINED_CHART_GRID_HEIGHT,
  };
}

export function getLegendEchartsConfig(config: Array<{ name: string; color: string }>) {
  const data = config.map((entry) => ({
    name: entry.name,
    itemStyle: {
      color: entry.color,
    },
    lineStyle: {
      color: entry.color,
    },
  }));

  // reverse the order of the legend dat ato match that of the y-axis
  data.reverse();

  return {
    data,
    height: COMBINED_CHART_LEGEND_HEIGHT,
  };
}

export function getSeriesEchartsConfig(
  data: Array<{ color: string; name: string; lineType?: string; lineWidth?: number }>,
  markLines: MarkLine[],
): EChartSeriesConfig[] {
  const series = data.map<EChartSeriesConfig>((entry, index) => ({
    type: 'line',
    lineStyle: {
      type: entry.lineType || 'line',
      width: entry.lineWidth || 2,
    },
    xAxisIndex: 0,
    yAxisIndex: index,
    name: entry.name,
    datasetIndex: index,
    animation: false,
    showSymbol: false,
    color: entry.color,
  }));

  const markLineSeries = getMarkLineSeries(markLines);

  return markLines.length > 0 ? [...series, markLineSeries] : series;
}

/**
 * Echarts configuration for the toolbox.
 *
 * saveAsImage: allows users to save the current chart as an image.
 */
export function getToolboxEchartsConfig() {
  return {
    show: true,
    feature: {
      saveAsImage: {
        name: 'BioNsight',
        pixelRatio: 2,
      },
    },
  };
}

export function getCombinedChartLayout(numberOfXAxis: number) {
  const rows = [
    COMBINED_CHART_LEGEND_HEIGHT,
    COMBINED_CHART_GRID_OFFSET,
    COMBINED_CHART_GRID_HEIGHT,
    COMBINED_CHART_X_AXIS_LABEL_HEIGHT,
    COMBINED_CHART_X_AXIS_ZOOM_HEIGHT,
  ]
    .map((i) => `${i}px`)
    .join(' ');

  const cols = [
    `${COMBINED_CHART_LEFT_OFFSET_PX * numberOfXAxis}px`,
    '1fr',
    `${COMBINED_CHART_RIGHT_OFFSET_PX}px`,
  ].join(' ');

  return { rows, cols };
}
