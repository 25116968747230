export const FEEDBACK_EMAIL = 'bionsight-cloud-feedback@eppendorf.de';
export const FEEDBACK_SUBJECT = encodeURIComponent('BioNsight cloud Feedback');
export const FEEDBACK_BODY = encodeURIComponent(
  `Creating a better BioNsight cloud experience together...

1) Please tell us about your experience with the BioNsight cloud.


2) What tasks were you using it for?`,
);
