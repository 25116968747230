import {
  UseMutationOptions,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';

import {
  ControlProcedureId,
  DataTrack,
  DataTrackFromTimestampItem,
  LatestDataPointObject,
} from '../../../../shared/common/types/setup';
import useClient from '../../../../shared/common/hooks/use-client';
import { ApiError } from '../../../../shared/common/types/api-error/api-error';

import { combineDataPoints } from './use-data-tracks.helpers';

// Request data points for a setup
export function useDataTracksFromTimestamp(
  controlProcedureId: string,
  dataTracks: Array<DataTrackFromTimestampItem>,
  setupId: string,
): UseQueryResult<LatestDataPointObject[], Error> {
  const { acquireAccessToken } = useAuthentication();
  const { pmsClient } = useClient();

  return useQuery({
    queryKey: [QKey.SETUPS, QKey.DATA_POINTS, setupId],
    enabled: !!dataTracks?.length,
    retry: false,
    queryFn: async () => {
      pmsClient.authorize(await acquireAccessToken());
      return pmsClient.getMeasurementsFromTimestamp({ dataTracks }, controlProcedureId);
    },
  });
}

// Request data points for a control procedure
export function useDataTracksLastTimestamp() {
  const { acquireAccessToken } = useAuthentication();
  const queryClient = useQueryClient();
  const { pmsClient } = useClient();

  const getLastDataPoints = async (
    controlProcedureId: string,
    dataTracks: Array<DataTrackFromTimestampItem>,
  ): Promise<LatestDataPointObject[]> => {
    try {
      return await queryClient.fetchQuery({
        queryKey: [QKey.DATA_POINTS, controlProcedureId, dataTracks[0].id],
        queryFn: async () => {
          pmsClient.authorize(await acquireAccessToken());
          return pmsClient.getMeasurementsFromTimestamp({ dataTracks }, controlProcedureId);
        },
        retry: false,
      });
    } catch (e) {
      return [];
    }
  };

  return { getLastDataPoints };
}

// Request last data points and combine with previous
export function useDataTracksTimestamp(
  setupId: string,
  {
    onSuccess,
    ...options
  }: UseMutationOptions<
    unknown,
    ApiError,
    { dataTracks: Array<DataTrackFromTimestampItem>; controlProcedureId: ControlProcedureId },
    unknown
  > = {},
) {
  const { acquireAccessToken } = useAuthentication();
  const queryClient = useQueryClient();
  const { pmsClient } = useClient();
  return useMutation({
    ...options,
    async mutationFn({ controlProcedureId, dataTracks }) {
      pmsClient.authorize(await acquireAccessToken());
      return pmsClient.getMeasurementsFromTimestamp({ dataTracks }, controlProcedureId);
    },
    mutationKey: [QKey.DATA_POINTS, setupId],
    onSuccess(data, variables, context) {
      // update cache
      data.forEach((item) => {
        queryClient.setQueryData<DataTrack>([QKey.SETUPS, setupId, item.dataTrackId], (prev) => {
          if (!prev) {
            return undefined;
          }
          const combinedDataPoints = combineDataPoints(prev.dataPoints, item.dataPoints);

          return {
            ...prev,
            dataPoints: combinedDataPoints,
          };
        });
      });

      onSuccess?.(data, variables, context);
    },
  });
}
