import { Organization } from '../organization';

import { OrganizationDTO } from './organization-dto';

function transformOrganizationDTO(dto: OrganizationDTO): Organization {
  return {
    ...dto,
    deactivatedAt: dto.deactivatedAt ? new Date(dto.deactivatedAt) : undefined,
  };
}

export default transformOrganizationDTO;
