import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ToggleButton } from '@biss/react-horizon-web';

import useLogger from '../../../../shared/common/hooks/use-logger/use-logger';
import TrackedEvent from '../../../../shared/common/tracked-event';

import {
  HOUR,
  SetupOverviewTimespanProps,
  TIME_SPAN_OPTIONS,
} from './setup-overview-timespan.definitions';

function SetupOverviewTimespan({
  timeSpan,
  setTimeSpan,
}: SetupOverviewTimespanProps): React.FunctionComponentElement<SetupOverviewTimespanProps> {
  const logger = useLogger();
  const timeSpanValue = timeSpan.toString();
  const onClick = (value: string) => {
    logger.trackEvent(TrackedEvent.SelectTimeSpan);
    setTimeSpan(+value);
  };

  return (
    <div data-testid="setup-overview-timespan" className="flex flex-col bg-white p-4">
      <div className="mb-2 self-start text-sm font-bold">
        <FormattedMessage
          description="Monitoring Overview: Time Span"
          defaultMessage="Time Span"
          id="4DDH5J"
        />
      </div>
      <ToggleButton defaultValue={timeSpanValue} onValueChange={onClick}>
        {TIME_SPAN_OPTIONS.map((item) => (
          <ToggleButton.Item key={item} value={item} disabled={item === timeSpanValue}>
            {item}&#8202;{HOUR}
          </ToggleButton.Item>
        ))}
      </ToggleButton>
    </div>
  );
}

export default SetupOverviewTimespan;
