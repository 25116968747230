import React from 'react';

import { KbdProps } from './kbd.definitions';

export function Kbd({ children }: KbdProps) {
  return (
    <kbd className="mx-1 rounded-sm border border-b-2 border-gray-100 bg-gray-50 px-2 text-red-400">
      {children}
    </kbd>
  );
}

export default Kbd;
