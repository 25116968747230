import React, { useEffect, useMemo } from 'react';
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from '@microsoft/applicationinsights-react-js';

import { ErrorMessage } from '../../components/error-message';

import { InsightsProps, createApplicationInsights, reactPlugin } from './insights.definitions';
import { InsightsGlobals } from './insights-globals';
import { manageAppInsightsCookiesEffect } from './insights.helpers';

function Insights({
  children,
  applicationInsightsInstrumentationKey,
  isCookieEnabled,
}: InsightsProps) {
  const appInsights = useMemo(
    () => createApplicationInsights(applicationInsightsInstrumentationKey, isCookieEnabled),
    [
      applicationInsightsInstrumentationKey,
      /* isCookieEnabled should not be a dependency, cookie management should be done using the cookie manager */
    ],
  );

  // uses the cookie manager to
  // update app insights config to enable or disable cookies
  // remove cookies from the browser if cookies were disabled
  useEffect(() => manageAppInsightsCookiesEffect(appInsights, isCookieEnabled), [isCookieEnabled]);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary appInsights={reactPlugin} onError={ErrorMessage}>
        <InsightsGlobals.Provider value={appInsights}>
          {children(appInsights, reactPlugin)}
        </InsightsGlobals.Provider>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
}

export default Insights;
