import { DataTrackType } from '../../../shared/common/types/setup';

export const DEFAULT_COLOR_KEY = 'DEFAULT';

export const DEFAULT_DATA_TRACK_TYPES = ['DO.PV', 'pH.PV', 'T.PV'] satisfies DataTrackType[];

export type FilterDataTrack = {
  color: string;
  selected: boolean;
};

export type SetupOverviewProps = {
  defaultSidebarOpen?: boolean;
};
