import { Modal } from '@biss/react-horizon-web';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import {
  EppendorfRole,
  MemberRole,
  Roles,
  isEppendorfAdministrator,
  useAuthContext,
} from '@biss/react-auth-web';

import useMember from '../../../common/hooks/use-member/use-member';
import RouteDefinition from '../../../../shared/common/routes/routes.definitions';
import MemberGeneralInfo from '../../../components/member-general-info/member-general-info';
import useUpdateMember from '../../../common/hooks/use-update-member/use-update-member';
import { Member } from '../../../common/types';
import LoadingIndicator from '../../../../shared/components/loading-indicator';

import { MemberDetailsState } from './member-details.definitions';

function MemberDetails() {
  const { id } = useParams();
  const navigate = useNavigate();

  if (id === undefined) {
    throw new Error('An id has to be provided via the route.');
  }
  const { account } = useAuthContext();
  const { data: member, isLoading, error, isError } = useMember(id);

  const { formatMessage } = useIntl();
  const { mutateAsync: updateMemberRoles } = useUpdateMember();
  const roles = (
    account && isEppendorfAdministrator(account)
      ? (['EppendorfAdministrator', 'EppendorfService'] satisfies EppendorfRole[])
      : (['Member', 'Administrator'] satisfies MemberRole[])
  ) satisfies Roles;
  const ownAccount = account?.accountId === id;
  const [memberDetailState, setMemberDetailState] = useState<MemberDetailsState>();
  const [open, setOpen] = React.useState(true);
  const onClose = () => {
    navigate(`${RouteDefinition.UserManagment}/members`);
  };

  useEffect(() => {
    if (!member) {
      return;
    }
    const memberCopy = JSON.parse(JSON.stringify(member)) as Member;
    if (memberCopy.invitation) {
      memberCopy.invitation.invitedAt = new Date(memberCopy.invitation.invitedAt);
      memberCopy.invitation.validUntil = new Date(memberCopy.invitation.validUntil);
    }
    setMemberDetailState({
      member: memberCopy,
      memberRolesValid: true,
      memberRolesChanged: false,
      memberMfaChanged: false,
    });
  }, [isLoading, member]);

  const handleRolesChange = (role: MemberRole | EppendorfRole, checked: boolean) => {
    setMemberDetailState((prevState) => {
      if (!prevState) {
        return prevState;
      }
      const assignedRoles = new Set(prevState.member.memberRoles);
      if (checked) {
        assignedRoles.add(role);
      } else {
        assignedRoles.delete(role);
      }
      return {
        ...prevState,
        member: { ...prevState.member, memberRoles: Array.from(assignedRoles) as Roles },
        memberRolesValid: assignedRoles.size > 0,
        memberRolesChanged: true,
      };
    });
  };

  const handleMfaChange = (checked: boolean) => {
    setMemberDetailState((prevState) => {
      if (!prevState) {
        return prevState;
      }
      return {
        ...prevState,
        member: { ...prevState.member, mfa: checked },
        memberMfaChanged: true,
      };
    });
  };

  const handleUpdate = () => {
    if (!memberDetailState) {
      return;
    }

    updateMemberRoles(
      {
        memberId: id,
        roles: memberDetailState.member.memberRoles,
        mfa: memberDetailState.member.mfa,
      },
      {
        onSuccess: () => {
          setOpen(false);
          onClose();
        },
      },
    );
  };

  if (isError) {
    return (
      <FormattedMessage
        description="An error occurred."
        defaultMessage="An error occurred. Message: {message}"
        values={{
          message: error?.message,
        }}
        id="0o7n3J"
      />
    );
  }

  if (isLoading || !memberDetailState?.member) {
    return <LoadingIndicator />;
  }

  return (
    <Modal
      open={open}
      onOpenChange={(isOpen: boolean) => {
        if (!isOpen) {
          onClose();
        }
      }}
      size="xs"
      title={formatMessage({
        defaultMessage: 'User Details',
        id: 'u8XYIF',
        description: 'User Details title.',
      })}
    >
      <Modal.Content>
        {isLoading || !memberDetailState?.member ? (
          <LoadingIndicator />
        ) : (
          <div className="w-full max-w-sm">
            <MemberGeneralInfo
              member={memberDetailState.member}
              roles={roles}
              onRolesChanged={handleRolesChange}
              onMfaChanged={handleMfaChange}
              errors={new Map()}
            />
          </div>
        )}
      </Modal.Content>
      <Modal.ButtonGroup>
        <Modal.Close asChild role="button">
          <Modal.Button>
            <FormattedMessage
              description="Cancel Button: label for cancel button"
              defaultMessage="Cancel"
              id="f4OIxJ"
            />
          </Modal.Button>
        </Modal.Close>

        <Modal.Button
          variant="positive"
          data-testid="saveButton"
          disabled={
            !(
              memberDetailState.member !== undefined &&
              !ownAccount &&
              memberDetailState.member.invitation === undefined &&
              ((memberDetailState.memberRolesChanged && memberDetailState.memberRolesValid) ||
                memberDetailState.memberMfaChanged)
            )
          }
          onClick={handleUpdate}
        >
          <FormattedMessage
            description="Save Button: label for save button"
            defaultMessage="Save"
            id="jJZMgE"
          />
        </Modal.Button>
      </Modal.ButtonGroup>
    </Modal>
  );
}

export default MemberDetails;
