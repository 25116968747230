import {
  ProcessRecordAttributes,
  ProcessRecordId,
} from '../../../../shared/common/types/process-record';

export type ProductMappingProps = {
  attributes: ProcessRecordAttributes;
  processRecordId: ProcessRecordId;

  /** whether the pill can be edited */
  disabled?: boolean;
};

export const PRODUCT_KEY = 'Product';
