import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import { Organization } from '../../types/organization/organization';
import { NewOrganization } from '../../types/organization/new-organization';
import QKey from '../../../../shared/common/hooks/keys';

import postNewOrganization from './use-create-new-organization.helpers';

function useCreateNewOrganization() {
  const queryClient = useQueryClient();
  const { acquireAccessToken } = useAuthentication();
  return useMutation({
    mutationKey: [QKey.ORGANIZATIONS, 'new'],
    mutationFn: async (organizationToCreate: NewOrganization) => {
      const accessToken = await acquireAccessToken();
      return postNewOrganization(organizationToCreate, accessToken);
    },
    onMutate: async (variables) => {
      // Cancel current queries for the organization list
      await queryClient.cancelQueries({ queryKey: [QKey.ORGANIZATIONS] });

      // Create optimistic organization
      const optimisticOrganization: Organization = {
        ...variables,
        organizationId: 'new-organization',
        active: true,
      };

      // Add optimistic organization to organizations list
      queryClient.setQueryData<Organization[]>([QKey.ORGANIZATIONS], (old) =>
        old?.concat([optimisticOrganization]),
      );

      // Return context with the optimistic organization
      return { optimisticOrganization };
    },
    onSuccess: (result, _, context) => {
      // Replace optimistic organization in the organizations list with the result
      queryClient.setQueryData<Organization[]>([QKey.ORGANIZATIONS], (old) =>
        old?.map((organization) =>
          organization.organizationId === context?.optimisticOrganization.organizationId
            ? result
            : organization,
        ),
      );
    },
    onError: (_1, _2, context) => {
      // Remove optimistic organization from the organizations list
      queryClient.setQueryData<Organization[]>([QKey.ORGANIZATIONS], (old) =>
        old?.filter(
          (organization) =>
            organization.organizationId !== context?.optimisticOrganization.organizationId,
        ),
      );
    },
  });
}

export default useCreateNewOrganization;
