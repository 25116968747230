import React, { FunctionComponentElement } from 'react';

import useFormatNumber from '../../../shared/common/hooks/use-format-number';
import { DEFAULT_FRACTIONAL_DIGITS } from '../../common/types/data-track';

import { DataTrackInfo } from './data-track-value.definitions';

export const valuePlaceholder = '--';

function DataTrackValue({
  engineeringUnit,
  value,
  fractionalDigits = DEFAULT_FRACTIONAL_DIGITS,
}: DataTrackInfo): FunctionComponentElement<DataTrackInfo> {
  const valueFormatter = useFormatNumber();
  const valueFormatted =
    typeof value === 'number' ? valueFormatter(value, fractionalDigits) : valuePlaceholder;

  return (
    <span>
      {valueFormatted}&#8202;{engineeringUnit}
    </span>
  );
}

export default DataTrackValue;
