import React, { FunctionComponentElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NotificationMessage, Button, DeleteIcon } from '@biss/react-horizon-web';

import useLogger from '../../../../../shared/common/hooks/use-logger/use-logger';
import ConfirmAction from '../../../../../shared/components/confirm-action/confirm-action';
import TrackedEvent from '../../../../../shared/common/tracked-event';

import { DeleteWorkflowMessageProps } from './delete-workflow-message.defiinitions';

function DeleteWorkflowMessage({
  visible,
  workflowTitle,
  onDelete,
}: DeleteWorkflowMessageProps): FunctionComponentElement<DeleteWorkflowMessageProps> | null {
  const intl = useIntl();
  const logger = useLogger();

  const handleOnClick = () => {
    logger.trackEvent(TrackedEvent.DeleteWorkflow);
    onDelete();
  };

  if (!visible) {
    return null;
  }

  return (
    <NotificationMessage
      data-testid="delete-wokflow-message"
      status="error"
      title={intl.formatMessage({
        description: 'Inactive Workflow title',
        defaultMessage: 'Inactive Workflow.',
        id: 'LDXCDX',
      })}
      action={
        <ConfirmAction
          confirmButtonText={
            <FormattedMessage
              description="Monitoring Confirm Action Modal: label for confirm button"
              defaultMessage="Delete"
              id="BIlNCW"
            />
          }
          title={
            <FormattedMessage
              description="Monitoring Confirm Action Modal: title for confirmation modal"
              defaultMessage="Confirm Deletion"
              id="kpLsY0"
            />
          }
          content={
            <FormattedMessage
              description="Monitoring Confirm Action Modal: description for confirmation modal"
              defaultMessage={`The inactive workflow with the title "{title}" and all its associated information will be deleted from BioNsight cloud. This action cannot be undone. Are you sure you want to delete this inactive workflow?`}
              id="UOs9Eg"
              values={{
                title: workflowTitle,
              }}
            />
          }
          onConfirm={handleOnClick}
          trigger={
            <Button kind="primary" mood="destructive" leftIcon={<DeleteIcon />}>
              <FormattedMessage
                description="Delete Workflow button title"
                defaultMessage="Delete Workflow"
                id="xLUua5"
              />
            </Button>
          }
        />
      }
    >
      <FormattedMessage
        description="Inactive Workflow description"
        defaultMessage="No new data arrived since more than 24 hours. Please check whether the DASware system is connected."
        id="djYlk9"
      />
    </NotificationMessage>
  );
}

export default DeleteWorkflowMessage;
