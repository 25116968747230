import React from 'react';
import { Modal } from '@biss/react-horizon-web';
import { FormattedMessage } from 'react-intl';

import { ModalActionsProps } from './modal-actions.definitions';

function ModalActions({
  organizationDetailState,
  onActivateOrganization,
  onSave,
}: ModalActionsProps) {
  return (
    <Modal.ButtonGroup>
      <Modal.Close asChild role="button">
        <Modal.Button>
          <FormattedMessage
            description="Cancel Button: label for cancel button"
            defaultMessage="Cancel"
            id="f4OIxJ"
          />
        </Modal.Button>
      </Modal.Close>
      {organizationDetailState.organization?.active ? (
        <Modal.Button
          variant="positive"
          onClick={onSave}
          disabled={!organizationDetailState.saveEnabled}
        >
          {!organizationDetailState.isDataHowLabSubscribed ||
          !organizationDetailState.isDataHowLabConnectionChanged ||
          organizationDetailState.isDataHowLabConnectionValid ? (
            <FormattedMessage
              description="Save Button: label for save button"
              defaultMessage="Save"
              id="jJZMgE"
            />
          ) : (
            <FormattedMessage
              description="Verify Button: label for verify button"
              defaultMessage="Verify DataHowLab Connection"
              id="hp4xdx"
            />
          )}
        </Modal.Button>
      ) : (
        <Modal.Close asChild role="button">
          <Modal.Button variant="positive" onClick={onActivateOrganization}>
            <FormattedMessage
              description="Reactivate Button: label for reactivate button"
              defaultMessage="Reactivate"
              id="Ms4B8E"
            />
          </Modal.Button>
        </Modal.Close>
      )}
    </Modal.ButtonGroup>
  );
}

export default ModalActions;
