import { useQueries, UseQueryOptions } from '@tanstack/react-query';

import { DataTrack } from '../../types/process-record';
import { PrsException } from '../../types/generated/prs.generated';
import QKey from '../keys';

import { UseMultipleAnalyticsDataTracksInput } from './use-multiple-analytics-data-tracks.definitions';

/**
 * fetches and stores multiple data tracks from different process records
 * @param processRecordToDataTracks - map of process record id to multiple data track ids
 */
export default function useMultipleAnalyticsDataTracks(
  processRecordToDataTracks: UseMultipleAnalyticsDataTracksInput,
) {
  return useQueries({
    queries: processRecordToDataTracks
      // create queries
      .map<UseQueryOptions<DataTrack, PrsException>>(([processRecordId, dataTrackId]) => ({
        queryKey: [QKey.ANALYTICS_DATA_TRACK, processRecordId, dataTrackId],
        // TODO(BIOCL-4467): fetch the data tracks
      })),
  });
}
