import { useEffect } from 'react';

import { TitleProps } from './title.definitions';

/**
 * wrapper component that sets the documenmts title when rendered
 */
function Title({ children, text }: TitleProps) {
  useEffect(() => {
    document.title = text;
  }, [text]);

  return children;
}

export default Title;
