import React, { useRef } from 'react';

import DebouncedInput, { DebouncedInputRef } from '../debounced-input';

import { SearchInputProps } from './search-input.definitions';

function SearchInput({ className, ...props }: SearchInputProps) {
  const inputRef = useRef<DebouncedInputRef>(null);

  return (
    <DebouncedInput
      // eslint-disable-next-line react/jsx-props-no-spreading -- wrapping reusable component
      {...props}
      ref={inputRef}
      type="search"
      className={`${className} appearance-none`}
      isSearch
      isClearable
      onClearClick={() => inputRef.current?.setValue('')}
    />
  );
}

export default SearchInput;
