import React from 'react';

import TrackedEvent from '../../../common/tracked-event';
import useLogger from '../../../common/hooks/use-logger/use-logger';
import YAxisRangeInputs from '../../../components/time-series-chart/y-axis-range-inputs';
import { YAxisRange } from '../../../components/time-series-chart/y-axis-range-inputs/y-axis-range-inputs.validation';

import { SideToolboxProps } from './side-toolbox.definitions';

function SideToolbox({ seriesName, range, onRangeChange }: SideToolboxProps) {
  const logger = useLogger();

  const handleRangeChange = (yAxisRange: YAxisRange, seriesType: string) => {
    onRangeChange?.(yAxisRange, seriesType);
    logger.trackEvent(TrackedEvent.SetYAxisRange);
  };

  return (
    <div className="pt-[4.5rem] max-md:hidden" data-testid="y-axis-side-toolbox">
      <YAxisRangeInputs
        className="flex flex-col justify-between gap-4 md:h-[295px]"
        range={range}
        labelPrefix={seriesName}
        onRangeChange={handleRangeChange}
        isReverse
      />
    </div>
  );
}

export default SideToolbox;
