import { useMutation } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import useClient from '../../../../shared/common/hooks/use-client';

function useWorkflowIdle(controlProcedureId: string, onMutated: (result: boolean) => void) {
  const { acquireAccessToken } = useAuthentication();
  const { pmsClient } = useClient();

  return useMutation({
    mutationFn: async () => {
      pmsClient.authorize(await acquireAccessToken());
      return pmsClient.idleEvaluation(controlProcedureId);
    },
    onSuccess: (result) => {
      onMutated(result.isIdle);
    },
    onError: () => onMutated(false),
  });
}

export default useWorkflowIdle;
