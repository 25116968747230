// TODO(BIOCL-1897): remove when transparent colors are added to the react-horizon-web
export function getValueBackgroundColor(color: string, opacity: number): string {
  const isValidHEX = /^#([A-Fa-f0-9]{6}){1,2}$/.test(color);
  if (isValidHEX) {
    const colors = color.match(/\w\w/g);
    if (colors) {
      const [r, g, b] = colors.map((x: string) => parseInt(x, 16));
      return `rgba(${r},${g},${b},${opacity})`;
    }
  }
  throw new Error('Incorrrect color');
}

export function getStyles(color: string, disabled: boolean) {
  const backgroundOpacity = 0.1;
  const styles: Record<string, string> = {
    borderColor: color,
    ...(!disabled && { backgroundColor: getValueBackgroundColor(color, backgroundOpacity) }),
  };
  return styles;
}
