import { ReactNode } from 'react';
import { z } from 'zod';

import { joinPaths } from '../../utils';

export const projectConfigSchema = z.object({
  PMS_BACKEND_URL: z.string().url(),
  PRS_BACKEND_URL: z.string().url(),
  UM_BACKEND_URL: z.string().url(),
  ROS_BACKEND_URL: z.string().url(),
  APPLICATION_INSIGHTS: z.string(),
  ACCOUNT_URL_IDP: z.string(),
  B2C_TENANT_ID: z.string(),
  B2C_FRONTEND_APP_CLIENTID: z.string(),
  ENVIRONMENT: z.string(),
});

export type ProjectConfig = z.infer<typeof projectConfigSchema>;
export type ConfigParameter = keyof ProjectConfig;

export type ProjectConfigWaterfallProps = {
  children: (config: ProjectConfig) => ReactNode;
  /**
   * used for tests
   */
  initialConfig?: ProjectConfig;
};

/**
 * base url to the frontend-config-service
 */
export const PROJECT_CONFIG_URL = joinPaths(
  process.env.REACT_APP_CONFIG_SERVICE_URL ?? process.env.PUBLIC_URL,
  'config',
);
