import React from 'react';
import { ProgressBar, DeleteIcon } from '@biss/react-horizon-web';

import { UploadFormProps } from './upload-form.definitions';

function UploadForm({ fileName, progress, isUploading, handleDeselectFile }: UploadFormProps) {
  return (
    <div className="flex h-96 flex-col items-start rounded border border-gray-600 bg-gray-100 p-2">
      <div className="flex w-full flex-row justify-between gap-2 rounded border border-gray-700 bg-white p-2">
        <div className="grid grid-rows-2 items-center gap-0">
          <span
            data-testid="data-track-name"
            className="overflow-hidden text-ellipsis whitespace-nowrap"
            key={fileName}
          >
            {fileName}
          </span>

          {isUploading && (
            // eslint-disable-next-line react/jsx-no-useless-fragment -- done so as to simplify the condition
            <>
              {progress < 100 ? (
                <ProgressBar data-testid="upload-progress" label="Uploading..." value={progress} />
              ) : (
                <ProgressBar data-testid="upload-progress" label="Processing..." isIndeterminate />
              )}
            </>
          )}
        </div>

        {isUploading === false && (
          <button
            data-testid="remove-selected-file-button"
            onClick={handleDeselectFile}
            type="button"
            className="p-2"
          >
            <DeleteIcon className="h-8 w-8 hover:text-red-600" />
          </button>
        )}
      </div>
    </div>
  );
}

export default UploadForm;
