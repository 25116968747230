import React from 'react';
import { HORIZON_COLORS } from '@biss/react-horizon-web';
import { FormattedMessage } from 'react-intl';

import { Kbd } from '../kbd';
import { DataTrackType, StructuredProcessRecordObject } from '../../common/types/process-record';

import { YAxisRange } from './y-axis-range-inputs/y-axis-range-inputs.validation';

export interface DataPointObject {
  ts: number;
  v: number;
}

export type SeriesId = string;
export type SeriesName = string;

/** data used to encode a series types name */
export type SeriesNameData = {
  seriesType: DataTrackType;
  engineeringUnit: string;

  /** filled in analytics */
  processRecordDisplayName?: string;

  /** filled in analytics */
  processRecordUnit?: string;
};

export interface Series {
  dataTrackId: SeriesId;
  dataTrackType: SeriesName;
  displayName: string;
  engineeringUnit: string;
  fractionalDigits?: number;
  dataPoints: Array<DataPointObject>;
  color: string;
  width?: number;
  lineType?: string;

  /** passed in analytics */
  processRecord?: StructuredProcessRecordObject;
}

export type SeriesMarkLine = {
  color: string;
  timestamp: number;
  name: string;
};

export type SeriesLegend = {
  engineeringUnit: string;
  color: string;
};

export type SeriesLegendData = {
  name: DataTrackType;
  itemStyle: {
    color: string;
  };
  lineStyle: {
    color: string;
  };
};

export type SeriesLegendSelected = Record<string, boolean>;
export type LegendSelectedChanged = {
  type: string;
  name: string;
  selected: SeriesLegendSelected;
};
export type TooltipStyles = {
  backgroundColor?: string;
  borderColor?: string;
};

export type TimeSeriesChartProps = {
  series: Record<SeriesName, Series[]>;
  startTime: number;
  stopTime: number;
  variant?: ChartVariant;
  combinedGraph?: boolean;
  showTooltip?: boolean;
  xAxisFormatter: (ts: number) => void;
  yAxisFormatter?: (value: number, fractionalDigits?: number) => void;
  seriesMarkLines?: SeriesMarkLine[];
  showToggleSplit?: boolean;
  showLegend?: boolean;
  seriesLegend?: Record<DataTrackType, SeriesLegend>;
  toggleSplitView?: (isCombined: boolean) => void;
  useRelativeYAxis?: boolean;
  showZoom?: boolean;
  showToolbox?: boolean;
  legendSelected?: SeriesLegendSelected;
  toggleLegendSelected?: (value: SeriesLegendSelected) => void;
  tooltipStyles?: TooltipStyles;

  /** whether to render the y-axis range inputs beside the charts */
  showSideToolbox?: boolean;

  /** whether to render the y-axis range inputs in a modal */
  showDetailModal?: boolean;

  /** the viewable and zoom-able range of the yAxis */
  yAxisRanges?: Map<SeriesName, YAxisRange>;
  onYAxisRangeChange?: (range: YAxisRange, seriesType: SeriesName) => void;
  setYAxisRanges?: (ranges: Map<SeriesName, YAxisRange>) => void;

  /** default open Y-Axis Modal */
  defaultOpenYAxisModal?: boolean;
};

export type DataSet = {
  dataTrackId: string;
  dimensions: ['ts', 'v'];
  source: DataPointObject[];
};

export const CHART_VARIANT = ['small', 'large'] as const;
export type ChartVariant = (typeof CHART_VARIANT)[number];

export type ChartSettings = {
  height: number;
  chartHeight: number;
  yAxisOffset: number;
  fontSize?: number;
  lineHeight?: number;
  lineStyle: {
    width: number;
  };
  markLine: {
    fontSize: number;
  };
  dataZoom: {
    left: number;
    height: number;
  };
  toolbox: {
    itemGap: number;
  };
  legend: {
    height: number;
  };
  grid: {
    height?: number;
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
    containLabel?: boolean;
  };
};

export type Param = {
  data: DataPointObject;
  marker: string;
  seriesName: string;
  color: string;
};

export interface DataZoom {
  batch?: DataZoom[];
  type: string;
  from: string;
  dataZoomId: string;
  animation: Animation;
  start?: number;
  end?: number;
}

export interface Animation {
  easing: string;
  duration: number;
  delay: number;
}

export enum ZoomResetDirection {
  Both,
  X,
  Y,
}

export type ZoomRangeType = { start: number; end: number };

export const markLineColor = HORIZON_COLORS.gray['900'];
export const markLineInoculation = 'Inoculation';
export const markLineFinished = 'Process Finished';
export const markLineLastUpdate = 'Last Update';

export const SHORTCUTS = (
  <ul className="flex flex-col">
    <li>
      <FormattedMessage
        defaultMessage="Use the scroll wheel on your mouse while pressing and holding"
        description="Modifier hints: Hold"
        id="dlw2RG"
      />
      <Kbd>
        <FormattedMessage defaultMessage="Shift" description="Modifier hints: Shift" id="o8AQHr" />
      </Kbd>
      <FormattedMessage
        defaultMessage="to zoom in and out."
        description="Modifier hints: Shift action"
        id="Olzeef"
      />
    </li>
    <li>
      <FormattedMessage
        defaultMessage="Use the scroll wheel on your mouse while pressing and holding"
        description="Modifier hints: Hold"
        id="dlw2RG"
      />
      <Kbd>
        <FormattedMessage defaultMessage="Ctrl" description="Modifier hints: Ctrl" id="JllEIq" />
      </Kbd>
      <FormattedMessage
        defaultMessage="to pan left and right."
        description="Modifier hints: Ctrl action"
        id="RQZt6B"
      />
    </li>
  </ul>
);

export const Y_AXIS_TIP = (
  <ul className="flex flex-col">
    <li>
      <FormattedMessage
        defaultMessage="Click on the"
        description="Modifier hints: Hold"
        id="Q8KOyB"
      />
      <strong className="mx-1">
        <FormattedMessage
          defaultMessage="Y-Axis labels"
          description="Modifier hints: Ctrl action"
          id="nFw4r/"
        />
      </strong>
      <FormattedMessage
        defaultMessage="to adjust the range bounds."
        description="Modifier hints: Ctrl action"
        id="FmDY8d"
      />
    </li>
  </ul>
);
export const LEGEND_NAME_MAX_DISPLAY_NAME_LENGTH = 20;
export const LEGEND_NAME_MAX_UNIT_LENGTH = 8;
