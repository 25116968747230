import { useIntl } from 'react-intl';

/**
 * The native <input type="datetime-local" ...> element expects the date
 *  in the format YYYY-MM-DDThh:mm:ss
 */
export default function useDateTimeLocalFormatter(withSeconds = false) {
  const intl = useIntl();

  return (date: Date) => {
    const parts = intl.formatDateToParts(date, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    });

    const obj: DateTimePartMap = {
      year: '2024',
      month: '01',
      day: '01',
      hour: '12',
      minute: '00',
      second: '00',
    };

    parts.forEach((part) => {
      const { type, value } = part;
      if (Object.keys(obj).includes(type)) {
        obj[type as keyof DateTimePartMap] = value;
      }
    });
    const withoutSeconds = `${obj.year}-${obj.month}-${obj.day}T${obj.hour}:${obj.minute}`;

    if (withSeconds) {
      return `${withoutSeconds}:${obj.second}`;
    }

    return withoutSeconds;
  };
}

type DateTimeKeys = Pick<
  Intl.DateTimeFormatPartTypesRegistry,
  'year' | 'month' | 'day' | 'hour' | 'minute' | 'second'
>;

type DateTimePartMap = {
  [key in keyof DateTimeKeys]: string;
};
