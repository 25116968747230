import React, { KeyboardEvent } from 'react';
import { useIntl } from 'react-intl';
import { Input } from '@biss/react-horizon-web';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { YAxisRangeProps } from './y-axis-range-controls.definitions';
import { YAxisRange, yAxisRangeSchema } from './y-axis-range-controls.validation';

function YAxisRangeControls({
  dataTrackType,
  yAxisRange,
  disabled,
  engineeringUnit,
  onYAxisRangeChange,
}: YAxisRangeProps): React.FunctionComponentElement<YAxisRangeProps> {
  const intl = useIntl();

  type Inputs = YAxisRange;
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({
    defaultValues: yAxisRange,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    resolver: zodResolver(yAxisRangeSchema),
  });

  const onYAxisRange = (range: YAxisRange) => {
    onYAxisRangeChange(range, dataTrackType);
  };

  const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && e.code === 'Enter') {
      e.currentTarget.blur();
    }
  };

  return (
    <form onBlur={handleSubmit(onYAxisRange)}>
      <div className="mb-2 grid grid-cols-2 gap-x-2" key={dataTrackType}>
        <Controller
          control={control}
          name="min"
          render={({ field }) => (
            <Input
              // eslint-disable-next-line react/jsx-props-no-spreading -- component controlled by <Controller />
              {...field}
              label={intl.formatMessage(
                {
                  defaultMessage: '{dataTrackType} Min {engineeringUnit}',
                  description: 'Overview configuration Y Axis Range: Min value',
                  id: 'SqHVme',
                },
                { dataTrackType, engineeringUnit },
              )}
              type="number"
              data-testid="y-axis-range-min"
              expand="auto"
              value={field.value ?? ''}
              error={errors.min?.message}
              disabled={disabled}
              onKeyDown={handleOnKeyDown}
            />
          )}
        />

        <Controller
          control={control}
          name="max"
          render={({ field }) => (
            <Input
              // eslint-disable-next-line react/jsx-props-no-spreading -- component controlled by <Controller />
              {...field}
              label={intl.formatMessage(
                {
                  defaultMessage: '{dataTrackType} Max {engineeringUnit}',
                  description: 'Overview configuration Y Axis Range: Max value',
                  id: 'mHV+79',
                },
                { dataTrackType, engineeringUnit },
              )}
              type="number"
              data-testid="y-axis-range-max"
              expand="auto"
              value={field.value ?? ''}
              error={errors.max?.message}
              disabled={disabled}
              onKeyDown={handleOnKeyDown}
            />
          )}
        />
      </div>
    </form>
  );
}

export default YAxisRangeControls;
