import { useIntl } from 'react-intl';

function useFormatNumber(): (value: number, fractionalDigits: number) => string {
  const intl = useIntl();

  return (value: number, fractionalDigits: number) =>
    intl.formatNumber(value, {
      maximumFractionDigits: fractionalDigits,
      minimumFractionDigits: fractionalDigits,
    });
}

export default useFormatNumber;
