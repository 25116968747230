import React from 'react';
import { Route } from 'react-router-dom';
import { useAuthContext, isEppendorfAdministrator } from '@biss/react-auth-web';

import NewOrganization from '../../scenes/new-organization';
import Protected from '../../../../shared/components/protected';
import Organizations from '../../scenes/organizations/organizations';
import { Title } from '../../../../shared/components/title';
import generateTitle from '../../../../shared/utils/generate-title';
import OrganizationDetails from '../../scenes/organization-details/organization-details';

function useOrganizationRoutes(): React.ReactElement | null {
  const { account } = useAuthContext();
  const organizationManagementEnabled = account === null || isEppendorfAdministrator(account);
  const section = 'Organization Management';
  return organizationManagementEnabled ? (
    <Route
      path="organizations"
      element={
        <Protected>
          <Title text={generateTitle(section, 'Organizations')}>
            <Organizations />
          </Title>
        </Protected>
      }
    >
      <Route
        path="new"
        element={
          <Title text={generateTitle(section, 'New Organization')}>
            <NewOrganization />
          </Title>
        }
      />
      <Route
        path=":id"
        element={
          <Title text={generateTitle(section, 'Organization Details')}>
            <OrganizationDetails />
          </Title>
        }
      />
    </Route>
  ) : null;
}

export default useOrganizationRoutes;
