import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import {
  DataTrack,
  ProcessRecordId,
  StructuredProcessRecordObject,
} from '../../../../shared/common/types/process-record';
import QKey from '../../../../shared/common/hooks/keys';
import useClient from '../../../../shared/common/hooks/use-client';
import { PrsException } from '../../../../shared/common/types/generated/prs.generated';

function useSubmitDataTracks(
  processRecordId: ProcessRecordId,
  {
    onSuccess,
    ...options
  }: UseMutationOptions<DataTrack[], PrsException, DataTrack[], unknown> = {},
) {
  const { acquireAccessToken } = useAuthentication();
  const { prsClient } = useClient();
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    async mutationFn(dataTracks: DataTrack[]) {
      // fetch
      prsClient.authorize(await acquireAccessToken());
      return prsClient.uploadDataTracksById(dataTracks, processRecordId);
    },
    mutationKey: [QKey.EXTERNAL_DATA_TRACK, processRecordId],
    onSuccess(data, variables, context) {
      // update cache
      queryClient.setQueryData<StructuredProcessRecordObject>(
        [QKey.PROCESS_RECORDS, processRecordId],
        (prev) => {
          if (!prev) {
            return undefined;
          }

          return {
            ...prev,
            dataTracks: [...prev.dataTracks, ...data],
          };
        },
      );

      data.forEach((dataTrack) => {
        queryClient.setQueryData<DataTrack>(
          [QKey.ANALYTICS_DATA_TRACK, processRecordId, dataTrack.dataTrackId],
          () => dataTrack,
        );
      });

      onSuccess?.(data, variables, context);
    },
  });
}

export default useSubmitDataTracks;
