import React, { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';

import { useIntl } from 'react-intl';

import EditableDataTable from '../../editable-data-table/editable-data-table';
import { DateCell, TextCell } from '../../editable-data-table/editable-data-table-cell';
import { EditableTableData } from '../../editable-data-table/editable-data-table.definitions';

import { ModalContentProps } from './modal-content.definitions';

function ModalContent({
  dataPoints,
  dataTrack,
  errorMessage,
  timestampDuplicates,
  savedRows,
  addDataPoint,
  deleteDataPoint,
  deleteSelectedRow,
  updateTableData,
}: ModalContentProps) {
  const intl = useIntl();

  // table columns configuration
  const columns = useMemo<ColumnDef<EditableTableData>[]>(
    () => [
      {
        accessorKey: 'ts',
        header: intl.formatMessage({
          defaultMessage: 'Time Point',
          description: 'Add data points table: Time Point column header',
          id: 'iDtkGo',
        }),
        cell: DateCell,
        meta: { disabled: savedRows, error: timestampDuplicates },
      },

      {
        accessorKey: 'v',
        header: `${dataTrack.dataTrackType} ${
          dataTrack.engineeringUnit && `(${dataTrack.engineeringUnit})`
        }`,
        cell: TextCell,
        meta: { disabled: savedRows, type: 'number' },
      },
    ],
    [timestampDuplicates],
  );

  return (
    <div className="max-h-[70vh] overflow-auto">
      <EditableDataTable
        disabledRows={savedRows}
        showDeleteButton={savedRows.length < dataPoints.length}
        columns={columns}
        updateTableData={updateTableData}
        data={dataPoints}
        addRow={addDataPoint}
        deleteRow={deleteDataPoint}
        deleteSelectedRow={deleteSelectedRow}
        errorMessage={errorMessage}
      />
    </div>
  );
}

export default ModalContent;
