import React from 'react';
import { Modal } from '@biss/react-horizon-web';
import { FormattedMessage } from 'react-intl';

import { ConfirmActionProps } from './confirm-action.definitions';

function ConfirmAction({
  trigger,
  defaultOpen,
  confirmButtonText,
  title,
  content,
  onOpenChange,
  onConfirm,
}: ConfirmActionProps) {
  const [open, setOpen] = React.useState(defaultOpen);

  return (
    <Modal
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={(isOpen: boolean) => {
        setOpen(isOpen);
        onOpenChange?.(isOpen);
      }}
      trigger={trigger}
      title={title}
    >
      <Modal.Content>{content}</Modal.Content>
      <Modal.ButtonGroup>
        <Modal.Close asChild role="button">
          <Modal.Button data-testid="cancel-action-button">
            <FormattedMessage
              description="Confirm Action Modal: label for cancel button"
              defaultMessage="Cancel"
              id="t0IQq9"
            />
          </Modal.Button>
        </Modal.Close>
        <Modal.Button variant="destructive" onClick={onConfirm} data-testid="confirm-action-button">
          {confirmButtonText}
        </Modal.Button>
      </Modal.ButtonGroup>
    </Modal>
  );
}

export default ConfirmAction;
